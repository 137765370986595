import { itemIF, TShorthand } from "../../data/types/item";
import { getMetaFromSnippet } from "../snippets/addAndGetMetas";
import { itemTypes } from "../meta/getMetas";
import { getItemValuesAsObj } from "./getItemValuesAsObject";
import { getItemName } from "./getItemName";
import { snippetIF } from "../../data/types/snippets";

export const renderSingleItem = ({
  itemType,
  item,
  snippet,
}: {
  itemType: itemTypes;
  item: itemIF;
  snippet: snippetIF;
}) => {
  const sh = {} as TShorthand;

  const meta = getMetaFromSnippet({
    snippet,
    itemType,
    itemName: item.name,
  });
  if (!meta) {
    return;
  }

  const propName = getItemName({
    item,
    meta,
  });

  if (!propName) {
    return;
  }
  if (!sh[propName]) {
    sh[propName] = [] as string[];
  }

  const attr = getItemValuesAsObj({
    itemType,
    item,
    snippet,
  });

  if(!attr) {
    return
  };

  let itemValueStr = "";
  Object.keys(attr).forEach((key) => {
    const space = itemValueStr === "" ? "" : " ";
    itemValueStr += `${space}${attr[key]}`;
  });

  if(itemValueStr === "") {
    return
  }

  if (sh[propName].length === 0) {
    sh[propName].push(itemValueStr);
  } else {
    sh[propName].push(
      `${meta.addMultipleTimes ? "ADDMULTIPLETIMES" : " "}${itemValueStr}`
    );
  }

  return sh;
};
