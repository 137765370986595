import { metaIF, EMetaFieldTypes } from "../../types/item";
import { standardUnits } from "../../css/constants/utils";

const inOptions = [
  {
    id: "SourceGraphic",
  },
  {
    id: "SourceAlpha",
  },
  {
    id: "BackgroundImage",
  },
  {
    id: "BackgroundAlpha",
  },
  {
    id: "FillPaint",
  },
  {
    id: "StrokePaint",
  },
];

export const inField = {
  name: "in",
  type: EMetaFieldTypes.select,
  options: inOptions,
  addResultsToOptions: true,
  help:
    "### in  \nSelect here if the current effect will be applied on a part of the referenced element (SVG or HTML) or on top of a preceding filter effect.  \n" +
    "You see in the list, apart from the default inputs, the result text of all filter effects of the current filter.  \n" +
    "For more information see for example [MDN - in](https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/in)",
};
export const in2Field = {
  name: "in2",
  type: EMetaFieldTypes.select,
  options: inOptions,
  addResultsToOptions: true,
  onOff: true,
};

const filterHelp =
  "### Assign Path Id  \n" +
  "You have a shape, for example a `circle`, and within it a `animateMotion` with a `duration`, `repeat count` and optional a `path`.  \n" +
  "If you don't want to define the `path` of the `circle` within `animateMotion`, add a `mpath` element as child of `animateMotion` and assign to its `href` attribute the id of any path within the current SVG.  \n" +
  "The `circle` then goes along the `path` with the `id`. If there is a `path` defined in `animateMotion`, this `path` is overwritten by the `path` assigned by `id`.";

export const SVG_CONSTANTS_FILTER: metaIF[] = [
  {
    name: "filter",
    level: "block",
    category: ["FILTER"],
    help: filterHelp,
    allowedChildren: [
      "title",
      "feBlend",
      "feFlood",
      "feColorMatrix",
      "feComposite",
      "feConvolveMatrix",
      "feGaussianBlur",
      "feImage",
      "feMerge",
      "feMorphology",
      "feOffset",
      "feDiffuseLighting",
      "feSpecularLighting",
      "feTile",
      "feTurbulence",
      "feDisplacementMap",
    ],
    fieldsOrder: ["x", "y", "width", "height"],
    fields: [
      {
        name: "x",
        type: EMetaFieldTypes.number,
        units: standardUnits,
      },
      {
        name: "y",
        type: EMetaFieldTypes.number,
        units: standardUnits,
      },
      {
        name: "width",
        type: EMetaFieldTypes.number,
        units: standardUnits,
      },
      {
        name: "height",
        type: EMetaFieldTypes.number,
        units: standardUnits,
      },
    ],
    group: [
      {
        name: "xyG",
        display: ["x", "y"],
        fields: ["x", "y"],
      },
      {
        name: "widthHeightG",
        display: ["width", "height"],
        fields: ["width", "height"],
      },
    ],
    default: {
      x: {
        value: -10,
        unit: "%",
      },
      y: {
        value: -10,
        unit: "%",
      },
      width: {
        value: 120,
        unit: "%",
      },
      height: {
        value: 120,
        unit: "%",
      },
    },
  },
  {
    name: "feBlend",
    level: "inline",
    category: ["FILTER"],
    allowedParent: ["filter"],
    fieldsOrder: ["in", "in2", "mode"],
    fields: [
      {
        name: "mode",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "normal",
          },
          {
            id: "multiply",
          },
          {
            id: "screen",
          },
          {
            id: "overlay",
          },
          {
            id: "darken",
          },
          {
            id: "lighten",
          },
          {
            id: "color-dodge",
          },
          {
            id: "color-burn",
          },
          {
            id: "hard-light",
          },
          {
            id: "soft-light",
          },
          {
            id: "difference",
          },
          {
            id: "exclusion",
          },
          {
            id: "hue",
          },
          {
            id: "saturation",
          },
          {
            id: "color",
          },
          {
            id: "luminosity",
          },
        ],
      },

      inField,
      in2Field,
    ],
    group: [
      {
        name: "mode",
        fields: ["mode"],
        help:
          "### feBlend  \n" +
          "See for example [MDN - feBlend](https://developer.mozilla.org/en-US/docs/Web/SVG/Element/feBlend)",
      },
      {
        name: "in",
        fields: ["in"],
      },
      {
        name: "in2",
        fields: ["in2"],
      },
    ],

    default: {
      in: {
        value: "SourceGraphic",
      },
      in2: {
        value: "SourceGraphic",
      },
      mode: {
        value: "normal",
      },
    },
  },
  {
    name: "feColorMatrix",
    level: "inline",
    category: ["FILTER"],
    allowedParent: ["filter"],
    fieldsOrder: [
      "in",
      "type",
      "matrix",
      "saturate",
      "hueRotate",
      "values",
      "result",
    ],
    fields: [
      inField,
      {
        name: "type",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "matrix",
            help:
              "### feColorMatrix matrix  \n" +
              "Enter in the field `values` a colour matrix.  \n" +
              "For explanations see for example [A List Apart - Finessing `feColorMatrix`](https://alistapart.com/article/finessing-fecolormatrix/)",
          },
          {
            id: "saturate",
            help:
              "### feColorMatrix saturate  \n" +
              "Enter in the field `values` a value greater or equal 0.  \n" +
              "0 to 1 means the image is under-saturated (deprived of colour), 1 is the original saturation and a value greater than 1 over-saturated the image (moves the different shades of a colour towards its pure value).",
          },
          {
            id: "hueRotate",
            help:
              "### feColorMatrix hueRotate  \n" +
              "Enter in the field `values` a value from 0 to 360 and replace so the colours of the image with the next colour in the colour wheel. Depending on the colour to start with, the sequence of the colours (with a step with of 30) is as follows: blue - purple - red - yellow - green - turquoise - blue.",
          },
        ],
      },
      {
        name: "matrix",
        type: EMetaFieldTypes.colourPicker,
        javascript: "values",
        dependentOn: {
          field: "type",
          values: ["matrix"],
        },
        onOff: true,
        wrapperFn: (valueObj) => {
          const r = valueObj.value.r;
          const g = valueObj.value.g;
          const b = valueObj.value.b;
          const a = valueObj.value.a;
          if (
            (r || r === 0) &&
            (g || g === 0) &&
            (b || b === 0) &&
            (a || a === 0)
          ) {
            return `${r} 0 0 0 0, 0 ${g} 0 0 0, 0 0 ${b} 0 0, 0 0 0 ${a} 0`;
          }
          return "";
        },
      },
      {
        name: "saturate",
        type: EMetaFieldTypes.number,
        javascript: "values",
        dependentOn: {
          field: "type",
          values: ["saturate"],
        },
        onOff: true,
      },
      {
        name: "hueRotate",
        type: EMetaFieldTypes.number,
        javascript: "values",
        dependentOn: {
          field: "type",
          values: ["hueRotate"],
        },
        onOff: true,
      },
      {
        name: "result",
        type: EMetaFieldTypes.string,
      },
    ],
    group: [
      {
        name: "inG",
        display: ["in"],
        fields: ["in"],
      },
      {
        name: "typeG",
        display: ["type"],
        fields: ["type"],
      },
      {
        name: "valuesG",
        display: ["matrix", "saturate", "hueRotate"],
        fields: ["matrix", "saturate", "hueRotate"],
      },
      {
        name: "resultG",
        display: ["result"],
        fields: ["result"],
      },
    ],
    default: {
      in: {
        value: "SourceGraphic",
      },
      type: {
        value: "matrix",
      },
      matrix: {
        value: { r: 0, g: 0, b: 0, a: 1 },
      },
      saturate: {
        value: 0.2,
      },
      hueRotate: {
        value: 180,
      },
      result: {
        value: "COLORMATRIX_10",
      },
    },
  },

  {
    name: "feComposite",
    level: "inline",
    category: ["FILTER"],
    allowedParent: ["filter"],
    fieldsOrder: ["operator", "k1", "k2", "k3", "k4", "in", "in2", "result"],
    fields: [
      {
        name: "operator",
        type: EMetaFieldTypes.select,
        options: [
          {
            id: "over",
          },
          {
            id: "in",
          },
          {
            id: "out",
          },
          {
            id: "atop",
          },
          {
            id: "xor",
          },
          {
            id: "lighter",
          },
          {
            id: "arithmetic",
          },
        ],
        onOff: true,
        help: "### feComposite  \nSee for example [MDN - feComposite](https://developer.mozilla.org/en-US/docs/Web/SVG/Element/feComposite)",
      },
      {
        name: "k1",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "operator",
          values: ["arithmetic"],
          dontDisableDependentOnField: true,
        },
      },
      {
        name: "k2",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "operator",
          values: ["arithmetic"],
          dontDisableDependentOnField: true,
        },
      },
      {
        name: "k3",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "operator",
          values: ["arithmetic"],
          dontDisableDependentOnField: true,
        },
      },
      {
        name: "k4",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "operator",
          values: ["arithmetic"],
          dontDisableDependentOnField: true,
        },
      },
      inField,
      in2Field,
      {
        name: "result",
        type: EMetaFieldTypes.string,
      },
    ],
    group: [
      {
        name: "operator",
        fields: ["operator"],
      },
      {
        name: "k1",
        fields: ["k1"],
      },
      {
        name: "k2",
        fields: ["k2"],
      },
      {
        name: "k3",
        fields: ["k3"],
      },
      {
        name: "k4",
        fields: ["k4"],
      },
      {
        name: "in",
        fields: ["in"],
      },
      {
        name: "in2",
        fields: ["in2"],
      },
      {
        name: "result",
        fields: ["result"],
      },
    ],
    default: {
      operator: {
        value: "in",
      },
      k1: {
        value: "0",
      },
      k2: {
        value: "1",
      },
      k3: {
        value: "1",
      },
      k4: {
        value: "0",
      },
      in: {
        value: "SourceGraphic",
      },
      in2: {
        value: "SourceGraphic",
      },
      result: {
        value: "COMPOSITE_10",
      },
    },
  },

  {
    name: "feConvolveMatrix",
    level: "inline",
    category: ["FILTER"],
    allowedParent: ["filter"],
    fieldsOrder: ["order", "kernelMatrix", "in", "result"],
    fields: [
      {
        name: "order",
        type: EMetaFieldTypes.string,
        onOff: true,
        help:
          "### feConvolveMatrix  \n" +
          "Define a pixel raster (a kernel matrix) that alters a pixel according to the values of its neighbouring pixels. This way, it becomes possible for you to create your own filter effects, such as a blur or a sharpening filter, or to create an extrusion.  \n" +
          "See for example [smashingmagazine - The Art Of SVG Filters And Why It Is Awesome - CREATING AN EXTRUSION](https://www.smashingmagazine.com/2015/05/why-the-svg-filter-is-awesome/).",
      },
      {
        name: "kernelMatrix",
        type: EMetaFieldTypes.textarea,
        onOff: true,
      },
      {
        name: "divisor",
        type: EMetaFieldTypes.number,
      },
      inField,
      {
        name: "result",
        type: EMetaFieldTypes.string,
      },
    ],
    group: [
      {
        name: "order",
        fields: ["order"],
      },
      {
        name: "kernelMatrix",
        fields: ["kernelMatrix"],
      },
      {
        name: "divisorG",
        display: ["divisor"],
        fields: ["divisor"],
      },
      {
        name: "in",
        fields: ["in"],
      },
      {
        name: "result",
        fields: ["result"],
      },
    ],
    default: {
      order: {
        value: "3,3",
      },
      kernelMatrix: {
        value: "1 0 0\n0 1 0\n0 0 1",
      },
      divisor: { value: 1 },
      in: {
        value: "SourceGraphic",
      },
      result: {
        value: "CONVOLVE_10",
      },
    },
  },

  {
    name: "feDisplacementMap",
    level: "inline",
    category: ["FILTER"],
    allowedParent: ["filter"],
    fieldsOrder: [
      "scale",
      "xChannelSelector",
      "yChannelSelector",
      "in",
      "in2",
      "result",
    ],
    fields: [
      {
        name: "scale",
        type: EMetaFieldTypes.number,
        onOff: true,
        help:
          "### feDisplacementMap  \n" +
          "Uses the pixel values from the image from `in2` to spatially displace the image from in.  \n" +
          "See for example [Conforming Text to Surface Texture with feDisplacementMap](https://tympanus.net/codrops/2019/02/12/svg-filter-effects-conforming-text-to-surface-texture-with-fedisplacementmap/)",
      },
      {
        name: "xChannelSelector",
        type: EMetaFieldTypes.select,
        options: [
          {
            id: "R",
          },
          {
            id: "G",
          },
        ],
      },
      {
        name: "yChannelSelector",
        type: EMetaFieldTypes.select,
        options: [
          {
            id: "R",
          },
          {
            id: "G",
          },
        ],
      },
      inField,
      in2Field,
      {
        name: "result",
        type: EMetaFieldTypes.string,
      },
    ],
    group: [
      {
        name: "scale",
        fields: ["scale"],
      },
      {
        name: "xChannelSelector",
        fields: ["xChannelSelector"],
      },
      {
        name: "yChannelSelector",
        fields: ["yChannelSelector"],
      },

      {
        name: "in",
        fields: ["in"],
      },
      {
        name: "in2",
        fields: ["in2"],
      },
      {
        name: "result",
        fields: ["result"],
      },
    ],

    default: {
      in: {
        value: "SourceGraphic",
      },
      in2: {
        value: "SourceGraphic",
      },
      scale: {
        value: 30,
      },
      xChannelSelector: {
        value: "R",
      },
      yChannelSelector: {
        value: "G",
      },
      result: {
        value: "DISPLACE_10",
      },
    },
  },

  {
    name: "feGaussianBlur",
    level: "inline",
    category: ["FILTER"],
    allowedParent: ["filter"],
    fieldsOrder: ["stdDeviation", "in", "result"],
    fields: [
      {
        name: "stdDeviation",
        type: EMetaFieldTypes.number,
        onOff: true,
        help: "### stdDeviation  \nSee for example [MDN - feGaussianBlur](https://developer.mozilla.org/en-US/docs/Web/SVG/Element/feGaussianBlur)",
      },
      inField,
      {
        name: "result",
        type: EMetaFieldTypes.string,
      },
    ],
    group: [
      {
        name: "stdDeviation",
        fields: ["stdDeviation"],
      },
      {
        name: "in",
        fields: ["in"],
      },
      {
        name: "result",
        fields: ["result"],
      },
    ],

    default: {
      stdDeviation: {
        value: 5,
      },
      in: {
        value: "SourceGraphic",
      },
      result: {
        value: "BLUR_10",
      },
    },
  },
  {
    name: "feImage",
    level: "inline",
    category: ["FILTER"],
    allowedParent: ["filter"],
    fieldsOrder: ["href", "in", "x", "y", "width", "height", "result"],
    fields: [
      {
        name: "href",
        javascript: "xlinkHref",
        type: EMetaFieldTypes.string,
        onOff: true,
      },
      inField,
      {
        name: "x",
        type: EMetaFieldTypes.number,
      },
      {
        name: "y",
        type: EMetaFieldTypes.number,
      },
      {
        name: "width",
        type: EMetaFieldTypes.number,
        units: [{ id: "%", min: 0, max: 100 }, { id: "px" }, { id: "rem" }],
      },
      {
        name: "height",
        type: EMetaFieldTypes.number,
        units: [{ id: "%", min: 0, max: 100 }, { id: "px" }, { id: "rem" }],
      },
      {
        name: "result",
        type: EMetaFieldTypes.string,
      },
    ],
    group: [
      {
        name: "href",
        fields: ["href"],
        help: "### feImage  \nSee for exampel [MDN - feImage](https://developer.mozilla.org/en-US/docs/Web/SVG/Element/feImage)",
      },
      {
        name: "xY",
        display: ["x", "y"],
        fields: ["x", "y"],
      },
      {
        name: "widthHeigth",
        display: ["width", "height"],
        fields: ["width", "height"],
      },
      {
        name: "in",
        fields: ["in"],
      },
      {
        name: "result",
        fields: ["result"],
      },
    ],
    default: {
      href: {
        value:
          "https://cssexy.s3.eu-west-2.amazonaws.com/anja_water_flower_on_head.jpg",
      },
      x: { value: 0 },
      y: { value: 0 },
      width: { value: 200, unit: "px" },
      height: { value: 200, unit: "px" },
      in: {
        value: "SourceGraphic",
      },
      result: { value: "IMAGE_10" },
    },
  },

  {
    name: "feMerge",
    level: "inline",
    allowedParent: ["filter"],
    allowedChildren: ["title", "feMergeNode"],
    category: ["FILTER"],
    fieldsOrder: ["result"],
    fields: [
      {
        name: "result",
        type: EMetaFieldTypes.string,
        onOff: true,
      },
    ],
    group: [
      {
        name: "result",
        fields: ["result"],
        help: "### feMerge  \nSee for example [MDN - feMergeNode](https://developer.mozilla.org/en-US/docs/Web/SVG/Element/feMerge)",
      },
    ],
    default: {
      result: {
        value: "MERGE_10",
      },
    },
  },
  {
    name: "feMergeNode",
    level: "inline",
    allowedParent: ["filter"],
    allowedChildren: ["title"],
    category: ["FILTER"],
    fieldsOrder: ["in"],
    fields: [inField],
    default: {
      in: {
        value: "SourceGraphic",
      },
    },
  },

  {
    name: "feMorphology",
    level: "inline",
    category: ["FILTER"],
    allowedParent: ["filter"],
    fieldsOrder: ["operator", "radiusX", "radiusY", "in", "result"],
    fields: [
      {
        name: "operator",
        type: EMetaFieldTypes.select,
        options: [
          {
            id: "dilate",
          },
          {
            id: "erode",
          },
        ],
        onOff: true,
        help:
          "### feMorphology  \n" +
          "See for example [codrops - SVG Filter Effects: Outline Text with feMorphology](https://tympanus.net/codrops/2019/01/22/svg-filter-effects-outline-text-with-femorphology/).",
      },
      {
        name: "radiusX",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
      {
        name: "radiusY",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
      inField,
      {
        name: "result",
        type: EMetaFieldTypes.string,
      },
    ],
    group: [
      {
        name: "operator",
        fields: ["operator"],
      },
      {
        name: "radius",
        display: ["radius x", "y"],
        fields: ["radiusX", "radiusY"],
      },
      {
        name: "in",
        fields: ["in"],
      },
      {
        name: "result",
        fields: ["result"],
      },
    ],

    wrapperForGroup: [
      {
        name: "feMorphology",
        fields: ["radiusX", "radiusY"],
        wrapperFn: (valueObj) =>
          `${valueObj.radiusX} ${valueObj.radiusY}`,
      },
    ],

    default: {
      operator: {
        value: "dilate",
      },
      radiusX: {
        value: 2,
      },
      radiusY: {
        value: 2,
      },
      in: {
        value: "SourceGraphic",
      },
      result: {
        value: "MORPH_10",
      },
    },
  },
  {
    name: "feOffset",
    level: "inline",
    category: ["FILTER"],
    allowedParent: ["filter"],
    fieldsOrder: ["dx", "dy", "in", "result"],
    fields: [
      {
        name: "dx",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
      {
        name: "dy",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
      inField,
      {
        name: "result",
        type: EMetaFieldTypes.string,
      },
    ],
    group: [
      {
        name: "offsetXY",
        display: ["offset dx", "dy"],
        fields: ["dx", "dy"],
        help:
          "### feOffset  \n" +
          "Creates a copy of the source and offsets it to the left or right (dx) and up or down (dy).  \n" +
          "For a better explanation see for example [MDN - feOffset](https://developer.mozilla.org/en-US/docs/Web/SVG/Element/feOffset)",
      },
      {
        name: "in",
        fields: ["in"],
      },
      {
        name: "result",
        fields: ["result"],
      },
    ],
    default: {
      dx: {
        value: 4,
      },
      dy: {
        value: 4,
      },
      in: {
        value: "SourceGraphic",
      },
      result: {
        value: "OFFSET_10",
      },
    },
  },

  {
    name: "feDiffuseLighting",
    level: "inline",
    category: ["FILTER"],
    allowedParent: ["filter"],
    allowedChildren: ["title", "fePointLight", "feDistantLight", "feSpotLight"],
    fieldsOrder: [
      "lighting-color",
      "surfaceScale",
      "diffuseConstant",
      "in",
      "result",
    ],
    fields: [
      {
        name: "lighting-color",
        javascript: "lightingColor",
        type: EMetaFieldTypes.colourPicker,
      },
      {
        name: "surfaceScale",
        type: EMetaFieldTypes.number,
      },
      {
        name: "diffuseConstant",
        type: EMetaFieldTypes.number,
      },
      inField,
      {
        name: "result",
        type: EMetaFieldTypes.string,
      },
    ],
    group: [
      {
        name: "lighting-color",
        fields: ["lighting-color"],
        help: "### feDiffuseLighting  \nSee for example [MDN - feDiffuseLighting](https://developer.mozilla.org/en-US/docs/Web/SVG/Element/feDiffuseLighting)",
      },

      {
        name: "surfaceScale",
        fields: ["surfaceScale"],
      },

      {
        name: "diffuseConstant",
        fields: ["diffuseConstant"],
      },
      {
        name: "in",
        fields: ["in"],
      },
      {
        name: "result",
        fields: ["result"],
      },
    ],
    default: {
      "lighting-color": { value: { r: 255, g: 255, b: 255, a: 1 } },
      surfaceScale: {
        value: 1,
      },
      diffuseConstant: { value: 1 },
      in: {
        value: "SourceGraphic",
      },
      result: {
        value: "DIFFUSE_10",
      },
    },
  },

  {
    name: "feFlood",
    level: "inline",
    category: ["FILTER"],
    allowedParent: ["filter"],
    fieldsOrder: ["flood-color", "result"],
    fields: [
      {
        name: "flood-color",
        type: EMetaFieldTypes.colourPicker,
        javascript: "floodColor",
        onOff: true,
        help: "### feFlood  \nFills the source with colour. If you want to apply the color just to a certian part of the source, use the `feComposite` filter. See how it is done for example in [smashingmagazine - The Art Of SVG Filters And Why It Is Awesome](https://www.smashingmagazine.com/2015/05/why-the-svg-filter-is-awesome/).",
      },
      {
        name: "result",
        type: EMetaFieldTypes.string,
      },
    ],
    group: [
      {
        name: "flood-color",
        fields: ["flood-color"],
      },
      {
        name: "result",
        fields: ["result"],
      },
    ],

    default: {
      "flood-color": { value: { r: 60, g: 188, b: 195, a: 1 } },
      result: {
        value: "FLOOD_10",
      },
    },
  },

  {
    name: "feSpecularLighting",
    level: "inline",
    category: ["FILTER"],
    allowedParent: ["filter"],
    allowedChildren: ["title", "fePointLight", "feDistantLight", "feSpotLight"],
    fieldsOrder: [
      "lighting-color",
      "specularExponent",
      "specularConstant",
      "surfaceScale",
      "in",
      "result",
    ],
    fields: [
      {
        name: "lighting-color",
        javascript: "lightingColor",
        type: EMetaFieldTypes.colourPicker,
      },
      {
        name: "specularExponent",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
      {
        name: "specularConstant",
        type: EMetaFieldTypes.number,
      },
      {
        name: "surfaceScale",
        type: EMetaFieldTypes.number,
      },
      inField,
      {
        name: "result",
        type: EMetaFieldTypes.string,
      },
    ],
    group: [
      {
        name: "lighting-color",
        fields: ["lighting-color"],
        help: "### feSpecularLighting  \nSee for example this excellent article [CSS-Tricks - A Look at SVG Light Source Filters](https://css-tricks.com/look-svg-light-source-filters/)",
      },
      {
        name: "specularExponent",
        fields: ["specularExponent"],
      },
      {
        name: "specularConstant",
        fields: ["specularConstant"],
      },
      {
        name: "surfaceScale",
        fields: ["surfaceScale"],
      },
      {
        name: "in",
        fields: ["in"],
      },
      {
        name: "result",
        fields: ["result"],
      },
    ],
    default: {
      "lighting-color": { value: { r: 255, g: 255, b: 255, a: 1 } },
      specularExponent: {
        value: 60,
      },
      specularConstant: {
        value: 1,
      },
      surfaceScale: {
        value: 6,
      },
      in: {
        value: "SourceGraphic",
      },
      result: {
        value: "SPEC_10",
      },
    },
  },
  {
    name: "fePointLight",
    level: "inline",
    category: ["FILTER"],
    allowedChildren: ["title"],
    fieldsOrder: ["x", "y", "z"],
    fields: [
      {
        name: "x",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
      {
        name: "y",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
      {
        name: "z",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
    ],
    group: [
      {
        name: "xYZ",
        display: ["x", "y", "z"],
        fields: ["x", "y", "z"],
        help: "### fePointLight  \n[MDN - fePointLight](https://developer.mozilla.org/en-US/docs/Web/SVG/Element/fePointLight)",
      },
    ],
    default: {
      x: {
        value: 50,
      },
      y: {
        value: 100,
      },
      z: {
        value: 200,
      },
    },
  },
  {
    name: "feDistantLight",
    level: "inline",
    category: ["FILTER"],
    allowedChildren: ["title"],
    fieldsOrder: ["azimuth", "elevation"],
    fields: [
      {
        name: "azimuth",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
      {
        name: "elevation",
        type: EMetaFieldTypes.number,
      },
    ],
    group: [
      {
        name: "azimuth",
        fields: ["azimuth"],
        help: "### feDistantLight  \nSee for example [feDistantLight](https://developer.mozilla.org/en-US/docs/Web/SVG/Element/feDistantLight)",
      },
      {
        name: "elevation",
        fields: ["elevation"],
      },
    ],
    default: {
      azimuth: {
        value: 25,
      },
      elevation: {
        value: 40,
      },
    },
  },
  {
    name: "feSpotLight",
    level: "inline",
    category: ["FILTER"],
    allowedChildren: ["title"],
    fieldsOrder: ["x", "y", "z", "limitingConeAngle"],
    fields: [
      {
        name: "x",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
      {
        name: "y",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
      {
        name: "z",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
      {
        name: "limitingConeAngle",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
    ],
    group: [
      {
        name: "xYZ",
        fields: ["x", "y", "z"],
      },
      {
        name: "limitingConeAngle",
        fields: ["limitingConeAngle"],
      },
    ],
    default: {
      x: {
        value: 400,
      },
      y: {
        value: 400,
      },
      z: {
        value: 900,
      },
      limitingConeAngle: {
        value: 9,
      },
    },
  },

  {
    name: "feTile",
    level: "inline",
    category: ["FILTER"],
    allowedParent: ["filter"],
    fieldsOrder: ["in", "x", "y", "width", "height", "result"],
    fields: [
      {
        name: "x",
        type: EMetaFieldTypes.number,
        help:
          "### feTile  \n" +
          "See for example [MDN - feTile](https://developer.mozilla.org/en-US/docs/Web/SVG/Element/feTile)",
      },
      {
        name: "y",
        type: EMetaFieldTypes.number,
      },
      {
        name: "width",
        type: EMetaFieldTypes.number,
      },
      {
        name: "height",
        type: EMetaFieldTypes.number,
      },
      inField,
      {
        name: "result",
        type: EMetaFieldTypes.string,
      },
    ],

    group: [
      {
        name: "in",
        fields: ["in"],
      },
      {
        name: "xY",
        fields: ["x", "y"],
      },
      {
        name: "widthHeight",
        fields: ["width", "height"],
      },
      {
        name: "result",
        fields: ["result"],
      },
    ],

    default: {
      in: {
        value: "SourceGraphic",
      },
      x: {
        value: 50,
      },
      y: {
        value: 50,
      },
      width: {
        value: 100,
      },
      height: {
        value: 100,
      },
      result: {
        value: "TILE_10",
      },
    },
  },

  {
    name: "feTurbulence",
    level: "inline",
    category: ["FILTER"],
    allowedParent: ["filter"],
    allowedChildren: ["title", "animate", "animateMotion", "animateTransform"],
    fieldsOrder: [
      "type",
      "baseFrequencyType",
      "baseFrequencyX",
      "baseFrequencyY",
      "numOctaves",
      "seed",
      "result",
    ],
    fields: [
      {
        name: "type",
        type: EMetaFieldTypes.select,
        options: [
          {
            id: "turbulence",
          },
          {
            id: "fractalNoise",
          },
        ],
        onOff: true,
        help:
          "### feTurbulence type   \n" +
          "Type of noise generated by feTurbulence: `turbulence` (default) or `fractalNoise`." +
          "`fractalNoise` generates a more cloudy and smooth pattern and is a suitable base for creating gas-base textures like clouds. `turbulence` generates more lines that simulate ripples and are thus suitable as a base for liquid textures.  \n" +
          "For same very nice examples what you can do with feTurbulence see [codrops - Creating Texture with feTurbulence](https://tympanus.net/codrops/2019/02/19/svg-filter-effects-creating-texture-with-feturbulence/)",
      },
      {
        name: "baseFrequencyType",
        type: EMetaFieldTypes.select,
        options: [
          {
            id: "value",
          },
          {
            id: "pair",
          },
        ],
        onOff: true,
        help:
          "### baseFrequency  \n" +
          "Affects the size (or scale) and the grain of the generated noise. Lower baseFrequency values (such as 0.001) generate larger patterns, while higher values (0.5+) produce smaller patterns. Values in the 0.02 to 0.2 range are useful starting points for most textures.  \n" +
          "The `baseFrequency` attribute also accepts a value `pair`. When you provide two values, the first will be used for the horizonral and the second one for the vertical base frequency.",
      },
      {
        name: "baseFrequencyX",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
      {
        name: "baseFrequencyY",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "baseFrequencyType",
          values: ["pair"],
        },
      },
      {
        name: "numOctaves",
        type: EMetaFieldTypes.number,
        help:
          "### number of octaves  \n" +
          "The higher the number of octaves, the more detail you can see in the noise it generates",
      },
      {
        name: "seed",
        type: EMetaFieldTypes.number,
      },
      {
        name: "result",
        type: EMetaFieldTypes.string,
      },
    ],
    group: [
      {
        name: "type",
        fields: ["type"],
      },
      {
        name: "baseFrequency",
        fields: ["baseFrequencyType", "baseFrequencyX", "baseFrequencyY"],
        display: ["baseFrequency", "x", "y"],
      },
      {
        name: "numOctaves",
        fields: ["numOctaves"],
      },
      {
        name: "seed",
        fields: ["seed"],
      },
      {
        name: "result",
        fields: ["result"],
      },
    ],

    wrapperForGroup: [
      {
        name: "feTurbulence",
        fields: ["baseFrequencyType", "baseFrequencyX", "baseFrequencyY"],
        wrapperFn: (valueObj) => {
          if (valueObj.baseFrequencyType === "pair") {
            return `${valueObj.baseFrequencyX},${valueObj.baseFrequencyY}`;
          }
          return `${valueObj.baseFrequencyX}`;
        },
      },
    ],

    default: {
      type: {
        value: "turbulence",
      },
      baseFrequencyType: {
        value: "value",
      },
      baseFrequencyX: {
        value: 0.05,
      },
      baseFrequencyY: {
        value: 0.04,
      },
      numOctaves: {
        value: 2,
      },
      seed: {
        value: 0,
      },
      result: {
        value: "TURBU_10",
      },
    },
  },
];
