import { NavLink } from "react-router-dom";
import { useStateContext } from "../../lib/state/state";
import {
  setIsLoading,
  userDataInSettings,
} from "../../lib/reducer/settings/actions";
import { PAGES } from "../../data/settings/constants";
import { selectPage } from "../../lib/reducer/settings/actions";
import { signOutFn } from "../../lib/signinup/signOut";
import { ReactComponent as Ceae } from "../../assets/imgs/ceae.svg";
import { ReactComponent as Css } from "../../assets/imgs/css.svg";
import { ReactComponent as Login } from "../../assets/imgs/login.svg";
import { OpenMenuButtonAndModal } from "./OpenMenu";
import "./cssexyNav.css";

export const NavbarBottom = () => {
  return (
    <footer className="bottom-nav">
      {/* <NavLink
            onClick={() => dispatch(selectPage(PAGES.TERMS))}
            to="/terms"
          >
            Terms
          </NavLink>
          <NavLink
            onClick={() => dispatch(selectPage(PAGES.PRIVACY))}
            to="/privacy"
          >
            Privacy
          </NavLink>
          <a
            href="http://sinfomania.com/"
            target="blank"
            onClick={(e) => e.preventDefault()}
          >
            &copy; Sinfomania Ltd. 2019
          </a> */}
      <div>&copy; Sinfomania Ltd. 2019</div>
    </footer>
  );
};

const MainMenu = () => {
  const {
    state: {
      settings: { selectedPage },
    },
    dispatch,
  } = useStateContext();
  return (
    <ul className="sub-nav-ul">
      {/* <li>
        <NavLink
          className={`sub-nav-link${selectedPage === PAGES.HOME ? " active" : ""}`}
          onClick={() => {
            if (selectedPage !== PAGES.HOME) {
              dispatch(selectPage(PAGES.HOME));
            }
          }}
          to="/"
        >
          Home
        </NavLink>
      </li> */}
      <li>
        <NavLink
          className={`sub-nav-link${selectedPage === PAGES.CODE ? " active" : ""}`}
          onClick={() => {
            if (selectedPage !== PAGES.CODE) {
              dispatch(selectPage(PAGES.CODE));
            }
          }}
          to="/code"
        >
          Editor
        </NavLink>
      </li>
      <li>
        <NavLink
          className={`sub-nav-link${selectedPage === PAGES.GALLERY ? " active" : ""}`}
          onClick={() => {
            if (selectedPage !== PAGES.GALLERY) {
              dispatch(selectPage(PAGES.GALLERY));
            }
          }}
          to="/gallery"
        >
          Gallery
        </NavLink>
      </li>
    </ul>
  );
};

const TopNavInnerMain = () => {
  return (
    <>
      <div className="main">
        <MainMenu />
      </div>
      <div className="main-icon">
        <OpenMenuButtonAndModal icon="menu">
          <MainMenu />
        </OpenMenuButtonAndModal>
      </div>
    </>
  );
};

const AccountMenu = () => {
  const {
    state: {
      settings: { selectedPage },
    },
    dispatch,
  } = useStateContext();

  return (
    <ul className="sub-nav-ul">
      <li>
        <NavLink
          className={`sub-nav-link`}
          onClick={() => {
            if (selectedPage !== PAGES.USER) {
              dispatch(selectPage(PAGES.USER));
            }
          }}
          to="/user"
        >
          Account
        </NavLink>
      </li>
      <li>
        <a
          href="#home"
          className="sub-nav-link"
          onClick={(e) => {
            e.preventDefault();
            const asyncFn = async () => {
              dispatch(setIsLoading(true));
              await signOutFn();
              dispatch(setIsLoading(false));

              dispatch(userDataInSettings({ userData: { email: undefined } }));
              dispatch(selectPage(PAGES.SIGNIN));
            };
            asyncFn();
          }}
        >
          Sign out
        </a>
      </li>
    </ul>
  );
};

const TopNavInnerAccount = () => {
  return (
    <>
      <div className="signed-in">
        <AccountMenu />
      </div>
      <div className="signed-in-icon">
        <OpenMenuButtonAndModal icon="account" direction="right">
          <AccountMenu />
        </OpenMenuButtonAndModal>
      </div>
    </>
  );
};

const SignedOutMenu = () => {
  const {
    state: {
      settings: { selectedPage },
    },
    dispatch,
  } = useStateContext();

  return (
    <ul className="sub-nav-ul">
      <li>
        <NavLink
          className={`sub-nav-link${selectedPage === PAGES.SIGNIN ? " active" : ""} ml-2r`}
          onClick={() => {
            if (selectedPage !== PAGES.SIGNIN) {
              dispatch(selectPage(PAGES.SIGNIN));
            }
          }}
          to="/signin"
        >
          Sign in
        </NavLink>
      </li>
    </ul>
  );
};

const SignedOutIcon = () => {
  const {
    state: {
      settings: { selectedPage },
    },
    dispatch,
  } = useStateContext();

  return (
    <NavLink
      className={`open-sub-menu-btn`}
      onClick={() => {
        if (selectedPage !== PAGES.SIGNIN) {
          dispatch(selectPage(PAGES.SIGNIN));
        }
      }}
      to="/signin"
    >
      <Login />
    </NavLink>
  );
};

const TopNavInnerSignIn = () => {
  return (
    <>
      <div className="signed-out">
        <SignedOutMenu />
      </div>
      <div className="signed-out-icon">
        <SignedOutIcon />
      </div>
    </>
  );
};

const CssexyLogo = () => {
  const { dispatch } = useStateContext();

  return (
    <>
      <div className="cssexy-logo">
        <NavLink
          className="nav-logo"
          onClick={() => dispatch(selectPage(PAGES.HOME))}
          to="/"
        >
          <Ceae />
        </NavLink>
      </div>
      <div className="cssexy-logo-small">
        <NavLink
          className="nav-logo"
          onClick={() => dispatch(selectPage(PAGES.HOME))}
          to="/"
        >
          <Css className="display-none-gt-xs" />
        </NavLink>
      </div>
    </>
  );
};

export const TopNavNew2 = () => {
  const {
    state: {
      settings: { user },
    },
  } = useStateContext();

  return (
    <nav className="top-nav-wrapper">
      <CssexyLogo />
      <TopNavInnerMain />
      {user?.email ? <TopNavInnerAccount /> : <TopNavInnerSignIn />}
    </nav>
  );
};
