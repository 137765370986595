import { snippetIF } from "../../data/types/snippets";
import { getIsAdmin, getUserName } from "../../lib/signinup/handleTokens";
import { getLocalDateAndTime } from "../../lib/dates/format";
import { PreviewWithToolbar } from "../preview/PreviewWithToolbar";
import { SimpleList } from "../atoms/SimpleList";
import { SectionTitle } from "../modules/SectionTitle";
import { GalleryBreadcrumb } from "./Breadcrumbs";
import { CloudSnippetActionBtns } from "./SnippetDetailsActionButtons";

import "./SnippetDetails.css";
import "./Breadcrumb.css";
import { itemTypes } from "../../lib/meta/getMetas";

const mySnippetHelp = `### View, download or delete

You are signed in and are viewing your own snippet. 

If your snippet is not already in your local collection, the Download button is visible. Click on it and the snippet is added to your local collection.

If you want to remove the snippet from the cloud, click on the Delete button. The snippet will be removed, but if you have it additionally in your local collection, it will remain there.
`;

const otherPersonSnippetHelp = `### View or download

You are signed in and are viewing someone else's snippet. 

If you want to add a copy of it to your local collection, click on the Download button.`;

const userIsNotSignedInHelp = `### Sign in to download or delete

To manage your own cloud snippets or download snippets from others, you need to be signed in.

If you are signed in, you can download your own snippets to edit them locally or delete them from the cloud. 

You can also download snippets from others to add them to your local collection.`;

export const SnippetDetails = ({
  snippet,
  setSelectedSnippet,
}: {
  snippet: snippetIF;
  setSelectedSnippet: (selectedSnippet: snippetIF | undefined) => void;
}) => {
  const isAdmin = getIsAdmin();
  const userName = getUserName();
  const isMySnippet = snippet.owner === userName;

  const actionTitle =
    isAdmin && !isMySnippet
      ? "Edit, download or delete cloud snippet (admin)"
      : isMySnippet
      ? "View or delete my cloud snippet"
      : userName
      ? "View or download cloud snippet"
      : "View cloud snippet";

  const snippetDetailData = [
    { key: "Name", value: snippet.name },
    { key: "Description", value: snippet.description },
    { key: "Updated", value: getLocalDateAndTime(snippet.updatedAt) },
    { key: "Owner", value: isMySnippet ? "Me" : snippet.owner },
    {
      key: "Downloaded",
      value: snippet.copyCount ? snippet.copyCount.toString() : "0",
    },
  ] as {
    key: string;
    value: string;
  }[];

  return (
    <div className="container snippet-details-box mt-1r">
      <h2 className="pb-1r">Snippets</h2>

      <GalleryBreadcrumb setSelectedSnippet={setSelectedSnippet} />

      <PreviewWithToolbar snippet={snippet} itemType={itemTypes.BLOCK_HTML} />

      <div className="mt-1r mb-1r">
        <SectionTitle
          label={actionTitle}
          size="large"
          help={
            !userName
              ? userIsNotSignedInHelp
              : isMySnippet
              ? mySnippetHelp
              : otherPersonSnippetHelp
          }
        />
      </div>

      <div className="flex-6633">
        <div className="flex-66">
          <SimpleList data={snippetDetailData} />
        </div>
        <div className="flex-33">
          <CloudSnippetActionBtns
            snippet={snippet}
            setSelectedSnippet={setSelectedSnippet}
          />
        </div>
      </div>
    </div>
  );
};
