import { FC, useState, useRef, useEffect } from "react";
import { QueryGetSnippetsArgs } from "cssexy-be/src/generated/graphql";
import { snippetIF } from "../../data/types/snippets";
import { useFetchSnippets } from "../../lib/snippets/useFetchSnippets";
import { getUserName } from "../../lib/signinup/handleTokens";
import { useStateContext } from "../../lib/state/state";
import { Info } from "../atoms/alert/alert";
import { SnippetDetails } from "./SnippetDetails";
import { SortAndFilterGallery } from "./SortAndFilter/SortAndFilter";
import { GalleryItem } from "./GalleryItem";

import "./Gallery.css";

const defaultSortAndFilterParams: QueryGetSnippetsArgs = {
  sort: { field: "updatedAt", direction: "DESC" },
  filter: { public: true },
  limit: 20,
};

const GalleryItemsLoadOnScroll: FC<{
  sortAndFilterParams: QueryGetSnippetsArgs;
  setSelectedSnippet: (s: snippetIF | undefined) => void;
}> = ({ sortAndFilterParams, setSelectedSnippet }) => {
  const {
    state: {
      settings: { isLoading },
    },
  } = useStateContext();

  const { serverSnippets: snippets, nextToken, loadMore } = useFetchSnippets({
    sortAndFilterParams,
  });

  const sentinelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const firstEntry = entries[0];
      if (firstEntry.isIntersecting && nextToken && !isLoading) {
        loadMore();
      }
    });
    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [nextToken, isLoading, loadMore]);

  const username = getUserName();
  const noSnippetsMsg = isLoading ? "LOADING..." : "NO SNIPPET FOUND.";

  return (
    <>
      {snippets.length === 0 && (
        <div>
          <Info isAlert={true}>{noSnippetsMsg}</Info>
        </div>
      )}

      {snippets.length > 0 && (
        <>
          <div className="flex-center">{`Loaded: ${snippets.length} snippets`}</div>
          {/* <div>
            <ol>
              {snippets.map((s) => {
                return (
                  <li key={s.id}>
                    <div className="flex gap-1">
                      <div>{s.name}</div>
                      <div>{s.public ? "public" : "private"}</div>
                      <div>{getLocalDateAndTime(s.updatedAt)}</div>
                      <div>{s.copyCount}</div>
                    </div>
                  </li>
                );
              })}
            </ol>
          </div> */}
          <div className="gallery-box row">
            {snippets.map((snippet, i) => {
              const displayUsername =
                snippet.owner === username ? "Me" : snippet.owner;
              return (
                <div className="col-12 col-lg-6" key={snippet.id}>
                  <GalleryItem
                    snippet={snippet}
                    setSelectedSnippet={setSelectedSnippet}
                    username={displayUsername}
                  />
                </div>
              );
            })}
          </div>
          <div ref={sentinelRef} style={{ height: "1px" }} />
        </>
      )}
    </>
  );
};

const Gallery: FC = () => {
  const [sortAndFilterParams, setSortAndFilterParams] =
    useState<QueryGetSnippetsArgs>(defaultSortAndFilterParams);
  const [selectedSnippet, setSelectedSnippet] = useState(
    undefined as snippetIF | undefined
  );

  if (selectedSnippet) {
    return (
      <SnippetDetails
        snippet={selectedSnippet}
        setSelectedSnippet={() => {
          setSelectedSnippet(undefined);
        }}
      />
    );
  }

  return (
    <div className="container gallery-container mt-2r">
      <SortAndFilterGallery
        sortAndFilterParams={sortAndFilterParams}
        setSortAndFilterParams={setSortAndFilterParams}
      />
      <GalleryItemsLoadOnScroll
        setSelectedSnippet={setSelectedSnippet}
        sortAndFilterParams={sortAndFilterParams}
      />
    </div>
  );
};

export default Gallery;
