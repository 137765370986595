import { TObject, itemIF } from "../../data/types/item";
import { itemTypes } from "../meta/getMetas";
import { getDisabledField } from "../items/getDisabledFields";
import { addSnippetIdToAttributeValues } from "../items/getIds";
import { getFieldsOrder } from "./getFieldsOrder";
import { snippetIF } from "../../data/types/snippets";
import { getMetaFromSnippet } from "../snippets/addAndGetMetas";
import { renderItemWithGroupWrapperFunction } from "./renderItemWithGroupWrapperFunction";
import { getValuesOfField } from "./getItemValuesOfField";
import {
  setKeyValueOnAttribute,
} from "./getItemValues_helper";
import { getItemValue_replaceValueForFontFamilyImportant } from "./getItemValueAsObject_getAttrName";
import { replaceFieldNameForDependentCanBeFieldsWithMainFieldName } from "./getItemFieldValueString";
import {replaceAttrNameWithGroupNameOrJaveScript} from "./getAttrName";

export const getItemValuesAsObj = ({
  itemType,
  item,
  snippet,
}: {
  itemType: itemTypes;
  item?: itemIF;
  snippet: snippetIF;
}): Record<string, string> | undefined => {
  if (!item || !item.value) {
    return;
  }

  const meta = getMetaFromSnippet({ snippet, itemType, itemName: item.name });
  if (!meta || !meta.fields) {
    console.warn(`No meta for item: ${item.name}`);
    return;
  }

  const assignedCSSVariableName = item.value?.assignedCssVariableName?.value;
  if (assignedCSSVariableName) {
    const attr: Record<string, string> = {};
    attr["assignedCssVariableName"] = `var(--${assignedCSSVariableName})`;
    return attr;
  }

  const disabledFields = getDisabledField({
    itemType,
    item,
    snippet,
  });
  const fieldsOrder = getFieldsOrder({ item, meta, disabledFields });

  const itemSnippetIdAdded = addSnippetIdToAttributeValues({
    item,
    meta,
  });

  const attrTmp: TObject = {};
  for (const fieldName of fieldsOrder) {
    getValuesOfField({
      itemType,
      fieldName,
      snippet,
      attr: attrTmp,
      item: itemSnippetIdAdded,
    });
  }

  const newAttr = replaceFieldNameForDependentCanBeFieldsWithMainFieldName({
    item,
    meta,
    attr: attrTmp,
  });

  const newAttr2 = renderItemWithGroupWrapperFunction({
    meta,
    attr: newAttr,
  });

  const keyValueOnAttributeSet = setKeyValueOnAttribute({
    attr: newAttr2,
    meta,
    item,
  });

  getItemValue_replaceValueForFontFamilyImportant({
    itemType,
    attr: keyValueOnAttributeSet,
    item,
    snippet,
  });

  const attrNamesReplacesWithGroupNamesOrJavaScript = replaceAttrNameWithGroupNameOrJaveScript({
    attr: keyValueOnAttributeSet,
    meta,
  });

  return attrNamesReplacesWithGroupNamesOrJavaScript;
};
