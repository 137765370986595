import { useState, FC } from "react";
import { CustomIframe } from "../atoms/CustomIFrame";
import { PreviewToolbar } from "../navbar/PreviewToolbar";
import { CodePreview } from "./CodePreview";
import { itemTypes } from "../../lib/meta/getMetas";
import { snippetIF } from "../../data/types/snippets";
import { useStateContext } from "../../lib/state/state";
import { SUB_PAGES } from "../../data/settings/constants";
import { useSnippetHtml } from "./useSnippetHtml";
import "./Preview.css";

export const PreviewWithToolbar: FC<{
  itemType: itemTypes;
  snippet: snippetIF;
}> = ({ itemType, snippet }) => {
  const {
    state: {
      settings: { selectedSubPage, previewSnippetId },
      snippet: { snippets },
    },
  } = useStateContext();

  const isSvgPreview = selectedSubPage === SUB_PAGES.SVG;

  const [pHeight, setPHeight] = useState(`320px`);
  const [pWidth, setPWidth] = useState(`100%`);
  const [splitScreenMode, setSplitScreenMode] = useState(false);

  const previewSnippet = previewSnippetId ? snippets.find((s) => s.id === previewSnippetId) : undefined;
  const snippetHtml = useSnippetHtml({
    snippet: previewSnippet ?? snippet,
    isSvgPreview,
    selectedSubPage,
  });
  const { sName, sHtml, sHeadCss, sCss, sFonts } = snippetHtml;

  return (
    <div className="preview-board">
      <PreviewToolbar
        pWidth={pWidth}
        setPWidth={setPWidth}
        pHeight={pHeight}
        setPHeight={setPHeight}
        splitScreenMode={splitScreenMode}
        setSplitScreenMode={setSplitScreenMode}
      />
      <div
        className="code-preview-box"
        style={{
          height: pHeight === "auto" ? undefined : pHeight,
        }}
      >
        <div
          className={`preview-box${
            itemType === itemTypes.SVGS ? " drawing-board" : ""
          }`}
          style={{
            width: splitScreenMode ? "50%" : pWidth,
            height: pHeight,
          }}
        >
          <CustomIframe
            title={`snippet preview for ${sName}`}
            width={splitScreenMode ? "100%" : pWidth}
            height={pHeight}
            className="i-frame"
            headContent={sFonts}
            htmlSelectorCss={sHeadCss}
            scrollable={true}
          >
            <div
              dangerouslySetInnerHTML={{ __html: sHtml }}
              className="top-div"
            />
            <style>{sCss}</style>
          </CustomIframe>
        </div>

        <CodePreview
          htmlStr={`${isSvgPreview ? "" : sCss}${sHtml}`}
          splitScreenMode={splitScreenMode}
        />
      </div>
    </div>
  );
};
