import { metaIF, EMetaFieldTypes } from "../../types/item";

export const SVG_CONSTANTS_SHAPES: metaIF[] = [
  {
    name: "circle",
    level: "block",
    category: ["SHAPE"],
    allowedChildren: ["title", "animate", "animateMotion", "animateTransform"],
    fieldsOrder: ["cx", "cy", "r"],
    fields: [
      {
        name: "cx",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
      {
        name: "cy",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
      {
        name: "r",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
    ],
    group: [
      {
        name: "cx",
        fields: ["cx", "cy"],
      },
      {
        name: "radius",
        fields: ["r"],
        display: ["r"],
      },
    ],

    default: {
      cx: {
        value: 50,
      },
      cy: {
        value: 50,
      },
      r: {
        value: 40,
      },
    },
  },
  {
    name: "ellipse",
    level: "block",
    category: ["SHAPE"],
    allowedChildren: ["title", "animate", "animateMotion", "animateTransform"],
    fieldsOrder: ["cx", "cy", "rx", "ry"],
    fields: [
      {
        name: "cx",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
      {
        name: "cy",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
      {
        name: "rx",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
      {
        name: "ry",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
    ],
    group: [
      {
        name: "cx",
        fields: ["cx", "cy"],
        help: "### ellipse center  \nEnter x and y as center of the ellipse.",
      },
      {
        name: "rx",
        fields: ["rx", "ry"],
        help:
          "### horizontal and vertical radius  \n" +
          "Enter a value for the horizontal (x) and the vertical (y) radius.",
      },
    ],

    default: {
      cx: {
        value: 50,
      },
      cy: {
        value: 50,
      },
      rx: {
        value: 50,
      },
      ry: {
        value: 30,
      },
    },
  },
  {
    name: "line",
    level: "block",
    category: ["SHAPE"],
    allowedChildren: ["title", "animate", "animateMotion", "animateTransform"],
    fieldsOrder: ["x1", "y1", "x2", "y2"],
    fields: [
      {
        name: "x1",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
      {
        name: "y1",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
      {
        name: "x2",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
      {
        name: "y2",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
    ],
    group: [
      {
        name: "x1",
        fields: ["x1", "y1"],
        help:
          "### line  \n" +
          "Draw a `line` of two points `x1` and `y1` to `x2` and `y2`.  \n" +
          "A `line` has no body and as such can't be filled. Apply `stroke` `color` and `width` to make a `line` visible.",
      },
      {
        name: "x2",
        fields: ["x2", "y2"],
      },
    ],

    default: {
      x1: { value: 0 },
      y1: { value: 0 },
      x2: { value: 50 },
      y2: { value: 50 },
    },
  },
  {
    name: "rect",
    level: "block",
    category: ["SHAPE"],
    allowedChildren: ["title", "animate", "animateMotion", "animateTransform"],
    fieldsOrder: ["rx", "ry", "x", "y", "width", "height"],
    fields: [
      {
        name: "x",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
      {
        name: "y",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
      {
        name: "rx",
        type: EMetaFieldTypes.number,
      },
      {
        name: "ry",
        type: EMetaFieldTypes.number,
      },
      {
        name: "width",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
      {
        name: "height",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
    ],

    group: [
      {
        name: "x",
        fields: ["x", "y"],
        help:
          "### rectangle left top  \n" +
          "Enter the x and y value of the left top of the rectangle.",
      },
      {
        name: "rx",
        fields: ["rx", "ry"],
        help:
          "### round corners  \n" +
          "Enter a x value for the horizontal and an y value for vertical rounding of the corners.",
      },
      {
        name: "width",
        fields: ["width"],
      },
      {
        name: "height",
        fields: ["height"],
      },
    ],

    default: {
      x: {
        value: 10,
      },
      y: {
        value: 10,
      },
      rx: {
        value: 0,
      },
      ry: {
        value: 0,
      },
      width: {
        value: 80,
      },
      height: {
        value: 40,
      },
    },
  },
  {
    name: "polygon",
    level: "block",
    category: ["POLYGON"],
    allowedChildren: [
      "title",
      "point",
      "animate",
      "animateMotion",
      "animateTransform",
    ],
    childrenAsAttributes: {
      attributeName: "points",
      childrenName: "point",
      children: ["point"],
      min: 3,
    },
    fieldsOrder: ["points"],
    fields: [
      {
        name: "points",
        type: EMetaFieldTypes.textarea,
        onOff: true,
        help:
          "### polygon  \n" +
          "Draw shapes with at least 3 points. The points are automatically connected with lines and the path is automatically closed.  \n" +
          'Enter in the field `points` of the `polygon` element the points as a string of comma separated coordinates, like "10,10 90,10 50,90", or add at least 3 `point` elements as `polygon` children.',
      },
    ],
    default: {
      points: {
        value: "10,10 90,50 10,90",
      },
    },
  },
  {
    name: "point",
    addMultipleTimes: true,
    level: "inline",
    category: ["POLYGON"],
    allowedParent: ["polygon"],
    allowedSiblings: [
      "title",
      "point",
      "animate",
      "animateMotion",
      "animateTransform",
    ],
    allowedChildren: [],
    addToShorthand: "points",
    fieldSeparator: ",",
    fieldsOrder: ["x", "y"],
    fields: [
      {
        name: "x",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
      {
        name: "y",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
    ],
    group: [
      {
        name: "xy",
        display: ["x", "y"],
        fields: ["x", "y"],
      },
    ],

    wrapperForGroup:[
      {
        name: "point",
        fields: ["x", "y"],
        wrapperFn: (valueObj) => `${valueObj.x},${valueObj.y}`,
      }
    ],

    default: {
      x: { value: 10 },
      y: { value: 10 },
    },
  },
];
