import { actionIF, itemAttributeChangeIF, itemIF } from "../../../data/types/item";
import { itemTypes } from "../../meta/getMetas";
import { IOptions } from "../../../data/types/item";

export const IMPORT_CSS_VARIABLE_FOR_EDIT = "IMPORT_CSS_VARIABLE_FOR_EDIT";
export const importCssVariableForEditSelector = ({
  value,
  editSelectorId,
  editPropId,
}: {
  value: string;
  editSelectorId: number;
  editPropId?: number;
}) => {
  return {
    type: IMPORT_CSS_VARIABLE_FOR_EDIT,
    itemType: itemTypes.CLASSES_CSS,
    value,
    editSelectorId,
    editPropId,
  };
};

export const SET_GOOGLE_FONT_FOR_FONT_FAMILY =
  "SET_GOOGLE_FONT_FOR_FONT_FAMILY";
export const setGoogleFontForFontFamily = (selectedOption: IOptions) => {
  return {
    type: SET_GOOGLE_FONT_FOR_FONT_FAMILY,
    itemType: itemTypes.CLASSES_CSS,
    selectedOption,
  };
};

export const ADD_FIELD_GROUP = "ADD_FIELD_GROUP";
export const addFieldGroup = ({
  fieldName,
  itemType,
  editItemId,
  editSnippetId
}: {
  fieldName: string;
  itemType: itemTypes;
  editItemId?: number;
  editSnippetId?: string;
}) => {
  return {
    type: ADD_FIELD_GROUP,
    itemType,
    fieldName,
    editItemId,
    editSnippetId
  };
};

export const REMOVE_FIELD_GROUP = "REMOVE_FIELD_GROUP";
export const removeFieldGroup = ({
  addOrRemoveEditItemId,
  itemType,
  fieldName,
  fieldGroupId,
  editItemId,
  editSnippetId
}: {
  addOrRemoveEditItemId?: number;
  itemType: itemTypes;
  fieldName: string;
  fieldGroupId: number;
  editItemId?: number;
  editSnippetId?: string;
}) => {
  return {
    type: REMOVE_FIELD_GROUP,
    addOrRemoveEditItemId,
    itemType,
    fieldName,
    fieldGroupId,
    editItemId,
    editSnippetId
  };
};

export const CLONE_ITEM_AND_CHILDREN = "CLONE_ITEM_AND_CHILDREN";
export const cloneItemAndChildren = ({
  itemType,
  snippetFromId,
  itemFromId,
  itemToId,
}: {
  itemType: itemTypes;
  snippetFromId?: string;
  itemFromId?: number;
  itemToId?: number;
}) => {
  return {
    type: CLONE_ITEM_AND_CHILDREN,
    itemType,
    snippetFromId,
    itemFromId,
    itemToId,
  };
};

export const COPY_ITEM_AND_PATH = "COPY_ITEM_AND_PATH";
export const copyItemAndPath = (itemType: itemTypes) => {
  return {
    type: COPY_ITEM_AND_PATH,
    itemType,
  };
};

export const SET_NEW_ITEM_VALUE = "SET_NEW_ITEM_VALUE";
export const setNewItemValue = ({
  itemType,
  fieldName,
  value,
  valueType,
  editItemId,
  fieldGroupName,
  addOrRemoveEditItemId,
  editSnippetId
}: itemAttributeChangeIF) => {
  return {
    type: SET_NEW_ITEM_VALUE,
    itemType,
    fieldName,
    value,
    valueType,
    editItemId,
    fieldGroupName,
    addOrRemoveEditItemId,
    editSnippetId
  };
};

export const MOVE_ITEM_DOWN = "MOVE_ITEM_DOWN";
export const moveItemDown = ({
  itemType,
  classId,
}: {
  itemType: itemTypes;
  classId?: number;
}) => {
  return {
    type: MOVE_ITEM_DOWN,
    itemType,
    classId,
  };
};
export const MOVE_ITEM_LEFT = "MOVE_ITEM_LEFT";
export const moveItemLeft = ({ itemType }: { itemType: itemTypes }) => {
  return {
    type: MOVE_ITEM_LEFT,
    itemType,
  };
};
export const MOVE_ITEM_RIGHT = "MOVE_ITEM_RIGHT";
export const moveItemRightAction = ({ itemType }: { itemType: itemTypes }) => {
  return {
    type: MOVE_ITEM_RIGHT,
    itemType,
  };
};
export const MOVE_ITEM_UP = "MOVE_ITEM_UP";
export const moveItemUp = ({
  itemType,
  classId,
}: {
  itemType: itemTypes;
  classId?: number;
}) => {
  return {
    type: MOVE_ITEM_UP,
    itemType,
    classId,
  };
};

export const MOVE_ITEM_DOWN_CSS = "MOVE_ITEM_DOWN_CSS";
export const moveItemDownCss = (selectorId:number) => {
  return {
    type: MOVE_ITEM_DOWN_CSS,
    itemType: itemTypes.CLASSES_CSS,
    selectorId
  };
};

export const MOVE_ITEM_UP_CSS = "MOVE_ITEM_UP_CSS";
export const moveItemUpCss = (selectorId:number) => {
  return {
    type: MOVE_ITEM_UP_CSS,
    itemType: itemTypes.CLASSES_CSS,
    selectorId
  };
};

export const ADD_REMOVE_CONNECTOR = "ADD_REMOVE_CONNECTOR";
export const addRemoveConnector = ({ name }: { name: string }) => {
  return {
    type: ADD_REMOVE_CONNECTOR,
    itemType: itemTypes.SELECTORS,
    name,
  };
};
export const SET_EDIT_ITEM = "SET_EDIT_ITEM";
export const setEditItemId = ({
  itemType,
  newEditItemId,
  editSnippetId,
}: {
  itemType?: string;
  newEditItemId?: number;
  editSnippetId?: string;
}) => {
  return {
    type: SET_EDIT_ITEM,
    itemType,
    newEditItemId,
    editSnippetId,
  };
};

export const SET_EDIT_CSS_PROPERTY = "SET_EDIT_CSS_PROPERTY";
export const setEditCssProperty = ({
  newEditItemId,
  selectedCategory,
}: {
  newEditItemId: number;
  selectedCategory: string;
}) => ({
  type: SET_EDIT_CSS_PROPERTY,
  itemType: itemTypes.CLASSES_CSS,
  newEditItemId,
  selectedCategory,
});

export const ADD_ITEM = "ADD_ITEM";
export const addItem = ({
  itemType,
  name,
  editSelectorId,
  addAfterItemId,
  editSnippetId
}: {
  itemType: itemTypes;
  name: string;
  editSelectorId?: number;
  addAfterItemId?: number;
  editSnippetId?: string;
}):actionIF => {
  return {
    type: ADD_ITEM,
    itemType,
    name,
    editSelectorId,
    addAfterItemId,
    editSnippetId
  } ;
};

export const COPY_ITEM = "COPY_ITEM";
export const copyItem = ({
  itemType,
  item,
  editSelectorId,
  addAfterItemId,
  editSnippetId
}: {
  itemType: itemTypes;
  item: itemIF;
  editSelectorId?: number;
  addAfterItemId?: number;
  editSnippetId?: string;
}):actionIF => {
  return {
    type: COPY_ITEM,
    itemType,
    item,
    editSelectorId,
    addAfterItemId,
    editSnippetId
  } ;
};


export const REPLACE_ITEM = "REPLACE_ITEM";
export const replaceItem = ({
  item,
  itemType,
}: {
  item: itemIF;
  itemType: itemTypes;
}) => {
  return {
    type: REPLACE_ITEM,
    itemType,
    item,
  };
};

export const REPLACE_ITEMS = "REPLACE_ITEMS";
export const replaceItems = ({
  items,
  itemType,
}: {
  items: itemIF[];
  itemType: itemTypes;
}) => ({
  type: REPLACE_ITEMS,
  itemType,
  items,
});

export const ITEM_IN_TREE_DELETE = "ITEM_IN_TREE_DELETE";
export const itemInTreeDelete = ({
  itemType,
  editItemId,
  snippetId,
  editSnippetId
}: {
  itemType: string;
  editItemId?: number;
  snippetId?: string;
  editSnippetId?: string;
}) => ({
  type: ITEM_IN_TREE_DELETE,
  itemType,
  editItemId,
  snippetId,
  editSnippetId
});

export const ITEM_IN_TREE_DELETE_CSS = "ITEM_IN_TREE_DELETE_CSS";
export const itemInTreeDeleteCss = () => ({
  type: ITEM_IN_TREE_DELETE_CSS,
  itemType: itemTypes.CLASSES_CSS,
});

export const ASSIGN_CSS_VARIABLE = "ASSIGN_CSS_VARIABLE";
export const assignCssVariable = ({
  variableId,
  cssVariableName,
  editSnippetId
}: {
  variableId: number;
  cssVariableName: string;
  editSnippetId?: string;
}) => {
  return {
    type: ASSIGN_CSS_VARIABLE,
    itemType: itemTypes.CLASSES_CSS,
    variableId,
    cssVariableName,
    editSnippetId
  };
};

export const ITEM_ATTRIBUTE_CHANGE = "ITEM_ATTRIBUTE_CHANGE";
export const itemAttributeChange = ({
  itemType,
  fieldName,
  value,
  valueType,
  editItemId,
  fieldGroupName,
}: itemAttributeChangeIF) => {
  return {
    type: ITEM_ATTRIBUTE_CHANGE,
    itemType,
    fieldName,
    value,
    valueType,
    editItemId,
    fieldGroupName,
  };
};

export const ITEM_EVENT_CHANGE = "ITEM_EVENT_CHANGE";
export const itemEventChange = ({
  itemType,
  fieldName,
  value,
  editEventId,
}: itemAttributeChangeIF) => {
  return {
    type: ITEM_EVENT_CHANGE,
    itemType,
    fieldName,
    editEventId,
    value,
  };
};

export const SELECT_HTML_SVG = "SELECT_HTML_SVG";
export const selectHtmlSvg = ({
  itemType,
  fieldName,
  value,
}: itemAttributeChangeIF) => {
  return {
    type: SELECT_HTML_SVG,
    itemType,
    fieldName,
    value,
  };
};
export const COPY_SVG_FROM_OTHER_SNIPPET = "COPY_SVG_FROM_OTHER_SNIPPET";
export const copySvgFromOtherSnippet = ({
  snippetFromId,
  itemFromId,
  itemToId,
}: {
  snippetFromId: string;
  itemFromId: number;
  itemToId?: number;
}) => {
  return {
    type: COPY_SVG_FROM_OTHER_SNIPPET,
    itemType: itemTypes.SVGS,
    snippetFromId,
    itemFromId,
    itemToId,
  };
};

export const REMOVE_HTML_EVENT = "REMOVE_HTML_EVENT";
export const removeHtmlEvent = ({
  itemType,
  editEventId,
}: {
  itemType: string;
  editEventId: number;
}) => ({
  type: REMOVE_HTML_EVENT,
  itemType,
  editEventId,
});

export const ADD_EVENT_TO_HTML = "ADD_EVENT_TO_HTML";
export const addEventToHtml = (itemType: string) => ({
  type: ADD_EVENT_TO_HTML,
  itemType,
});

export const ASSIGN_SVG_TO_HTML = "ASSIGN_SVG_TO_HTML";
export const assignSvgToHtml = ({
  itemType,
  svgId,
}: {
  itemType: string;
  svgId?: number;
}) => ({
  type: ASSIGN_SVG_TO_HTML,
  itemType,
  svgId,
});

export const ASSIGN_CLASS_TO_HTML_OR_SVG = "ASSIGN_CLASS_TO_HTML_OR_SVG";

export const assignClassToHtmlOrSvg = ({
  itemType,
  classIds,
  editItemId,
  editSnippetId,
}: {
  itemType: string;
  classIds: number[];
  editItemId?: number;
  editSnippetId?: string;
}) => ({
  type: ASSIGN_CLASS_TO_HTML_OR_SVG,
  itemType,
  classIds,
  editItemId,
  editSnippetId,
});

export const UNASSIGN_CLASS_TO_HTML_OR_SVG = "UNASSIGN_CLASS_TO_HTML_OR_SVG";

export const unassignClassToHtmlOrSvg = ({
  itemType,
  classIds,
  editItemId,
  editSnippetId,
}: {
  itemType: string;
  classIds: number[];
  editItemId?: number;
  editSnippetId?: string;
}) => ({
  type: UNASSIGN_CLASS_TO_HTML_OR_SVG,
  itemType,
  classIds,
  editItemId,
  editSnippetId,
});
