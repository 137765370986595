import { FC } from "react";
import { itemIF } from "../../data/types/item";
import { snippetIF } from "../../data/types/snippets";
import { IOptions } from "../../data/types/item";
import { useStateContext } from "../../lib/state/state";
import { setNewItemValue } from "../../lib/reducer/items/actions";
import { itemTypes } from "../../lib/meta/getMetas";
import { getOptions } from "../../lib/items/helper";
import { getMetaFromSnippet } from "../../lib/snippets/addAndGetMetas";

export const EditFieldSelectSimple: FC<{
  options: IOptions[];
  fieldValue: string;
  isFieldDisabled?: boolean;
  setValue: (value: string) => void;
}> = ({ options, fieldValue, isFieldDisabled, setValue }) => {
  return (
    <>
      <select
        title="select"
        value={fieldValue}
        disabled={isFieldDisabled}
        onChange={(e) => {
          const optionId = e.target.value;
          setValue(optionId);
        }}
      >
        {options.map((option, i) => (
          <option key={i} value={option.id}>
            {option.id}
          </option>
        ))}
      </select>
    </>
  );
};

type TEditFieldSelect = {
  itemType: itemTypes;
  fieldName: string;
  options?: IOptions[];
  isFieldDisabled?: boolean;

  addOrRemoveEditItemId?: number;
  editItem: itemIF;
  fieldGroupName?: string;
  editSnippetIdOther?: string;

  snippet: snippetIF;
};

export const EditFieldSelect: FC<TEditFieldSelect> = ({
  itemType,
  fieldName,
  isFieldDisabled,
  editItem,
  snippet,
  addOrRemoveEditItemId,
  fieldGroupName,
  editSnippetIdOther,
}) => {
  const { dispatch } = useStateContext();

  const fieldValue = editItem.value?.[fieldName]?.value;

  const meta = getMetaFromSnippet({
    snippet,
    itemType,
    itemName: editItem.name,
  });
  const fieldMeta = meta?.fields?.find((f) => f?.name === fieldName);

  const options = fieldMeta
    ? getOptions({
        fieldMeta,
        snippet,
        editItemId: editItem?.id!,
      })
    : [];

  return (
    <EditFieldSelectSimple
      options={options}
      fieldValue={fieldValue}
      isFieldDisabled={isFieldDisabled}
      setValue={(value) => {
        dispatch(
          setNewItemValue({
            addOrRemoveEditItemId,
            editItemId: editItem.id,
            fieldGroupName,
            fieldName,
            itemType,
            value,
            editSnippetId: editSnippetIdOther,
          })
        );
      }}
    />
  );
};
