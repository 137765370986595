import { itemIF } from "../../../data/types/item";
import { snippetIF } from "../../../data/types/snippets";
import { getMeta, itemTypes } from "../../meta/getMetas";
import { getItemsOfType } from "../../snippets/addAndGetMetas";

export const addFieldGroup = ({
  itemType,
  itemId,
  fieldName,
  snippet,
}: {
  itemType?: itemTypes;
  itemId: number;
  fieldName?: string;
  snippet: snippetIF;
}) => {


  if (!itemType) {
    console.warn("addFieldGroup: no itemType");
    return;
  }
  if (!fieldName) {
    console.warn("addFieldGroup: no fieldName");
    return;
  }
  const items = getItemsOfType({
    itemType,
    snippet,
  });
  const editItem = items.find((i) => i.id === itemId);
  if (!editItem) {
    console.warn("addFieldGroup: no editItem");
    return;
  }
  try {
    const fieldValue: { value: itemIF[] } = editItem.value?.[fieldName] ?? {
      value: [],
    };
    const meta = getMeta(itemType, fieldName);
    if (
      !meta ||
      !meta.category.includes("addOrRemoveFieldGroup") ||
      !meta.default
    ) {
      return;
    }

    const newId = Math.max(...fieldValue.value.map((v) => v.id), 0) + 1;
    const newPos = Math.max(...fieldValue.value.map((v) => v.position), 0) + 1;
    const newItem = {
      id: newId,
      name: fieldName,
      position: newPos,
      value: meta.default,
    };
    const newIitem = {
      ...editItem,
      value: {
        ...editItem.value,
        [fieldName]: {
          ...fieldValue,
          value: [...fieldValue.value, newItem],
        },
      },
    };
    return newIitem;
  } catch (e) {
    console.error(e);
  }
};

export const removeFieldGroup = ({
  items,
  editItemId,
  fieldName,
  fieldGroupId,
}: {
  items: itemIF[];
  editItemId: number;
  fieldName: string;
  fieldGroupId: number;
}): { items: itemIF[]; editItemId: number } => {
  const rfgEditItem = items.find((i) => i.id === editItemId);
  if (!rfgEditItem) {
    console.warn("REMOVE_FIELD_GROUP: no editItem");
    return { items, editItemId };
  }
  const rfgFieldName = fieldName;
  if (!rfgFieldName) {
    console.warn("REMOVE_FIELD_GROUP: no fieldName");
    return { items, editItemId };
  }

  const fgObj = rfgEditItem?.value?.[rfgFieldName];
  if (!fgObj || !fgObj.value) {
    console.warn("REMOVE_FIELD_GROUP: no fieldGroups");
    return { items, editItemId };
  }

  const newFgs = fgObj.value.filter((fg: any) => fg.id !== fieldGroupId);

  const newRfgEditItem = {
    ...rfgEditItem,
    value: {
      ...rfgEditItem.value,
      [rfgFieldName]: {
        ...fgObj,
        value: newFgs,
      },
    },
  };
  return {
    items: items.map((i) => {
      if (i.id === newRfgEditItem.id) {
        return newRfgEditItem;
      }
      return i;
    }),
    editItemId,
  };
};
