import { itemIF, metaIF, metaFieldIF } from "../../data/types/item";
import { snippetIF } from "../../data/types/snippets";
import { itemTypes } from "../meta/getMetas";
import { isValueDefault } from "./getItemData";
import { getMetaFromSnippet, getItemsOfType } from "../snippets/addAndGetMetas";

// if fields are dependent on a certain value of another field, don't display when the value of the other field is not the dependent on value
const getIsDependentFieldDisabled = ({
  itemType,
  fieldMeta,
  item,
  meta,
  snippet,
}: {
  itemType: itemTypes;
  fieldMeta: metaFieldIF;
  item: itemIF;
  meta?: metaIF;
  snippet: snippetIF;
}) => {
  if (fieldMeta.dependentOn) {
    const dependentOnValue = getFieldItemValue({
      itemType,
      item,
      metaName: item.name,
      snippet,
      dependentOnField: fieldMeta.dependentOn.field,
    });
    if (!dependentOnValue) {
      return true;
    }
    if (
      !fieldMeta.dependentOn.values.includes(dependentOnValue) &&
      !fieldMeta.dependentOn.dontDisableDependentOnField
    ) {
      return true;
    }
    return false;
  }

  if (
    fieldMeta.dependentOnValueInOtherField &&
    fieldMeta.dependentOnValueInOtherField.field &&
    fieldMeta.dependentOnValueInOtherField.wrapperFn
  ) {
    if (item.value) {
      const dependentFieldValue =
        item.value && item.value[fieldMeta.dependentOnValueInOtherField.field]
          ? item.value[fieldMeta.dependentOnValueInOtherField.field].value
          : undefined;
      if (dependentFieldValue) {
        const conditionIsMet = fieldMeta.dependentOnValueInOtherField.wrapperFn(
          item.value[fieldMeta.name],
          dependentFieldValue
        );
        if (!conditionIsMet) {
          return true;
        }
      }
    }
  }
  return false;
};

const getFieldItemValue = ({
  itemType,
  item,
  snippet,
  metaName,
  dependentOnField,
}: {
  itemType: itemTypes;
  item: itemIF;
  snippet: snippetIF;
  metaName: string;
  dependentOnField: string;
}) => {
  if (item.value && item.value[dependentOnField]) {
    return item.value[dependentOnField].value;
  }

  const items = getItemsOfType({
    itemType,
    snippet,
  });
  const addOrRemoveFieldGroupMainItem = items?.find(
    (item) => item.value && item.value[metaName]
  );
  if (
    addOrRemoveFieldGroupMainItem?.value &&
    addOrRemoveFieldGroupMainItem.value[dependentOnField]
  ) {
    return addOrRemoveFieldGroupMainItem.value[dependentOnField].value;
  }
  if (dependentOnField.endsWith("Select")) {
    return "value";
  }
};

export const getDependendOnDisabledFields = ({
  itemType,
  item,
  snippet,
  disabledFields,
}: {
  itemType: itemTypes;
  item: itemIF;
  snippet: snippetIF;
  disabledFields: string[];
}) => {
  const meta = getMetaFromSnippet({
    snippet,
    itemType,
    itemName: item.name,
  });
  if (!meta?.fields) {
    return;
  }
  meta.fields.forEach((fieldMeta: metaFieldIF) => {
    const isDependentFieldDisabled = getIsDependentFieldDisabled({
      itemType,
      fieldMeta,
      item,
      snippet,
    });
    if (isDependentFieldDisabled && !disabledFields.includes(fieldMeta.name)) {
      disabledFields.push(fieldMeta.name);
    }
  });

  // are fields dependent on disabled fields? -> disable
  meta.fields.forEach((fieldMeta) => {
    if (
      fieldMeta?.dependentOn &&
      disabledFields.includes(fieldMeta.dependentOn?.field)
    ) {
      if (!disabledFields.includes(fieldMeta.name)) {
        disabledFields.push(fieldMeta.name);
      }
    }
  });
};

export const getGroupsToHide = ({
  itemType,
  item,
  snippet,
  hiddenGroups,
}: {
  itemType: itemTypes;
  item: itemIF;
  snippet: snippetIF;
  hiddenGroups: string[];
}) => {
  const meta = getMetaFromSnippet({
    snippet,
    itemType,
    itemName: item.name,
  });

  // a group is hidden when its first field depends on a certain value of another field in another group

  meta?.group?.forEach((g) => {
    if (!g) {
      console.error("error in group meta");
      console.error(g);
      return;
    }

    if (g.dependentOn) {
      const dependentOnValue = getFieldItemValue({
        itemType,
        item,
        metaName: item.name,
        snippet,
        dependentOnField: g.dependentOn.field,
      });

      if (!dependentOnValue) {
        hiddenGroups.push(g.name);
        return;
      }
      if (!g.dependentOn.values.includes(dependentOnValue)) {
        hiddenGroups.push(g.name);
      }
      return;
    }
  });
};

/* disable if 
  field status dontRender set, 
  no field value, 
  field item value and field meta has no onOff set to true 
  field meta defaults equal equivalent item values
*/

export const disableFieldInRender = ({
  fieldMeta,
  meta,
  item,
}: {
  fieldMeta: metaFieldIF;
  meta: metaIF;
  item: itemIF;
}): boolean => {
  const fieldName = fieldMeta.name;
  const firstFieldInGroupMeta = getFirstFieldInGroupMeta({
    fieldName,
    meta,
  });

  if (fieldMeta.dontRender) {
    return true;
  }
  if (!item.value || !item.value[fieldName]) {
    // disable if field has no value
    return true;
  }
  /*
  check if field or the first field in the group has set onOff to true 
  in the item or in the field meta data
  * */

  /*
  if field is canBe field or a dependent Value or Calculate field, check the on off on the base field
  */

  const itemFieldOnOff = firstFieldInGroupMeta
    ? item.value[firstFieldInGroupMeta.name]?.onOff
    : item.value[fieldName]?.onOff;
  const fieldMetaOnOff = firstFieldInGroupMeta
    ? firstFieldInGroupMeta.onOff
    : fieldMeta?.onOff;
  if (!itemFieldOnOff && !fieldMetaOnOff) {
    return true;
  }
  const isDefault = isValueDefault(item.value[fieldName], fieldMeta);
  if (isDefault) {
    return true;
  }
  return false;
};

const disableDependentAutoGeneratedField = ({
  itemType,
  item,
  snippet,
  disabledFields,
}: {
  itemType: itemTypes;
  item: itemIF;
  snippet: snippetIF;
  disabledFields: string[];
}) => {
  const meta = getMetaFromSnippet({
    snippet,
    itemType,
    itemName: item.name,
  });
  // example width value 100px -> Select field is value or calculate -> remove Select field
  if (itemType === itemTypes.CLASSES_CSS) {
    meta?.fields?.forEach((fm) => {
      const keys = Object.keys(fm);
      if (disabledFields.includes(fm.name)) {
        return;
      }

      const hasCanBeField = keys.find((k) => {
        if (k.includes("canBe")) {
          return true;
        }
        return false;
      });
      if (hasCanBeField) {
        const fieldValue = item.value?.[fm.name]?.value;
        if (fieldValue && fieldValue !== "value") {
          const valueFieldName = fm.name + "Value";
          const valueFieldValue = item.value?.[valueFieldName];
          if (valueFieldValue && !disabledFields.includes(valueFieldName)) {
            disabledFields.push(valueFieldName);
          }
        }
        if (fieldValue && fieldValue !== "calculate") {
          const calculateFieldName = fm.name + "Calculate";
          const calculateFieldValue = item.value?.[calculateFieldName];
          if (
            calculateFieldValue &&
            !disabledFields.includes(calculateFieldName)
          ) {
            disabledFields.push(calculateFieldName);
          }
        }
      }
    });
  }
};

// if fields are dependentOn another field, disable other field if:
// - field dependentOn does not have the dontDisable
export const disableDependingOnValueDependOnFieldOrField = ({
  itemType,
  item,
  snippet,
  disabledFields,
}: {
  itemType: itemTypes;
  item: itemIF;
  snippet: snippetIF;
  disabledFields: string[];
}) => {
  // example border: all 1px solid blue; -> if the condition for at least one dependent field is fullfilled -> disable dependentOn.field
  /*
    get all dependentOn.fields
    loop through dependentOn fields: get dependentOn.field item.value 
    is value in dependentOn.values of field? 
      yes -> disable dependentOn.field, no -> disable field
    * */

  const meta = getMetaFromSnippet({
    snippet,
    itemType,
    itemName: item.name,
  });

  const dependentOnFields2 = meta?.fields?.reduce((r, fm) => {
    if (!fm) {
      console.error("error in field meta");
      console.error(meta);
      return r;
    }

    if (
      fm.dependentOn?.field &&
      !fm.dependentOn.dontDisableDependentOnField &&
      !r.includes(fm.dependentOn.field)
    ) {
      r.push(fm.dependentOn.field);
    }
    return r;
  }, [] as string[]);

  // metaBorder: dependentOnFields2 = ["main"]
  dependentOnFields2?.forEach((fieldName) => {
    const dependentOnFieldValue = item.value?.[fieldName]?.value;
    if (dependentOnFieldValue) {
      // borderItem: value of main field is "all"
      meta?.fields?.forEach((fm) => {
        if (!fm) {
          console.error("error in field meta");
          console.error(meta);
          return;
        }

        if (fm.dependentOn?.field === fieldName) {
          // all fields dependent on the value in "main" to be "all"
          if (fm.dependentOn.values.includes(dependentOnFieldValue)) {
            // allowed value -> disable dependentOn.field
            if (!disabledFields.includes(fieldName)) {
              disabledFields.push(fieldName);
            }
          } else {
            // value is not allowed for field -> disable field
            if (!disabledFields.includes(fm.name)) {
              disabledFields.push(fm.name);
            }
          }
        }
      });
    }
  });
};

export const getFirstFieldInGroupMeta = ({
  fieldName,
  meta,
}: {
  fieldName: string;
  meta: metaIF;
}): metaFieldIF | undefined => {
  const group = meta?.group;

  if (!group) {
    return;
  }

  for (const g of group) {
    if (g.fields.includes(fieldName)) {
      const firstFieldInGroupName = g.fields[0];
      return meta.fields?.find((f) => f.name === firstFieldInGroupName);
    }
  }
};

// if the first field in a group is disabled, disable all fields in the group
const disableDependentOnFields = ({
  item,
  meta,
  disabledFields,
}: {
  item: itemIF;
  meta?: metaIF;
  disabledFields: string[];
}) => {
  if (!meta?.fields) {
    return;
  }
  meta.fields.forEach((fm) => {
    if (!fm) {
      console.error("error in field meta");
      console.error(meta);
      return;
    }

    const isDisabled = disableFieldInRender({
      fieldMeta: fm,
      meta,
      item,
    });

    if (isDisabled) {
      if (!disabledFields.includes(fm.name)) {
        disabledFields.push(fm.name);
      }
    }
  });
};

export const getDisabledField = ({
  itemType,
  item,
  snippet,
}: {
  itemType: itemTypes;
  item: itemIF;
  snippet: snippetIF;
}): string[] => {

  const meta = getMetaFromSnippet({
    snippet,
    itemType,
    itemName: item.name,
  });
  const disabledFields = [] as string[];

  getDependendOnDisabledFields({
    itemType,
    item,
    snippet,
    disabledFields,
  });

  disableDependentOnFields({
    item,
    meta,
    disabledFields,
  });

  disableDependentAutoGeneratedField({
    itemType,
    item,
    snippet,
    disabledFields,
  });

  disableDependingOnValueDependOnFieldOrField({
    itemType,
    item,
    snippet,
    disabledFields,
  });

  return disabledFields;
};
