import { FC } from "react";
import { ReactComponent as Down } from "../../assets/imgs/arrow-down.svg";
import { eCFormat } from "../../lib/utils/culoriColor";
import "./SelectSimpleNative.css";

export const SelectSimpleNative: FC<{
  label: string;
  dontShowLabel?: boolean;
  options: { value: eCFormat; label: string }[];
  selectedValue: eCFormat;
  setSelectedValue: (value: eCFormat) => void;
}> = ({ label, dontShowLabel, options, selectedValue, setSelectedValue }) => {
  return (
    <label className={`select-simple-native label`}>
      <span className={dontShowLabel ? "sr-only" : ""}>{label}</span>
      <div className="select-box">
        <select
          className="sm"
          value={selectedValue}
          onChange={(e) => {
            const newValue = e.target.value as eCFormat;
            setSelectedValue(newValue);
          }}
        >
          {options.map((option) => (
            <option
              key={option.value}
              value={option.value}
              selected={option.value === selectedValue}
            >
              {option.label}
            </option>
          ))}
        </select>
        <Down className="icon" />
      </div>
    </label>
  );
};
