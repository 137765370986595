import { FC } from "react";

export const NoSvg: FC<{ htmlParentClassName?: string }> = ({
  htmlParentClassName,
}) => {
  return (
    <svg
      width="70"
      height="20"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlParentClassName || ""}
    >
      <text x="5px" y="15px">
        NO SVG
      </text>
    </svg>
  );
};