import { useEffect, useState, FC } from "react";
import { ReactComponent as Download } from "../../assets/imgs/cloud-download.svg";
import { ReactComponent as CloudOff } from "../../assets/imgs/cloud-off.svg";
import { snippetIF } from "../../data/types/snippets";
import { useStateContext } from "../../lib/state/state";
import { getIsAdmin, getUserName } from "../../lib/signinup/handleTokens";
import { deleteSnippetGQL } from "../../lib/lambda/deleteSnippet";
import { setIsLoading } from "../../lib/reducer/settings/actions";
import { updateServerSnippetAfterDownload } from "../../lib/lambda/downloadSnippetFromGalleryToLocal";
import { copySnippet } from "../../lib/reducer/snippet/actions";
import { Tooltip } from "../atoms/tooltip";
import { Notification } from "../atoms/Notification";
import { ModalWrapperRelativToParentPosition } from "../modules/TailwindModal";
import { useDivPosition } from "../modules/GetPositionWrapper";

const ConfirmCloudSnippetDelete: FC<{
  snippetId?: string;
  setSelectedSnippet?: (selectedSnippet: snippetIF | undefined) => void;
  setIsModalOpen?: (isOpen: boolean) => void;
}> = ({ snippetId, setSelectedSnippet, setIsModalOpen }) => {
  return (
    <div className="p-1r pl-15r">
      <p>
        Do you really want to delete the cloud snippet? If it is gone it is
        gone.
      </p>
      <div className="flex gap-1 mt-2r mb-1r">
        <button
          type="button"
          className="button orange"
          onClick={() => {
            const asyncFn = async () => {
              setIsLoading(true);
              const response = await deleteSnippetGQL({ id: snippetId || "" });
              if (response && setSelectedSnippet) setSelectedSnippet(undefined);

              setIsLoading(false);
              setIsModalOpen && setIsModalOpen(false);
            };
            asyncFn();
          }}
        >
          Delete
        </button>
        <button
          type="button"
          className="button gray-outline"
          onClick={() => {
            setIsModalOpen && setIsModalOpen(false);
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

const useGetIsSnippetInLocalCollection = (snippetId: string) => {
  const {
    state: {
      snippet: { snippets },
    },
  } = useStateContext();
  const [isInLocalCollection, setIsInLocalCollection] = useState(false);

  useEffect(() => {
    const snippet = snippets.find((s) => s.id === snippetId);
    setIsInLocalCollection(!!snippet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snippetId]);

  return isInLocalCollection;
};

export const CloudSnippetActionBtns: FC<{
  snippet?: snippetIF;
  setSelectedSnippet: (selectedSnippet: snippetIF | undefined) => void;
}> = ({ snippet, setSelectedSnippet }) => {
  const { dispatch } = useStateContext();

  const [msg, setMsg] = useState("");
  const [showMsg, setShowMsg] = useState(false);

  const { divRef, divPos } = useDivPosition();

  const isSnippetInLocalCollection = useGetIsSnippetInLocalCollection(
    snippet?.id || ""
  );

  if (!snippet) return null;

  const isAdmin = getIsAdmin();
  const userName = getUserName();
  const canDownload = !!userName && !isSnippetInLocalCollection;
  const canDelete = isAdmin || snippet.owner === userName;

  return (
    <div className="form-elements-wrapper">
      <div className="btn-row" ref={divRef}>
        {canDownload && (
          <button
            type="button"
            className={`button blue icon tooltip-container-parent${
              !isSnippetInLocalCollection ? "" : " disabled"
            }`}
            onClick={() => {
              if (isSnippetInLocalCollection) return;

              const asyncFn = async () => {
                setIsLoading(true);

                const response = await updateServerSnippetAfterDownload(
                  snippet.id
                );

                setIsLoading(false);

                if (response.msg) {
                  setMsg(response.msg);
                  setShowMsg(true);
                }

                if (!response.msg?.includes("Error")) {
                  const newSnippet = {
                    ...snippet,
                    id: "123",
                    owner: "anonymous",
                    copyCount: 1,
                    createdAt: undefined,
                    updatedAt: undefined,
                    public: false,
                  };

                  dispatch(copySnippet(newSnippet));
                  if (response.copyCount) {
                    setSelectedSnippet({
                      ...snippet,
                      copyCount: response.copyCount,
                    });
                  }
                }
              };
              asyncFn();
            }}
          >
            <span className="sr-only">Download snippet</span>
            <Download />
            <Tooltip
              tooltipText="Add to your local collection"
              direction="top-right"
            />
            {showMsg && (
              <Notification
                msg={msg}
                setShowMsg={setShowMsg}
                notificationPosition="right"
              />
            )}
          </button>
        )}

        {canDelete && (
          <ModalWrapperRelativToParentPosition
            title="Delete selector"
            component={
              <ConfirmCloudSnippetDelete
                snippetId={snippet?.id}
                setSelectedSnippet={setSelectedSnippet}
              />
            }
            disabled={!canDelete}
            parentPosition={divPos}
          >
            <button
              type="button"
              className={`button orange icon ${canDelete ? "" : " disabled"}`}
              onClick={() => {}}
            >
              <span className="sr-only">Delete snippet</span>
              <CloudOff />
            </button>
          </ModalWrapperRelativToParentPosition>
        )}
      </div>
    </div>
  );
};
