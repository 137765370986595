import { TObject, itemIF, metaIF } from "../../data/types/item";
import { itemTypes } from "../meta/getMetas";
import { getItemValuesAsObj } from "./getItemValuesAsObject";
import { buildCombinations } from "./css/build-combinations";
import {
  getItemsOfType,
  getMetaFromSnippet,
} from "../../lib/snippets/addAndGetMetas";
import { snippetIF } from "../../data/types/snippets";

const getSelectorNameStringFromObj = ({
  valueObj,
  meta,
}: {
  valueObj: { [key: string]: string };
  meta: metaIF;
}) => {
  let selectorName = "";

  Object.keys(valueObj).forEach((key) => {
    const space = selectorName === "" ? "" : " ";
    const pre = meta.prefix ? meta.prefix : "";
    selectorName += space + pre + valueObj[key];
  });
  return selectorName;
};

const getSelectorPreValue = ({
  selector,
  prefixMeta,
}: {
  selector?: TObject;
  prefixMeta?: metaIF;
}) => {
  if (!selector || selector.level === 1) {
    return "";
  }
  if (selector.prefix) {
    if (["AMPERSAND"].includes(selector.prefix.name)) {
      return "";
    }
    if (prefixMeta) {
      const prefixDisplay = prefixMeta.display
        ? prefixMeta.display
        : prefixMeta?.name;
      return ` ${prefixDisplay} `;
    }
  }
  return " ";
};

// get just the one selector name and not the squence of the selector names (when the selector has ancestor and children and/or connectors in the selector tree) -> getSelectorNameArray
export const getSelectorName = ({
  selector,
  snippet,
}: {
  selector: itemIF;
  snippet: snippetIF;
}): string | undefined => {
  const meta = getMetaFromSnippet({
    snippet,
    itemType: itemTypes.SELECTORS,
    itemName: selector.name,
  });
  if (
    !meta ||
    !selector?.value ||
    meta.name === "::before" ||
    meta.name === "::after"
  ) {
    return meta?.display ? meta.display : selector?.name;
  }

  const valueObj = getItemValuesAsObj({
    itemType: itemTypes.SELECTORS,
    item: selector,
    snippet,
  });

  if(!valueObj) {
    return
  }

  const selectorNameStr = getSelectorNameStringFromObj({
    valueObj,
    meta,
  });

  const beforeName =
    selector.name === "animation"
      ? "animation: "
      : selector.name === "@media"
      ? "@media "
      : "";

  const selectorName = `${beforeName}${selectorNameStr}`;

  return selectorName;
};

export const getSelectorNameArray = ({
  selector,
  selectorNamesObjs,
  snippet,
}: {
  selector: itemIF;
  selectorNamesObjs: { prefix: string; name: string }[];
  snippet: snippetIF;
}) => {
  const selectorName = getSelectorName({
    selector,
    snippet,
  });

  const prefixMeta = selector?.prefix
    ? getMetaFromSnippet({
        snippet,
        itemType: itemTypes.CONNECTOR,
        itemName: selector.prefix.name,
      })
    : undefined;
  const prefix = getSelectorPreValue({ selector, prefixMeta });
  selectorNamesObjs.unshift({
    prefix,
    name: selectorName!,
  });

  const selectors = getItemsOfType({
    snippet,
    itemType: itemTypes.SELECTORS,
  });
  const parent = selectors?.find((s) => s.id === selector.parent);

  if (!parent || parent.name === "@media") {
    return;
  }

  getSelectorNameArray({
    selector: parent,
    selectorNamesObjs,
    snippet,
  });
};

export const getSelectorNameStr = ({
  selectorNamesObjs,
}: {
  selectorNamesObjs: { prefix: string; name: string }[];
}) => {
  // build groups of comma connected selectors
  const allGroups: string[][] = [];
  let count = 0;
  selectorNamesObjs.forEach((no, index) => {
    if (index < count) {
      return;
    }

    if (
      selectorNamesObjs[index + 1] &&
      selectorNamesObjs[index + 1].prefix === " , "
    ) {
      // if next item has prefix, add this to the current item instead of its comma prefix
      const group = [`${no.prefix}${no.name}`];
      count += 1;
      while (
        selectorNamesObjs[count] &&
        selectorNamesObjs[count].prefix === " , "
      ) {
        const ns = selectorNamesObjs[count];
        group.push(`${no.prefix}${ns.name}`);
        count += 1;
      }
      allGroups.push(group);
    } else {
      allGroups.push([`${no.prefix}${no.name}`]);
      count += 1;
    }
  });

  // loop through and build combinations
  const resultArray = buildCombinations(allGroups);
  return resultArray.join(", ");
};
