import { itemIF } from "../../data/types/item";
import { itemTypes } from "../meta/getMetas";
import { getAddAsSiblingOrChild } from "./getAddAsSiblingOrChild";
import { getKidsIds } from "./getDescendantsAndAncestors";
import { snippetIF } from "../../data/types/snippets";
import {
  getItemsOfType,
  replaceItemsAndEditItemIdOfType,
} from "../snippets/addAndGetMetas";
import { getMetaFromSnippet } from "../snippets/addAndGetMetas";
import { getMetaWithAllFields } from "../meta/getMetaWithAllFields";
const clone = require("rfdc")();

export const setLevelAndPosition = ({
  itemType,
  item,
  editItem,
  snippetFrom,
  snippetTo,
}: {
  itemType: itemTypes;
  item: itemIF;
  editItem?: itemIF;
  snippetFrom: snippetIF;
  snippetTo: snippetIF;
}) => {
  const itemsTo = getItemsOfType({
    itemType,
    snippet: snippetTo,
  });

  if (!editItem) {
    const level1 = itemsTo.filter((i) => i.level === 1);
    const maxPos = Math.max(...level1.map((i) => i.position), 0);
    return {
      ...item,
      parent: 0,
      level: 1,
      position: maxPos + 1,
    };
  }

  const addAsSiblingOrChild = getAddAsSiblingOrChild({
    itemType,
    newItem: item,
    editItem,
    snippet: snippetFrom,
  });

  // add as child
  if (addAsSiblingOrChild) {
    const editItemChildren = itemsTo.filter((i) => i.parent === editItem.id);
    const maxPos = Math.max(...editItemChildren.map((i) => i.position), 0);
    return {
      ...item,
      parent: editItem.id,
      level: editItem.level ? editItem.level + 1 : 1,
      position: maxPos + 1,
    };
  }

  // add as sibling
  return {
    ...item,
    parent: editItem.parent,
    level: editItem.level,
    position: editItem.position + 1,
  };
};

export const getNewItem = ({
  itemType,
  itemFrom,
  itemToId,
  snippetFrom,
  snippetTo,
}: {
  itemType: itemTypes;
  itemFrom: itemIF;
  itemToId?: number;
  snippetFrom: snippetIF;
  snippetTo: snippetIF;
}) => {
  const itemsTo = getItemsOfType({
    itemType,
    snippet: snippetTo,
  });

  let maxIdItemsTo = Math.max(...itemsTo.map((i) => i.id), 0);
  maxIdItemsTo += 1;
  const itemNewId = {
    ...itemFrom,
    id: maxIdItemsTo,
  };

  const itemTo = itemToId ? itemsTo.find((i) => i.id === itemToId) : undefined;

  return setLevelAndPosition({
    itemType,
    item: itemNewId,
    editItem: itemTo,
    snippetFrom,
    snippetTo,
  });
};

export const addNewItemToItems = ({
  itemType,
  newItem,
  itemToId,
  snippetFrom,
  snippetTo,
}: {
  itemType: itemTypes;
  newItem: itemIF;
  itemToId?: number;
  snippetFrom: snippetIF;
  snippetTo?: snippetIF;
}) => {
  const snippetTo2 = snippetTo || snippetFrom;
  const newItemWithParentLevelPosition = getNewItem({
    itemType,
    itemFrom: newItem,
    itemToId,
    snippetFrom,
    snippetTo: snippetTo2,
  });

  const itemsTo = getItemsOfType({
    itemType,
    snippet: snippetTo2,
  });
  const itemsToIncreaseSiblingPosition = itemsTo.map((i) => {
    if (
      i.parent === newItemWithParentLevelPosition.parent &&
      i.position >= newItemWithParentLevelPosition.position
    ) {
      return {
        ...i,
        position: i.position + 1,
      };
    }
    return i;
  });

  const newItemsToAll = [
    ...itemsToIncreaseSiblingPosition,
    newItemWithParentLevelPosition,
  ];

  return {
    items: newItemsToAll,
    editItemId: newItemWithParentLevelPosition.id,
  };
};

export const addNewItem = ({
  itemType,
  itemName,
  snippet,
  itemsTo,
  itemToId,
  editSelectorId,
  editItemId,
}: {
  itemType: itemTypes;
  itemName: string;
  snippet: snippetIF;
  itemsTo: itemIF[];
  itemToId?: number;
  editSelectorId?: number;
  editItemId: number;
}) => {
  const metaFromSnippet = getMetaFromSnippet({
    snippet,
    itemType,
    itemName,
  });

  const meta =
    metaFromSnippet ||
    getMetaWithAllFields({
      itemType,
      itemName,
      snippet,
    });

  if (!meta) {
    console.error("Can't add ", itemName, " without meta");
    return { items: itemsTo, editItemId };
  }

  const newItem2 = { name: itemName } as itemIF;
  newItem2.value = meta.default;

  if (itemType === itemTypes.CLASSES_CSS) {
    if (!editSelectorId) {
      console.error("Can't add ", itemName, " without editSelectorId");
      return { items: itemsTo, editItemId };
    }

    newItem2.classId = editSelectorId;
  }

  return addNewItemToItems({
    itemType,
    newItem: newItem2,
    itemToId,
    snippetFrom: snippet,
  });
};

export const copyItem = ({
  itemType,
  item,
  itemToId,
  snippet,
}: {
  itemType: itemTypes;
  item: itemIF;
  itemToId?: number;
  snippet: snippetIF;
}) => {
  const copy = clone(item);
  return addNewItemToItems({
    itemType,
    newItem: copy,
    itemToId,
    snippetFrom: snippet,
  });
};

/*
  * getChildrenAndResetIdsParentsLevel

  * new item/itemsFrom can be html, svg, or selector
  * if properties and htmlsOSvgs are provided, new item/itemsFrom are selectors 

  * - get descendants of itemOld
  * - on descendants reset id and level and collect new ids as object array with {oldId, newId}
  * - for descendants reset parent id with the help of selectors oldNewIds
  * - if propertiesFrom and newProperties are provided, reset classId of the properties with the help of selectors oldNewIds 
  * - if htmlsOSvgs and newHtmlsOSvgs are provided, reset assignedClassId of the html or svg with the help of selectors oldNewIds
  * - return {newItems, }
  * - reset levels
  * - reset properties and htmlsOSvgs
  * 
  * return new items, and, if provided, new properties and new htmlsOSvgs
*/

type TOldNewIds = { oldId: number; newId: number };

type TResult = {
  items: itemIF[];
  oldNewIds?: TOldNewIds[];
};
export const getChildrenAndResetIdsParentsLevel = ({
  newItem,
  itemIdOld,
  itemsFrom,
}: {
  newItem: itemIF;
  itemIdOld: number;
  itemsFrom: itemIF[];
}): TResult | undefined => {
  const kidsIds = [] as number[];
  const itemOld = itemsFrom.find((i) => i.id === itemIdOld);
  if (!itemOld) {
    console.warn("getChildrenAndResetIdsParentsLevel: itemOld not found");
    return;
  }
  getKidsIds({
    item: itemOld,
    items: itemsFrom,
    kidsIds,
  });
  const kids = itemsFrom.filter((item) => kidsIds.includes(item.id));

  const oldNewIds = [{ oldId: itemIdOld, newId: newItem.id }];
  let newId = newItem.id;
  const levelOldNew = newItem.level! - itemOld.level!;

  const kidsNewId = kids.map((kid) => {
    newId++;
    oldNewIds.push({ oldId: kid.id, newId });
    return { ...kid, id: newId, level: kid.level! + levelOldNew };
  });

  const kidsNewParent = kidsNewId.map((kid) => {
    const parentOldNew = oldNewIds.find((id) => id.oldId === kid.parent);
    return {
      ...kid,
      parent: parentOldNew ? parentOldNew.newId : kid.parent,
    };
  }) as itemIF[];

  let newData = { items: kidsNewParent, oldNewIds } as TResult;
  return newData;
};

export const getSelectorProperties = ({
  oldNewIds,
  snippetFrom,
  snippetTo,
}: {
  oldNewIds?: TOldNewIds[];
  snippetFrom: snippetIF;
  snippetTo: snippetIF;
}): snippetIF => {
  const propertiesTo = getItemsOfType({
    itemType: itemTypes.CLASSES_CSS,
    snippet: snippetTo,
  });
  if (!oldNewIds) {
    return snippetTo;
  }
  const addProperties = [] as itemIF[];
  const ptIds = propertiesTo?.map((p) => p.id) || [];
  let ptMaxId = Math.max(...ptIds, 0);

  const propertiesFrom = getItemsOfType({
    itemType: itemTypes.CLASSES_CSS,
    snippet: snippetFrom,
  });

  oldNewIds.forEach((id) => {
    const selectorProperties = propertiesFrom.filter(
      (p) => p.classId === id.oldId
    );
    selectorProperties.forEach((p) => {
      ptMaxId++;
      addProperties.push({
        ...p,
        id: ptMaxId,
        classId: id.newId,
      });
    });
  });

  if (addProperties.length === 0) {
    return snippetTo;
  }

  const newProperties = [...(propertiesTo || []), ...addProperties];

  const newSnippetTo = replaceItemsAndEditItemIdOfType({
    itemType: itemTypes.CLASSES_CSS,
    snippet: snippetTo,
    items: newProperties,
    editItemId: addProperties[0].id,
  });
  return newSnippetTo;
};
