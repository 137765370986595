import { itemIF } from "../../data/types/item";
import { getassignedClassNames } from "../render/css/get-assigned-selectors";

type TIdAndName = {
  id: number;
  name: string;
};
export const writeHtmlNames = ({
  htmls,
  svgs,
  selectors
}: {
  htmls?: itemIF[];
  svgs?: itemIF[];
  selectors?: itemIF[];
}): TIdAndName[] => {
  if (!htmls) return [];
  
  const editSnippetSvgs = svgs?.filter(
    (c) => c.name === "svg" && c.level === 1
  );

  return htmls?.map((html) => {
    let htmlName = html.name;

    if (html.name === "svg") {
      if (html.value?.htmlSvgCodeSnippetSvgId?.value && editSnippetSvgs) {
        const svg = editSnippetSvgs.find(
          (s) => s.id === html.value?.htmlSvgCodeSnippetSvgId?.value
        );
        if (svg) {
          htmlName += ` name="${svg.value?.main.value}"`;
        }
      } else {
        htmlName += ` name="NO SVG"`;
      }
    }

    if (html.name === "input") {
      htmlName += ` type="${html.value?.type.value}"`;
    }
    if (html.name === "label") {
      if (html.value?.for?.onOff && html.value.for.value !== "") {
        htmlName += ` for='${html.value.for.value}'`;
      }
    }

    if (html.events) {
      html.events.forEach((e) => {
        htmlName += " " + e.value?.type.value;
      });
    }

    const assignedClassNames = getassignedClassNames({
      assignedIds: html.assignedClasses,
      selectors,
    });
    htmlName +=
      assignedClassNames === "" ? "" : ` class='${assignedClassNames}'`;

    if (html.value?.id?.onOff && html.value.id.value) {
      htmlName += ` id='#${html.value.id.value}'`;
    }

    return {
      id: html.id,
      name: htmlName,
    } as TIdAndName;
  });
};
