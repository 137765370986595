import { metaIF, EMetaFieldTypes } from "../../types/item";
import { standardUnits } from "./utils";

export const CSS_PROPERTIES_DIMENSION: metaIF[] = [
  {
    name: "height",
    category: ["DIMENSION"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        units: standardUnits,
        onOff: true,
        canBeAuto: true,
        canBeCalculated: true,
        canBeInherited: true,
        canBeInitial: true,
      },
    ],

    group: [
      {
        name: "heightG",
        fields: ["main"],
        display: ["height"],
      },
    ],

    default: {
      main: { value: 200, unit: "px" },
    },
  },
  {
    name: "max-height",
    category: ["DIMENSION"],
    fieldsOrder: ["main", "value"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [{ id: "value" }, { id: "none" }],
      },
      {
        name: "value",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnits,
        dependentOn: {
          field: "main",
          values: ["value"],
        },
      },
    ],
    group: [
      {
        name: "maxHeightG",
        fields: ["main", "value"],
        display: ["max-height", "value"],
      },
    ],

    default: {
      main: { value: "value" },
      value: { value: 50, unit: "%" },
    },
  },
  {
    name: "max-width",
    category: ["DIMENSION"],
    fieldsOrder: ["main", "value"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [{ id: "value" }, { id: "none" }],
        canBeCalculated: true,
      },
      {
        name: "value",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnits,
        dependentOn: {
          field: "main",
          values: ["value"],
        },
      },
    ],
    group: [
      {
        name: "maxWidthG",
        fields: ["main", "value"],
        display: ["max-width", "value"],
      },
    ],

    default: {
      main: { value: "value" },
      value: { value: 400, unit: "px" },
    },
  },
  {
    name: "min-height",
    category: ["DIMENSION"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnits,
        canBeCalculated: true,
        canBeInherited: true,
        canBeInitial: true,
        help:
          "#### min-height  \n" +
          "Sets the minimum height of the selected elements.  \n" +
          "If `min-height` and `height` and/or `max-height` target the same element " +
          "and `height` or `max-height` is smaller than `min-height`, then `min-height` takes precedence.",
      },
    ],
    default: {
      main: { value: 100, unit: "px" },
    },
  },
  {
    name: "min-width",
    category: ["DIMENSION"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnits,
        canBeCalculated: true,
        canBeInherited: true,
        canBeInitial: true,
        canBeAddOptions: ["max-content"],
        help:
          "#### min-width  \n" +
          "Sets the minimum width of the selected elements.  \n" +
          "If `min-width` and `width` and/or `max-width` target the same element " +
          "and `width` or `max-width` is smaller than `min-width`, then `min-width` takes precedence.",
      },
    ],
    group: [
      {
        name: "minWidthG",
        fields: ["main"],
        display: ["min-width"],
      },
    ],
    default: {
      main: { value: 100, unit: "px" },
    },
  },
  {
    name: "object-fit",
    category: ["DIMENSION"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          { id: "contain" },
          { id: "cover" },
          { id: "fill" },
          { id: "none" },
          { id: "scale-down" },
        ],
        help:
          "#### object-fit  \n" +
          "If width and height is set on the image HTML, object-fit controls how image is scaled and positioned within:  \n" +
          "**contain** keeps the aspect ration of the image, the image is made to fill the box and if there is space, the image is centered horizontally or vertically.  \n" +
          "**cover** keeps the aspect ration of the image, the image is made to fill the box, clipping parts of the image, if necessarty, horizontally or vertically.  \n" +
          "**fill** centers the image, shows it in its original size, if the image is larger than its box, horizontally and vertically a clipping of the image is displayed.  \n" +
          "**none** means no scaling, clipping and positioning of the image within its box.  \n" +
          "**scale-down** is the smaller image of contain or none.",
      },
    ],
    default: {
      main: { value: "cover" },
    },
  },

  {
    name: "width",
    category: ["DIMENSION"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnits,
        canBeAuto: true,
        canBeCalculated: true,
        canBeInherited: true,
        canBeInitial: true,
        canBeAddOptions: ["max-content"],
      },
    ],
    group: [
      {
        name: "widthG",
        fields: ["main"],
        display: ["width"],
      },
    ],
    default: {
      main: { value: 200, unit: "px" },
    },
  },
];
