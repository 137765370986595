import MainLayout from "../MainLayout";
import { SignInUpMain } from "../user/signinup/Main";

const SignInUp = () => {
  return (
    <MainLayout title="User" description="Sign up or Sign in">
      <SignInUpMain />
    </MainLayout>
  );
};

export default SignInUp;
