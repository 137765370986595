import {
  itemIF,
  metaFieldIF,
  TValueType,
} from "../../../data/types/item";
import { snippetIF } from "../../../data/types/snippets";
import { itemTypes } from "../../meta/getMetas";
import { getMetaFromSnippet } from "../../snippets/addAndGetMetas";

const clone = require("rfdc")();

const switchFieldsOfGroupOnOrOff = ({
  itemType,
  item,
  fieldName,
  snippet,
}: {
  itemType: itemTypes;
  item: itemIF;
  fieldName: string;
  snippet: snippetIF;
}) => {
  const cItem = clone(item);
  const meta = getMetaFromSnippet({
    snippet,
    itemType,
    itemName: item.name,
  });

  const fieldsInGroup = [] as string[];
  meta?.group?.forEach((g) => {
    if (g.fields.includes(fieldName)) {
      g.fields.forEach((gf) => fieldsInGroup.push(gf));
    }
  });

  const onOrOff = !cItem.value?.[fieldName]?.onOff ? true : false;

  if (fieldsInGroup.length > 0) {
    fieldsInGroup.forEach((name) => {
      const cItemValue = cItem.value ?? {};
      const cItemValueFieldName = cItemValue[name] ?? {};
      cItem.value = {
        ...cItemValue,
        [name]: {
          ...cItemValueFieldName,
          onOff: onOrOff,
        },
      };
    });
  } else {
    const onOrOff = !cItem.value?.[fieldName]?.onOff ? true : false;
    const cItemValue = cItem.value ?? {};
    const cItemValueFieldName = cItemValue[fieldName] ?? {};
    cItem.value = {
      ...cItemValue,
      [fieldName]: {
        ...cItemValueFieldName,
        onOff: onOrOff,
      },
    };
  }

  return cItem;
};

const isValidCheckMetaConditionInner = ({
  fieldMeta,
  value,
  valueStr,
}: {
  fieldMeta: metaFieldIF;
  value?: any;
  valueStr?: string;
}) => {
  if (fieldMeta.conditions) {
    if (value || value === 0) {
      if (fieldMeta.conditions.greaterOrEqual0 && value < 0) {
        return false;
      }
      if (fieldMeta.conditions.lessOrEqual1 && value > 1) {
        return false;
      }

      if (
        fieldMeta.conditions.min ||
        (fieldMeta.conditions.min === 0 && value < fieldMeta.conditions.min)
      ) {
        return false;
      }
      if (
        fieldMeta.conditions.max ||
        (fieldMeta.conditions.max === 0 && value > fieldMeta.conditions.max)
      ) {
        return false;
      }
    }

    if (valueStr && fieldMeta.conditions.regex) {
      const pattern = new RegExp(fieldMeta.conditions.regex, "i");
      if (!pattern.test(valueStr)) {
        return false;
      }
    }
  }

  return true;
};

const changeItemIFValueInner = ({
  itemType,
  item,
  fieldName,
  valueType,
  value,
  snippet,
}: {
  itemType: itemTypes;
  item: itemIF;
  fieldName: string;
  valueType?: TValueType;
  value: any;
  snippet: snippetIF;
}): itemIF => {
  if (!itemType) {
    console.error("changeItemIFValueInner: No meta data found for ", item);
    return item;
  }

  if (valueType === "onOff") {
    // switch the onOff value of the field, or, if the field is part of a group, all fields of the group
    const newI = switchFieldsOfGroupOnOrOff({
      itemType,
      item,
      fieldName,
      snippet,
    });
    return newI;
  }

  const meta = getMetaFromSnippet({
    snippet,
    itemType,
    itemName: item.name,
  });

  const fieldMeta = meta?.fields?.find((f) => f?.name === fieldName);
  if (fieldMeta && !valueType) {
    const areConditionsMet = isValidCheckMetaConditionInner({
      fieldMeta,
      value: valueType === "number" ? value : undefined,
      valueStr: valueType === "string" ? value : undefined,
    });
    if (!areConditionsMet) {
      return item;
    }
  }

  let newValue = value;
  if (fieldName === "gradientId" && item.value?.[fieldName]?.value) {
    newValue = 0;
  }

  // if background-image svg: remove empty spaces and line breaks between brackets >< and replace "" with '
  if (meta?.name === "background-image" && fieldName === "svg") {
    newValue = value
      .replace(/>(\s)+</g, "><")
      .replace(/<style>(\s)+/g, "<style>")
      .replace(/{(\s)+/g, "{")
      .replace(/;(\s)+/g, ";")
      .replace(/}(\s)+/g, "}")
      .replace(/"/g, "'");
  }

  const changeFieldKey = valueType || "value";
  const valObj = item.value?.[fieldName];

  const newValueField = valObj
    ? { ...valObj, [changeFieldKey]: newValue }
    : { [changeFieldKey]: newValue };

  const newItem = {
    ...item,
    value: {
      ...item.value,
      [fieldName]: newValueField,
    },
  } as itemIF;
  return newItem;
};

const getAddFieldGroupItem = ({
  itemType,
  fieldGroupName,
  editItem,
  fieldName,
  valueType,
  value,
  snippet,
  addOrRemoveEditItemId,
}: {
  itemType: itemTypes;
  fieldGroupName?: string;
  editItem: itemIF;
  fieldName: string;
  valueType?: TValueType;
  value: any;
  snippet: snippetIF;
  addOrRemoveEditItemId?: number;
}): itemIF | undefined => {
  if (!fieldGroupName) {
    return;
  }

  const fieldGroup = editItem.value && editItem?.value[fieldGroupName];
  if (!fieldGroup) {
    console.warn("getAddFieldGroupItem: no fieldGroup");
    return;
  }

  if (!addOrRemoveEditItemId && addOrRemoveEditItemId !== 0) {
    console.warn(
      "getAddFieldGroupItem: no addOrRemoveEditItemId for fieldGroup"
    );
    return;
  }

  const fieldGroupEditItem: itemIF | undefined = fieldGroup.value.find(
    (i: itemIF) => i.id === addOrRemoveEditItemId
  );
  if (!fieldGroupEditItem) {
    console.warn("getAddFieldGroupItem: no fieldGroupEditItem");
    return;
  }

  const newItem = changeItemIFValueInner({
    itemType,
    item: fieldGroupEditItem,
    fieldName,
    valueType,
    value,
    snippet,
  });

  const newEditItem = {
    ...editItem,
    value: {
      ...editItem.value,
      [fieldGroupName]: {
        ...fieldGroup,
        value: fieldGroup.value.map((i: itemIF) =>
          i.id === addOrRemoveEditItemId ? newItem : i
        ),
      },
    },
  } as itemIF;
  return newEditItem;
};

export const changeItemValue = ({
  itemType,
  fieldName,
  newValue,
  fieldGroupName,
  valueType,
  addOrRemoveEditItemId,
  item,
  snippet,
}: {
  itemType?: itemTypes;
  fieldName?:string;
  newValue?: any;
  fieldGroupName?: string;
  valueType?: TValueType;
  addOrRemoveEditItemId?: number;
  item: itemIF; 
  snippet: snippetIF;
}): itemIF | undefined => {

  if (!itemType || !fieldName || newValue === undefined || newValue === null) {
    console.warn("SET_NEW_ITEM_VALUE: no itemType, fieldName or newValue");
    return;
  }

  const newFieldGroupEditItem = getAddFieldGroupItem({
    itemType,
    fieldGroupName,
    editItem: item,
    addOrRemoveEditItemId,
    fieldName,
    valueType,
    value: newValue,
    snippet,
  });
  if (newFieldGroupEditItem) {
    return newFieldGroupEditItem;
  }

  const changedEditItem: itemIF = changeItemIFValueInner({
    itemType,
    item,
    fieldName,
    valueType,
    value: newValue,
    snippet,
  });

  return changedEditItem;
};
