import { actionIF } from "../../../data/types/item";
import { snippetsIF } from "../../../data/types/snippets";
import {
  ADD_SNIPPET,
  COPY_SNIPPET,
  DELETE_SNIPPET,
  SET_EDIT_SNIPPET,
} from "./actions";
import {getNewSnippetOrCopy} from "./addOrCopy";

type TSnippetReducerNew = {
  snippet: snippetsIF;
  action: actionIF;
};

export const snippetReducer = ({
  snippet,
  action,
}: TSnippetReducerNew): snippetsIF | undefined => {
  try {
    switch (action.type) {
      case COPY_SNIPPET:
      case ADD_SNIPPET:
        const newSnippet = getNewSnippetOrCopy({
          snippet:
            action.snippet ??
            snippet.snippets.find((s) => s.id === snippet.editSnippetId),
          isCopy: action.type === "COPY_SNIPPET",
        });
        if (!newSnippet) {
          console.warn("No newSnippet found");
          return snippet;
        }
        return {
          ...snippet,
          snippets: [...snippet.snippets, newSnippet],
          editSnippetId: newSnippet.id,
        } as snippetsIF;

      case DELETE_SNIPPET:
        const dsNewSnippets = snippet.snippets.filter(
          (s) => s.id !== snippet.editSnippetId
        );
        return {
          ...snippet,
          snippets: dsNewSnippets,
          editSnippetId: dsNewSnippets?.[0]?.id ?? "",
        };

      case SET_EDIT_SNIPPET:
        if (action.snippetId) {
          return {
            ...snippet,
            editSnippetId: action.snippetId,
          } as snippetsIF;
        }

        return snippet;

      default:
    }
  } catch (error) {
    console.error("snippetReducer error", error);
  }
};
