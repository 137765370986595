import React, { FC } from "react";
import { snippetIF } from "../../data/types/snippets";
import { itemTypes } from "../../lib/meta/getMetas";
import { NoSvg } from "../preview/RenderSvg";
import {
  getItemsOfType,
  getMetaFromSnippet,
} from "../../lib/snippets/addAndGetMetas";
import { BlockElement } from "./BlockElement";
import { getSvgCss } from "../../lib/items/getDescendantsAndAncestors";
import { itemIF } from "../../data/types/item";

/*
@htmlParentClassName is used to pass the parent HTML class to the child SVG in the HTML tree
*/

export const RenderElementAndChildren: FC<{
  itemType: itemTypes;
  item: itemIF;
  snippet: snippetIF;
}> = ({ itemType, item, snippet }) => {

  if (itemType === itemTypes.BLOCK_HTML && item.name === "svg") {
    /*
  if item name is svg and itemType is html, 
  render the assigned svg instead but in the position of the original html 
  */


    const htmlSvgId = item.value?.htmlSvgCodeSnippetSvgId?.value;
    if (!htmlSvgId || htmlSvgId === 0) {
      return <NoSvg />;
    }

    const svg = snippet.data.svgs.find((s) => s.id === htmlSvgId);
    if (!svg) return <NoSvg />;

    const newSvg: itemIF = {
      ...svg,
      parent: item.parent,
      level: item.level,
      position: item.position,
      assignedClasses: item.assignedClasses,
    };

    return (
      <RenderElementAndChildren
        itemType={itemTypes.SVGS}
        item={newSvg}
        snippet={snippet}
      />
    );
  }

  const items = getItemsOfType({ snippet, itemType });

  const children = items.filter((i) => i.parent === item.id);
  if (children.length === 0) {
    return <BlockElement itemType={itemType} item={item} snippet={snippet} />;
  }
  const sorted = children.sort((a, b) =>
    a.position > b.position ? 1 : a.position < b.position ? -1 : 0
  );

  const renderChildren: React.ReactElement[] = [];

  for (const child of sorted) {
    let isAttrChild = false;
    const meta = getMetaFromSnippet({
      snippet,
      itemType,
      itemName: child.name,
    });
    if (
      meta?.childrenAsAttributes &&
      meta.allowedChildren?.includes(child.name) &&
      meta?.childrenAsAttributes?.children.includes(child.name)
    ) {
      isAttrChild = true;
    }

    if (!isAttrChild) {
      renderChildren.push(
        <RenderElementAndChildren
          key={child.id}
          itemType={itemType}
          item={child}
          snippet={snippet}
        />
      );
    }
  }

  let svgCss = "";
  if (itemType === itemTypes.SVGS && item.name === "svg") {
    svgCss = getSvgCss({ svg: item, snippet });
  }

  return (
    <BlockElement
      itemType={itemType}
      item={item}
      snippet={snippet}
      svgCss={svgCss}
    >
      {renderChildren}
    </BlockElement>
  );
};
