import {
  FC,
  useRef,
  useState,
  useEffect,
  ReactNode,
  useLayoutEffect,
} from "react";
import { createPortal } from "react-dom";
import "./CustomIFrame.css";

// make overflow dependent of   scrollable?: boolean;
// bug canBe does not work in old margin and padding selectMain? -> render in new way with main, mainValue ...

const getHeadContentWithDefaultStyles = ({
  headContent,
  htmlSelectorCss,
  scrollable,
}: {
  headContent: string;
  htmlSelectorCss: string;
  scrollable?: boolean;
}) => {
  const headContentWithDefaultStyles = `${headContent}

html, body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  overflow: ${scrollable ? "auto" : "hidden"};
}
* {
  box-sizing: border-box;
}

body {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.top-div {
  overflow: hidden;
  position: relative;
}

${htmlSelectorCss}
`;
  return headContentWithDefaultStyles;
};

type CustomIframeProps = {
  title: string;
  width?: string;
  height?: string;
  className?: string;
  children?: ReactNode;
  headContent?: string;
  htmlSelectorCss?: string;
  scrollable?: boolean;
};
export const CustomIframe: FC<CustomIframeProps> = ({
  title,
  width,
  height,
  className,
  children,
  headContent,
  htmlSelectorCss,
  scrollable,
}) => {
  const [mountNode, setMountNode] = useState(
    undefined as HTMLElement | undefined
  );

  const frameRef = useRef<HTMLIFrameElement>(null);
  const [isIFrameLoaded, setIsIFrameLoaded] = useState(false);

  const iframe = frameRef.current;
  const body = iframe?.contentWindow?.document?.body;

  useLayoutEffect(() => {
    const body = frameRef.current?.contentWindow?.document?.body;
    if (body) {
      setIsIFrameLoaded(true);
    }
  }, []);

  const headContentWithDefaultStyles = getHeadContentWithDefaultStyles({
    headContent: headContent || "",
    htmlSelectorCss: htmlSelectorCss || "",
    scrollable,
  });

  useLayoutEffect(() => {
    if (!headContentWithDefaultStyles) return;

    const iframeDocument = iframe?.contentDocument;
    if (iframeDocument) {
      const head = iframeDocument.head;

      const existingStyleElement = head.querySelector("style");
      if (existingStyleElement) {
        existingStyleElement.textContent = headContentWithDefaultStyles;
      } else {
        const styleElement = document.createElement("style");
        styleElement.textContent = headContentWithDefaultStyles;
        head.appendChild(styleElement);
      }

      setIsIFrameLoaded(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headContentWithDefaultStyles, iframe, body, width, height]);

  useEffect(() => {
    if (body) {
      setMountNode(body);
    }
  }, [body, isIFrameLoaded]);

  return (
    <iframe
      title={title}
      style={{
        width: width ? width : "100%",
        height: height ? height : "100%",
        overflow: scrollable ? "scroll" : "hidden",
      }}
      className={`${className}`}
      ref={frameRef}
    >
      {mountNode && createPortal(children, mountNode)}
    </iframe>
  );
};
