import { StateProvider, getInitialState } from "./lib/state/state";
import { CRoutes } from "./components/routes/Routes";
import { mainReducer } from "./lib/reducer/combineReducer";
import "./App.css";

const App = () => {
  const initialState = getInitialState();

  return (
    <div className="app cssexy-main page tw-variables">
      <StateProvider initialState={initialState} reducer={mainReducer}>
        <CRoutes />
      </StateProvider>
    </div>
  );
};

export default App;
