import { FC } from "react";
import { Color } from "culori";
import { eCFormat, formatColor } from "../../../lib/utils/culoriColor";

export const PresetColours: FC<{
  colors?: Color[];
  setColor: (newColor: Color) => void;
  format: eCFormat;
}> = ({ colors, setColor, format }) => {
  if (!colors) return null;
  return (
    <div className="presets mt-05r">
      {colors.map((c, i) => {
        const colorStr = formatColor({ color: c, format });
        return (
          <button
            key={i}
            type="button"
            style={{ backgroundColor: colorStr }}
            onClick={() => {
              setColor(c);
            }}
          >
            <span className="sr-only">{colorStr}</span>
          </button>
        );
      })}
    </div>
  );
};
