import { useState, useEffect, FC } from "react";
import { useStateContext } from "../../lib/state/state";
import { setEditItemId } from "../../lib/reducer/items/actions";
import { itemIF, itemWithChildrenIF } from "../../data/types/item";
import { snippetIF } from "../../data/types/snippets";
import { itemTypes, getMeta } from "../../lib/meta/getMetas";
import { buildTree } from "../../lib/items/buildTree";
import { ianIF } from "../../lib/items/SortAndFind";
import { getOpenItemsIds } from "../../lib/tree/open-and-close-branch";
import { RenderTreeButtonAndChildren } from "../forms/EditTree";
import { useScrollIntoView } from "../atoms/useScrollIntoView";
import { getassignedClassNames } from "../../lib/render/css/get-assigned-selectors";

const getItemsAndName = ({
  items,
  selectors,
}: {
  items: itemIF[];
  selectors?: itemIF[];
}) => {
  const itemsAndName = items.map((svg) => {
    const meta = getMeta(itemTypes.SVGS, svg.name);
    let svgName = meta?.display ? meta.display : svg.name;
    if (svg.name === "svg") {
      svgName += ` name='${svg.value?.main.value}'`;
    }

    const idHref = svg.value?.href?.value;
    if (idHref) {
      svgName += ` href="#${idHref}"`;
    }

    const idGradient = svg.value?.gradientId?.value;
    if (idGradient) {
      svgName += ` fill: url(#${idGradient})`;
    }

    if (svg.events) {
      svg.events.forEach((e) => {
        svgName += " " + e.value?.type.value;
      });
    }

    const assignedClassNames = getassignedClassNames({
      assignedIds: svg.assignedClasses,
      selectors,
    });
    svgName +=
      assignedClassNames === "" ? "" : ` class='${assignedClassNames}'`;

    if (svg.value?.id?.onOff && svg.value.id.value) {
      svgName += ` id='#${svg.value.id.value}'`;
    }

    return {
      ...svg,
      name: svgName,
    };
  });

  return itemsAndName;
};

export const SvgTreeForSnippet: FC<{
  snippet?: snippetIF;
  selectedItem?: itemIF;
  itemOnClick: (item: itemIF) => void;
}> = ({ snippet, selectedItem, itemOnClick }) => {
  const items = snippet?.data?.svgs || [];

  const [branches, setBranches] = useState([] as itemWithChildrenIF[]);
  useEffect(() => {
    if (!items) {
      setBranches([]);
    } else {
      const itemsAndName = getItemsAndName({
        items,
        selectors: snippet?.data?.selectors,
      });

      const tree = buildTree(itemsAndName);
      setBranches(tree);

      const idsAndNamesClasses = [] as ianIF[];
      const classSelectors = snippet?.data?.selectors?.filter(
        (c) => c.name === "class"
      );

      classSelectors?.forEach((s) => {
        const sameNameItem = idsAndNamesClasses.find(
          (ian) => ian.name === s.value?.main?.value
        );
        if (sameNameItem) {
          idsAndNamesClasses.forEach((ian) => {
            if (ian.name === s.value?.main?.value) {
              ian.sameNameIds = ian.sameNameIds ? ian.sameNameIds : [];
              ian.sameNameIds.push(s.id);
            }
          });
        } else {
          idsAndNamesClasses.push({
            idNumber: s.id,
            name: s.value?.main.value,
            sameNameIds: [s.id],
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snippet, snippet?.updatedAt]);

  const listRef = useScrollIntoView(selectedItem);

  if (!snippet) {
    return (
      <div className="tree-table">
        <div>SELECT A SNIPPET</div>
      </div>
    );
  }

  if (!items || items.length === 0) {
    return (
      <div className="tree-table">
        <div>NO SVG IN SNIPPET</div>
      </div>
    );
  }

  const openItemsIds = getOpenItemsIds({
    items,
    item: selectedItem,
  });

  return (
    <div className="tree-table" ref={listRef}>
      <div className="html-tree-container form-elements-wrapper">
        {branches.map((item: itemWithChildrenIF) => {
          return (
            <RenderTreeButtonAndChildren
              key={item.id}
              itemType={itemTypes.SVGS}
              item={item}
              editItem={selectedItem}
              editFn={itemOnClick}
              openItemsIds={openItemsIds}
              dontShowCodePreview={true}
            />
          );
        })}
      </div>
    </div>
  );
};

export const SvgsItemsTree = () => {
  const {
    state: {
      snippet: { snippets, editSnippetId },
    },
    dispatch,
  } = useStateContext();
  const editSnippet = snippets.find((s: snippetIF) => s.id === editSnippetId);
  const items = editSnippet?.data?.svgs || [];
  const editSvgId = editSnippet?.data?.editSvgId || 0;
  const editItem = items.find((s: itemIF) => s.id === editSvgId);

  const itemOnClick = (item: itemIF) => {
    dispatch(
      setEditItemId({ itemType: itemTypes.SVGS, newEditItemId: item.id })
    );
  };

  return (
    <SvgTreeForSnippet
      snippet={editSnippet}
      selectedItem={editItem}
      itemOnClick={itemOnClick}
    />
  );
};
