import { EMetaFieldTypes } from "./types/item";

export const fieldsAll = {
  overflowMain: {
    name: "main",
    type: EMetaFieldTypes.select,
    onOff: true,
    help: "#### overflow  \n",
    options: [
      {
        id: "visible",
        help:
          "#### visible  \n" +
          "When content is wider and/or longer than its container, then the content flows out of the container.",
      },
      {
        id: "hidden",
        help:
          "#### hidden  \n" +
          "When content is wider and/or longer than its container, then the overflow is hidden.",
      },
      {
        id: "scroll",
        help:
          "#### scroll  \n" +
          "When content is wider and/or longer than its container, " +
          "there are scrollbars added to display the overflow on the right and bottom.",
      },
      {
        id: "auto",
        help:
          "#### auto  \n" +
          "When content is wider and/or longer than its container, " +
          "there are scrollbars added where needed to display the overflow on the right and/or bottom.",
      },
    ],
  },
  timing: {
    name: "timing",
    type: EMetaFieldTypes.select,
    onOff: true,
    options: [
      {
        id: "linear",
        help:
          "#### linear  \n" +
          "Same speed from start to end. Equivalent to `cubic-bezier(0,0,1,1)`.",
      },
      {
        id: "ease",
        help:
          "#### ease  \n" +
          "Starts and ends slowly. Equivalent to `cubic-bezier(0.25,0.1,0.25,1)`.",
      },
      {
        id: "ease-in",
        help: "#### ease-in  \nStarts slowly. Equivalent to `cubic-bezier(0.42,0,1,1)`.",
      },
      {
        id: "ease-out",
        help: "#### ease-out  \nEnds slowly. Equivalent to `cubic-bezier(0,0,0.58,1)`.",
      },
      {
        id: "ease-in-out",
        help:
          "#### ease-in-out  \n" +
          "Slow start and end. Equivalent to `cubic-bezier(0.42,0,0.58,1)`.",
      },
      {
        id: "cubic-bezier",
        help:
          "### cubic bezier x1 y1 x2 y2  \n" +
          "The cubic bezier curve for the timing of the transition is shaped by 2 lines; the first line has 0 0 as starting and x1 y1 as the end point; the second line starts with x2 y2 and ends with 1 0. x1 y1 x2 y2 are values between 0 and 1. The flatter a line is the slower the effect transits. If the line is steep, then the effect processes fast.",
      },
      {
        id: "steps",
        help:
          "### steps  \n" +
          "When the `timing` is set to `steps`, the transition is conducted in distinct steps.  \n" +
          "Enter the number of `intervals` for the transition and `start` or `end` for the `direction`. " +
          "The `direction` controls if the effect of the interval starts at the beginning of the interval or its end.",
      },
      {
        id: "none",
      },
    ],
  },
  timingX1: {
    name: "x1",
    type: EMetaFieldTypes.number,
    conditions: {
      min: 0,
      max: 1,
    },
  },
  timingY1: {
    name: "y1",
    type: EMetaFieldTypes.number,
    conditions: {
      min: 0,
      max: 1,
    },
  },
  timingX2: {
    name: "x2",
    type: EMetaFieldTypes.number,
    conditions: {
      min: 0,
      max: 1,
    },
  },
  timingY2: {
    name: "y2",
    type: EMetaFieldTypes.number,
    conditions: {
      min: 0,
      max: 1,
    },
  },
  timingIntervals: {
    name: "intervals",
    type: EMetaFieldTypes.number,
  },
  timingDirection: {
    name: "direction",
    type: EMetaFieldTypes.select,
    default: { value: "end" },
    options: [
      {
        id: "start",
      },
      {
        id: "end",
      },
    ],
  },
};
