import { useStateContext } from "../../lib/state/state";
import { itemIF } from "../../data/types/item";
import { itemTypes } from "../../lib/meta/getMetas";
import { setEditItemId } from "../../lib/reducer/items/actions";
import { EditItem } from "../forms/EditItem";
import { AssignSvgToHtml } from "../organism/svg/AssignToHtml";
import { MoveOrDeleteButtonsHtml } from "./MoveOrDelete";
import { AddHtmlBoxWithButton } from "../forms/AddHtml";
import { HtmlTreeForSnippet } from "../render/HtmlItemsTree";
import { DisplayHideChildren2 } from "../modules/DisplayOrHideSubs2";
import { AssignClassToHtml } from "./AssignClassToHtml";
import { SectionTitle } from "../modules/SectionTitle";

const CRUDHtml = () => {
  const {
    state: {
      snippet: { snippets, editSnippetId },
    },
    dispatch,
  } = useStateContext();

  const editSnippet = snippets?.find((s) => s.id === editSnippetId);
  const htmls = editSnippet?.data?.htmls || [];
  const editHtml = htmls.find((h) => h.id === editSnippet?.data?.editHtmlId);

  if (!editSnippet) return null;
  const itemType = itemTypes.BLOCK_HTML;

  return (
    <div className="mb-2r">
      <DisplayHideChildren2 titles={["Tree", "Add", "Edit", "Assign CSS"]}>
        <div>
          <SectionTitle
            label="Select, move, clone or delete HTML"
            size="large"
          />
          <div className="mt-1r">
            <MoveOrDeleteButtonsHtml />
          </div>

          <SectionTitle label="HTML tree" />
          <HtmlTreeForSnippet
            snippet={editSnippet}
            selectedItem={editHtml}
            itemOnClick={(item: itemIF) => {
              dispatch(
                setEditItemId({
                  itemType,
                  newEditItemId: item.id,
                })
              );
            }}
          />
        </div>

        <AddHtmlBoxWithButton />

        <div className="form-elements-wrapper">
          {editHtml?.name === "svg" && (
            <div className="mt-2r">
              <AssignSvgToHtml />
            </div>
          )}
          <div className="mt-1r">
            <EditItem
              editItem={editHtml}
              itemType={itemType}
              snippet={editSnippet}
            />
          </div>
        </div>

        <div>
          <div className="mb-1r">
            <SectionTitle label="Assign class to HTML" size="large" />
          </div>

          <AssignClassToHtml itemType={itemType} />
        </div>
      </DisplayHideChildren2>
    </div>
  );
};

export default CRUDHtml;
