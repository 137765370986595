import { FC, useEffect, useState } from "react";
import { formatRgb, parse, converter } from "culori";
import {
  convertRgbaToCulori,
  tRgba,
  noColourRgba,
} from "../../lib/utils/color";
import { itemTypes } from "../../lib/meta/getMetas";
import { snippetIF } from "../../data/types/snippets";
import { itemIF } from "../../data/types/item";
import { useStateContext } from "../../lib/state/state";
import { setNewItemValue } from "../../lib/reducer/items/actions";
import { ColourPickerAB2 } from "./ColorPickerAB/ColourPickerHsla2";

export const CssexyWrapperForColorPicker: FC<{
  itemType: itemTypes;
  fieldName: string;
  item: itemIF;
  snippet: snippetIF;
  isFieldDisabled?: boolean;
  addOrRemoveEditItemId?: number;
  fieldGroupName?: string;
  editSnippetIdOther?: string;
}> = ({
  itemType,
  fieldName,
  item,
  snippet,
  isFieldDisabled,
  addOrRemoveEditItemId,
  fieldGroupName,
  editSnippetIdOther,
}) => {
  const { dispatch } = useStateContext();

  const fieldValue: tRgba = item.value?.[fieldName]?.value ?? noColourRgba;
  const culoriColor = convertRgbaToCulori(fieldValue);
  const [colorStr, setColorStr] = useState<string>(formatRgb(culoriColor));

  useEffect(() => {
    const color = parse(colorStr);
    if (!color) {
      console.error("Invalid color provided");
      return;
    }
    const toRgb = converter("rgb");
    const rgb = toRgb(color);

    const denormalized: tRgba = {
      r: Math.round(rgb.r * 255),
      g: Math.round(rgb.g * 255),
      b: Math.round(rgb.b * 255),
      a: rgb.alpha || rgb.alpha === 0 ? rgb.alpha : 1,
    };
    if (
      denormalized.r !== fieldValue.r ||
      denormalized.g !== fieldValue.g ||
      denormalized.b !== fieldValue.b ||
      denormalized.a !== fieldValue.a
    ) {
      dispatch(
        setNewItemValue({
          addOrRemoveEditItemId,
          editItemId: item.id,
          fieldGroupName,
          fieldName,
          itemType,
          value: denormalized,
          editSnippetId: editSnippetIdOther,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorStr]);

  const stylepacks = snippet?.data?.stylepacks;
  const stylepack = stylepacks?.find(
    (s) => s.id === snippet?.data?.editStylepackId
  );
  const presetColours = stylepack?.value?.stylepackColors?.value as itemIF[];
  if (!presetColours || presetColours.length === 0) return null;

  if (!presetColours || presetColours.length === 0) {
    return null;
  }
  const presetColourStrs: string[] = presetColours.map((c) => {
    const rgba: tRgba = c.value?.color?.value ?? { r: 0, g: 0, b: 0, a: 1 };
    const culoriColor = convertRgbaToCulori(rgba);
    const formatted = formatRgb(culoriColor);

    return formatted;
  });

  return (
    <ColourPickerAB2
      colour={colorStr}
      setColour={setColorStr}
      presetColours={presetColourStrs}
      isFieldDisabled={isFieldDisabled}
    />
  );
};
