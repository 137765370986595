import { itemIF, TSelectorData } from "../../../data/types/item";
import { snippetIF } from "../../../data/types/snippets";
import { itemTypes } from "../../meta/getMetas";
import { getItemsOfType } from "../../snippets/addAndGetMetas";
import { addCssStrForProperties } from "./getSelectorData";

export const getKeyframesAndStepsForAssignedAnimations = ({
  assignedAnimations,
  snippet,
}: {
  assignedAnimations: itemIF[];
  snippet:snippetIF;
}) => {
  const selectors = getItemsOfType({
    itemType: itemTypes.SELECTORS,
    snippet,
  });
  const properties = getItemsOfType({
    itemType: itemTypes.CLASSES_CSS,
    snippet,
  });
  const assignedKeyframesAndSteps = [] as itemIF[];
  assignedAnimations?.forEach((animation) => {
    const keyframe = selectors.find(
      (s) =>
        s.name === "@keyframes" &&
        s.value?.main?.value &&
        s.value.main.value === animation.value?.keyframeId?.value
    );
    if (!keyframe) {
      return;
    }
    const stepsWithCss = selectors?.filter((selector) => {
      if (selector.name === "step" && selector.parent === keyframe.id) {
        if (properties.find((p) => p.classId === selector.id)) {
          return true;
        }
      }
      return false;
    });
    if (stepsWithCss.length > 1) {
      assignedKeyframesAndSteps.push(keyframe);
      stepsWithCss.forEach((step) => assignedKeyframesAndSteps.push(step));
    }
  });
  return assignedKeyframesAndSteps;
};

export const getKeyframesSData = ({
  assignedKeyframesAndSteps,
  properties,
  lineBreak = "",
  addSpaceAtLineStart = "",
  snippet
}: {
  assignedKeyframesAndSteps: itemIF[];
  properties: itemIF[];
  lineBreak?: string;
  addSpaceAtLineStart?: string;
  snippet: snippetIF;
}) => {
  const keyframes = assignedKeyframesAndSteps.filter(
    (selector) => selector.name === "@keyframes"
  );
  const keyframesAndStepsSDataS = [] as TSelectorData[];
  keyframes.forEach((selector) => {
    if (!selector.value?.main?.value) {
      return;
    }
    const newKeyframesSData = {
      nameStr: `@keyframes ${selector.value.main.value}`,
      selector,
    } as TSelectorData;
    keyframesAndStepsSDataS.push(newKeyframesSData);

    const steps = assignedKeyframesAndSteps.filter(
      (s) => s.name === "step" && s.parent === selector.id
    );
    steps.forEach((step) => {
      if (!step.value?.step?.value) {
        return;
      }
      const stepProperties = properties?.filter((p) => p.classId === step.id);

      addCssStrForProperties({
        sData: newKeyframesSData,
        properties: stepProperties,
        selector,
        lineBreak,
        addSpaceAtLineStart,
        snippet
      })

      const newStepSData = {
        nameStr: `${step.value.step.value}% {`,
        selector: step,
        css: newKeyframesSData.css,
      } as TSelectorData;
      keyframesAndStepsSDataS.push(newStepSData);
    });
  });
  return keyframesAndStepsSDataS;
};

export const getKeyframesAndStepsCss = ({
  keyframesAndStepsSDataS,
  lineBreak = "",
  addSpaceAtLineStart = "",
}: {
  keyframesAndStepsSDataS: TSelectorData[];
  lineBreak?: string;
  addSpaceAtLineStart?: string;
}) => {
  const keyframesSDataS = keyframesAndStepsSDataS.filter(
    (sData) => sData.selector.name === "@keyframes"
  );
  let keyframeCss = "";
  keyframesSDataS.forEach((keyframeSData) => {
    keyframeCss += `${addSpaceAtLineStart}${keyframeSData.nameStr} {${lineBreak}`;

    const stepsSDataS = keyframesAndStepsSDataS.filter(
      (sData) =>
        sData.selector.name === "step" &&
        sData.selector.parent === keyframeSData.selector.id
    );
    stepsSDataS.forEach((sData) => {
      keyframeCss += `${addSpaceAtLineStart}      ${sData.nameStr} ${lineBreak}`;
      keyframeCss += `${sData.css}`;
      keyframeCss += `${addSpaceAtLineStart}      }${lineBreak}`;
    });

    keyframeCss += `${addSpaceAtLineStart}}${lineBreak}`;
  });

  return keyframeCss;
};
