import { metaIF, EMetaFieldTypes } from "../../types/item";
import { standardUnits } from "./utils";
export const CSS_PROPERTIES_TEXT: metaIF[] = [
  {
    name: "color",
    category: ["TEXT"],
    fieldsOrder: ["main", "colorPicker"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        options: [{ id: "value" }],
        canBeInherited: true,
        canBeInitial: true,
        onOff: true,
      },
      {
        name: "colorPicker",
        type: EMetaFieldTypes.colourPicker,
        dependentOn: {
          field: "main",
          values: ["value"],
        },
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main", "colorPicker"],
        display: ["select", "color"],
      },
    ],
    default: {
      main: { value: "value" },
      colorPicker: { value: { r: 60, g: 188, b: 195, a: 1 } },
    },
  },
  {
    name: "text-fill-color",
    category: ["TEXT"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.colourPicker,
        onOff: true,
        help:
          "### text-fill-color  \n" +
          "When used together with the `background-clip` property with the value `text`, and a color with transparency (alpha value smaller than 100), then you can display one or more background images or gradients as text color.  \n" +
          "For more information see for example [codrops - Experiments with background-clip: text](https://tympanus.net/codrops/2011/12/12/experiments-with-background-clip-text/)",
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["text-fill-color"],
      },
    ],
    default: { main: { value: { r: 60, g: 188, b: 195, a: 1 } } },
  },
  {
    name: "font-family",
    category: ["TEXT"],
    fieldsOrder: ["main"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        canBeInherited: true,
        canBeInitial: true,
        onOff: true,
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["font-family"],
      }
    ],
    default: {
      main: {        
        value: `Georgia, serif`,
      },
    },
    help: "### font-family  \nYou can add and delete Google fonts in Snippets > Stylepacks > Fonts.",
  },
  {
    name: "font-size",
    category: ["TEXT"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnits,
        canBeInherited: true,
        canBeInitial: true,
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["font-size"],
      },
    ],
    default: { main: { value: 1.5, unit: "rem" } },
  },
  {
    name: "font-smooth",
    category: ["TEXT"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          { id: "auto" },
          { id: "always" },
          { id: "never" },
          { id: "antialiased" },
          { id: "value" },
        ],
      },
      {
        name: "value",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: [{ id: "rem" }, { id: "px" }, { id: "vw" }],
        dependentOn: {
          field: "main",
          values: ["value"],
        },
      },
    ],
    group: [
      {
        name: "mainG",
        display: ["font-smooth"],
        fields: ["main"],
      },
      {
        name: "valueG",
        fields: ["value"],
        display: ["value"],
      },
    ],
    default: { main: { value: "always" }, value: { value: 2, unit: "rem" } },
  },
  {
    name: "font-style",
    category: ["TEXT"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "normal",
          },
          {
            id: "italic",
          },
          {
            id: "oblique",
          },
        ],
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["font-style"],
      },
    ],
    default: { main: { value: "normal" } },
  },

  {
    name: "font-weight",
    category: ["TEXT"],
    fieldsOrder: ["main", "value"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "lighter",
          },
          {
            id: "normal",
          },
          {
            id: "bold",
          },
          {
            id: "bolder",
          },
          {
            id: "value",
          },
        ],
        canBeInherited: true,
        canBeInitial: true,
      },
      {
        name: "value",
        type: EMetaFieldTypes.number,
        onOff: true,
        dependentOn: {
          field: "main",
          values: ["value"],
        },
      },
    ],
    group: [
      {
        name: "fontWeightSelectG",
        fields: ["main", "value"],
        display: ["font-weight", "value"],
      },
    ],
    default: { main: { value: "normal" }, value: { value: 300 } },
  },
  {
    name: "font-variant",
    category: ["TEXT"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "normal",
          },
          {
            id: "small-caps",
          },
        ],
        help:
          "#### font-variant \n" +
          "`small-caps` converts lowercase letters to uppercase letters, but the uppercase letters appear in a smaller font size than the original uppercase letters.",
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["font-variant"],
      },
    ],
    default: { main: { value: "normal" } },
  },

  {
    name: "letter-spacing",
    category: ["TEXT"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: [
          { id: "px" },
          { id: "pt" },
          { id: "cm" },
          { id: "em" },
          { id: "rem" },
        ],
        help:
          "### letter-spacing  \n" +
          "Sets extra space between characters. The default is 0. Negative values are allowed.",
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["letter-spacing"],
      },
    ],
    default: {
      main: {
        value: 3,
        unit: "px",
      },
    },
  },
  {
    name: "line-height",
    category: ["TEXT"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnits,
        canBeInherited: true,
        canBeInitial: true,
        help:
          "#### line-height  \n" +
          "Sets the line height for text. A growing line-height increases the speace on top and bottom of every text row.  \n" +
          "If `%` is selected as unit, the percentage is relative to the size if the font within the element.",
      },
    ],
    group: [{ name: "mainG", fields: ["main"], display: ["line-height"] }],
    default: { main: { value: 1.5, unit: "rem" } },
  },
  {
    name: "text-align",
    category: ["TEXT"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "left",
          },
          {
            id: "right",
          },
          {
            id: "center",
          },
          {
            id: "justify",
          },
        ],
        canBeInitial: true,
        canBeInherited: true,
      },
    ],
    group: [{ name: "mainG", fields: ["main"], display: ["text-align"] }],
    default: { main: { value: "left" } },
  },
  {
    name: "text-decoration",
    category: ["TEXT"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          { id: "none" },
          { id: "underline" },
          { id: "overline" },
          { id: "line-through" },
        ],
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["text-decoration"],
      },
    ],
    default: { main: { value: "underline" } },
  },
  {
    name: "text-indent",
    category: ["TEXT"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: [{ id: "px" }, { id: "rem" }, { id: "%" }],
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["text-indent"],
      },
    ],
    default: { main: { value: 1, unit: "rem" } },
  },
  {
    name: "text-overflow",
    category: ["TEXT"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "clip",
          },
          {
            id: "ellipsis",
          },
        ],
        help:
          "#### text-overflow  \n" +
          "When text is overflowing its container on the right, you can show with `text-overflow` " +
          "that text is hidden. If you select `ellipse`, 3 dots are shown before the hidden text.",
        warning:
          "#### text-overflow  \n" +
          "For `text-overflow` to have an effect, the following properties have to be set:  \n" +
          "`overflow: hidden;`  \n" +
          "`white-space: nowrap;`",
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["text-overflow"],
      },
    ],
    default: { main: { value: "ellipsis" } },
  },
  {
    name: "text-transform",
    category: ["TEXT"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          { id: "none" },
          { id: "capitalize" },
          { id: "uppercase" },
          { id: "lowercase" },
        ],
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["text-transform"],
      },
    ],
    default: { main: { value: "none" } },
  },
  {
    name: "vertical-align",
    category: ["TEXT"],
    fieldsOrder: ["main", "align"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        help:
          "### vertical-align  \n" +
          "Aligns an inline element vertically relative to the standard text line. You can align, for example letters or words and images.  \n",
        options: [
          {
            id: "baseline",
            help:
              "#### baseline  \n" +
              "The bottom of a letter that doesn't descent downwards, for example a.",
          },
          {
            id: "sub",
            help:
              "#### sub  \n" +
              "Subscript baseline of the font which is lower than the bottom of g.",
          },
          {
            id: "super",
            help:
              "#### super  \n" +
              "Superscript baseline of the font which is higher than the top of f.",
          },
          {
            id: "top",
            help: "#### top  \nTop of the tallest element in the line.",
          },
          {
            id: "text-top",
            help: "#### text-top  \nTop of the baseline of the font, for example the top of f.",
          },
          {
            id: "middle",
            help:
              "#### middle  \n" +
              "The middle between the top of the tallest and the bottom of the lowest element in the line.",
          },
          {
            id: "bottom",
            help: "#### bottom  \nBottom of the lowest element in the line.",
          },
          {
            id: "text-bottom",
            help:
              "#### text-bottom  \n" +
              "Bottom of the baseline of the font, for example the bottom of g.",
          },
          {
            id: "value",
            help:
              "#### value  \n" +
              "Move the inline element up (positive value) or down (negative value). " +
              "If you choose % as unit, the `value` is relative to the line height.",
          },
        ],
      },
      {
        name: "align",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: [{ id: "%" }, { id: "px" }, { id: "rem" }],
        dependentOn: {
          field: "main",
          values: ["value"],
        },
      },
    ],
    group: [
      {
        name: "vertical-align",
        fields: ["main"],
      },
      {
        name: "value",
        fields: ["align"],
      },
    ],

    default: {
      main: { value: "baseline" },
      align: { value: 20, unit: "%" },
    },
  },
  {
    name: "white-space",
    category: ["TEXT"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        help:
          "### white-space  \n" +
          "Controls if multiple whitespaces in a sequence are collapsed to one and if text wraps automatically in its container.  \n" +
          "`normal`: Sequences of whitespaces are collapsed to one. Text will wrapp when necessary.  \n" +
          "`nowrap`: Sequences of whitespaces are collapsed to one. Text will not wrapp automatically.  \n" +
          "`pre`: Sequences of whitespaces are preserved. Text will only wrap when line breaks are found.  \n" +
          "`pre-line`: Sequences of whitespaces are collapsed to one. Text will wrapp when necessary and on line break.  \n" +
          "`pre-wrap`: Sequences of whitespaces are preserved. Text will wrapp when necessary and on line break.",
        options: [
          {
            id: "normal",
          },
          {
            id: "nowrap",
          },
          {
            id: "pre",
          },
          {
            id: "pre-line",
          },
          {
            id: "pre-wrap",
          },
        ],
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["white-space"],
      },
    ],
    default: { main: { value: "normal" } },
  },
  {
    name: "word-break",
    category: ["TEXT"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "normal",
          },
          {
            id: "break-all",
          },
          {
            id: "keep-all",
          },
          {
            id: "break-word",
          },
        ],
        help: "### word-break  \nSee for example [CSS-TRICKS - Handling Long Words and URLs](https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/)",
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["word-break"],
      },
    ],
    default: { main: { value: "break-word" } },
  },

  {
    name: "word-spacing",
    category: ["TEXT"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: [
          { id: "px" },
          { id: "pt" },
          { id: "cm" },
          { id: "em" },
          { id: "rem" },
        ],
        help:
          "### word-spacing  \n" +
          "Sets the space between words. The default is 0.25em. Negative values are allowed.",
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["word-spacing"],
      },
    ],
    default: {
      main: {
        value: 0.5,
        unit: "em",
      },
    },
  },
  {
    name: "word-wrap",
    category: ["TEXT"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          { id: "normal" },
          { id: "break-word" },
          { id: "initial" },
          { id: "inherit" },
        ],
        help:
          "### word-wrap  \n" +
          "See for example [w3schools - CSS word-wrap Property](https://www.w3schools.com/cssref/css3_pr_word-wrap.asp).",
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["word-wrap"],
      },
    ],
    default: {
      main: {
        value: "normal",
      },
    },
  },
  {
    name: "writing-mode",
    category: ["TEXT"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          { id: "horizontal-tb" },
          { id: "vertical-rl" },
          { id: "vertical-lr" },
        ],
        help: "### writing-mode  \nSee for example [w3schools - CSS writing mode property](https://www.w3schools.com/cssref/css3_pr_writing-mode.asp)",
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["writing-mode"],
      },
    ],
    default: {
      main: {
        value: "vertical-rl",
      },
    },
  },
];
