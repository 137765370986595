import React, { FC } from "react";
import { useStateContext } from "../../lib/state/state";
import { SUB_PAGES } from "../../data/settings/constants";
import { selectSubPage } from "../../lib/reducer/settings/actions";
import { OpenMenuButtonAndModal } from "./OpenMenu";
import { SyncEditSnippetBtn } from "../cloud/UpdateSnippetInCloud";

import "./NavbarMenu.css";
import "./cssexyNav.css";

const MainSubpage: FC<{
  setIsMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setIsMenuOpen }) => {
  const {
    state: {
      settings: { selectedSubPage },
    },
    dispatch,
  } = useStateContext();

  return (
    <>
      <ul className="sub-nav-ul main-subpage small">
        <li>
          <button
            type="button"
            className={`sub-nav-link${selectedSubPage === SUB_PAGES.SNIPPETS ? " active" : ""}`}
            onClick={() => {
              if (selectedSubPage !== SUB_PAGES.SNIPPETS) {
                dispatch(selectSubPage(SUB_PAGES.SNIPPETS));
                setIsMenuOpen && setIsMenuOpen(false);
              }
            }}
          >
            SNIPPET
          </button>
        </li>
        <li>
          <button
            type="button"
            className={`sub-nav-link${selectedSubPage === SUB_PAGES.HTML ? " active" : ""}`}
            onClick={(e) => {
              e.preventDefault();
              if (selectedSubPage !== SUB_PAGES.HTML) {
                dispatch(selectSubPage(SUB_PAGES.HTML));
                setIsMenuOpen && setIsMenuOpen(false);
              }
            }}
          >
            HTML
          </button>
        </li>
        <li>
          <button
            type="button"
            className={`sub-nav-link${selectedSubPage === SUB_PAGES.CSS ? " active" : ""}`}
            onClick={(e) => {
              e.preventDefault();
              if (selectedSubPage !== SUB_PAGES.CSS) {
                dispatch(selectSubPage(SUB_PAGES.CSS));
                setIsMenuOpen && setIsMenuOpen(false);
              }
            }}
          >
            CSS
          </button>
        </li>

        <li>
          <button
            type="button"
            className={`sub-nav-link${selectedSubPage === SUB_PAGES.SVG ? " active" : ""}`}
            onClick={(e) => {
              e.preventDefault();
              if (selectedSubPage !== SUB_PAGES.SVG) {
                dispatch(selectSubPage(SUB_PAGES.SVG));
                setIsMenuOpen && setIsMenuOpen(false);
              }
            }}
          >
            SVG
          </button>
        </li>
      </ul>
    </>
  );
};

const MainCss: FC<{
  selOrProp: TSelOrProp;
  setSelOrProp: (selOrProp: TSelOrProp) => void;
  setIsMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ selOrProp, setSelOrProp, setIsMenuOpen }) => {
  return (
    <div className="main-css">
      <div className="separator" />
      <ul className="sub-nav-ul small">
        <li>
          <button
            type="button"
            className={`sub-nav-link${selOrProp === "SELECTOR" ? " active" : ""}`}
            onClick={() => {
              setSelOrProp("SELECTOR");
              setIsMenuOpen && setIsMenuOpen(false);
            }}
          >
            SELECTOR
          </button>
        </li>

        <li>
          <button
            type="button"
            className={`sub-nav-link${selOrProp === "PROPERTY" ? " active" : ""}`}
            onClick={(e) => {
              setSelOrProp("PROPERTY");
              setIsMenuOpen && setIsMenuOpen(false);
            }}
          >
            CSS PROPERTY
          </button>
        </li>
      </ul>
    </div>
  );
};

const MainAll: FC<{
  selOrProp: TSelOrProp;
  setSelOrProp: (selOrProp: TSelOrProp) => void;
  setIsMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ selOrProp, setSelOrProp, setIsMenuOpen }) => {
  const {
    state: {
      settings: { selectedSubPage },
    },
    dispatch,
  } = useStateContext();

  return (
    <ul className="sub-nav-ul main-css small">
      <li>
        <button
          type="button"
          className={`sub-nav-link${selectedSubPage === SUB_PAGES.SNIPPETS ? " active" : ""}`}
          onClick={() => {
            if (selectedSubPage !== SUB_PAGES.SNIPPETS) {
              dispatch(selectSubPage(SUB_PAGES.SNIPPETS));
              setIsMenuOpen && setIsMenuOpen(false);
            }
          }}
        >
          SNIPPET
        </button>
      </li>
      <li>
        <button
          type="button"
          className={`sub-nav-link${selectedSubPage === SUB_PAGES.HTML ? " active" : ""}`}
          onClick={(e) => {
            e.preventDefault();
            if (selectedSubPage !== SUB_PAGES.HTML) {
              dispatch(selectSubPage(SUB_PAGES.HTML));
              setIsMenuOpen && setIsMenuOpen(false);
            }
          }}
        >
          HTML
        </button>
      </li>
      <li>
        <button
          type="button"
          className={`sub-nav-link${selectedSubPage === SUB_PAGES.CSS ? " active" : ""}`}
          onClick={(e) => {
            e.preventDefault();
            if (selectedSubPage !== SUB_PAGES.CSS) {
              dispatch(selectSubPage(SUB_PAGES.CSS));
              setIsMenuOpen && setIsMenuOpen(false);
            }
          }}
        >
          CSS
        </button>
      </li>

      <li>
        <button
          type="button"
          className={`sub-nav-link${selectedSubPage === SUB_PAGES.SVG ? " active" : ""}`}
          onClick={(e) => {
            e.preventDefault();
            if (selectedSubPage !== SUB_PAGES.SVG) {
              dispatch(selectSubPage(SUB_PAGES.SVG));
              setIsMenuOpen && setIsMenuOpen(false);
            }
          }}
        >
          SVG
        </button>
      </li>

      <li className="separator" />

      <li>
        <button
          type="button"
          className={`sub-nav-link${selOrProp === "SELECTOR" ? " active" : ""}`}
          onClick={() => {
            setSelOrProp("SELECTOR");
            setIsMenuOpen && setIsMenuOpen(false);
          }}
        >
          SELECTOR
        </button>
      </li>

      <li>
        <button
          type="button"
          className={`sub-nav-link${selOrProp === "PROPERTY" ? " active" : ""}`}
          onClick={(e) => {
            setSelOrProp("PROPERTY");
            setIsMenuOpen && setIsMenuOpen(false);
          }}
        >
          CSS PROPERTY
        </button>
      </li>
    </ul>
  );
};

const MidNavMain: FC<{
  selOrProp: TSelOrProp;
  setSelOrProp: (selOrProp: TSelOrProp) => void;
  setIsMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ selOrProp, setSelOrProp, setIsMenuOpen }) => {
  return (
    <div className="full">
      <MainSubpage setIsMenuOpen={setIsMenuOpen} />

      <div className="upload ">
        <SyncEditSnippetBtn />
      </div>

      <MainCss
        selOrProp={selOrProp}
        setSelOrProp={setSelOrProp}
        setIsMenuOpen={setIsMenuOpen}
      />
    </div>
  );
};

const MidNavMainNoCss: FC<{
  setIsMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setIsMenuOpen }) => {
  return (
    <div className="no-css">
      <MainSubpage setIsMenuOpen={setIsMenuOpen} />

      <div className="upload ">
        <SyncEditSnippetBtn notificationPosition="right" />
      </div>
    </div>
  );
};

export type TSelOrProp = "SELECTOR" | "PROPERTY";

export const MidNav = ({
  selOrProp,
  setSelOrProp,
  setIsMenuOpen,
}: {
  selOrProp: TSelOrProp;
  setSelOrProp: (selOrProp: TSelOrProp) => void;
  setIsMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const {
    state: {
      settings: { selectedSubPage },
    },
  } = useStateContext();

  return (
    <div className="mid-nav-wrapper-box">
      <div className="container">
        <div className="mid-nav-wrapper">
          <div className="main">
            {selectedSubPage === SUB_PAGES.CSS && (
              <MidNavMain selOrProp={selOrProp} setSelOrProp={setSelOrProp} />
            )}
            {selectedSubPage !== SUB_PAGES.CSS && (
              <MidNavMainNoCss setIsMenuOpen={setIsMenuOpen} />
            )}
          </div>
          <div className="hamburger-main">
            <OpenMenuButtonAndModal icon="widgets">
              {selectedSubPage !== SUB_PAGES.CSS && (
                <MainSubpage setIsMenuOpen={setIsMenuOpen} />
              )}
              {selectedSubPage === SUB_PAGES.CSS && (
                <MainAll selOrProp={selOrProp} setSelOrProp={setSelOrProp} />
              )}
            </OpenMenuButtonAndModal>
            <div className="upload ">
              <SyncEditSnippetBtn notificationPosition="right" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
