import { FC, useEffect, useRef, useState } from "react";
import { itemIF } from "../../data/types/item";
import { useStateContext } from "../../lib/state/state";
import { setNewItemValue } from "../../lib/reducer/items/actions";
import { itemTypes } from "../../lib/meta/getMetas";

type TEditFieldTextarea = {
  itemType: itemTypes;
  fieldName: string;
  editItem: itemIF;
  isFieldDisabled?: boolean;

  addOrRemoveEditItemId?: number;
  fieldGroupName?: string;
  editSnippetIdOther?: string;
};
export const EditFieldTextarea: FC<TEditFieldTextarea> = ({
  itemType,
  fieldName,
  isFieldDisabled,
  editItem,

  addOrRemoveEditItemId,
  fieldGroupName,
  editSnippetIdOther,
}) => {
  const { dispatch } = useStateContext();
  const fieldValue = editItem.value?.[fieldName]?.value;

  return (
    <EditFieldTextareaSimple
      fieldName={fieldName}
      fieldValue={fieldValue}
      isFieldDisabled={isFieldDisabled}
      setValue={(value) => {
        dispatch(
          setNewItemValue({
            addOrRemoveEditItemId,
            editItemId: editItem.id,
            fieldGroupName,
            fieldName,
            itemType,
            value,
            editSnippetId: editSnippetIdOther,
          })
        );
      }}
    />
  );
};

export const EditFieldTextareaSimple: FC<{
  fieldName: string;
  fieldValue: string;
  isFieldDisabled?: boolean;
  setValue: (value: string) => void;
}> = ({ fieldName, fieldValue, isFieldDisabled, setValue }) => {
  const [newValue, setNewValue] = useState(fieldValue);

  // for autosize of textarea
  const textRef = useRef<any>(null);

  useEffect(() => {
    textRef.current.style.height = "auto";
    textRef.current.style.height = `${textRef.current.scrollHeight}px`;

    setNewValue(fieldValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValue]);

  return (
    <textarea
      title={`Enter ${fieldName}`}
      disabled={isFieldDisabled}
      cols={45}
      rows={4}
      value={newValue}
      ref={textRef}
      onChange={(event) => {
        setNewValue(event.target.value);
      }}
      onBlur={() => {
        if (newValue === fieldValue) return;

        setValue(newValue);
      }}
    />
  );
};
