import { itemIF } from "../../../data/types/item";
import { snippetIF } from "../../../data/types/snippets";
import { getFeaturingSelectors } from "./getFeaturingSelectors";
import { getCssForStep, getCssForMedia } from "./getCssCode";
import { getCssForClassOrHtml } from "./getSelectorData";
import { itemTypes } from "../../meta/getMetas";

export type TCreateComposedSelectorItem = {
  selector?: itemIF;
  selectors?: itemIF[];
  properties?: itemIF[];
  lineBreak?: string;
  addSpaceAtLineStart?: string;
  snippet: snippetIF;
};

const createComposedSelectorItem = ({
  selector,
  lineBreak,
  addSpaceAtLineStart = "",
  snippet
}: TCreateComposedSelectorItem) => {
  const data = {
    selector,
    lineBreak,
    addSpaceAtLineStart,
    snippet
  } as TCreateComposedSelectorItem;
  if (selector?.name === "@keyframes") {
    const ssForKeyframes = {
      nameStr: `@keyframes ${selector.value?.main.value}`,
      selector,
    };
    return ssForKeyframes;
  } else if (selector?.name === "step") {
    const cssForStep = getCssForStep(data);
    return cssForStep;
  } else if (selector?.name === "@media") {
    const cssForMedia = getCssForMedia(data);
    return cssForMedia;
  } else {
    const cssForClassOrHtml = getCssForClassOrHtml(data);
    return cssForClassOrHtml;
  }
};

export const initializeComposedSelectorItems = ({
  snippet,
}: {
  snippet: snippetIF;
}) => {
  const linebreak = "\n";

  const { classSelectors, mediaSelectors, keyframeSelectors } =
    getFeaturingSelectors({
      itemType: itemTypes.BLOCK_HTML,
      snippet
    });

  const classComposedSelectorItems = classSelectors.map((item) =>
    createComposedSelectorItem({
      selector: item,
      lineBreak: linebreak,
      snippet
    })
  );

  const mediaComposedSelectorItems = mediaSelectors.map((item) =>
    createComposedSelectorItem({
      selector: item,
      lineBreak: linebreak,
      snippet
    })
  );

  const keyframeComposedSelectorItems = keyframeSelectors.map((item) =>
    createComposedSelectorItem({
      selector: item,
      lineBreak: linebreak,
      snippet
    })
  );

  return {
    classComposedSelectorItems,
    mediaComposedSelectorItems,
    keyframeComposedSelectorItems,
  };
};
