import React, { FC } from "react";
import { QueryGetSnippetsArgs } from "cssexy-be/src/generated/graphql";
import { OpenPopup } from "../../modules/OpenPopup";
import { RenderOpenModal } from "../../modules/TailwindModal";
import { PopupSort } from "./PopupSort";
import { MineOrAllPublic } from "./MineOrAllPublic";
import { SearchInput } from "./SearchInput";
import "./SortAndFilter.css";

const FilterCombined: FC<{
  sortAndFilterParams: QueryGetSnippetsArgs;
  setSortAndFilterParams: React.Dispatch<
    React.SetStateAction<QueryGetSnippetsArgs>
  >;
  setIsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ sortAndFilterParams, setSortAndFilterParams, setIsModalOpen }) => {
  return (
    <>
      <div className="m-1r border-b-l4">
        <MineOrAllPublic
          sortAndFilterParams={sortAndFilterParams}
          setSortAndFilterParams={setSortAndFilterParams}
          setIsModalOpen={setIsModalOpen}
        />
      </div>
      <div className="p-1r pb-2r">
        <SearchInput
          sortAndFilterParams={sortAndFilterParams}
          setSortAndFilterParams={setSortAndFilterParams}
          setIsModalOpen={setIsModalOpen}
        />
      </div>
    </>
  );
};

export const SortAndFilterGallery: FC<{
  sortAndFilterParams: QueryGetSnippetsArgs;
  setSortAndFilterParams: React.Dispatch<
    React.SetStateAction<QueryGetSnippetsArgs>
  >;
}> = ({ sortAndFilterParams, setSortAndFilterParams }) => {
  return (
    <section className="filters-section mb-2r">
      <h2 className="sr-only">Product filters</h2>
      <div className="sort-and-filter-gallery">
        <div className="sort-container">
          <OpenPopup
            PopupInner={
              <PopupSort
                sortAndFilterParams={sortAndFilterParams}
                setSortAndFilterParams={setSortAndFilterParams}
              />
            }
          />
        </div>
        <div className="title">Snippet Gallery</div>

        <RenderOpenModal
          title="Select filter"
          icon="open"
          direction="right"
          component={
            <FilterCombined
              sortAndFilterParams={sortAndFilterParams}
              setSortAndFilterParams={setSortAndFilterParams}
            />
          }
        ></RenderOpenModal>
      </div>
    </section>
  );
};
