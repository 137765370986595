import { metaIF, itemIF } from "../../data/types/item";
import { snippetIF } from "../../data/types/snippets";
import { itemTypes } from "../meta/getMetas";
import { getMetaFromSnippet } from "../snippets/addAndGetMetas";

export const isAllowedAddElement = ({
  m,
  allowed,
}: {
  m: metaIF;
  allowed?: string[];
}) => {
  if (allowed) {
    if (allowed.includes("all")) {
      return true;
    }
    if (allowed.includes("inline")) {
      if (m.level === "inline") {
        return true;
      }
    }
    if (allowed.includes(m.name)) {
      return true;
    }
  }
  return false;
};

/*
sequence of events

edit item meta has allowedChildren -> if item is in allowedChildren, return true

item meta has allowedParent -> if edit item is in allowedParent, return true

edit item meta has allowedSiblings -> if item is in allowedSiblings, return false, otherwise true

item meta has allowedSiblings -> if edit item is in allowedSiblings, return false, otherwise true

if item name is "text" and edit item name is not "text", return true

if edit item name is "text", return false

if item meta has availableOnlyLevel and the level is equal to edit item level, return false

if item name is not "svg" and edit item level is 1, return true

if edit meta level is "block" and item meta level is "inline", return true

if edit meta level is "inline" and item meta level is "block", return false

return false

*/

export const getAddAsSiblingOrChild = ({
  itemType,
  newItem,
  editItem,
  snippet
}: {
  itemType: itemTypes;
  newItem: itemIF;
  editItem?: itemIF;
  snippet: snippetIF;
}) => {
  if (!editItem) {
    return false;
  }

  const meta = getMetaFromSnippet({
    snippet,
    itemType,
    itemName: newItem.name,
  }) 
  const editItemMeta = getMetaFromSnippet({
    snippet,
    itemType,
    itemName: editItem.name,
  }) 
  
  if (!meta || !editItemMeta || meta.name === editItemMeta.name) {
    return false;
  }

  if (editItemMeta.allowedChildren) {
    const emAllowedChildren = isAllowedAddElement({
      allowed: editItemMeta.allowedChildren,
      m: meta,
    });
    if (emAllowedChildren) {
      return true;
    }
  }

  if (meta.allowedParent) {
    const mAllowedParent = isAllowedAddElement({
      allowed: meta.allowedParent,
      m: editItemMeta,
    });
    if (mAllowedParent) {
      return true;
    }
  }

  if (editItemMeta.allowedSiblings) {
    const emAllowedSiblings = isAllowedAddElement({
      allowed: editItemMeta.allowedSiblings,
      m: meta,
    });
    if(emAllowedSiblings) {
      return false;
    }
  }

  if (meta.allowedSiblings) {
    const mAllowedSiblings = isAllowedAddElement({
      allowed: meta.allowedSiblings,
      m: editItemMeta,
    });
    if(mAllowedSiblings) {
      return false;
    }
  }

  if (meta.name === "text") {
    return editItemMeta.name !== "text";
  }

  if (editItemMeta.name === "text") {
    return false;
  }

  if (meta.availableOnlyLevel) {
    if (meta.availableOnlyLevel === editItem.level) {
      return false;
    }
  }

  if (meta.name !== "svg" && editItem.level === 1) {
    return true;
  }

  if (editItemMeta.level === "block" && meta.level === "inline") {
    return true;
  }

  if (editItemMeta.level === "inline" && meta.level === "block") {
    return false;
  }

  return false;
};