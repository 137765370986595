import { FC } from "react";
import { useStateContext } from "../../lib/state/state";
import {
  itemTypes,
  getCategoryMetasForItemType,
} from "../../lib/meta/getMetas";
import { itemIF } from "../../data/types/item";
import { addItem } from "../../lib/reducer/items/actions";
import { TCSSCategory } from "../../data/css/constants/all";
import { SectionTitle } from "../modules/SectionTitle";
import {
  separetSetOnParent,
  isCssPropsBtnDisabled,
} from "../../lib/css/main";

const addCssPropertyOtherHelp =
  "### Add CSS property  \nThe following properties are disbaled under the given circumstances:  \n" +
  "- `content` and `counter-increment`: are used just within the pseudo-elements `::before` and `::after`.  \n" +
  "- `variable`: has an effect and is correct in the root element only.";

interface IAddCssProperty {
  items: itemIF[];
  editSelector?: itemIF;
  selectedCategory: TCSSCategory;
}
export const AddCssProperty: FC<IAddCssProperty> = ({
  items,
  editSelector,
  selectedCategory,
}) => {
  const {
    state: {
      snippet: { editSnippetId, snippets },
    },
    dispatch,
  } = useStateContext();
  const itemType = itemTypes.CLASSES_CSS;
  const cssMetaForSelectedCategory = getCategoryMetasForItemType({
    category: selectedCategory,
    itemType,
  });

  const parentChild = separetSetOnParent(cssMetaForSelectedCategory);

  const child = parentChild?.child?.filter((i) => {
    if (!i) {
      return false;
    }
    return true;
  });
  const parent = parentChild?.parent?.filter((i) => {
    if (!i) {
      return false;
    }
    return true;
  });

  const editSnippet = snippets.find((s) => s.id === editSnippetId);
  const editPropertyId = editSnippet?.data?.editPropertyId;

  let addCssPropertyHelp =
    selectedCategory === "OTHER" ? addCssPropertyOtherHelp : "";

  return (
    <>
      <div className="mt-2r mb-1r">
        <SectionTitle
          label="Select property"
          help={addCssPropertyHelp !== "" ? addCssPropertyHelp : undefined}
        />
      </div>
      <div>
        {child && (
          <div className="mb-05r">
            <SectionTitle label="Set on parent" />
          </div>
        )}
        <div className="btn-row mb-1r">
          {parent?.map((m) => {
            const isDisabled = isCssPropsBtnDisabled({
              meta: m,
              items,
              editSelector,
            });
            return (
              <button
                key={m.name}
                type="button"
                className={`button blue-outline${
                  isDisabled ? " disabled" : ""
                }`}
                onClick={() => {
                  if (isDisabled) return;

                  dispatch(
                    addItem({
                      itemType,
                      name: m.name,
                      addAfterItemId: editPropertyId,
                      editSelectorId: editSelector?.id,
                    })
                  );
                }}
              >
                {m.name}
              </button>
            );
          })}
        </div>
      </div>

      {child && (
        <div>
          <div className="mb-05r">
            <SectionTitle label="Set on child" />
          </div>
          <div className="btn-row mb-1r">
            {child.map((m) => {
              const isDisabled = isCssPropsBtnDisabled({
                meta: m,
                items,
                editSelector,
              });

              return (
                <button
                  key={m.name}
                  type="button"
                  className={`button blue-outline${
                    isDisabled ? " disabled" : ""
                  }`}
                  onClick={() => {
                    if (isDisabled) return;


                    dispatch(
                      addItem({
                        itemType,
                        name: m.name,
                        addAfterItemId: editPropertyId,
                        editSelectorId: editSelector?.id,
                      })
                    );
                  }}
                >
                  {m.name}
                </button>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};
