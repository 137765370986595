import { useState } from "react";
import { useStateContext } from "../../lib/state/state";
import { SUB_PAGES } from "../../data/settings/constants";
import CRUDHtml from "../html/Main2";
import Mysnippets from "../mySnippets/Main2";
import CRUDCSS from "../css/Main2";
import CRUDSVG from "../svg/Main2";
import MainLayout from "../MainLayout";
import { MidNav, TSelOrProp } from "../navbar/MidNav";
import { PreviewWithToolbar } from "../preview/PreviewWithToolbar";
import { itemTypes } from "../../lib/meta/getMetas";
import { Editor } from "./Editor";

const Code = () => {
  const {
    state: {
      settings: { selectedSubPage },
      snippet: { snippets, editSnippetId },
    },
  } = useStateContext();

  const [selOrProp, setSelOrProp] = useState("SELECTOR" as TSelOrProp);

  const editSnippet = snippets.find((s) => s.id === editSnippetId);

  const selectedPageIsSvg = selectedSubPage === SUB_PAGES.SVG;

  return (
    <MainLayout
      title="Snippet"
      description="Define reusable units of HTML, Selectors, CSS and SVGs with the help of a Snippet."
    >
      {!editSnippet && <div>Snippet not found</div>}
      {editSnippet && (
        <PreviewWithToolbar
          snippet={editSnippet}
          itemType={selectedPageIsSvg ? itemTypes.SVGS : itemTypes.BLOCK_HTML}
        />
      )}

      {/* <div className="container">
        <Editor />
      </div> */}

      <div className="mt-2r">
        <MidNav selOrProp={selOrProp} setSelOrProp={setSelOrProp} />
      </div>

      <div className="container mt-2r">
        {selectedSubPage === SUB_PAGES.SNIPPETS && <Mysnippets />}
        {selectedSubPage === SUB_PAGES.HTML && <CRUDHtml />}
        {selectedSubPage === SUB_PAGES.CSS && <CRUDCSS selOrProp={selOrProp} />}
        {selectedSubPage === SUB_PAGES.SVG && <CRUDSVG />}
      </div>
    </MainLayout>
  );
};

export default Code;
