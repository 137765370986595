import React, { useState, useRef, FC } from "react";
import { Color, formatHsl, Hsl } from "culori";
import {
  colorsAreEqual,
  getNewColor,
  colorToHsl,
} from "../../../lib/utils/culoriColor";
import { eHsl } from "../../../lib/utils/color";
import { ShowColor } from "./ColourPickerHsla_EnterHslRgbHex";
import { EnterNewValue } from "./ColourPickerHsla_EnterHslRgbHex";
import "./ColourPickerHsla_Forms.css";

const hslsForHueGradient: Hsl[] = [
  { mode: "hsl", h: 0, s: 1, l: 0.5, alpha: 1 },
  { mode: "hsl", h: 60, s: 1, l: 0.5, alpha: 1 },
  { mode: "hsl", h: 120, s: 1, l: 0.5, alpha: 1 },
  { mode: "hsl", h: 180, s: 1, l: 0.5, alpha: 1 },
  { mode: "hsl", h: 240, s: 1, l: 0.5, alpha: 1 },
  { mode: "hsl", h: 300, s: 1, l: 0.5, alpha: 1 },
  { mode: "hsl", h: 360, s: 1, l: 0.5, alpha: 1 },
];

const getGradient = (hsls: Hsl[]): string => {
  let hslsCss = "";
  hsls.forEach((hsl) => {
    const hslCss = formatHsl(hsl);
    hslsCss += `${hslsCss.length > 0 ? ", " : ""}${hslCss}`;
  });
  return `linear-gradient(to right, ${hslsCss})`;
};

const getBackgroundImage = ({
  type,
  color,
}: {
  type: eHsl;
  color: Hsl;
}): string => {
  if (type === eHsl.hue) {
    return getGradient(hslsForHueGradient);
  }
  if (type === eHsl.saturation) {
    const hslsForSaturationGradient: Hsl[] = [
      {
        ...color,
        s: 0,
        l: 0.5,
        alpha: 1,
      },
      {
        ...color,
        s: 1,
        l: 0.5,
        alpha: 1,
      },
    ];
    return getGradient(hslsForSaturationGradient);
  }
  if (type === eHsl.lightness) {
    const hslsForLightnessGradient: Hsl[] = [
      {
        ...color,
        l: 0,
        alpha: 1,
      },
      {
        ...color,
        l: 0.5,
        alpha: 1,
      },
      {
        ...color,
        l: 1,
        alpha: 1,
      },
    ];
    return getGradient(hslsForLightnessGradient);
  }

  const hslsForTransparencyGradient: Hsl[] = [
    {
      ...color,
      alpha: 0,
    },
    {
      ...color,
      alpha: 1,
    },
  ];
  return getGradient(hslsForTransparencyGradient);
};

const getIndicatorPosition = ({
  type,
  color,
}: {
  type: eHsl;
  color: Hsl;
}): number => {
  return type === eHsl.hue
    ? ((color.h ?? 0) / 360) * 100
    : type === eHsl.saturation
    ? color.s * 100
    : type === eHsl.lightness
    ? color.l * 100
    : (color.alpha ?? 1) * 100;
};

const PickHSLA2: FC<{
  color: Color;
  setColor: (color: Color) => void;
  type: eHsl;
}> = ({ color, setColor, type }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);

  // This function updates the color based on a given clientX value.
  const updateColorFromPosition = (clientX: number) => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      let x = clientX - rect.left;
      const width = rect.width;

      if (x < 0) x = 0;
      if (x > width) x = width;
      const newC = x / width;
      const newColor = getNewColor({
        type,
        newValue: newC,
        color,
      });
      if (newColor && !colorsAreEqual({ color1: color, color2: newColor })) {
        setColor(newColor);
      }
    }
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);

    updateColorFromPosition(event.clientX);

    const handleMouseMove = (moveEvent: MouseEvent) => {
      updateColorFromPosition(moveEvent.clientX);
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  };

  const hsl = colorToHsl(color);
  const backgroundImage = getBackgroundImage({ type, color: hsl });
  const indicatorPosition = getIndicatorPosition({ type, color: hsl });

  if (type === eHsl.transparency) {
    return (
      <div className="colour-picker form mt-05r">
        <div className="group mt-05r">
          <div
            ref={containerRef}
            className="color-continuum transparency"
            title="Click to select transparency"
            style={{
              backgroundImage,
            }}
          >
            <div
              className="color-indicator"
              style={{
                left: `${indicatorPosition}%`,
                cursor: `${isDragging ? "grabbing" : ""}`,
              }}
              onMouseDown={handleMouseDown}
            />
          </div>
          <EnterNewValue
            title="a"
            value={hsl.alpha ?? 1}
            setValue={(a) => {
              if (hsl.alpha !== a) {
                const newHsl: Color = { ...hsl, alpha: a };
                setColor(newHsl);
              }
            }}
            testNumber={(a) => {
              if (a < 0) {
                return 0;
              }
              if (a > 1) {
                return 1;
              }
              const aRounded = Math.round(a * 100) / 100;
              return aRounded;
            }}
          />
        </div>
      </div>
    );
  }

  const typeClass =
    type === eHsl.hue
      ? "hue"
      : type === eHsl.saturation
      ? "saturation"
      : type === eHsl.lightness
      ? "lightness"
      : "transparency";

  return (
    <div
      ref={containerRef}
      className={`color-continuum ${typeClass}`}
      title={`Click to select ${type}`}
      style={{
        backgroundImage,
      }}
    >
      <div
        className="color-indicator"
        style={{
          left: `${indicatorPosition}%`,
          cursor: `${isDragging ? "grabbing" : ""}`,
        }}
        onMouseDown={handleMouseDown}
      />
    </div>
  );
};

export const ColorContinuum: FC<{
  color: Color;
  setColor: (hsla: Color) => void;
}> = ({ color, setColor }) => {
  return (
    <>
      <PickHSLA2 color={color} setColor={setColor} type={eHsl.hue} />
      <PickHSLA2 color={color} setColor={setColor} type={eHsl.saturation} />
      <PickHSLA2 color={color} setColor={setColor} type={eHsl.lightness} />
      <PickHSLA2 color={color} setColor={setColor} type={eHsl.transparency} />
      <ShowColor color={color} setColor={setColor} />
    </>
  );
};
