export const standardUnits = [
  { id: "px" },
  { id: "rem" },
  { id: "%" },
  { id: "cm" },
  { id: "in" },
  { id: "pt" },
  { id: "em" },
  { id: "vw" },
  { id: "vh" },
  { id: "vmin" },
  { id: "vmax" },
];

export const standardUnitsNoPct = [
  { id: "px" },
  { id: "rem" },
  { id: "cm" },
  { id: "in" },
  { id: "pt" },
  { id: "em" },
  { id: "vw" },
  { id: "vh" },
  { id: "vmin" },
  { id: "vmax" },
];

export const gridUnits = [
  { id: "fr" },
  ...standardUnits,
]

export const noUnitWhenNull = (value: number, unit: string) => {
  if (value === 0) {
    return `${value}`;
  }
  return `${value}${unit}`;
};

export const getRgbaStr = (color: {
  r: number;
  g: number;
  b: number;
  a: number;
}) => {
  return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
};

export const helpObj: { [key: string]: string } = {
  pileBackgroundImages:
    "### Add multiple backgrounds  \n" +
    "You can add mulitple `backgrounds` of the same or different types (`image` or `gradient`) to the `CSS Properties Tree` by clicking more than once in `Add CSS Property` on the `background` or `background-gradient` button.  \n" +
    "`Backgrounds` underneath other backgrounds are visible only when and where those higher up have transparent areas. You can create transparent areas in a gradient by setting the opacity of a colour to a value less than 100.  \n" +
    "For more information about multiple backgrounds, see for example [MDN - Using multiple backgrounds](https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Backgrounds_and_Borders/Using_multiple_backgrounds)",
};
