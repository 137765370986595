import { snippetIF } from "../../data/types/snippets";
import { getNewSnippetCss } from "./getNewSnippetAfterchangeInItems";
import { getHtmlSelectorCss } from "../render/getHtmlSelectorCss";
import { getGoogleFontImportStringForSnippet } from "./getGoogleFontImportString";
import {
  generateHtmlForSnippet,
  getRenderSvgString,
} from "../../components/render/generateHtmlString";
import { addMetasToSnippet } from "./addAndGetMetas";

export const renderCssAndHtmlForSnippet = (snippet: snippetIF): snippetIF => {  
  const newS = addMetasToSnippet(snippet);
  const snippetCss = getNewSnippetCss({
    editSnippet: newS,
  });
  const newS2 = { ...newS, snippetCss };

  const htmlSelectorCss = getHtmlSelectorCss(newS);
  const newS3 = { ...newS2, htmlSelectorCss };

  const googleFontImportString = getGoogleFontImportStringForSnippet({
    snippet: newS,
  });
  const newS4 = { ...newS3, googleFontImportString };

  const snippetHtml = generateHtmlForSnippet(newS);
  const newS5 = { ...newS4, htmlString: snippetHtml };

  const snippetSvg = getRenderSvgString(newS5);
  const newS6 = { ...newS5, htmlStringSvg: snippetSvg };

  return newS6;
};
