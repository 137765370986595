import { itemIF, TSelectorData, TPosAndSh } from "../../../data/types/item";
import { getKids } from "../../items/getDescendantsAndAncestors";
import { sortSDatas, getCssForClassOrHtml } from "./getSelectorData";
import { getSelectorNameArray, getSelectorNameStr } from "../getSelectorName";
import { getChildrenSelectors } from "../../items/getAssignedSelectorsNew";
import { getCssCode } from "./getCssCode";
import { snippetIF } from "../../../data/types/snippets";

export const getCssStrFromSortedSData = ({
  sortedMediasSDataS,
  lineBreak = "",
  addSpaceAtLineStart = "",
}: {
  sortedMediasSDataS: TSelectorData[];
  lineBreak?: string;
  addSpaceAtLineStart?: string;
}) => {
  let cssStrMedia = "";
  sortedMediasSDataS.forEach((sData) => {
    if (sData.selector.name === "@media") {
      if (cssStrMedia !== "") {
        cssStrMedia += `${addSpaceAtLineStart}}${lineBreak}`;
      }
      cssStrMedia += `${addSpaceAtLineStart}${sData.nameStr} {${lineBreak}`;
      return;
    }

    const selectorCss = getCssCode({
      posAndShs: sData.posAndShs as TPosAndSh[],
      lineBreak,
      addSpaceAtLineStart: addSpaceAtLineStart + "          ",
    });

    cssStrMedia +=
      `${addSpaceAtLineStart}   ${sData.nameStr} {${lineBreak}` +
      `${selectorCss}` +
      `${addSpaceAtLineStart}     }${lineBreak}`;
  });

  if (cssStrMedia !== "") {
    cssStrMedia += `${addSpaceAtLineStart}}${lineBreak}`;
  }

  return `${cssStrMedia !== "" ? `\n` : ""}${cssStrMedia}`;
};

const getMatchArr = ({
  regStr,
  str,
  matchArr,
}: {
  regStr: string;
  str: string;
  matchArr: string[][];
}) => {
  const re = new RegExp(`(${regStr}):(\\d+)`);
  const match = str.match(re);
  if (match && match[1] && match[1] === regStr) {
    match.push(str);
    matchArr.push(match);
    return true;
  }
};
const matchArrToStr = ({
  matchArr,
  sorted,
}: {
  matchArr: string[][];
  sorted: string[];
}) => {
  matchArr.sort((a, b) => {
    if (a[2] && b[2]) {
      const aN = Number(a[2]);
      const bN = Number(b[2]);
      if (aN && bN) {
        return aN > bN ? 1 : aN < bN ? -1 : 0;
      }
    }
    return 1;
  });
  matchArr.map((m) => (m[3] ? m[3] : "")).forEach((str) => sorted.push(str));
};

const mediaMinWidthSortAsc = (mediaStrs: string[]) => {
  const sorted = [] as string[];
  const minWMatchArr = [] as string[][];
  const maxWMatchArr = [] as string[][];
  const minHMatchArr = [] as string[][];
  const maxHMatchArr = [] as string[][];
  mediaStrs.forEach((mstr) => {
    if (
      getMatchArr({
        regStr: "min-width",
        str: mstr,
        matchArr: minWMatchArr,
      })
    ) {
      return;
    }
    if (
      getMatchArr({
        regStr: "max-width",
        str: mstr,
        matchArr: maxWMatchArr,
      })
    ) {
      return;
    }
    if (
      getMatchArr({
        regStr: "min-height",
        str: mstr,
        matchArr: minHMatchArr,
      })
    ) {
      return;
    }
    if (
      getMatchArr({
        regStr: "max-height",
        str: mstr,
        matchArr: maxHMatchArr,
      })
    ) {
      return;
    }

    sorted.push(mstr);
  });

  matchArrToStr({
    matchArr: minWMatchArr,
    sorted,
  });
  matchArrToStr({
    matchArr: maxWMatchArr,
    sorted,
  });
  matchArrToStr({
    matchArr: minHMatchArr,
    sorted,
  });
  matchArrToStr({
    matchArr: maxHMatchArr,
    sorted,
  });

  return sorted;
};

export const getMediaSelectorsWithAssignedClassChild = ({
  assignedClassNames,
  snippet,
}: {
  assignedClassNames: string[];
  snippet: snippetIF;
}) => {
  const medias = snippet.data.selectors?.filter((s) => s.name === "@media");
  return medias?.filter((m) => {
    const kids = [] as itemIF[];
    getKids({
      item: m,
      items: snippet.data.selectors,
      kids,
    });
    if (
      kids.find(
        (k) =>
          k.name === "class" &&
          k.value?.main?.value &&
          assignedClassNames.includes(k.value.main.value)
      )
    ) {
      return true;
    }
    return false;
  });
};

export const getGroupedAndSortMediaSelectors = ({
  mediaSelectorsWithAssignedChildClass,
  mediaSelectorsPlusChildClassesWithDataSorted,
  snippet,
}: {
  mediaSelectorsWithAssignedChildClass: itemIF[];
  mediaSelectorsPlusChildClassesWithDataSorted: TSelectorData[];
  snippet: snippetIF;
}) => {
  const mediasSData = mediaSelectorsWithAssignedChildClass.map((selector) => {
    const selectorNamesObjs = [] as { prefix: string; name: string }[];
    getSelectorNameArray({
      selector,
      selectorNamesObjs,
      snippet,
    });
    return {
      selector,
      nameStr: getSelectorNameStr({
        selectorNamesObjs: selectorNamesObjs,
      }),
    } as TSelectorData;
  });

  const grouped = {} as { [key: string]: TSelectorData[] };
  mediasSData.forEach((sData) => {
    const match1 = /^@media[^{]+/.exec(sData.nameStr);
    const mediaNameStr = match1 ? match1[0].trim() : "";
    if (!grouped[mediaNameStr]) {
      grouped[mediaNameStr] = [sData];
    } else {
      grouped[mediaNameStr].push(sData);
    }
  });

  const mediaStrs = Object.keys(grouped).map((key) => key);
  const sortedMediaStrs = mediaMinWidthSortAsc(mediaStrs);

  sortedMediaStrs.forEach((mediaNameStr) => {
    const sDataS = grouped[mediaNameStr];
    sortSDatas(sDataS);
    sDataS.forEach((mediaSData) => {
      const childrenSelectors = [] as itemIF[];
      getChildrenSelectors({
        selector: mediaSData.selector,
        snippet,
        assignedSelectorsPlusChildren: childrenSelectors,
      });

      if (childrenSelectors.length === 0) {
        return;
      }

      mediaSelectorsPlusChildClassesWithDataSorted.push(mediaSData);

      childrenSelectors.forEach((selector) => {
        const childSData = getCssForClassOrHtml({
          selector,
          snippet,
        });
        mediaSelectorsPlusChildClassesWithDataSorted.push(childSData);
      });
    });
  });
};
