import {
  CognitoIdentityProviderClient,
  InitiateAuthCommand,
  AuthenticationResultType,
} from "@aws-sdk/client-cognito-identity-provider";

const client = new CognitoIdentityProviderClient({ region: "eu-west-2" });

const decodeToken = (token: string) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
};

export const isTokenExpired = (token: string): boolean => {
  try {
    const jsonPayload = decodeToken(token);
    const { exp } = jsonPayload;

    const now = new Date();
    return now.getTime() > exp * 1000;
  } catch (e) {
    console.error("Error checking token expiration", e);
    return true; // Assume expired on error
  }
};

export const refreshTokens = async () => {
  try {
    const rememberMeTokensStr = window.localStorage.getItem("tokens");
    const sessionTokensStr = window.sessionStorage.getItem("tokens");
    const tokensStr = rememberMeTokensStr || sessionTokensStr;

    if (!tokensStr) {
      return;
    }
    let tokens = JSON.parse(tokensStr) as AuthenticationResultType;

    const AccessToken = tokens.AccessToken || "";
    const tokenIsExpired = isTokenExpired(AccessToken);

    if (!tokenIsExpired) {
      const IdToken = tokens.IdToken || "";
      const jsonPayload = decodeToken(IdToken);
      return jsonPayload.email;
    }

    if (tokenIsExpired) {
      const RefreshToken = tokens.RefreshToken || "";
      const clientId = process.env.REACT_APP_CLIENT_ID;

      const command = new InitiateAuthCommand({
        AuthFlow: "REFRESH_TOKEN_AUTH",
        AuthParameters: {
          REFRESH_TOKEN: RefreshToken,
        },
        ClientId: clientId,
      });

      const response = await client.send(command);
      const auth = response.AuthenticationResult as AuthenticationResultType;

      if (auth?.IdToken) {
        const newTokens = {
          IdToken: auth.IdToken,
          AccessToken: auth.AccessToken,
          RefreshToken: auth.RefreshToken,
        };
        window.localStorage.setItem("tokens", JSON.stringify(newTokens));

        const jsonPayload = decodeToken(auth.IdToken);
        return jsonPayload.email;
      }
    }
  } catch (e) {
    console.error((e as Error).message);
    window.localStorage.removeItem("tokens");
  }
};

const getDecodedIdToken = () => {
  const rememberMeTokensStr = window.localStorage.getItem("tokens");
  const sessionTokensStr = window.sessionStorage.getItem("tokens");
  const tokensStr = rememberMeTokensStr || sessionTokensStr;

  if (tokensStr) {
    const tokens = JSON.parse(tokensStr);
    const IdToken = tokens.IdToken;
    if (IdToken) {
      return decodeToken(IdToken);
    }
  }
};

export type TUser = {
  id: string;
  username: string;
  email: string;
  groups: string[];
};

export const getUserId = (): string | undefined => {
  try {
    const decodedIdToken = getDecodedIdToken();
    return decodedIdToken?.sub || "";
  } catch (e) {
    console.error((e as Error).message);
  }
};

export const getUserName = () => {
  try {
    const decodedIdToken = getDecodedIdToken();
    const un = decodedIdToken?.["preferred_username"];
    return un || "";
  } catch (e) {
    console.error((e as Error).message);
  }
};

export const getEmail = () => {
  try {
    const decodedIdToken = getDecodedIdToken();
    return decodedIdToken?.email || "";
  } catch (e) {
    console.error((e as Error).message);
  }
};

export const getIsAdmin = () => {
  const decodedIdToken = getDecodedIdToken();
  const groups = (decodedIdToken?.["cognito:groups"] || []) as string[];
  return groups.includes("admin");
};

export const getAccessToken = async () => {
  try {
    const tokensStr = window.localStorage.getItem("tokens");
    if (tokensStr) {
      const tokens = JSON.parse(tokensStr);
      return tokens.AccessToken;
    }
  } catch (e) {
    console.error((e as Error).message);
  }
};
