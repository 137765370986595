import { itemIF, groupIF, EMetaFieldTypes } from "../../data/types/item";
import { itemTypes } from "../../lib/meta/getMetas";
import {
  getDependendOnDisabledFields,
  getGroupsToHide,
} from "../../lib/items/getDisabledFields";
import { EditFieldCheckbox } from "./Checkbox";
import { ModalWrapperRelativToParentPosition } from "../modules/TailwindModal";
import { useDivPosition } from "../modules/GetPositionWrapper";

import { AddOrRemoveFieldGroup } from "./AddOrRemoveFieldGroup";
import { RenderGroupFieldsOnly } from "./RenderGroupFieldsOnly";
import { snippetIF } from "../../data/types/snippets";
import { getMetaFromSnippet } from "../../lib/snippets/addAndGetMetas";

/*
Explanation of editSnippetIdOther:
- edit snippet id (in the app state) is the snippet selected in the general HTML, CSS, SVG Editor 
- editSnippetIdOther is the snippet selected in the Stylepack Editor
- it has to be passed to the action changing the stylepack, for the reducer to know which snippet to change
- and in the Colourpicker of the stylepack to show its own preset colours and not the one of the edit stylepack of the edit snippet in the general editor
*/

export const EditGroup = ({
  itemType,
  group,
  editItem,
  isItemDisabled,
  addOrRemoveEditItemId,
  editSnippetIdOther,
  snippet,
}: {
  itemType: itemTypes;
  group: groupIF;
  editItem: itemIF;
  isItemDisabled?: boolean;
  addOrRemoveEditItemId?: number;
  editSnippetIdOther?: string;
  snippet: snippetIF;
}) => {
  group.fields.forEach((fieldName) => {
    if (fieldName === "selectContentSvg") {
      return null;
    }
  });

  const meta = getMetaFromSnippet({
    snippet,
    itemType,
    itemName: editItem.name,
  });

  const hiddenGroups = [] as string[];
  if (snippet && editItem) {
    getGroupsToHide({
      itemType,
      item: editItem,
      snippet,
      hiddenGroups,
    });
  }

  const { divRef, divPos } = useDivPosition();
  if (hiddenGroups.includes(group.name)) {
    return null;
  }

  const firstFieldInGroupName = group.fields[0];
  const firstFieldMeta = meta?.fields?.find(
    (f) => f?.name === firstFieldInGroupName
  );
  if (
    !firstFieldMeta ||
    firstFieldMeta.dontEdit ||
    firstFieldMeta.type === "x"
  ) {
    return null;
  }
  const dependendOnDisabledFields = [] as string[];

  if (!snippet) return <div>NO EDIT SNIPPET</div>;

  if (editItem) {
    getDependendOnDisabledFields({
      itemType,
      item: editItem,
      snippet: snippet,
      disabledFields: dependendOnDisabledFields,
    });
  }

  let isFirstInGroupFieldDisabled = dependendOnDisabledFields.includes(
    firstFieldInGroupName
  );
  if (isFirstInGroupFieldDisabled) {
    return null;
  }

  const help = group.help
    ? group.help
    : firstFieldMeta?.help
    ? firstFieldMeta?.help
    : "";
  const warning = group.warning
    ? group.warning
    : firstFieldMeta?.warning
    ? firstFieldMeta?.warning
    : "";



  const isAddOrRemoveItem = meta?.category.includes("addOrRemoveFieldGroup");
  if (!editItem) return <div>NO EDIT ITEM</div>;

  const fieldGroupName = isAddOrRemoveItem ? editItem.name : undefined;
  const firstFieldValue = editItem?.value?.[firstFieldInGroupName];

  if (!editItem) return <div>NO EDIT ITEM</div>;

  const isGroupDisabled =
    isItemDisabled ||
    (firstFieldMeta && !firstFieldMeta?.onOff && !firstFieldValue?.onOff);

  const groupName = group.display?.[0] ? group.display[0] : group.name;

  return (
    <>
      {firstFieldMeta.type === EMetaFieldTypes.addOrRemoveFieldGroup && <div>{groupName}</div>}
      <div className="edit-item-group-wrapper" ref={divRef}>
        {!isAddOrRemoveItem && (
          <div className="flex align-center flex-1">
            <label
              className={`label${firstFieldMeta?.onOff ? " disabled" : ""}`}
            >
              <span className="sr-only">enable/disable</span>
              <EditFieldCheckbox
                itemType={itemType}
                fieldName={firstFieldInGroupName}
                valueType="onOff"
                editItem={editItem}
                snippet={snippet}
                isFieldDisabled={firstFieldMeta?.onOff}
                addOrRemoveEditItemId={addOrRemoveEditItemId}
                fieldGroupName={fieldGroupName}
                editSnippetIdOther={editSnippetIdOther}
              />
            </label>
          </div>
        )}

        <AddOrRemoveFieldGroup
          itemType={itemType}
          group={group}
          firstFieldInGroupName={firstFieldInGroupName}
          editItem={editItem}
          editSnippetIdOther={editSnippetIdOther}
          snippet={snippet}
          isItemDisabled={isGroupDisabled || isItemDisabled}
          addOrRemoveEditItemId={addOrRemoveEditItemId}
          isAddOrRemoveItem={isAddOrRemoveItem}
          dependendOnDisabledFields={dependendOnDisabledFields}
        />

        {firstFieldMeta.type !== EMetaFieldTypes.addOrRemoveFieldGroup &&
          !isAddOrRemoveItem && (
            <div className="flex gap-1 wrap justify-start w-100p">
              <RenderGroupFieldsOnly
                itemType={itemType}
                group={group}
                dependendOnDisabledFields={dependendOnDisabledFields}
                isGroupDisabled={isGroupDisabled}
                editItem={editItem}
                snippet={snippet}
                editSnippetIdOther={editSnippetIdOther}
              />
            </div>
          )}

        {(help || warning) && (
          <div className="flex align-center gap-1">
            {help && (
              <ModalWrapperRelativToParentPosition
                content={help}
                icon="info"
                parentPosition={divPos}
              ></ModalWrapperRelativToParentPosition>
            )}
            {warning && (
              <ModalWrapperRelativToParentPosition
                content={warning}
                icon="warning"
                parentPosition={divPos}
              ></ModalWrapperRelativToParentPosition>
            )}
          </div>
        )}
      </div>
    </>
  );
};
