import { useStateContext } from "../../lib/state/state";
import { SectionTitle } from "../modules/SectionTitle";
import { setEditItemId } from "../../lib/reducer/items/actions";
import { itemTypes } from "../../lib/meta/getMetas";

export const SelectStylepackButtons = () => {
  const {
    state: {
      snippet: { snippets, editSnippetId },
    },
    dispatch,
  } = useStateContext();

  const editSnippet = snippets.find((s) => s.id === editSnippetId);
  const itemType = itemTypes.STYLEPACK;
  const stylepacks = editSnippet?.data.stylepacks;
  const editStylepackId = editSnippet?.data.editStylepackId;

  return (
    <div className="form-elements-wrapper">
      <SectionTitle label="Select a stylepack" />

      <div className="btn-row mt-1r">
        {stylepacks &&
          stylepacks.length > 0 &&
          stylepacks.map((stylepack) => (
            <button
              type="button"
              key={stylepack.id}
              className={`button${editStylepackId === stylepack.id ? " blue" : " blue-outline"}`}
              onClick={() => {
                if (!editSnippet) return;

                dispatch(
                  setEditItemId({
                    itemType,
                    newEditItemId: stylepack.id,
                    editSnippetId: editSnippet?.id,
                  })
                );
              }}
            >
              {stylepack.value?.name.value}
            </button>
          ))}
        {stylepacks && stylepacks.length === 0 && (
          <button type="button" className="button blue-outline disabled">
            SNIPPET HAS NO STYLEPACK YET
          </button>
        )}
      </div>
    </div>
  );
};