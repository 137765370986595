import { itemIF, TPosAndSh } from "../../data/types/item";
import { getMetaFromSnippet } from "../snippets/addAndGetMetas";
import { itemTypes } from "../meta/getMetas";
import { snippetIF } from "../../data/types/snippets";
import { renderSingleItem } from "./renderSingleItem";
import { buildValueStrForShorthandGroup } from "./shortHandGroupBuildValueStr";

// returns just items without the addToShorthand flag which are separatly rendered
const getAndSortSingleItems = ({
  itemType,
  items,
  snippet,
}: {
  itemType: itemTypes;
  items: itemIF[];
  snippet: snippetIF;
}): itemIF[] => {
  const singleItems = items.filter((item) => {
    const meta = getMetaFromSnippet({
      snippet,
      itemType,
      itemName: item.name,
    });
    if (!meta) {
      return false;
    }
    const shorthandStr = meta.addToShorthand;
    if (!shorthandStr) {
      return true;
    }
    return false;
  });

  const sorted = singleItems
    .slice()
    .sort((a, b) =>
      a.name === b.name
        ? a.position > b.position
          ? 1
          : a.position < b.position
          ? -1
          : 0
        : 0
    );

  return sorted;
};

export const getShortHandItems = ({
  itemType,
  items,
  snippet,
}: {
  itemType: itemTypes;
  items: itemIF[];
  snippet: snippetIF;
}) => {
  const itemsWithShorthandStr = {} as { [key: string]: itemIF[] };

  for (const item of items) {
    const meta = getMetaFromSnippet({
      snippet,
      itemType,
      itemName: item.name,
    });
    if (!meta) {
      continue;
    }
    const shorthandStr = meta.addToShorthand;
    if (!shorthandStr) {
      continue;
    }

    if (!itemsWithShorthandStr[shorthandStr]) {
      itemsWithShorthandStr[shorthandStr] = [item];
    } else {
      itemsWithShorthandStr[shorthandStr].push(item);
    }
  }

  return itemsWithShorthandStr;
};

export const getShortHand = ({
  items,
  itemType,
  snippet,
}: {
  items: itemIF[];
  itemType: itemTypes;
  snippet: snippetIF;
}) => {
  const posAndShS = [] as TPosAndSh[];
  const sortedSingleItems = getAndSortSingleItems({
    itemType,
    items,
    snippet,
  });

  sortedSingleItems.forEach((item) => {
    const meta = getMetaFromSnippet({
      snippet,
      itemType,
      itemName: item.name,
    });
    if (!meta) {
      return;
    }
    const sh = renderSingleItem({
      itemType,
      item,
      snippet,
    });
    if (sh) {
      posAndShS.push({ pos: item.position, sh });
    }
  });

  const itemsWithShorthandStr = getShortHandItems({ itemType, items, snippet });

  Object.keys(itemsWithShorthandStr).forEach((shorthandStr) => {
    const group = itemsWithShorthandStr[shorthandStr];
    const posAndSh = buildValueStrForShorthandGroup({
      itemType,
      items: group,
      snippet,
    });
    if (posAndSh) {
      posAndShS.push(posAndSh);
    }
  });

  const sorted2 = posAndShS.slice().sort((a, b) => a.pos - b.pos);
  return sorted2;
};
