import React, { createContext, useReducer, useContext } from "react";
import { initialMain, initialMainIF } from "../../data/main/constants";
import { PAGES } from "../../data/settings/constants";
import { snippetIF, snippetsIF } from "../../data/types/snippets";
import { getNewSnippet } from "../../data/snippet/initalValues";
import { renderCssAndHtmlForSnippet } from "../snippets/renderCssAndHtmlForSnippet";

const getSnippetsFromLocalStorage = () => {
  // window.localStorage.removeItem("snippetState");
  const snippetStateLS = window.localStorage.getItem("snippetState");
  const snippetStateLSParsed =
    snippetStateLS && snippetStateLS !== "undefined"
      ? (JSON.parse(snippetStateLS) as snippetsIF)
      : undefined;

  if (!snippetStateLSParsed) {
    return;
  }

  const snippets = snippetStateLSParsed?.snippets;
  if (!snippets) {
    return;
  }

  const newSnippets = snippets?.map((snippet) => {
    return renderCssAndHtmlForSnippet(snippet);
  });
  const newSnippetState = {
    ...snippetStateLSParsed,
    snippets: newSnippets,
  };
  return newSnippetState;
};

export const getInitialState = (): initialMainIF => {
  // window.localStorage.removeItem("settingsState");
  const settingsLS = window.localStorage.getItem("settingsState");
  const settingsLSParsed =
    settingsLS && settingsLS !== "undefined"
      ? JSON.parse(settingsLS)
      : initialMain.settings;

  let selectedPage = PAGES.HOME;
  Object.keys(PAGES).forEach((p) => {
    if (window.location.href.includes(p.toLowerCase())) {
      if (
        p === PAGES.GALLERY ||
        p === PAGES.CODE ||
        p === PAGES.TERMS ||
        p === PAGES.PRIVACY ||
        p === PAGES.USER
      ) {
        selectedPage = p;
      }
    }
  });

  let initialSettings;
  if (settingsLSParsed) {
    initialSettings = settingsLSParsed;
  } else {
    initialSettings = initialMain.settings;
  }

  const snippetStateLSParsed = getSnippetsFromLocalStorage();

  // there must be at least one content and one code snippet
  const newSnippetState: snippetsIF = snippetStateLSParsed?.snippets
    ? snippetStateLSParsed
    : {
        snippets: [] as snippetIF[],
        editSnippetId: "",
      };

  if (!newSnippetState.snippets[0]) {
    const newSnippet = getNewSnippet();
    newSnippetState.snippets.push(newSnippet);
    newSnippetState.editSnippetId = newSnippet.id;
  }

  const newState = {
    snippet: newSnippetState,
    settings: {
      ...initialSettings,
      sWidth: window.innerWidth,
      sHeight: "400",
      splitScreenMode: false,
      selectedPage,
    },
  };
  return newState;
};

interface IContextProps {
  state: initialMainIF;
  dispatch: React.Dispatch<any>;
}

export const StateContext = createContext({} as IContextProps);

export const StateProvider: React.FC<{
  reducer: React.Reducer<any, any>;
  initialState: initialMainIF;
  children: React.ReactNode;
}> = ({ reducer, initialState, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return (
    <StateContext.Provider value={value}>{children}</StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
