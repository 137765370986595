import MainLayout from "../MainLayout";
import { Account } from "../user/Account";

const UserPage = () => {
  return (
    <MainLayout title="User" description="Sign up or Sign in">
      <Account />
    </MainLayout>
  );
};

export default UserPage;
