import React, { FC, ReactNode } from "react";
import { itemIF, itemWithChildrenIF } from "../../data/types/item";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

export const GetHTMLElement: FC<{
  item: itemWithChildrenIF | itemIF;
  attr: { [key: string]: any };
  svgCss?: string;
  children?: ReactNode;
}> = ({ item, attr, svgCss, children }) => {
  switch (item.name) {
    case "a":
      return <a {...attr}>{children}</a>;
    case "abbr":
      return <abbr {...attr}>{children}</abbr>;
    case "br":
      return <br />;
    case "blockquote":
      return <blockquote {...attr}>{children}</blockquote>;
    case "bdo":
      return <bdo {...attr}>{children}</bdo>;
    case "cite":
      return <cite>{children}</cite>;
    case "q":
      return <q {...attr}>{children}</q>;

    case "datalist":
      return <datalist {...attr}>{children}</datalist>;
    case "div":
      return <div {...attr}>{children}</div>;
    case "dl":
      return <dl {...attr}>{children}</dl>;
    case "dt":
      return <dt {...attr}>{children}</dt>;
    case "dd":
      return <dd {...attr}>{children}</dd>;
    case "hr":
      return <hr {...attr} />;
    case "button":
      return <button {...attr}>{children}</button>;
    case "form":
      return (
        <form onSubmit={(e) => e.preventDefault()} {...attr}>
          {children}
        </form>
      );
    case "fieldset":
      return <fieldset {...attr}>{children}</fieldset>;
    case "legend":
      return <legend {...attr}>{children}</legend>;

    case "h1":
      return <h1 {...attr}>{children}</h1>;
    case "h2":
      return <h2 {...attr}>{children}</h2>;
    case "h3":
      return <h3 {...attr}>{children}</h3>;
    case "h4":
      return <h4 {...attr}>{children}</h4>;
    case "h5":
      return <h5 {...attr}>{children}</h5>;
    case "h6":
      return <h6 {...attr}>{children}</h6>;
    case "img":
      return <img alt="" {...attr} />;
    case "input":
      if (!attr.value) {
        attr.value = "";
      }
      attr.onChange = () => {};
      return <input {...attr} />;
    case "label":
      return <label {...attr}>{children}</label>;
    case "li":
      return <li {...attr}>{children}</li>;
    case "ol":
      return <ol {...attr}>{children}</ol>;
    case "p":
      return <p {...attr}>{children}</p>;
    case "pre":
      return <pre {...attr}>{children}</pre>;

    case "select":
      return <select {...attr} onChange={() => {
        console.log('select change');
      }}>{children}</select>;
    case "optgroup":
      return <optgroup {...attr}>{children}</optgroup>;
    case "option":
      return <option {...attr}>{children}</option>;
    case "progress":
      return <progress {...attr}>{children}</progress>;

    case "span":
      return <span {...attr}>{children}</span>;

    case "b":
      return <b {...attr}>{children}</b>;
    case "code":
      return <code {...attr}>{children}</code>;
    case "samp":
      return <samp {...attr}>{children}</samp>;
    case "strong":
      return <strong {...attr}>{children}</strong>;
    case "i":
      return <i {...attr}>{children}</i>;
    case "em":
      return <em {...attr}>{children}</em>;
    case "mark":
      return <mark {...attr}>{children}</mark>;
    case "small":
      return <small {...attr}>{children}</small>;
    case "del":
      return <del {...attr}>{children}</del>;
    case "ins":
      return <ins {...attr}>{children}</ins>;
    case "sub":
      return <sub {...attr}>{children}</sub>;
    case "sup":
      return <sup {...attr}>{children}</sup>;

    case "svg": {
      if (item.level === 1) {
        attr.xmlns = "http://www.w3.org/2000/svg";
      }
      return (
        <svg {...attr}>
          {svgCss && svgCss !== "" && <style>{svgCss}</style>}
          {children}
        </svg>
      );
    }

    case "g":
      return <g {...attr}>{children}</g>;
    case "defs":
      return <defs>{children}</defs>;
    case "symbol":
      return <symbol {...attr}>{children}</symbol>;
    case "use":
      return <use {...attr} />;
    case "title":
      return <title>{item.value?.text?.value}</title>;
    case "circle":
      return <circle {...attr}>{children}</circle>;
    case "rect":
      return <rect {...attr}>{children}</rect>;
    case "ellipse":
      return <ellipse {...attr}>{children}</ellipse>;
    case "line":
      return <line {...attr}>{children}</line>;
    case "path":
      return <path {...attr}>{children}</path>;
    case "polygon":
      return <polygon {...attr}>{children}</polygon>;

    case "svg-text":
      return (
        <text {...attr}>
          {item.value?.main?.value}
          {children}
        </text>
      );

    case "textPath":
      return <textPath {...attr}>{item.value?.main?.value}</textPath>;

    case "table":
      return <table {...attr}>{children}</table>;
    case "caption":
      return <caption {...attr}>{children}</caption>;
    case "colgroup":
      return <colgroup {...attr}>{children}</colgroup>;
    case "col":
      return <col {...attr} />;
    case "text":
      if (item.value?.markdown?.onOff) {
        return (
          <Markdown remarkPlugins={[remarkGfm]}>
            {item.value?.text.value}
          </Markdown>
        );
      } else {
        return <>{item.value?.text.value}</>;
      }
    case "textarea":
      return <textarea {...attr} />;
    case "thead":
      return <thead {...attr}>{children}</thead>;
    case "tbody":
      return <tbody {...attr}>{children}</tbody>;
    case "tfoot":
      return <tfoot {...attr}>{children}</tfoot>;
    case "tr":
      return <tr {...attr}>{children}</tr>;
    case "th":
      return <th {...attr}>{children}</th>;
    case "td":
      return <td {...attr}>{children}</td>;
    case "ul":
      return <ul {...attr}>{children}</ul>;

    case "linearGradient":
      return <linearGradient {...attr}>{children}</linearGradient>;
    case "radialGradient":
      return <radialGradient {...attr}>{children}</radialGradient>;
    case "stop":
      return <stop {...attr} />;

    case "filter":
      return <filter {...attr}>{children}</filter>;
    case "feBlend":
      return <feBlend {...attr}>{children}</feBlend>;
    case "feFlood":
      return <feFlood {...attr}>{children}</feFlood>;
    case "feColorMatrix":
      return <feColorMatrix {...attr}>{children}</feColorMatrix>;
    case "feComposite":
      return <feComposite {...attr}>{children}</feComposite>;
    case "feConvolveMatrix":
      return <feConvolveMatrix {...attr}>{children}</feConvolveMatrix>;
    case "feGaussianBlur":
      return <feGaussianBlur {...attr}>{children}</feGaussianBlur>;
    case "feImage":
      return <feImage {...attr}>{children}</feImage>;
    case "feMerge":
      return <feMerge {...attr}>{children}</feMerge>;
    case "feMergeNode":
      return <feMergeNode {...attr} />;
    case "feMorphology":
      return <feMorphology {...attr}>{children}</feMorphology>;
    case "feOffset":
      return <feOffset {...attr}>{children}</feOffset>;

    case "feDiffuseLighting":
      return <feDiffuseLighting {...attr}>{children}</feDiffuseLighting>;
    case "feSpecularLighting":
      return <feSpecularLighting {...attr}>{children}</feSpecularLighting>;
    case "fePointLight":
      return <fePointLight {...attr} />;
    case "feDistantLight":
      return <feDistantLight {...attr} />;
    case "feSpotLight":
      return <feSpotLight {...attr} />;

    case "feTile":
      return <feTile {...attr}>{children}</feTile>;
    case "feTurbulence":
      return <feTurbulence {...attr}>{children}</feTurbulence>;
    case "feDisplacementMap":
      return <feDisplacementMap {...attr}>{children}</feDisplacementMap>;
    case "animate":
      return <animate {...attr} />;
    case "animateMotion":
      return <animateMotion {...attr}>{children}</animateMotion>;
    case "animateTransform":
      return <animateTransform {...attr}>{children}</animateTransform>;
    case "mpath":
      return <mpath {...attr} />;
    case "address":
      return <address {...attr}>{children}</address>;
    case "article":
      return <article {...attr}>{children}</article>;
    case "aside":
      return <aside {...attr}>{children}</aside>;
    case "details":
      return <details {...attr}>{children}</details>;
    case "figcaption":
      return <figcaption {...attr}>{children}</figcaption>;
    case "figure":
      return <figure {...attr}>{children}</figure>;
    case "footer":
      return <footer {...attr}>{children}</footer>;
    case "header":
      return <header {...attr}>{children}</header>;
    case "main":
      return <main {...attr}>{children}</main>;
    case "nav":
      return <nav {...attr}>{children}</nav>;
    case "section":
      return <section {...attr}>{children}</section>;
    case "summary":
      return <summary {...attr}>{children}</summary>;
    case "time":
      return <time {...attr}>{children}</time>;
    case "audio":
      return <audio {...attr}>{children}</audio>;
    case "video":
      return <video {...attr}>{children}</video>;
    case "source":
      return <source {...attr}></source>;
    // This fires for inserted content snippets
    default:
      return <>{children}</>;
  }
};
