import { useStateContext } from "../../lib/state/state";
import { itemTypes } from "../../lib/meta/getMetas";
import { itemIF } from "../../data/types/item";
import { snippetIF } from "../../data/types/snippets";
import { DisplayHideChildren2 } from "../modules/DisplayOrHideSubs2";
import { SectionTitle } from "../modules/SectionTitle";
import { EditItem } from "../forms/EditItem";
import { AssignClassToHtml } from "../html/AssignClassToHtml";
import { MoveCopyOrDeleteButtons } from "./SvgMoveCopyOrDeleteButtons";
import { SvgsItemsTree } from "./Tree2";
import { AddSvg } from "./Add";

const CRUDSVG = () => {
  const {
    state: {
      snippet: { snippets, editSnippetId },
    },
  } = useStateContext();

  const editSnippet = snippets.find((s: snippetIF) => s.id === editSnippetId);
  const svgs = editSnippet?.data?.svgs || [];
  const editSvgId = editSnippet?.data?.editSvgId || 0;
  const editSvg = svgs.find((s: itemIF) => s.id === editSvgId);

  const disableTitles = [];
  if (!editSvg) {
    disableTitles.push("Edit");
    disableTitles.push("Assign CSS");
  }

  const itemType = itemTypes.SVGS;

  if(!editSnippet) return null;

  return (
    <div className="mb-2r">
      <DisplayHideChildren2
        titles={["Tree", "Add", "Edit", "Assign CSS"]}
        disableTitles={disableTitles}
      >
        <div>
          <SectionTitle
            label="Select, move, clone or delete SVG"
            size="large"
          />

          <div className="mt-1r mb-1r">
            <SectionTitle label="Move, clone or delete" />
          </div>

          <MoveCopyOrDeleteButtons editSvg={editSvg} svgs={svgs} />

          <div className="mt-1r mb-05r">
            <SectionTitle label="SVG tree" />
          </div>

          <SvgsItemsTree />
        </div>

        <AddSvg items={svgs} editItem={editSvg} />

        <EditItem itemType={itemType} editItem={editSvg} snippet={editSnippet} />

        <div>
          <div className="mb-1r">
            <SectionTitle label="Assign CSS class to SVG" size="large" />
          </div>

          <AssignClassToHtml itemType={itemType} />
        </div>
      </DisplayHideChildren2>
    </div>
  );
};

export default CRUDSVG;
