import {
  itemIF,
  metaIF,
} from "../../data/types/item";
import { snippetIF } from "../../data/types/snippets";

export const getFontFamilyCssName = ({
  item,
  snippet,
}: {
  item: itemIF;
  snippet: snippetIF;
}) => {
  if (item.name === "font-family") {
    const stylepack = snippet.data.stylepacks?.find(
      (s) => s.id === snippet.data.editStylepackId
    );
    const stylepackFonts = stylepack?.value?.stylepackFonts.value as itemIF[];

    const ffName = item.value?.main?.value;
    const itemFont = stylepackFonts?.find(
      (f) => f.value?.font?.value === ffName
    );

    return itemFont?.value?.newCss?.value;
  }
};

export const setKeyValueOnAttribute = ({
  attr,
  meta,
  item,
}: {
  attr: Record<string, string>;
  meta?: metaIF;
  item?: itemIF;
}) => {
  const fieldMetaWithKeyValue = meta?.fields?.find(
    (f) => f.useValueAsKeyOfField
  );
  if (!fieldMetaWithKeyValue || !item) return attr;

  const key = item.value?.[fieldMetaWithKeyValue.name]?.value;
  const value =
    item.value?.[fieldMetaWithKeyValue.useValueAsKeyOfField ?? ""]?.value;

  if (!key || !value) return attr;

  const newAttr: Record<string, string> = {};
  newAttr[key] = value;

  const allFields = Object.keys(attr);
  const keyValueFields = [
    fieldMetaWithKeyValue.name,
    fieldMetaWithKeyValue.useValueAsKeyOfField,
  ];

  for (const field of allFields) {
    if (!keyValueFields.includes(field)) {
      newAttr[field] = attr[field];
    }
  }

  return newAttr;
};

