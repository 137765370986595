import { TObj, metaIF, EMetaFieldTypes } from "../../types/item";
import { getCategoryMetas } from "../../../lib/data/meta";
import { SELECTORS_STATE } from "./constants-state";
import { SELECTORS_CHILD } from "./child";
import { SELECTORS_ANIMATION } from "./animation";
import { SELECTORS_MEDIAQUERY } from "./mediaquerry";
export const CONNECTOR_TYPE: string = "CONNECTORS";

export const CONNECTOR_CATEGORIES_HELP = {
  connector:
    "With a `connector` you define the relation between the current selector in the tree and its parent.  \n" +
    "If you do not add a `connector`, a space is set between the 2 of them, like in `.row .column`, what means all styles in `.column` are applied for all elements with the class `.column`, who have an ancestor with the class `.row`.  \n" +
    "A `connector` can be added to a Selector in the `Selector Tree` on level 2 or higher.  \n" +
    "You can't add a `connector` to an `animation`.  \n" +
    "The connector have the following effect:  \n" +
    "`&`  \nno space between the selectors  \n`.class1.class2`  \nCSS in `class2` is applied for elements with both classes.  \n" +
    "`>`  \n`.class1 > .class2`  \nCSS in `class2` is applied to elements with `class2` who are children of an element with `class1`.  \n" +
    "`+`  \n`.class1 + .class2`  \nCSS in `class2` is applied to elements with `class2` who are next sibling of an element with `class1`.  \n" +
    "`~`  \n`.class1 ~ .class2`  \nCSS in `class2` is applied to elements with `class2` who have a preceeding sibling with `class1`.",
} as TObj;

export const SELECTOR_CATEGORIES_HELP: { [key: string]: string } = {
  selector:
    "If you want to style a HTML or SVG, create first a Class and assign it to an HTML or SVG:  \n" +
    "- Click in the HTML or SVG section on the tab `Edit Html/Svg`.  \n" +
    "- Click on the sub category `Assign Class`.  \n" +
    "- Select the snippet which has the `Class` in it.  \n" +
    "- Click on the `Class` which you want to add to or remove from the class list of the selected HTML or SVG.  \n\n" +
    "To make rules more specific, add further `Selectors`, like other `Classes` or `Pseudo elements`, as children of the current `Class`. \n" +
    "You can add a `Connector` to the child, like > or ~, to describe the relationship to the `parent`.  \n" +
    "If you want to assign an Id to a HTML or SVG, go to the HTML or SVG tab, open Edit Html or Edit Svg and enter a unique name for the HTML or SVG attribute `id`. Assigned `ids` are then available in the Selector Tree on level 1.",
  html:
    "If you want to style a certain HTML element, like a `div` or `label`, click on the `html` button and enter the name of the HTML down in the section `Edit Selector`.  \n" +
    "You can combine the `hmtl` Selector with any other Selector in the `Selector Tree`, but you can't add a `html` Selector on the first level of the Tree.  \n" +
    "Example: `.row div.red` styles all divs with the class `.red`, if they have an ancestor with the class `.row`; add `.row` in the `Selector Tree` on level 1, `div` on level 2 and `&.red` on level 3.",
  attribute:
    'They style an HTML element with a certain attribute or certain value of an attribute. `input="radio"`, for example, selects all radio buttons.',
  "pseudo-class":
    "A `pseudo class` defines a special state of the element.  \n" +
    "For detailed information on a specific pseudo-class, add it to your Selector tree and open the Edit tab. There, you'll find form fields for its attributes and icons for additional information or warnings, if applicable.",
  "pseudo-element":
    "Styles a certain part of the element, like in `::first-line` where just the first line of the text is selected and can be styled.  \n" +
    "To see the online help for a `pseudo element`, add it to the `Selector Assets` box. The button to open the online help is then displayed in `Edit Selector`.",
  all: "See for example [MDN - Universal selectors](https://developer.mozilla.org/en-US/docs/Web/CSS/Universal_selectors)",
  custom: `Enter here any selector name that is not available in the list above.   

For example, if you want to style a cross browser compatible **input range slider**, you need at least the following pseudo classes: **::-webkit-slider-runnable-track**, **::-moz-range-track**, **::-ms-track**, **::-webkit-slider-thumb**, **::-moz-range-thumb** and **::-ms-thumb**.`,

  query: `Within the **@media** rule you define one or several conditions, like "apply just on screens with a width of less than 768px", and then add selectors and CSS properties which have an effect only when the conditions are met.  

To formulate media rules 
- Add **@media** on level 1 of your **Selector tree**.
- Click on the **Edit** tab and define conditions.
- Add classes or other selectors as children of the **@media** rule.
- Define CSS properties for the selectors underneath the **@media** rule. Those properties are then just applied to the target elements when the output media fullfills the condition of the media rule.`,

  animation: `An animation has 2 main parts, a **keyframe** and the **animation** definition.  

First you define the animation steps in a **keyframe**:
- Add a **keyframe** on level 1 to your **Selector Tree**, open the **Edit** tab and give it a name.
- Add steps to the keyframe, which define on a scale from 0 to 100%, when a certain state of the targeted element is reached.  
- Add CSS properties to the steps, for example background color white for step 1 and blue for step 2.

Second you configure the animation:
- Open the **Selector Tree** and select e.g. the class *box* to animate the html element with this class assign.
- Add the CSS property **animation**. 
- Open the **Edit** tab and select the keyframe you want to use.
- Configure the **duration** and other animation settings.`,

  // javascript:
  //   "### scrollTop  \n" +
  //   "You can define a condition as a child of an `id` and add CSS properties to the condition. The CSS properties are just applied when the condition is met. A condition can be, for example, scroll down more than 80px.",
};

export const SELECTOR_CATEGORIES_TITLES = {
  selector: "Class",
  html: "Html",
  attribute: "Attribute",
  "pseudo-element": "Pseudo element",
  "pseudo-class": "Pseudo class",
  custom: "Custom",
  selectAllElements: "Select all elements",
  query: "Media query",
  animation: "Animation",
  javascript: "scrollTop",
} as TObj;

export const helpAll = {
  contentText:
    "You can enter text, an empty string or CSS entities.  \n" +
    "You find a list of CSS entities for example [here](https://www.w3schools.com/cssref/css_entities.asp). Enter the (octal) number of the entity with a leading `\\`, like in \\00A9.  \n",
};

const textBlocks = {
  beforeOrAfter:
    "Adds a pseudo element as first child of the selected element.  \n" +
    helpAll.contentText +
    "The pseudo inherits all inheritable CSS properties (like `color`) from the element to which it is attached.  \n" +
    "You can style the pseudo element like a real HTML element.  \n" +
    "Its default `display` is `inline`. If you want to add `padding` and `margin`, give the pseudo a POSITION - `display` `block` or `inline-block` first.  \n" +
    "If you want to place or transform the pseudo without changing the position of the main element, then give it a POSITION - `position` `absolute` attribute.  \n" +
    "Example: `<p.info></p>` can have a pseudo element as marker:  \n" +
    "`.info::after {`  \n" +
    "&nbsp;&nbsp;`content: 'INFO';`  \n" +
    "&nbsp;&nbsp;`position:absolute;`  \n" +
    "&nbsp;&nbsp;`left:-2rem;`  \n" +
    "&nbsp;&nbsp;`top:1rem;`  \n" +
    "&nbsp;&nbsp;`transform: rotateZ(90deg);`  \n" +
    "}",
  beforeOrAfterWarning:
    "The pseudo elements `::before` and `::after` are not meant to be used on replaced elements like `inputs` and `image` elements.",
};

export const CONNECTORS: metaIF[] = [
  {
    name: "AMPERSAND",
    display: "&",
    javascript: "&",
    availableFromLevel: 2,
    level: "inline",
    category: ["connector"],
  },
  {
    name: "COMMA",
    display: ",",
    javascript: ",",
    availableFromLevel: 2,
    level: "inline",
    category: ["connector"],
  },
  {
    name: "GREATERTHAN",
    display: ">",
    javascript: ">",
    availableFromLevel: 2,
    level: "inline",
    category: ["connector"],
  },
  {
    name: "PLUS",
    display: "+",
    javascript: "+",
    availableFromLevel: 2,
    level: "inline",
    category: ["connector"],
  },
  {
    name: "TILDE",
    display: "~",
    javascript: "~",
    level: "inline",
    category: ["connector"],
    availableFromLevel: 2,
    help:
      "### ~  \n" +
      "`element1 ~ element2` selects `element2` under the condition that it has a preceding sibling `element1`. `element1` does not have to be immediately preceding to `element2`.  \n" +
      "Example: `p ~ ul { background: red }` gives a red background to all `ul`s with a `p` as a preceding sibling.",
  },
];

export const SELECTORS: metaIF[] = [
  ...SELECTORS_CHILD,
  ...SELECTORS_STATE,
  ...SELECTORS_ANIMATION,
  ...SELECTORS_MEDIAQUERY,
  {
    name: "::after",
    level: "inline",
    category: ["pseudo-element"],
    prefix: "::after",
    availableFromLevel: 2,
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.textarea,
        wrapperFn: (valueObj) => `"${valueObj.value}"`,
        dontRender: true,
        onOff: true,
        help: "### ::after  \n" + textBlocks.beforeOrAfter,
        warning: "### ::after  \n" + textBlocks.beforeOrAfterWarning,
      },
    ],

    default: {
      main: {
        value: "TEXT",
      },
    },
  },
  {
    name: "::before",
    level: "inline",
    category: ["pseudo-element"],
    prefix: "::before ",
    availableFromLevel: 2,
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.textarea,
        wrapperFn: (valueObj) => `"${valueObj.value}"`,
        dontRender: true,
        onOff: true,
        help: "### ::before  \n" + textBlocks.beforeOrAfter,
        warning: "### ::before  \n" + textBlocks.beforeOrAfterWarning,
      },
    ],
    default: { main: { value: "\\00A9" } },
  },
  {
    name: "::first-line",
    level: "inline",
    category: ["pseudo-element"],
    availableFromLevel: 2,
    help:
      "### ::first-line  \n" +
      "Selects the first line of text.  \n" +
      "The following CSS property groups can be used with `::first-line`: `font`, `color`, `background`, `word-spacing`, `letter-spacing`, `text-decoration`, `vertical-align`, `text-transform` and `line-height`.  \n" +
      "For example: `p.intro::first-line { background: blue; }` styles the first line of the intro text blue.",
  },
  {
    name: "::first-letter",
    level: "inline",
    category: ["pseudo-element"],
    availableFromLevel: 2,
    help:
      "### ::first-letter  \n" +
      "Selects the first letter of the text.  \n" +
      "The following CSS property groups can be used with `::first-letter`: `font`, `color`, `background`, `margin`, `padding`, `border`, `text-decoration`, `vertical-align`, `text-transform` and `line-height`.  \n" +
      "For example: `p.intro::first-letter { font-size: 3rem; }` styles the first letter of the intro text 3 times the size of the normal text.",
  },
  {
    name: "::selection",
    level: "inline",
    category: ["pseudo-element"],
    availableFromLevel: 2,
    help:
      "### ::selection  \n" +
      "Style a user selection with the CSS properties `color`, `background`, `cursor` and `outline`.  \n" +
      "Example:  \n" +
      "`<div.select>Select text and you'll see it with green background and white text color.</div>`  \n" +
      "`.select::selection { color: white; background: green; }`",
  },

  {
    name: ":lang",
    level: "inline",
    category: ["pseudo"],
    prefix: ":lang",
    availableFromLevel: 2,
    fieldsOrder: ["lang"],
    fields: [
      {
        name: "lang",
        type: EMetaFieldTypes.string,
        wrapperFn: (valueObj) => `(${valueObj.value})`,
        onOff: true,
        help:
          "### :lang  \n" +
          "The language of a document is usually set by the `lang` attribute on the `html` element. You can alter the default language for a text sections via the `lang` attribute on an element underneath the top `html` element. Here we use the `lang` attribute on `div` and `p`.  \n" +
          "You can style an element with a certain language with the help of the `:lang` pseudo element. Add the `:lang` selector and enter the [ISO language code](https://www.w3schools.com/tags/ref_language_codes.asp) for the respective language.",
      },
    ],
    group: [
      {
        name: "language",
        fields: ["lang"],
      },
    ],
    default: {
      lang: {
        value: "de",
        onOff: true,
      },
    },
  },

  {
    name: ":not",
    level: "inline",
    category: ["pseudo"],
    availableFromLevel: 2,
    prefix: ":not",
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.string,
        wrapperFn: (valueObj) => `(${valueObj.value})`,
        onOff: true,
        help:
          "### :not  \n" +
          "Enter the description of the selectors, which you want to exclude. For example `a` or `:last-child`. If you want to exclude elements with a certain class, add `.` before the class name. For example `.dropdown-toggle`.  \n\n" +
          "See for example [Codrops - CSS Reference :not()](https://tympanus.net/codrops/css_reference/not/)",
      },
    ],
    default: {
      main: {
        value: "p",
      },
    },
  },
  {
    name: ":read-only",
    level: "inline",
    category: ["pseudo"],
    availableFromLevel: 2,
  },
  {
    name: ":target",
    level: "inline",
    category: ["pseudo"],
    availableFromLevel: 2,
    help:
      "### :target  \n" +
      "If a link is clicked pointing to an element with a certain id in the same page, the element with the id can be styled with the help of the `:target` selector.  \n" +
      'Example: `<a href="#header1">Jump to header 1</a>` `<h1 id="header1">Header 1</h1>` `#header1:target { background: red; }` ',
  },
  {
    name: "custom",
    level: "inline",
    category: ["custom"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.string,
        onOff: true,
      },
    ],
    default: {
      main: {
        value: "::-webkit-slider-runnable-track",
      },
    },
  },
  {
    name: "attribute",
    availableFromLevel: 2,
    level: "inline",
    category: ["attribute"],
    prefix: "",
    fieldsOrder: ["attribute", "equals", "value"],
    fields: [
      {
        name: "attribute",
        type: EMetaFieldTypes.string,
        onOff: true,
        help:
          "### HTML attributes  \n" +
          "See for example [w3schools - CSS Attribute Selectors](https://www.w3schools.com/css/css_attribute_selectors.asp)  \n" +
          "Tiny reference:  \n" +
          "- attribute~='value': attribute value contains a specific word  \n" +
          "- attribute*='value': attribute value contains a specific value  \n" +
          "- attribute|='value': attribute starts with a specific value  \n" +
          "- attribute^='value': attribute value starts with a specific value  \n" +
          "- attribute$='value': attribute value ends with a specific value",
      },
      {
        name: "equals",
        type: EMetaFieldTypes.select,
        dontDisplayForValue: "none",
        options: [
          {
            id: "=",
          },
          {
            id: "~=",
          },
          { id: "*=" },
          { id: "|=" },
          { id: "^=" },
          { id: "$=" },
          { id: "none" },
        ],
        onOff: true,
        help:
          "### attributes `equals` value  \n" +
          "`none`: Selects elements with a certain attribute, whatever the value of the attribute is. " +
          "`a[target]` for example selects all links with a `target` attribute.  \n" +
          '`attributes = value`: exact match like in `a[target="_blank"]`.  \n' +
          "`attributes ~= value`: does the attribute value contain the word?  \n" +
          "`attributes *= value`: does the attribute value contain the expression? " +
          'For example `[class~="blue"]` selects elements with the class "blue-button", "underline-blue" etc.  \n' +
          "`attributes |= value`: does the attribute value start with the word? For example `[class|=top]`.  \n" +
          "`attributes ^= value`: does the attribute value start with the expression? For example `[class|=to]`.  \n" +
          "`attributes $= value`: does the attribute value end with the expression? For example `[class$=ing]`.",
      },
      {
        name: "value",
        type: EMetaFieldTypes.string,
        dependentOn: {
          field: "equals",
          values: ["=", "~=", "*=", "|=", "^=", "$="],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
    ],

    group: [
      {
        name: "attributeEqualsValue",
        display: ["attribute", "equals", "value"],
        fields: ["attribute", "equals", "value"],
      },
    ],

    wrapperForGroup: [
      {
        name: "attribute",
        fields: ["attribute", "equals", "value"],
        wrapperFn: (vObj) => {
          const attribute = vObj.attribute;
          const equals = vObj.equals;
          const value = vObj.value;
          if (equals === "none") {
            return `[${attribute}]`;
          }
          return `[${attribute}${equals}"${value}"]`;
        },
      },
    ],

    default: {
      attribute: { value: "class" },
      equals: { value: "^=" },
      value: { value: "col-" },
    },
    help:
      '#### `[attribute]` or `[attribute="value"]`  \n' +
      'Style HTML elements with a certain attribute or attribute value, like `a[target]` or `input[type="text"]`.',
  },
  {
    name: "class",
    level: "block",
    category: ["selector"],
    prefix: ".",

    fieldsOrder: ["main"],

    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.string,
        conditions: {
          regex: "^[a-z-_][a-z0-9-_]*$",
        },
        onOff: true,
        help: `### Class name
        
You define here the *class* name. Keep it unique and descriptive.

In the HTML and SVG tree you can assign the class to multiple tags. If there are several classes with the same name they are displayed just once in the **Assign** menu.

Use characters, numbers, hyphens and underscores. The first character of the name must not be a number.`,
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["name"],
      },
    ],
    default: {
      main: { value: "class" },
    },
  },
  {
    name: "html",
    level: "inline",
    category: ["html"],
    // availableFromLevel: 2,
    prefix: "",

    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.string,
        autocomplete: "htmlSelectors",
        onOff: true,
        help:
          "### html  \n" +
          "You can add any HTML elements (`div`, `span`, `a` ...) as Assets and " +
          "then use them in the `Selector Tree` as part of a specific `Selector` or " +
          "as `Selector` without any further specification.  \n" +
          "Click in `Add Selector` on `html`.  \n" +
          "Enter the name of the HTML element in `Assets` in `EDIT` mode.  \n" +
          "Select in `Selector Tree` the Selector Part under which you want to add the html Selector.  \n" +
          "Click in `Assets` in `Add` mode on the html Selector Asset.  \n" +
          "Examples: The `a` Selector without any further specification can be used " +
          "to style all links in the current snippet. `.row > div` targets all `div` who are " +
          "children of an element with the class `.row`.",
      },
    ],
    default: {
      main: {
        value: "html",
      },
    },
  },
  {
    name: "scrollTop",
    level: "inline",
    category: ["javascript"],
    allowedParent: ["id"],
    allowedChildren: [],
    fieldsOrder: ["main", "greaterOrLess", "value"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        options: [
          {
            id: "scrollTop",
          },
        ],
      },
      {
        name: "greaterOrLess",
        type: EMetaFieldTypes.select,
        options: [
          {
            id: ">",
          },
          {
            id: "<",
          },
        ],
      },
      {
        name: "value",
        type: EMetaFieldTypes.number,
      },
    ],
    group: [
      {
        name: "main",
        fields: ["main"],
      },
      {
        name: "greaterOrLess",
        display: ["greater or less"],
        fields: ["greaterOrLess"],
      },
      {
        name: "value",
        fields: ["value"],
      },
    ],
    default: {
      main: {
        value: "scrollTop",
      },
      greaterOrLess: {
        value: ">",
      },
      value: {
        value: 80,
      },
    },
  },

  {
    name: "SELECTSALLELEMENTS",
    display: "*",
    level: "inline",
    category: ["selectAllElements"],
    help:
      "### *  \n" +
      "Selects all elements.  \n" +
      "For example `div.yellow *` styles all descendants of `div`s with the class `yellow`.",
  },

  {
    name: "::slider-runnable-track",
    level: "block",
    category: ["range"],
    availableFromLevel: 2,
  },
  {
    name: "::range-track",
    level: "block",
    category: ["range"],
    availableFromLevel: 2,
  },
  {
    name: "::track",
    level: "block",
    category: ["range"],
    availableFromLevel: 2,
  },
];

export const getConnectorMeta = (name: string) => {
  return CONNECTORS.find((a: metaIF) => a.name === name);
};

export const getSelectorMeta = (name: string) => {
  return SELECTORS.find((a: metaIF) => a.name === name);
};

export const CONNECTOR_CATEGORIES: { [key: string]: { order: string[] } } = {
  connector: { order: ["AMPERSAND", "COMMA", "GREATERTHAN", "PLUS", "TILDE"] },
};
export const getConnectorCategoryMetas = (category: string) => {
  return getCategoryMetas({
    category,
    categories: CONNECTOR_CATEGORIES,
    metas: CONNECTORS,
  });
};

export const SELECTOR_CATEGORIES: { [key: string]: { order?: string[] } } = {
  selector: {},
  html: {},
  attribute: {},
  "pseudo-element": {
    order: [
      "::before",
      "::after",
      "::first-line",
      "::first-letter",
      "::selection",
    ],
  },
  "pseudo-class": {
    order: [
      ":active",
      ":link",
      ":visited",
      ":checked",
      ":disabled",
      ":enabled",
      ":empty",
      ":focus",
      ":hover",
      ":in-range",
      ":out-of-range",
      ":invalid",
      ":valid",
      ":first-child",
      ":first-of-type",
      ":last-child",
      ":last-of-type",
      ":nth-child",
      ":nth-last-child",
      ":nth-last-of-type",
      ":nth-of-type",
      ":lang",
      ":not",
      ":read-only",
      ":target",
    ],
  },
  selectAllElements: {},
  query: {},
  animation: { order: ["@keyframes", "step"] },
  // javascript: {},
  custom: {},
};

export const getSelectorCategoryMetas = (category: string) => {
  return getCategoryMetas({
    category,
    categories: SELECTOR_CATEGORIES,
    metas: SELECTORS,
  });
};
