// add addStr before or after (addAfter = true) str in array
export const addInArray = ({
  array,
  str,
  addStr,
  addAfter,
}: {
  array: string[];
  str: string;
  addStr: string;
  addAfter?: boolean;
}) => {
  const index = array.findIndex((f) => f === str);
  if (index === -1) {
    return [...array];
  }
  if (addAfter) {
    return [...array.slice(0, index + 1), addStr, ...array.slice(index + 1)];
  }
  return [...array.slice(0, index), addStr, ...array.slice(index)];
};
