import { useState, useEffect } from "react";
import { snippetIF } from "../../data/types/snippets";
import { useStateContext } from "../state/state";
import { setIsLoading } from "../reducer/settings/actions";
import { loadSnippets } from "../lambda/getSnippets";
import { renderCssAndHtmlForSnippet } from "./renderCssAndHtmlForSnippet";
import { QueryGetSnippetsArgs } from "cssexy-be/src/generated/graphql";
import { getUserNamesForUserIds } from "../api/getUserNamesForUserIds";

const saveSnippetsToLocal = (snippets: snippetIF[]) => {
  localStorage.setItem("serverSnippets", JSON.stringify(snippets));
};
// const getSnippetsFromLocal = (): snippetIF[] => {
//   const snippetsJson = localStorage.getItem("serverSnippets");
//   return snippetsJson ? JSON.parse(snippetsJson) : [];
// };

const fetchSnippets = async ({
  sortAndFilterParams,
}: {
  sortAndFilterParams: QueryGetSnippetsArgs;
}): Promise<{ snippets: snippetIF[]; nextToken?: string }> => {
  const loadedSnippets = await loadSnippets({
    variables: sortAndFilterParams,
  });
  const filteredAndSorted = loadedSnippets.snippets.filter((s) => s);
  saveSnippetsToLocal(filteredAndSorted);

  const userNames = await getUserNamesForUserIds(
    filteredAndSorted.map((s) => s.owner).filter(Boolean) as string[]
  );

  const snippets = await Promise.all(
    filteredAndSorted.map(async (s) => {
      const snippetWithCssAndHtmlRendered = await renderCssAndHtmlForSnippet(s);
      const username = s.owner ? userNames[s.owner] : undefined;
      return {
        ...snippetWithCssAndHtmlRendered,
        owner: username || "NONE",
      };
    })
  );

  const nextToken = loadedSnippets.nextToken;

  return { snippets: snippets.filter(Boolean), nextToken };
};

export const useFetchSnippets = ({
  sortAndFilterParams,
}: {
  sortAndFilterParams: QueryGetSnippetsArgs;
}): {
  serverSnippets: snippetIF[];
  nextToken: string | undefined;
  cssRenderComplete: boolean;
  loadMore: () => Promise<void>;
} => {
  const [serverSnippets, setServerSnippets] = useState<snippetIF[]>([]);
  const [nextToken, setNextToken] = useState<string | undefined>(undefined);
  const [cssRenderComplete, setCssRenderComplete] = useState(false);

  const { dispatch } = useStateContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setIsLoading(true));

        const result = await fetchSnippets({ sortAndFilterParams });
        setServerSnippets(result.snippets);
        setNextToken(result.nextToken);

        if (
          result.snippets.length > 0 &&
          !result.snippets.some((s) => !s.snippetCss)
        ) {
          setCssRenderComplete(true);
        }

        dispatch(setIsLoading(false));
      } catch (error) {
        dispatch(setIsLoading(false));
        console.error("Error fetching snippets:", error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortAndFilterParams.sort, sortAndFilterParams.filter]);

  const loadMore = async () => {
    if (!nextToken) return;
    try {
      dispatch(setIsLoading(true));
      const result = await fetchSnippets({
        sortAndFilterParams: { ...sortAndFilterParams, nextToken },
      });

      if (!result.snippets || result.snippets.length === 0) return;

      // Append new snippets to the existing list.
      setServerSnippets((prev) => [...prev, ...result.snippets]);
      setNextToken(result.nextToken);
      // Update cssRenderComplete if applicable.
      if (!result.snippets.some((s) => !s.snippetCss)) {
        setCssRenderComplete(true);
      }
      dispatch(setIsLoading(false));
    } catch (error) {
      dispatch(setIsLoading(false));
      console.error("Error fetching more snippets:", error);
    }
  };

  return { serverSnippets, nextToken, cssRenderComplete, loadMore };
};
