import { metaIF, itemIF, TObj, EMetaFieldTypes } from "../../types/item";
import { getCategoryMetas } from "../../../lib/data/meta";
import { htmlHelpTexts } from "../../html/constants";
import { svgGradientsHelp } from "./gradient";
import { SVG_CONSTANTS_ANIMATE } from "./animate";
import { SVG_CONSTANTS_META } from "./meta";
import { SVG_CONSTANTS_SHAPES } from "./shapes";
import { SVG_CONSTANTS_PATH } from "./path";
import { SVG_CONSTANTS_GRADIENTS } from "./gradient";
import { SVG_CONSTANTS_TEXT } from "./text";
import { SVG_CONSTANTS_FILTER } from "./filter";

export const getSvgMeta = (name: string): metaIF | undefined => {
  return SVGS_META.find((a: metaIF) => a.name === name);
};

export const SVGS = "SVGS";
export const SVGS_CATEGORIES: { [key: string]: { order?: string[] } } = {
  SVG: { order: ["svg", "defs", "g", "title", "desc", "use", "symbol"] },
  SHAPE: { order: ["circle", "rect", "ellipse", "line"] },
  POLYGON: { order: ["polygon", "point"] },
  PATH: { order: ["path", "M", "L", "C", "S", "Q", "T", "A", "Z"] },
  TEXT: { order: ["svg-text", "textPath"] },
  ANIMATE: { order: ["animate", "animateMotion", "animateTransform", "mpath"] },
  GRADIENT: { order: ["linearGradient", "radialGradient", "stop"] },
  FILTER: {
    order: [
      "filter",
      "feBlend",
      "feFlood",
      "feColorMatrix",
      "feComposite",
      "feConvolveMatrix",
      "feDisplacementMap",
      "feGaussianBlur",
      "feImage",
      "feMerge",
      "feMergeNode",
      "feMorphology",
      "feOffset",
      "feDiffuseLighting",
      "feSpecularLighting",
      "fePointLight",
      "feSpotLight",
      "feDistantLight",
      "feTile",
      "feTurbulence",
    ],
  },
};

export const SVGS_CATEGORY_HELP: { [key: string]: string } = {
  SVG:
    "### Add SVG  \n" +
    "SVG as a topic is huge and wonderul, to say the least, lots you can do, but lots to learn. To inform yourself about SVG in general and SVG coding you could start here: [CSS Tricks - https://css-tricks.com/lodge/svg/](https://css-tricks.com/lodge/svg/).  \n" +
    "CSSexy is trying to make it especially easy to create great work, art and funny things with SVG with clicks and helpers attached.  \n" +
    "Every SVG image has a `SVG` element as a parent and one or more `shape`, `polygon`, `path`, `text` or `filter` elements as children, which define, with the exception of filter, the shape of the SVG image.  \n" +
    "To define colour and stroke, go to the tab `CSS` - `Selectors` - `Add Selector`, add `classes` or `ids`. Add `CSS Properties` to your `classes` and `ids`. Properties specific for SVG you find in the category SVG.  \n" +
    "Back in the SVG tab, assign the `classes` and `ids` to a `SVG`, `g` or any other child of the SVG element.",
  FILTER:
    "### Add Filter  \n" +
    "For general information about SVG filter see especially [Smashing Magazine - The Art Of SVG Filters And Why It Is Awesome](https://www.smashingmagazine.com/2015/05/why-the-svg-filter-is-awesome/), [codedrops - SVG Filters 101](https://tympanus.net/codrops/2019/01/15/svg-filters-101/) and [W3C - Filter Effects Module Level](https://drafts.fxtf.org/filter-effects/#feGaussianBlurElement) \n" +
    "A SVG can be assigned as follows: add a `filter` element within a SVG and underneath the filter add effects like `feTurbulence` and `feDisplacementMap`. Assign an `id` to the filter element. Afterwards the `id` is displayed in the section `Assign SVG Filter Id` (left bottom). Select a SVG or a shape and assign the SVG filter Id to apply the effects of the filter. Alternatively if you want to apply the effect to a HTML element to, for example, distort the text within, assign the filter CSS property in the category OTHER.  \n" +
    "If you want to add a filter effect to a HTML element, add the SVG with the filter (with Id) somewhere in the HTML. The SVG can be empty, apart from the filter. Open the CSS tag, select a Selector which targets the HTML element, choose the category SVG, add a filter property, select the Id of the filter of the SVG already added somewhere in your HTML. Done. Easy. :)",
};

export const SVGS_CATEGORY_TITLE = {
  SVG: "SVG",
  SHAPE: "Shape",
  PATH: "Path",
  POLYGEN: "Polygen",
  TEXT: "Text",
  ANIMATE: "Animate",
  GRADIENT: "Gradient",
  FILTER: "Filter",
} as TObj;

export const allFields = {
  hrefUrl: {
    name: "href",
    type: EMetaFieldTypes.string,
    help: "### href  \nDefines a link to a resource as a reference URL.",
  },
  notOnItemAssignId: ["linearGradient", "radialGradient", "filter"],
  notOnItemId: [
    "stop",
    "title",
    "feBlend",
    "feFlood",
    "feColorMatrix",
    "feComposite",
    "feConvolveMatrix",
    "feGaussianBlur",
    "feImage",
    "feMerge",
    "feMergeNode",
    "feMorphology",
    "feOffset",
    "feDiffuseLighting",
    "feSpecularLighting",
    "fePointLight",
    "feDistantLight",
    "feSpotLight",
    "feTile",
    "feTurbulence",
    "feDisplacementMap",
    "textPath",
  ],
};

export const SVGS_META: metaIF[] = [
  ...SVG_CONSTANTS_META,
  ...SVG_CONSTANTS_SHAPES,
  ...SVG_CONSTANTS_PATH,
  ...SVG_CONSTANTS_TEXT,
  ...SVG_CONSTANTS_ANIMATE,
  ...SVG_CONSTANTS_GRADIENTS,
  ...SVG_CONSTANTS_FILTER,
  {
    name: "ALL",
    level: "block",
    category: ["ALL"],
    fieldsOrder: ["id", "gradientId", "filterId"],
    fields: [
      {
        name: "id",
        type: EMetaFieldTypes.string,
        conditions: {
          regex: "^[a-z-_][a-z0-9-_]*$",
        },
        help: htmlHelpTexts.helpId,
        notOnItem: allFields.notOnItemId,
      },

      {
        name: "gradientId",
        javascript: "fill",
        type: EMetaFieldTypes.assignSvgGradientId,
        notOnItem: [...allFields.notOnItemAssignId, ...allFields.notOnItemId],
        help: svgGradientsHelp,
        wrapperFn: (valueObj) => `url(#${valueObj.value})`,
      },
      {
        name: "filterId",
        javascript: "filter",
        type: EMetaFieldTypes.assignSvgFilterId,
        notOnItem: [...allFields.notOnItemAssignId, ...allFields.notOnItemId],
        help: svgGradientsHelp,
        wrapperFn: (valueObj) => `url(#${valueObj.value})`,
      },
    ],
    group: [
      {
        name: "id",
        fields: ["id"],
      },
      {
        name: "gradientIdG",
        display: ["Assign Gradient"],
        fields: ["gradientId"],
      },
      {
        name: "filterIdG",
        display: ["Assign Filter"],
        fields: ["filterId"],
      },
    ],

    default: {
      id: {
        value: "id-1",
      },
      gradientId: {
        value: "",
      },
      filterId: {
        value: "",
      },
    },
  },

  {
    name: "mpath",
    level: "inline",
    category: ["ANIMATE"],
    allowedChildren: ["title"],
    fieldsOrder: ["href"],
    fields: [
      {
        name: "href",
        type: EMetaFieldTypes.selectPathId,
        onOff: true,
        wrapperFn: (valueObj) => valueObj.value ? `#${valueObj.value}` : "",
        help:
          "### href  \n" +
          "You can assign a filter by clicking on its ID. If there is none yet, go to the tab `SVG`, add a filter and assign an ID to the filter. Back in `CSS` the ID is displayed and you can connect the filter with the selector by filter ID.",
      },
    ],
    default: {
      "href": {
        value: 0,
      },
    },
  },
];

const svgMeta = SVGS_META.find((a: metaIF) => a.name === "svg");
const pathMeta = SVGS_META.find((a: metaIF) => a.name === "path");
export const initialSvgs: { svgs: itemIF[]; editSvgId: number } = {
  svgs: [
    {
      id: 1,
      name: "svg",
      value: svgMeta ? svgMeta.default : SVGS_META[0].default,
      level: 1,
      parent: 0,
      position: 1,
    },
    {
      id: 2,
      name: "path",
      value: pathMeta ? pathMeta.default : SVGS_META[0].default,
      level: 2,
      parent: 1,
      position: 1,
    },
  ],
  editSvgId: 1,
};

export const getSvgCategoryMetas = (category: string) => {
  return getCategoryMetas({
    category,
    categories: SVGS_CATEGORIES,
    metas: SVGS_META,
  });
};
