import { metaIF, EMetaFieldTypes } from "../../types/item";
import { standardUnits } from "./utils";

const rotateUnits = [
  { id: "deg" },
  { id: "grad" },
  { id: "rad" },
  { id: "turn" },
];

export const CSS_PROPERTIES_TRANSFORM: metaIF[] = [
  {
    name: "backface-visibility",
    category: ["TRANSFORM"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "visible",
          },
          {
            id: "hidden",
          },
        ],
        help:
          "### backface-visibility  \n" +
          "See for example [w3schools - CSS backface-visibility Property](https://www.w3schools.com/cssref/css3_pr_backface-visibility.asp)",
      },
    ],
    group: [
      { name: "mainG", fields: ["main"], display: ["backface-visibility"] },
    ],
    default: { main: { value: "hidden" } },
  },
  {
    name: "matrix",
    category: ["TRANSFORM"],
    addToShorthand: "transform",
    fieldsOrder: [
      "scaleX",
      "skewY",
      "skewX",
      "scaleY",
      "translateX",
      "translateY",
    ],
    fields: [
      {
        name: "scaleX",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
      {
        name: "skewY",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
      {
        name: "skewX",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
      {
        name: "scaleY",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
      {
        name: "translateX",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
      {
        name: "translateY",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
    ],
    group: [
      {
        name: "scaleG",
        fields: ["scaleX", "scaleY"],
        display: ["scaleX", "scaleY"],
      },
      {
        name: "skewG",
        fields: ["skewX", "skewY"],
        display: ["skewX", "skewY"],
      },
      {
        name: "translateG",
        fields: ["translateX", "translateY"],
        display: ["translateX", "translateY"],
      },
    ],
    wrapperForGroup: [
      {
        name: "matrix",
        fields: [
          "scaleX",
          "skewY",
          "skewX",
          "scaleY",
          "translateX",
          "translateY",
        ],
        wrapperFn: (vObj) => {
          const scaleX = vObj.scaleX;
          const skewY = vObj.skewY;
          const skewX = vObj.skewX;
          const scaleY = vObj.scaleY;
          const translateX = vObj.translateX;
          const translateY = vObj.translateY;
          return `matrix(${scaleX}, ${skewY}, ${skewX}, ${scaleY}, ${translateX}, ${translateY})`;
        },
      },
    ],
    default: {
      scaleX: {
        value: 1,
      },
      skewX: {
        value: 0,
      },
      skewY: {
        value: 0,
      },
      scaleY: {
        value: 1,
      },
      translateX: {
        value: 0,
      },
      translateY: {
        value: 0,
      },
    },
    help:
      "### matrix  \n" +
      "The matrix is build as matrix(scaleX, skewY, skewX, scaleY, translateX, translateY).  \n" +
      "For further information see for example [MDN - Matrix](https://developer.mozilla.org/en-US/docs/Web/CSS/transform-function/matrix())",
  },

  {
    name: "perspective",
    category: ["TRANSFORM"],
    setOnParent: true,
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnits,
        help:
          "#### perspective  \n" +
          "Defines from how far away the user looks on the screen. `perspective` is set for the parent of the elements to be transformed.  \n" +
          "It activates the 3rd dimension. The smaller its value is, the more dramatic are transforms " +
          "in the 3rd dimension, like `rotateY` or `translateZ`. The higher its value is, the smaller is the visiable effect of 3D-transforms, " +
          "the perspective is from 'far away'. " +
          "With no perspective for the element set or value of 0 for the perspective, there is no movement in the 3rd dimension. " +
          "rotateY, for example, just squeezed the element horizontally, but it doesn't reshape as a real 3D object should.",
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["perspective"],
      },
    ],
    default: { main: { value: 400, unit: "px" } },
  },
  {
    name: "perspective-origin",
    category: ["TRANSFORM"],
    setOnParent: true,
    fieldsOrder: ["x-position", "y-position"],
    fields: [
      {
        name: "x-position",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: [
          { id: "%" },
          { id: "px" },
          { id: "rem" },
          { id: "vw" },
          { id: "vh" },
        ],
        help: "#### perspective-origin  \nSee for example [MDN - perspective-origin](https://developer.mozilla.org/en-US/docs/Web/CSS/perspective-origin)",
      },
      {
        name: "y-position",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: [
          { id: "%" },
          { id: "px" },
          { id: "rem" },
          { id: "vw" },
          { id: "vh" },
        ],
      },
    ],
    group: [
      {
        name: "positionG",
        fields: ["x-position", "y-position"],
        display: ["x-position", "y-position"],
      },
    ],
    default: {
      "x-position": { value: 200, unit: "%" },
      "y-position": { value: 200, unit: "%" },
    },
  },

  {
    name: "rotate",
    category: ["TRANSFORM"],
    addToShorthand: "transform",
    fieldsOrder: ["X", "Y", "Z"],
    fields: [
      {
        name: "X",
        type: EMetaFieldTypes.number,
        units: rotateUnits,
        onOff: false,
      },
      {
        name: "Y",
        type: EMetaFieldTypes.number,
        units: rotateUnits,
        onOff: false,
      },
      {
        name: "Z",
        type: EMetaFieldTypes.number,
        units: rotateUnits,
        onOff: true,
      },
    ],
    group: [
      {
        name: "XG",
        fields: ["X"],
      },
      {
        name: "YG",
        fields: ["Y"],
      },
      {
        name: "ZG",
        fields: ["Z"],
      },
    ],
    wrapperForGroup: [
      {
        name: "rotate",
        fields: ["X", "Y", "Z"],
        wrapperFn: (vObj) => {
          const X = vObj.X;
          const Y = vObj.Y;
          const Z = vObj.Z;

          if (X && Y && Z) {
            return `rotateX(${X}) rotateY(${Y}) rotate(${Z})`;
          }

          if (X && Z) {
            return `rotateX(${X}) rotate(${Z})`;
          }

          if (Y && Z) {
            return `rotateY(${Y}) rotate(${Z})`;
          }
          return `rotate(${Z})`;
        },
      },
    ],
    default: {
      X: { value: 45, unit: "deg" },
      Y: { value: 45, unit: "deg" },
      Z: { value: 45, unit: "deg" },
    },
  },

  {
    name: "scale",
    category: ["TRANSFORM"],
    addToShorthand: "transform",
    fieldsOrder: ["X", "Y", "Z"],
    fields: [
      {
        name: "X",
        type: EMetaFieldTypes.number,
      },
      {
        name: "Y",
        type: EMetaFieldTypes.number,
      },
      {
        name: "Z",
        type: EMetaFieldTypes.number,
      },
    ],

    wrapperForGroup: [
      {
        name: "scale",
        fields: ["X", "Y", "Z"],
        wrapperFn: (vObj) => {
          const X = vObj.X;
          const Y = vObj.Y;
          const Z = vObj.Z;

          if (!Z && X && Y) {
            if (X === Y) {
              return `scale(${X})`;
            }
          }

          let scaleStr = "";
          if (X) scaleStr += `scaleX(${X}) `;
          if (Y) scaleStr += `scaleY(${Y}) `;
          if (Z) scaleStr += `scaleZ(${Z}) `;

          return scaleStr.trim();
        },
      },
    ],

    default: {
      X: { value: 2, onOff: true },
      Y: { value: 2, onOff: true },
      Z: { value: 2 },
    },
  },
  {
    name: "skew",
    category: ["TRANSFORM"],
    addToShorthand: "transform",
    fieldsOrder: ["X", "Y"],
    fields: [
      {
        name: "X",
        type: EMetaFieldTypes.number,
        units: rotateUnits,
        onOff: false,
      },
      {
        name: "Y",
        type: EMetaFieldTypes.number,
        units: rotateUnits,
        onOff: false,
      },
    ],
    group: [
      {
        name: "XG",
        fields: ["X"],
      },
      {
        name: "YG",
        fields: ["Y"],
      },
    ],
    wrapperForGroup: [
      {
        name: "skew",
        fields: ["X", "Y"],
        wrapperFn: (vObj) => {
          const X = vObj.X;
          const Y = vObj.Y;

          let skewStr = "";
          if (X) skewStr += `skewX(${X}) `;
          if (Y) skewStr += `skewY(${Y}) `;

          return skewStr.trim();
        },
      },
    ],
    default: {
      X: { value: 30, unit: "deg", onOff: true },
      Y: { value: 30, unit: "deg" },
    },
  },
  {
    name: "transform-origin",
    category: ["TRANSFORM"],
    fieldsOrder: ["axis", "X", "xValue", "Y", "yValue", "Z", "zValue"],
    fields: [
      {
        name: "axis",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "axis",
          },
        ],
        canBeInitial: true,
        canBeInherited: true,
        help:
          "#### transform-origin  \n" +
          "Especially relevant for rotations.  \n" +
          "If you rotate around the X axis with `50% 0 0` the element rotates around its upper side, " +
          "with `50% 50% 0` around itself and with `50% 100% 0` around its bottom side.  \n" +
          "If you rotate around the Y axis, the first `transform-origin` value (X) defines the position of the axis: " +
          "`0 50% 0` makes the element rotate around its left side, `50% 50% 0` around itself and `100% 50% 0` around its right side.  \n" +
          "The last value of `transform-origin` moves the element as defined in X and Y, but pushes the x or y axis towards you, " +
          "when Z is posive, or away from you, when Z is negativ. " +
          "`50% 50% 100px` and `rotateX` moves the x axis from the vertical center of the element 100px towards you. " +
          "At 180deg is the element back in its original horizontal and vertical position, but 200px closer to you.",
      },
      {
        name: "X",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "value",
          },
          {
            id: "left",
          },
          {
            id: "center",
          },
          {
            id: "right",
          },
        ],
      },
      {
        name: "xValue",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnits,
        dependentOn: { field: "X", values: ["value"] },
      },

      {
        name: "Y",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "value",
          },
          {
            id: "top",
          },
          {
            id: "center",
          },
          {
            id: "bottom",
          },
        ],
      },
      {
        name: "yValue",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnits,
        dependentOn: { field: "Y", values: ["value"] },
      },
      {
        name: "Z",
        type: EMetaFieldTypes.number,
        units: standardUnits,
        onOff: false,
      },
    ],
    group: [
      {
        name: "axisG",
        fields: ["axis"],
        display: ["axis"],
      },
      {
        name: "XG",
        fields: ["X", "xValue", "Y", "yValue"],
        display: ["X", "value", "Y", "value"],
        dependentOn: { field: "axis", values: ["axis"] },
      },
      {
        name: "ZG",
        fields: ["Z"],
        display: ["Z"],
        dependentOn: { field: "axis", values: ["axis"] },
      },
    ],

    wrapperForGroup: [
      {
        name: "transform-origin",
        fields: ["axis", "X", "xValue", "Y", "yValue", "Z"],
        wrapperFn: (valObj) => {
          const valX = valObj.X;
          const valY = valObj.Y;
          const valZ = valObj.Z;
          const valXValue = valObj.xValue;
          const valYValue = valObj.yValue;

          const xStr = valX === "value" ? valXValue : valX;
          const yStr = valY === "value" ? valYValue : valY;

          if (valZ) {
            return `${xStr} ${yStr} ${valZ}`;
          }
          return `${xStr} ${yStr}`;
        },
      },
    ],

    default: {
      axis: { value: "axis" },
      X: { value: "center" },
      xValue: { value: 50, unit: "%" },
      Y: { value: "center" },
      yValue: { value: 50, unit: "%" },
      Z: { value: 0, unit: "px" },
    },
  },
  {
    name: "transform-style",
    category: ["TRANSFORM"],
    setOnParent: true,
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        options: [{ id: "flat" }, { id: "preserve-3d" }],
        onOff: true,
        help:
          "#### transform-style  \n" +
          "`preserve-3d` keeps the 3D effect for the children, when you transform the parent. \n" +
          "For example you have a 3D dice, made from 6 `divs`. " +
          "Those 6 `divs` are within another `div` with `perspective` and `transform-style:preserve-3d` set. " +
          "If you rotate the outer `div`, you'll see all sides of the 3D dice. " +
          "Remove the `transform-style` from the parent or set it to `flat`, and the rotation of the " +
          "outer `div` just shows a flat element rotating instead.",
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["transform-style"],
      },
    ],
    default: { main: { value: "preserve-3d" } },
  },
  {
    name: "translate",
    category: ["TRANSFORM"],
    addToShorthand: "transform",
    fieldsOrder: ["X", "Y", "Z"],
    fields: [
      {
        name: "X",
        type: EMetaFieldTypes.number,
        onOff: false,
        units: standardUnits,
      },
      {
        name: "Y",
        type: EMetaFieldTypes.number,
        onOff: false,
        units: standardUnits,
      },
      {
        name: "Z",
        type: EMetaFieldTypes.number,
        onOff: false,
        units: standardUnits,
      },
    ],
    group: [
      {
        name: "XG",
        fields: ["X"],
      },
      {
        name: "YG",
        fields: ["Y"],
      },
      {
        name: "ZG",
        fields: ["Z"],
      },
    ],
    wrapperForGroup: [
      {
        name: "translate",
        fields: ["X", "Y", "Z"],
        wrapperFn: (vObj) => {
          const X = vObj.X;
          const Y = vObj.Y;
          const Z = vObj.Z;

          let translateStr = "";
          if (X) translateStr += `translateX(${X}) `;
          if (Y) translateStr += `translateY(${Y}) `;
          if (Z) translateStr += `translateZ(${Z}) `;

          return translateStr.trim();
        },
      },
    ],
    default: {
      X: { value: 100, unit: "px", onOff: true },
      Y: { value: 100, unit: "px" },
      Z: { value: 100, unit: "px" },
    },
    help: `Moves the selected element to the right or left (X), up or down (Y) and/or for or back (Z).
    
To move the element along the Z-axis, you need to set the perspective on the parent element.`,
  },
];
