import { metaIF, metaFieldIF, groupIF } from "../../data/types/item";
import { addInArray } from "../utils/addStrInArrayBeforeOrAfter";
const clone = require("rfdc")();

const addCanBeOptionsOrValueOption = (fieldMeta: metaFieldIF): metaFieldIF => {
  const selectOptions =
    fieldMeta.type === "select" && fieldMeta.options
      ? [...fieldMeta.options]
      : [
          {
            id: "value",
          },
        ];

  const newOptions = [];

  // add auto generated options
  if (fieldMeta.canBeAuto && !selectOptions.find((o) => o.id === "auto")) {
    newOptions.push({ id: "auto" });
  }
  if (
    fieldMeta.canBeCalculated &&
    !selectOptions.find((o) => o.id === "calculate")
  ) {
    newOptions.push({ id: "calculate" });
  }
  if (
    fieldMeta.canBeInherited &&
    !selectOptions.find((o) => o.id === "inherit")
  ) {
    newOptions.push({ id: "inherit" });
  }
  if (
    fieldMeta.canBeInitial &&
    !selectOptions.find((o) => o.id === "initial")
  ) {
    newOptions.push({ id: "initial" });
  }
  if (fieldMeta.canBeAddOptions) {
    fieldMeta.canBeAddOptions.forEach((option) => {
      if (!selectOptions.find((o) => o.id === option))
        newOptions.push({ id: option });
    });
  }

  const newSelectOptions = [...selectOptions, ...newOptions];
  return {
    ...fieldMeta,
    options: newSelectOptions,
  };
};

// if fieldMeta already is a select, add the new options to the existing options
// if not, add a select with a value option, and use the original field as value field
const getCanBeSelect = ({
  meta,
  fieldMeta,
}: {
  meta: metaIF;
  fieldMeta: metaFieldIF;
}): void => {
  const newFieldMeta = addCanBeOptionsOrValueOption(fieldMeta);
  if (newFieldMeta.type === "select" && newFieldMeta.options) {
    meta.fields = meta.fields?.map((f) => {
      if (f.name === fieldMeta.name) {
        return newFieldMeta;
      }
      return f;
    });
    if (fieldMeta.canBeCalculated) {
      addCalculationField({ fieldMeta, meta });
    }
  
    return;
  }

  // change type of original field to select and options
  // create a new field with field name + "Value" and type of original field

  const selectField = {
    ...newFieldMeta,
    type: "select",
  } as metaFieldIF;
  const valueField = {
    name: `${fieldMeta.name}Value`,
    type: fieldMeta.type,
    units: fieldMeta.units,
    dependentOn: {
      field: fieldMeta.name,
      values: ["value"],
    },
  } as metaFieldIF;

  const newFields = meta.fields?.map((f) => {
    if (f.name === fieldMeta.name) {
      return selectField;
    }
    return f;
  });
  const newFields2 = newFields
    ? [...newFields, valueField]
    : [selectField, valueField];

  const newFieldsOrder = meta.fieldsOrder
    ? addInArray({
        array: meta.fieldsOrder,
        str: fieldMeta.name,
        addStr: valueField.name,
        addAfter: true,
      })
    : [fieldMeta.name, valueField.name];

  const newDefault = meta.default?.[fieldMeta.name]
    ? {
        ...meta.default,
        [fieldMeta.name]: {
          value: "value",
        },
        [valueField.name]: meta.default[fieldMeta.name],
      }
    : {
        [fieldMeta.name]: {
          value: "value",
        },
      };

  const newGroups = addFieldToGroup({
    newGroups: meta.group ?? [],
    fieldName: fieldMeta.name,
    newFieldName: valueField.name,
    newFieldDisplayName: "value",
  });

  meta.fieldsOrder = newFieldsOrder;
  meta.fields = newFields2;
  meta.group = newGroups;
  meta.default = newDefault;

  if (fieldMeta.canBeCalculated) {
    addCalculationField({ fieldMeta, meta });
  }
};

export const addFieldToGroup = ({
  newGroups,
  fieldName,
  newFieldName,
  newFieldDisplayName,
}: {
  newGroups: groupIF[];
  fieldName: string;
  newFieldName: string;
  newFieldDisplayName: string;
}) => {
  const groupsCopy = [...newGroups];

  let isInNewMetaGroup = groupsCopy.find((g) => g.fields.includes(fieldName));
  if (!isInNewMetaGroup) {
    const newGroup = {
      name: `${fieldName}G`,
      fields: [fieldName, newFieldName],
      display: [fieldName, newFieldDisplayName],
    };
    return [...groupsCopy, newGroup];
  }

  const fields = isInNewMetaGroup.fields;
  const newFields = addInArray({
    array: fields,
    str: fieldName,
    addStr: newFieldName,
    addAfter: true,
  });

  const index = fields.findIndex((f) => f === fieldName);
  const displays = isInNewMetaGroup.display ?? [];

  const newDisplayes = newFields.length-1 === displays.length ? [
    ...displays.slice(0, index + 1),
    newFieldDisplayName,
    ...displays.slice(index + 1),
  ] : [...displays, fieldName, newFieldDisplayName];

  return groupsCopy.map((g) => {
    if (g.name === isInNewMetaGroup.name) {
      return {
        ...g,
        fields: newFields,
        display: newDisplayes,
      };
    }
    return g;
  });
};

const addCalculationField = ({
  fieldMeta,
  meta,
}: {
  fieldMeta: metaFieldIF;
  meta: metaIF;
}): void => {
  const calcFieldName = `${fieldMeta.name}Calculate`;
  const newField = {
    name: calcFieldName,
    type: "string",
    dependentOn: {
      field: fieldMeta.name,
      values: ["calculate"],
    },
    onOff: fieldMeta.onOff,
    wrapperFn: (valueObj) => `calc(${valueObj.value})`,
  } as metaFieldIF;

  const newFields = meta.fields ? [...meta.fields, newField] : [newField];
  const valueFieldName = fieldMeta.name + "Value";
  const addAfterFieldName = meta.fields?.find(f => f.name === valueFieldName) ? valueFieldName : fieldMeta.name;

  const newFieldsOrder = meta.fieldsOrder
    ? addInArray({
        array: meta.fieldsOrder,
        str: addAfterFieldName,
        addStr: calcFieldName,
        addAfter: true,
      })
    : [fieldMeta.name, calcFieldName];

  const newDefault = meta.default
    ? {
        ...meta.default,
        [calcFieldName]: {
          value: "100% - 1rem",
        },
      }
    : {
        [calcFieldName]: {
          value: "100% - 1rem",
        },
      };

  const newGroups = addFieldToGroup({
    newGroups: meta.group ?? [],
    fieldName: addAfterFieldName,
    newFieldName: calcFieldName,
    newFieldDisplayName: "calculate",
  });

  meta.fields = newFields;
  meta.fieldsOrder = newFieldsOrder;
  meta.default = newDefault;
  meta.group = newGroups;
};

// attention! clone meta before passing it to this function to avoid side effects
export const addCanBeFieldsToFieldsFieldsOrderGroupDefault = (
  newMeta: metaIF
): metaIF => {
  const cloned: metaIF = clone(newMeta);
  const fields = cloned.fields;
  if (!fields) {
    return newMeta;
  }
  for (const fieldMeta of fields) {
    if (
      fieldMeta.canBeAuto ||
      fieldMeta.canBeCalculated ||
      fieldMeta.canBeInherited ||
      fieldMeta.canBeInitial ||
      fieldMeta.canBeAddOptions
    ) {
      getCanBeSelect({ meta: cloned, fieldMeta });
    }
  }

  return cloned;
};
