import {
  EMetaFieldTypes,
  TObject,
  itemIF,
  metaFieldIF,
} from "../../data/types/item";
import { itemTypes } from "../meta/getMetas";
import { getFieldValueStr } from "./getItemFieldValueString";
import { getItemFieldValueStringForAddOrRemoveFieldGroup } from "./getItemFieldValueStringForAddOrRemoveFieldGroup";
import { snippetIF } from "../../data/types/snippets";
import { getMetaFromSnippet } from "../snippets/addAndGetMetas";

const isKeyframeId = ({
  fieldMeta,
  attr,
  item,
}: {
  fieldMeta: metaFieldIF | undefined;
  attr: TObject;
  item: itemIF;
}) => {
  if (fieldMeta?.name === "keyframeId") {
    attr["keyframeId"] =
      !item.value?.keyframeId?.value || item.value.keyframeId.value === ""
        ? "none"
        : item.value.keyframeId.value;
    return true;
  }
};

export const getValuesOfField = ({
  itemType,
  fieldName,
  snippet,
  attr,
  item,
}: {
  itemType: itemTypes;
  fieldName: string;
  snippet: snippetIF;
  attr: TObject;
  item: itemIF;
}): void => {
  const meta = getMetaFromSnippet({ snippet, itemType, itemName: item.name });

  const fieldMeta = meta?.fields?.find((f) => f?.name === fieldName);
  if (!fieldMeta) {
    console.warn(`Field has no field meta: ${fieldName}`, meta);
    return;
  }

  if (
    isKeyframeId({
      fieldMeta,
      attr,
      item,
    })
  ) {
    return;
  }

  if (fieldMeta?.type === EMetaFieldTypes.addOrRemoveFieldGroup) {
    getItemFieldValueStringForAddOrRemoveFieldGroup({
      itemType,
      fieldMeta,
      item,
      snippet,
      attr,
    });

    return;
  }

  if (!(item.value && item.value[fieldMeta.name])) {
    console.warn(`Field has no value: ${fieldMeta.name}`, meta);
    return;
  }

  const valueStr = getFieldValueStr({
    itemType,
    fieldMeta,
    item,
    snippet,
  });
  if (valueStr || valueStr === "") {
    attr[fieldMeta.name] = valueStr;
  }
};
