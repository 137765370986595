import { TSelectorData, TPosAndSh } from "../../../data/types/item";
import { getCssCode } from "./getCssCode";

const getKeyframesAndStepsCss = ({
  keyframeComposedSelectorItems,
  lineBreak = "",
  addSpaceAtLineStart = "",
}: {
  keyframeComposedSelectorItems: TSelectorData[];
  lineBreak?: string;
  addSpaceAtLineStart?: string;
}) => { 
  const keyframesSDataS = keyframeComposedSelectorItems.filter(
    (sData) => sData.selector?.name === "@keyframes"
  );
  let keyframeCss = "";
  keyframesSDataS.forEach((keyframeSData) => {
    keyframeCss += `${addSpaceAtLineStart}${keyframeSData.nameStr} {${lineBreak}`;

    const stepsSDataS = keyframeComposedSelectorItems.filter(
      (sData) =>
        sData.selector?.name === "step" &&
        sData.selector.parent === keyframeSData.selector.id
    );
    stepsSDataS.forEach((sData) => {
      keyframeCss += `${addSpaceAtLineStart}      ${sData.nameStr} ${lineBreak}`;
      keyframeCss += `${sData.css}`;
      keyframeCss += `${addSpaceAtLineStart}      }${lineBreak}`;
    });

    keyframeCss += `${addSpaceAtLineStart}}${lineBreak}`;
  });

  return keyframeCss;
};

const getCssStrFromSortedSData = ({
  mediaComposedSelectorItems,
  lineBreak = "",
  addSpaceAtLineStart = "",
}: {
  mediaComposedSelectorItems: TSelectorData[];
  lineBreak?: string;
  addSpaceAtLineStart?: string;
}) => {
  let cssStrMedia = "";
  mediaComposedSelectorItems.forEach((sData) => {
    if (sData.selector.name === "@media") {
      if (cssStrMedia !== "") {
        cssStrMedia += `${addSpaceAtLineStart}}${lineBreak}`;
      }
      cssStrMedia += `${addSpaceAtLineStart}${sData.nameStr} {${lineBreak}`;
      return;
    }

    const selectorCss = getCssCode({
      posAndShs: sData.posAndShs as TPosAndSh[],
      lineBreak,
      addSpaceAtLineStart: addSpaceAtLineStart + "          ",
    });
    cssStrMedia +=
      `${addSpaceAtLineStart}   ${sData.nameStr} {${lineBreak}` +
      `${selectorCss}` +
      `${addSpaceAtLineStart}     }${lineBreak}`;
  });

  if (cssStrMedia !== "") {
    cssStrMedia += `${addSpaceAtLineStart}}${lineBreak}`;
  }

  return `${cssStrMedia !== "" ? `\n` : ""}${cssStrMedia}`;
};

export const createCssString = ({
  lineBreak,
  addSpaceAtLineStart,
  classComposedSelectorItems,
  mediaComposedSelectorItems,
  keyframeComposedSelectorItems,
}: {
  lineBreak: string;
  addSpaceAtLineStart: string;
  classComposedSelectorItems?: TSelectorData[];
  mediaComposedSelectorItems?: TSelectorData[];
  keyframeComposedSelectorItems?: TSelectorData[];
}) => {
  const keyframeCss = keyframeComposedSelectorItems
    ? getKeyframesAndStepsCss({
        keyframeComposedSelectorItems,
        lineBreak,
        addSpaceAtLineStart,
      })
    : "";

  let assignedSelectorsCssStr = "";

  classComposedSelectorItems &&
    classComposedSelectorItems.forEach((sData) => {
      assignedSelectorsCssStr += `${
        assignedSelectorsCssStr === "" ? lineBreak : ""
      }${addSpaceAtLineStart}${sData.nameStr} {${lineBreak}${
        sData.css
      }${addSpaceAtLineStart}}${lineBreak}`;
    });

  const mediaCssStr = mediaComposedSelectorItems
    ? getCssStrFromSortedSData({
        mediaComposedSelectorItems,
        lineBreak,
        addSpaceAtLineStart,
      })
    : "";

  return `${keyframeCss}${assignedSelectorsCssStr}${mediaCssStr}`;
};
