import { useState, useEffect, FC } from "react";
import { useStateContext } from "../../lib/state/state";
import { snippetIF } from "../../data/types/snippets";
import { itemIF, itemWithChildrenIF } from "../../data/types/item";
import { itemTypes } from "../../lib/meta/getMetas";
import { setEditItemId } from "../../lib/reducer/items/actions";
import { RenderTreeButtonAndChildren } from "../forms/EditTree";
import { getOpenItemsIds } from "../../lib/tree/open-and-close-branch";
import { buildTree } from "../../lib/items/buildTree";
import { writeHtmlNames } from "../../lib/items/writeHtmlNames";
import { useScrollIntoView } from "../atoms/useScrollIntoView";
import "./HtmlItemsTree.css";

export const HtmlTreeForSnippet: FC<{
  snippet?: snippetIF;
  selectedItem?: itemIF;
  itemOnClick?: (item: itemIF) => void;
  dontShowCodePreview?: boolean;
}> = ({ dontShowCodePreview, snippet, selectedItem, itemOnClick }) => {
  const { dispatch } = useStateContext();

  const items = snippet?.data?.htmls!;
  const [branches, setBranches] = useState([] as itemWithChildrenIF[]);
  useEffect(() => {
    if (!items) {
      setBranches([]);
      return;
    }
    const itemsAndName = writeHtmlNames({
      htmls: items,
      selectors: snippet?.data?.selectors,
      svgs: snippet?.data?.svgs,
    });
    const itemsWithLongName = items.map((i) => {
      const itemAndName = itemsAndName.find((iwn) => iwn.id === i.id);
      return {
        ...i,
        name: itemAndName?.name || i.name,
      };
    }) as itemIF[];

    const newBranches = buildTree(itemsWithLongName);
    setBranches(newBranches);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snippet, snippet?.updatedAt]);

  const listRef = useScrollIntoView(selectedItem);

  if (!snippet) {
    return (
      <div className="tree-table">
        <div>SELECT A SNIPPET</div>
      </div>
    );
  }

  const openItemsIds = getOpenItemsIds({
    items,
    item: selectedItem,
  });

  const handleItemOnClick = (item: itemIF) => {
    if (itemOnClick) {
      itemOnClick(item);
    } else {
      dispatch(
        setEditItemId({
          itemType: itemTypes.BLOCK_HTML,
          newEditItemId: item.id,
        })
      );
    }
  };

  return (
    <div className="tree-table" ref={listRef}>
      <div className="html-tree-container form-elements-wrapper">
        {branches.length > 0 &&
          branches.map((item: itemWithChildrenIF) => {
            return (
              <RenderTreeButtonAndChildren
                key={item.id}
                itemType={itemTypes.BLOCK_HTML}
                item={item}
                editItem={selectedItem}
                editFn={handleItemOnClick}
                openItemsIds={openItemsIds}
                dontShowCodePreview={dontShowCodePreview}
              />
            );
          })}
      </div>
    </div>
  );
};
