import { itemTypes } from "../meta/getMetas";
import { itemIF, itemValueIF } from "../../data/types/item";


// go through fieldNamesEndWithSelect and replace
// const mainFieldName = fieldName.replace("Select", "");
// fieldName -> mainFieldName
// mainFieldName -> mainFieldNameValue
// remove fieldName from fieldNamesEndWithSelect
// call replaceEndsWithSelectInItem recursively
const replaceEndsWithSelectInItem = ({
  item,
  fieldNamesEndWithSelect,
}: {
  item: itemIF;
  fieldNamesEndWithSelect: string[];
}) => {
  const fieldNameEndsWithSelect = fieldNamesEndWithSelect[0];
  if(!fieldNameEndsWithSelect) return item;

  const mainFieldName = fieldNameEndsWithSelect.replace("Select", "");

  const itemValue = item.value;
  if(!itemValue) return item;
  const newItemValue: itemValueIF = {};

  const fieldNames = Object.keys(itemValue);
  for (const fieldName of fieldNames) {
    if(fieldName === mainFieldName) {
      newItemValue[`${mainFieldName}Value`] = itemValue[fieldName];
      continue;
    }
    if(fieldName === fieldNameEndsWithSelect) {
      newItemValue[mainFieldName] = itemValue[fieldName];
      continue;
    }
    newItemValue[fieldName] = itemValue[fieldName];
  }

  const newItem = {
    ...item,
    value: newItemValue,
  }

  fieldNamesEndWithSelect.shift();

  if(!fieldNamesEndWithSelect.length) return newItem;

  return replaceEndsWithSelectInItem({
    item: newItem,
    fieldNamesEndWithSelect,
  });
};

export const replaceEndsWithSelectFields = ({
  itemType,
  items,
}: {
  itemType: itemTypes;
  items: itemIF[];
}) => {
  if (itemType !== itemTypes.CLASSES_CSS) {
    return items;
  }
  const newItems = items.map((item) => {
    const itemValues = item.value;
    if (!itemValues) return item;

    const fieldNames = Object.keys(itemValues);

    const fieldNamesEndWithSelect = fieldNames.filter((fieldName) => {
      return fieldName.endsWith("Select");
    });

    if (!fieldNamesEndWithSelect.length) return item;

    const newItem = replaceEndsWithSelectInItem({
      item,
      fieldNamesEndWithSelect,
    });

    return newItem;
  });

  return newItems;
};
