import { getCssForClassOrHtml } from "../../lib/render/css/getSelectorData";
import { snippetIF } from "../../data/types/snippets";

export const getHtmlSelectorCss = (snippet: snippetIF) => {
  const selectors = snippet.data.selectors;
  const allhtmls = selectors?.filter((s) => s.name === "html");
  const htmls = allhtmls?.filter((s) => s.value?.main?.value === "html");
  let htmlSelectorCss = "";
  htmls?.forEach((h) => {
    if (!snippet) return;

    const data = getCssForClassOrHtml({
      selector: h,
      lineBreak: "\n",
      addSpaceAtLineStart: "",
      snippet,
    });
    htmlSelectorCss += data.css;
  });

  if (htmlSelectorCss === "") return "";
  return `html {
${htmlSelectorCss}
}`;
};
