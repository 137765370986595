import { useState, useEffect, use } from "react";
import { useStateContext } from "../../lib/state/state";
import { itemIF } from "../../data/types/item";
import { snippetIF } from "../../data/types/snippets";
import { ReactComponent as Copy } from "../../assets/imgs/copy-all.svg";
import { ReactComponent as Delete } from "../../assets/imgs/delete.svg";
import { copySvgFromOtherSnippet } from "../../lib/reducer/items/actions";
import { itemInTreeDelete } from "../../lib/reducer/items/actions";
import { itemTypes } from "../../lib/meta/getMetas";
import { setPreviewSnippetId } from "../../lib/reducer/settings/actions";
import {
  SortAndFindSnippets,
  SelectSnippetButtons,
} from "../modules/SortAndFind";
import { SvgTreeForSnippet } from "../svg/Tree2";

// add a preview snippet flag in the app state, and if filles display the preview snippet

export const CopySvgFromOtherSnippet = () => {
  const {
    state: {
      snippet: { snippets, editSnippetId },
      settings: { previewSnippetId },
    },
    dispatch,
  } = useStateContext();

  const editSnippet = snippets.find((s) => s.id === editSnippetId);
  const [selectedSnippetId, setSelectedSnippetId] = useState<
    string | undefined
  >(undefined);
  const snippetFrom = snippets.find((s) => s.id === selectedSnippetId);
  const [snippets2, setSnippets2] = useState<snippetIF[]>([]);

  const [selectedItemFromSnippet, setSelectedItemFromSnippet] = useState(
    undefined as itemIF | undefined
  );

  console.log("selectedItemFromSnippet", selectedItemFromSnippet);

  const [selectedItemToSnippet, setSelectedItemToSnippet] = useState(
    undefined as itemIF | undefined
  );

  useEffect(() => {
    return () => {
      dispatch(setPreviewSnippetId());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const allButEditSnippet = snippets
      .filter((s) => s.data.svgs?.length > 0)
      .filter((s) => s.id !== editSnippetId);
    setSnippets2(allButEditSnippet);
  }, [editSnippetId, snippets]);

  useEffect(() => {
    if (
      selectedSnippetId &&
      previewSnippetId &&
      selectedSnippetId !== previewSnippetId
    ) {
      dispatch(setPreviewSnippetId(selectedSnippetId));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSnippetId]);

  const snippetFromPreviewBtnClass =
    snippetFrom?.id === previewSnippetId ? "blue" : "blue-outline";
  const snippetToPreviewBtnClass = !previewSnippetId ? "blue" : "blue-outline";

  return (
    <>
      <div>
        <SortAndFindSnippets allSnippets={snippets2} title="Select snippet">
          <SelectSnippetButtons
            selectedSnippetId={selectedSnippetId!}
            setSelectedSnippetId={setSelectedSnippetId}
            color="gray-outline"
          />
        </SortAndFindSnippets>
      </div>

      <div className="form-elements-wrapper flex-k50p mt-2r">
        <div>
          <div className="section-title medium mb-1r">
            <div className="mr-1r">Copy from</div>
            <button
              className={`button icon ${snippetFromPreviewBtnClass}`}
              onClick={() => {
                if (!snippetFrom || snippetFrom?.id === editSnippet?.id) {
                  return;
                }
                dispatch(setPreviewSnippetId(snippetFrom.id));
              }}
            >
              Preview
            </button>
            <button
              type="button"
              className={`button icon blue`}
              onClick={() => {
                if (!selectedItemFromSnippet) return;
                dispatch(
                  copySvgFromOtherSnippet({
                    snippetFromId: selectedSnippetId!,
                    itemFromId: selectedItemFromSnippet?.id!,
                    itemToId: selectedItemToSnippet?.id!,
                  })
                );
              }}
            >
              <span className="sr-only">Copy SVG</span>
              <Copy />
            </button>
          </div>

          <SvgTreeForSnippet
            snippet={snippetFrom}
            selectedItem={selectedItemFromSnippet}
            itemOnClick={(itemId) => {
              setSelectedItemFromSnippet(itemId);
            }}
          />
        </div>

        <div>
          <div className="section-title medium mb-1r flex justify-space-between">
            <div>Copy to</div>
            <button
              className={`button icon ${snippetToPreviewBtnClass}`}
              onClick={() => {
                dispatch(setPreviewSnippetId());
              }}
            >
              Preview
            </button>

            <button
              type="button"
              className={`button icon orange${
                !selectedItemToSnippet ? " disabled" : ""
              }`}
              onClick={() => {
                if (!selectedItemToSnippet) return;
                dispatch(
                  itemInTreeDelete({
                    itemType: itemTypes.SVGS,
                    editItemId: selectedItemToSnippet?.id!,
                  })
                );
              }}
            >
              <span className="sr-only">Delete HTML in Copy to snippet</span>
              <Delete />
            </button>
          </div>

          <SvgTreeForSnippet
            snippet={editSnippet}
            selectedItem={selectedItemToSnippet}
            itemOnClick={(itemId) => {
              setSelectedItemToSnippet(itemId);
            }}
          />
        </div>
      </div>
    </>
  );
};
