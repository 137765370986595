import { FC } from "react";
import { useStateContext } from "../../lib/state/state";
import { itemTypes } from "../../lib/meta/getMetas";
import { addItem } from "../../lib/reducer/items/actions";
import { itemIF } from "../../data/types/item";
import { Button } from "../atoms/button";
import { SectionTitle } from "../modules/SectionTitle";
import { getAddItemIsDisabled } from "../../lib/items/getIsItemDisabled";
import { getItemsInCategory } from "../../lib/css/main";

export const AddSvg: FC<{ items: itemIF[]; editItem: itemIF | undefined }> = ({
  items,
  editItem,
}) => {
  const { dispatch } = useStateContext();

  const itemType = itemTypes.SVGS;
  const itemsInCategories = getItemsInCategory({
    itemType,
    withCategories: true,
  });

  const parent = items.find((i) => i.id === editItem?.parent);
  const disabledMetas = getAddItemIsDisabled({
    editItem,
    parent,
    items: itemsInCategories,
  });

  return (
    <>
      <div className="mb-1r">
        <SectionTitle label="Add SVG" size="large" />
      </div>
      {itemsInCategories.map((iic) => {
        return (
          <div key={iic.name} className="add-buttons-box mb-1r">
            <SectionTitle label={iic.title!} />
            <div className="btn-row">
              {iic.items.map((item) => {
                const allButSvgDisbled = !editItem && item.name !== "svg";
                return (
                  <Button
                    key={item.name}
                    disableBtn={
                      allButSvgDisbled || disabledMetas.includes(item.name)
                    }
                    btnOnClick={() => {
                      dispatch(
                        addItem({
                          itemType,
                          name: item.name,
                          addAfterItemId: editItem?.id,
                        })
                      );
                    }}
                  >
                    {item.display ? item.display : item.name}
                  </Button>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};
