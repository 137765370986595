import { FC, useState } from "react";
import { useStateContext } from "../../lib/state/state";
import { ReactComponent as UploadToCloud } from "../../assets/imgs/cloud-upload.svg";
import { getUserId } from "../../lib/signinup/handleTokens";
import { Tooltip } from "../atoms/tooltip";
import { Notification } from "../atoms/Notification";
import { createOrUpdateSnippet } from "./createOrUpdateSnippet";

export const SyncEditSnippetBtn: FC<{ notificationPosition?: "right" }> = ({
  notificationPosition,
}) => {
  const {
    state: {
      snippet: { snippets, editSnippetId },
      settings: { isLoading },
    },
    dispatch,
  } = useStateContext();

  const [msg, setMsg] = useState("");
  const [showMsg, setShowMsg] = useState(false);

  const editSnippet = snippets?.find((s) => s.id === editSnippetId);
  const userId = getUserId() as string | undefined;

  const isBtnDisabled = !userId || isLoading;
  const btnClassName = `toolbar-button${
    isBtnDisabled ? " disabled" : ""
  } tooltip-container-parent`;
  const tooltipText = isBtnDisabled
    ? "Sign in to cloud sync the current snippet"
    : "Cloud sync the current snippet";

  return (
    <div
      className={btnClassName}
      onClick={() => {
        if (!userId || isLoading || !editSnippet) {
          setMsg("Error: You need to be signed in to upload snippets.");
          setShowMsg(true);
          return;
        }

        createOrUpdateSnippet({
          editSnippet,
          userId,
          dispatch,
          setMsg,
          setShowMsg,
        });
      }}
    >
      <UploadToCloud />
      <Tooltip tooltipText={tooltipText} direction="left-center" />

      {showMsg && (
        <Notification
          msg={msg}
          notificationPosition={notificationPosition}
          setShowMsg={setShowMsg}
        />
      )}
    </div>
  );
};
