import { TObject, metaFieldIF } from "../../data/types/item";
import { itemTypes } from "../meta/getMetas";
import { snippetDataIF } from "../../data/types/snippets";

export const getEditItemDataStr = ({
  itemType,
}: {
  itemType: itemTypes;
}): keyof snippetDataIF => {
  return itemType === itemTypes.STYLEPACK
    ? "editStylepackId"
    : itemType === itemTypes.BLOCK_HTML
    ? "editHtmlId"
    : itemType === itemTypes.SELECTORS
    ? "editSelectorId"
    : itemType === itemTypes.CLASSES_CSS
    ? "editPropertyId"
    : "editSvgId";
};

export const getItemsDataStr = (itemType: itemTypes): keyof snippetDataIF => {
  const dataStr =
    itemType === itemTypes.STYLEPACK
      ? "stylepacks"
      : itemType === itemTypes.BLOCK_HTML
      ? "htmls"
      : itemType === itemTypes.SELECTORS
      ? "selectors"
      : itemType === itemTypes.CLASSES_CSS
      ? "properties"
      : "svgs";
  return dataStr;
};

// check if field meta defaults are equal to the values for those fields in the item
export const isValueDefault = (
  valueObj: TObject,
  fieldMeta: metaFieldIF
): boolean => {
  if (fieldMeta.default) {
    let countDefault = 0,
      countEqual = 0;
    Object.keys(fieldMeta.default).forEach((d) => {
      if (fieldMeta.default?.[d] === valueObj[d]) {
        countEqual += 1;
      }
      countDefault += 1;
    });
    if (countDefault === countEqual) {
      return true;
    }
  }
  return false;
};

const getRgbaString = (obj: TObject) =>
  `rgba(${obj.r},${obj.g},${obj.b},${obj.a})`;

export const getValueStr = ({ valObj }: { valObj: TObject }) => {
  if (!valObj || valObj.value === false) {
    return "";
  }

  const unit = valObj
    ? valObj.unit === "s"
      ? "s"
      : valObj.unit && valObj.value !== 0 && valObj.value !== "0"
      ? valObj.unit
      : ""
    : "";

  if (valObj.value === true || valObj.value === false) {
    return valObj.value;
  }

  return valObj
    ? valObj.value || valObj.value === 0
      ? valObj.value.r || valObj.value.r === 0
        ? getRgbaString(valObj.value)
        : `${valObj.value}${unit}`
      : ""
    : "";
};


