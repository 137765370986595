import { FC } from "react";
import { snippetIF } from "../../data/types/snippets";
import { CustomIframe } from "../atoms/CustomIFrame";
import { useSnippetHtml } from "../preview/useSnippetHtml";

export const GalleryItem: FC<{
  snippet: snippetIF;
  setSelectedSnippet: (s: snippetIF) => void;
  username?: string;
}> = ({ snippet, setSelectedSnippet, username }) => {

  const snippetHtml = useSnippetHtml({
    snippet,
  });
  const { sName, sHtml, sHeadCss, sCss, sFonts } = snippetHtml;


  return (
    <div className="gallery-item">
      <div
        className="overlay"
        onClick={() => {
          setSelectedSnippet(snippet);
        }}
      >
        <div>{snippet.name}</div>
        <div>{username}</div>
      </div>

      <CustomIframe
            title={`snippet preview for ${sName}`}
            headContent={sFonts}
            htmlSelectorCss={`${sHeadCss}${sCss}`}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: sHtml,
          }}
        />
      </CustomIframe>
    </div>
  );
};

