import { useStateContext } from "../../../lib/state/state";
import { itemIF } from "../../../data/types/item";
import { itemTypes } from "../../../lib/meta/getMetas";
import { assignSvgToHtml } from "../../../lib/reducer/items/actions";
import { Button } from "../../atoms/button";
import { SortAndFindChildren } from "../../modules/SortAndFind/SortAndFindItems";

const AssignSvgToHtmlBtns = ({
  items,
  editItem,
}: {
  items?: itemIF[];
  editItem: itemIF | undefined;
}) => {
  const { dispatch } = useStateContext();
  if (!items || items.length === 0) {
    return (
      <div className="btn-row">
        <Button disableBtn={true} btnOnClick={() => {}}>
          NO SVG IN SNIPPET
        </Button>
      </div>
    );
  }

  return (
    <div className="btn-row mt-1r">
      {items.map((s) => {
        const isDisabled = editItem?.name === "text";
        const isActive =
          editItem?.value?.htmlSvgCodeSnippetSvgId.value === s.id;
        return (
          <button
            key={s.id}
            type="button"
            className={`button ${isActive ? "blue" : "blue-outline"}${isDisabled ? " disabled" : ""}`}
            onClick={() => {
              if (isDisabled) return;
              dispatch(
                assignSvgToHtml({
                  itemType: itemTypes.BLOCK_HTML,
                  svgId: s.id,
                })
              );
            }}
          >
            {`${s.value?.main.value}`}
          </button>
        );
      })}
    </div>
  );
};

export const AssignSvgToHtml = () => {
  const {
    state: {
      snippet: { snippets, editSnippetId },
    },
  } = useStateContext();
  const editSnippet = snippets?.find((s) => s.id === editSnippetId);
  const htmls = editSnippet?.data?.htmls || [];
  const editHtml = htmls.find((h) => h.id === editSnippet?.data?.editHtmlId);

  if (!editSnippet) return null;

  const topLevelSvgs = editSnippet.data.svgs?.filter(
    (c) => c.name === "svg" && c.level === 1
  );

  return (
    <SortAndFindChildren allItems={topLevelSvgs} title="Assign SVG to HTML">
      <AssignSvgToHtmlBtns editItem={editHtml} />
    </SortAndFindChildren>
  );
};
