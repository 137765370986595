import { metaIF, EMetaFieldTypes } from "../../types/item";
import { standardUnits } from "./utils";

export const CSS_PROPERTIES_TABLE: metaIF[] = [
  {
    name: "border-collapse",
    category: ["TABLE"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "separate",
          },
          {
            id: "collapse",
          },
        ],
        help:
          "### border-collapse  \n" +
          "See for example [w3schoos - border-collape](https://www.w3schools.com/cssref/pr_border-collapse.asp)",
      },
    ],
    group: [{ name: "mainG", fields: ["main"], display: ["border-collapse"] }],
    default: {
      main: {
        value: "collapse",
      },
    },
  },
  {
    name: "border-spacing",
    category: ["TABLE"],
    fieldsOrder: ["main", "horizontal_spacing", "vertical_spacing"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "value",
          },
          {
            id: "pair",
          },
        ],
        help:
          "### border-spacing  \n" +
          "See for example [w3schoos - border-spacing](https://www.w3schools.com/cssref/pr_border-spacing.asp)",
        warning:
          "### Set border-collapse to seperate  \nThis property only works when `border-collapse` is `seperate`",
      },
      {
        name: "horizontal_spacing",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnits,
      },
      {
        name: "vertical_spacing",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnits,
        dependentOn: {
          field: "main",
          values: ["pair"],
        },
      },
    ],
    group: [
      {
        name: "valueOrPair",
        fields: ["main"],
        display: ["spacing"],
      },
      {
        name: "hSpacingG",
        fields: ["horizontal_spacing"],
        display: ["horizontal"],
      },
      {
        name: "vSpacingG",
        fields: ["vertical_spacing"],
        display: ["vertical"],
      },
    ],

    wrapperForGroup: [
      {
        name: "border-spacing",
        fields: ["main", "horizontal_spacing", "vertical_spacing"],
        wrapperFn: (vObj) => {
          const main = vObj.main;
          const horizontal_spacing = vObj.horizontal_spacing;
          const vertical_spacing = vObj.vertical_spacing;

          const verticalSpacing =
            main === "pair"
              ? ` ${vertical_spacing}`
              : "";
          return `${horizontal_spacing}${verticalSpacing}`;
        },
      },
    ],

    default: {
      main: {
        value: "value",
      },
      horizontal_spacing: {
        value: 1,
        unit: "rem",
      },
      vertical_spacing: {
        value: 0.5,
        unit: "rem",
      },
    },
  },
  {
    name: "caption-side",
    category: ["TABLE"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        canBeInherited: true,
        canBeInitial: true,
        options: [
          {
            id: "top",
          },
          {
            id: "bottom",
          },
        ],
        help:
          "### caption-side  \n" +
          "Define the placement of the HTML element `caption` before or after the `table`.",
      },
    ],
    group: [{ name: "mainG", fields: ["main"], display: ["caption-side"] }],
    default: {
      main: {
        value: "top",
      },
    },
  },
  {
    name: "empty-cells",
    category: ["TABLE"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        canBeInherited: true,
        canBeInitial: true,
        options: [
          {
            id: "show",
          },
          {
            id: "hide",
          },
        ],
        help:
          "### empty-cells  \n" +
          "Set whether or not to display borders on empty cells in a table.",
        warning:
          "### empty-cells  \n" +
          "Has no effect if `border-collapse` is `collapse`.",
      },
    ],
    group: [{ name: "mainG", fields: ["main"], display: ["empty-cells"] }],
    default: {
      main: {
        value: "show",
      },
    },
  },
];
