import { FC, ReactElement } from "react";
import { itemIF } from "../../data/types/item";
import { GetHTMLElement } from "./GetHtmlOrSvgElement";
import { snippetIF } from "../../data/types/snippets";
import { getItemValuesAsObj } from "../../lib/render/getItemValuesAsObject";
import { itemTypes } from "../../lib/meta/getMetas";
import { getassignedClassNames } from "../../lib/render/css/get-assigned-selectors";

export const BlockElement: FC<{
  itemType: itemTypes;
  item: itemIF;
  snippet: snippetIF;
  children?: ReactElement[];
  svgCss?: string;
}> = ({ itemType, item, snippet, children, svgCss }) => {
  if (item.name === "noSvg") {
    return (
      <svg width="70" height="20" xmlns="http://www.w3.org/2000/svg">
        <text x="5px" y="15px">
          NO SVG
        </text>
      </svg>
    );
  }

  const attr = getItemValuesAsObj({ itemType, item, snippet });

  const assignedClassNames = getassignedClassNames({
    assignedIds: item?.assignedClasses || [],
    selectors: snippet.data.selectors,
  });

  const newAttr = {
    ...attr,
    className: assignedClassNames !== "" ? assignedClassNames : undefined,
  };

  const cssexyHtml = (
    <GetHTMLElement item={item} attr={newAttr} svgCss={svgCss}>
      {children}
    </GetHTMLElement>
  );

  return cssexyHtml;
};
