import { metaIF, TObj } from "../../data/types/item";
import {
  HTMLS,
  getHtmlCategoryMetas,
  HTML_CATEGORIES,
  HTMLS_CATEGORIES_TITLES,
  getHtmlMeta,
} from "../../data/html/constants";
import { getEventMeta } from "../../data/events/constants";
import {
  SELECTORS,
  SELECTOR_CATEGORIES_TITLES,
  CONNECTORS,
  CONNECTOR_CATEGORIES,
  SELECTOR_CATEGORIES,
  getSelectorMeta,
  getConnectorMeta,
  getConnectorCategoryMetas,
  getSelectorCategoryMetas,
  CONNECTOR_CATEGORIES_HELP,
} from "../../data/selectors/constants/all";
import {
  CSS_CATEGORIES,
  CSS_PROPERTIES,
  getCssMeta,
  getCssCategoryMetas,
} from "../../data/css/constants/all";
import {
  SVGS_META,
  SVGS_CATEGORIES,
  SVGS_CATEGORY_TITLE,
  getSvgMeta,
  getSvgCategoryMetas,
} from "../../data/svg/constants/all";
import { getStylepackMeta } from "../../data/stylepack/constants";

export enum itemTypes {
  BLOCK_HTML = "BLOCK_HTML",
  EVENT = "EVENT",
  CONNECTOR = "CONNECTOR",
  SELECTORS = "SELECTORS",
  CLASSES_CSS = "CLASSES_CSS",
  CSS_CATEGORY = "CSS_CATEGORY",
  SVGS = "SVGS",
  SNIPPET = "SNIPPET",
  SNIPPETTREE = "SNIPPETTREE",
  SETTINGS = "SETTINGS",
  STYLEPACK = "STYLEPACK",
}
export const getMeta = (itemType: string, name: string): metaIF | undefined => {
  const getMetaFn =
    itemType === itemTypes.SVGS
      ? getSvgMeta
      : itemType === itemTypes.CONNECTOR
        ? getConnectorMeta
        : itemType === itemTypes.SELECTORS
          ? getSelectorMeta
          : itemType === itemTypes.CLASSES_CSS
            ? getCssMeta
            : itemType === itemTypes.EVENT
              ? getEventMeta
              : itemType === itemTypes.STYLEPACK
                ? getStylepackMeta
                : getHtmlMeta;
  return getMetaFn(name);
};

export const getMetas = (itemType: string): metaIF[] => {
  switch (itemType) {
    case itemTypes.BLOCK_HTML:
      return HTMLS;
    case itemTypes.CONNECTOR:
      return CONNECTORS;
    case itemTypes.SELECTORS:
      return SELECTORS;
    case itemTypes.CLASSES_CSS:
      return CSS_PROPERTIES;
    case itemTypes.SVGS:
      return SVGS_META;
    default:
      return [];
  }
};

export const getCategories = (itemType: string) => {
  switch (itemType) {
    case itemTypes.BLOCK_HTML:
      return HTML_CATEGORIES;
    case itemTypes.CONNECTOR:
      return CONNECTOR_CATEGORIES;
    case itemTypes.SELECTORS:
      return SELECTOR_CATEGORIES;
    case itemTypes.CLASSES_CSS:
      return CSS_CATEGORIES;
    case itemTypes.SVGS:
      return SVGS_CATEGORIES;
    default:
      return {};
  }
};

export const getCategoryTitle = (itemType: string) => {
  switch (itemType) {
    case itemTypes.SVGS:
      return {
        title: SVGS_CATEGORY_TITLE,
      };
    case itemTypes.BLOCK_HTML:
      return {
        title: HTMLS_CATEGORIES_TITLES ? HTMLS_CATEGORIES_TITLES : undefined,
      };
    case itemTypes.CONNECTOR:
      return {
        title: CONNECTOR_CATEGORIES_HELP,
      };
    case itemTypes.SELECTORS:
      return {
        title: SELECTOR_CATEGORIES_TITLES,
      };
    default:
      return { title: {} as TObj };
  }
};

export const getCategoryMetasForItemType = ({
  category,
  itemType,
}: {
  category: string;
  itemType: string;
}) => {
  switch (itemType) {
    case itemTypes.BLOCK_HTML:
      return getHtmlCategoryMetas(category);
    case itemTypes.CONNECTOR:
      return getConnectorCategoryMetas(category);
    case itemTypes.SELECTORS:
      return getSelectorCategoryMetas(category);
    case itemTypes.CLASSES_CSS:
      return getCssCategoryMetas(category);
    case itemTypes.SVGS:
      return getSvgCategoryMetas(category);
    default:
      return [];
  }
};