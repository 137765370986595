import { metaIF, EMetaFieldTypes } from "../types/item";

export const HTMLS_FORM: metaIF[] = [
  {
    name: "form",
    level: "block",
    category: ["FORM"],
    conditions: { onlyOne: true },
    fieldsOrder: [
      "action",
      "method",
      "name",
      "target",
      "autocomplete",
      "novalidate",
    ],
    fields: [
      {
        name: "action",
        type: EMetaFieldTypes.string,
        help: "#### form  \nFor a short explanation see for example [w3schools - HTML <form> Tag](https://www.w3schools.com/tags/tag_form.asp)",
      },
      {
        name: "method",
        type: EMetaFieldTypes.select,
        options: [{ id: "get" }, { id: "post" }],
      },
      {
        name: "name",
        type: EMetaFieldTypes.string,
      },
      {
        name: "target",
        type: EMetaFieldTypes.select,
        options: [
          { id: "_blank" },
          { id: "_self" },
          { id: "_parent" },
          { id: "_top" },
        ],
      },
      {
        name: "autocomplete",
        javascript: "autoComplete",
        type: EMetaFieldTypes.select,
        options: [
          {
            id: "on",
          },
          { id: "off" },
        ],
      },
      {
        name: "novalidate",
        javascript: "noValidate",
        type: EMetaFieldTypes.boolean,
      },
    ],
    group: [
      {
        name: "action",
        fields: ["action"],
      },
      {
        name: "method",
        fields: ["method"],
      },
      {
        name: "name",
        fields: ["name"],
      },
      {
        name: "target",
        fields: ["target"],
      },
      {
        name: "autocomplete",
        fields: ["autocomplete"],
      },
      { name: "novalidateG", fields: ["novalidate"], display: ["novalidate"] },
    ],

    default: {
      action: { value: "example.com/answerme", onOff: true },
      method: { value: "get", onOff: true },
      name: { value: "my-form", onOff: true },
      target: { value: "_self" },
      autocomplete: { value: "off" },
      novalidate: { value: true },
    },
  },

  {
    name: "input",
    level: "inline",
    category: ["FORM"],
    hasNoEndTag: true,
    allowedChildren: [],
    fieldsOrder: [
      "type",
      "value",
      "name",
      "disabled",
      "readonly",
      "required",
      "defaultValue",
      "defaultChecked",
      "placeholder",
      "multiple",
      "min",
      "max",
      "step",
      "pattern",
    ],
    fields: [
      {
        name: "type",
        type: EMetaFieldTypes.select,
        options: [
          {
            id: "button",
          },
          {
            id: "checkbox",
          },
          {
            id: "color",
          },
          {
            id: "date",
          },
          {
            id: "email",
          },
          {
            id: "file",
          },
          { id: "number" },
          { id: "password" },
          { id: "radio" },
          {
            id: "range",
          },
          { id: "search" },
          { id: "submit" },
          { id: "tel" },
          { id: "text" },
          { id: "url" },
        ],
        onOff: true,
        editInContentSnippet: true,
      },
      {
        name: "name",
        type: EMetaFieldTypes.string,
        editInContentSnippet: true,
      },
      {
        name: "disabled",
        type: EMetaFieldTypes.boolean,
      },
      {
        name: "readonly",
        type: EMetaFieldTypes.boolean,
        javascript: "readOnly",
        dependentOn: {
          field: "type",
          values: ["text", "search", "url", "tel", "email", "password"],
          dontDisableDependentOnField: true,
        },
      },
      {
        name: "required",
        type: EMetaFieldTypes.boolean,
      },
      {
        name: "defaultChecked",
        type: EMetaFieldTypes.boolean,
        dependentOn: {
          field: "type",
          values: ["checkbox", "radio"],
          dontDisableDependentOnField: true,
        },
      },
      {
        name: "placeholder",
        type: EMetaFieldTypes.string,
      },
      {
        name: "multiple",
        type: EMetaFieldTypes.boolean,
        help: "### multiple  \nIf you want to allow the user to select multiple files or enter multiple comma-separated email addresses, then you have to select the checkbox.",
      },
      {
        name: "min",
        type: EMetaFieldTypes.string,
        dependentOn: {
          field: "type",
          values: ["number", "range", "date"],
          dontDisableDependentOnField: true,
        },
      },
      {
        name: "max",
        type: EMetaFieldTypes.string,
        dependentOn: {
          field: "type",
          values: ["number", "range", "date"],
          dontDisableDependentOnField: true,
        },
      },
      {
        name: "step",
        type: EMetaFieldTypes.number,
      },
      {
        name: "pattern",
        type: EMetaFieldTypes.textarea,
        dependentOn: {
          field: "type",
          values: ["text", "date", "search", "url", "tel", "email", "password"],
          dontDisableDependentOnField: true,
        },
        help: `### pattern
Specifies a regular expression that the input element's value is checked against, when you enter the value and on form submission.  

For example \`[A-Za-z]{3}\` will only accept 3 letters. Any other input will set the invalid attribute which can, for example, be style with 

\`\`\`
input:invalid {
  border: 2px solid red;
}
input:valid {
  border: 2px solid green;
}
\`\`\`
`
      },
      {
        name: "value",
        type: EMetaFieldTypes.string,
      },
    ],

    group: [
      { name: "typeG", fields: ["type"], display: ["type"] },
      { name: "valueG", fields: ["value"], display: ["value"] },
      { name: "nameG", fields: ["name"], display: ["name"] },
      { name: "disabledG", fields: ["disabled"], display: ["disabled"] },
      { name: "readonlyG", fields: ["readonly"], display: ["readonly"] },
      { name: "requiredG", fields: ["required"], display: ["required"] },
      {
        name: "defaultCheckedG",
        fields: ["defaultChecked"],
        display: ["checked"],
      },
      {
        name: "placeholderG",
        fields: ["placeholder"],
        display: ["placeholder"],
      },
      {
        name: "autocompleteG",
        fields: ["autocomplete"],
        display: ["autocomplete"],
      },
      {
        name: "listG",
        fields: ["list"],
        display: ["datalist"],
      },
      {
        name: "multipleG",
        fields: ["multiple"],
        display: ["multiple"],
        dependentOn: {
          field: "type",
          values: ["file", "email"],
          dontDisableDependentOnField: true,
        },
      },
      {
        name: "minG",
        fields: ["min", "max", "step"],
        display: ["min", "max", "step"],
        dependentOn: {
          field: "type",
          values: [
            "number",
            "range",
            "date",
            "datetime-local",
            "month",
            "week",
            "time",
          ],
          dontDisableDependentOnField: true,
        },
        help: `### min max step  

The attributes **min**, **max** and **step** are used for the input types **number**, **range**, **date**, **datetime-local**, **month**, **week** and **time** for the minumum or maximum value that is acceptable and valid for the input containing the attribute.  

An invalid input can be styled for example as follows: \`input:invalid {  <br/>  border: solid red 3px; }\`

The **step** sets the stepping interval when clicking up and down spinner buttons, moving a slider left and right on a range, and validating the different date types. If not explicitly included, step defaults to 1 for number and range, and 1 unit type (minute, week, month, day) for the date/time input types.

Examples:

\`<input type="date" min="2019-12-25" step="1">\` step in days   
\`<input type="month" min="2019-12" step="12">\` step in months  
\`<input type="week" min="2019-W23" step="2">\` step in weeks  
\`<input type="time" min="09:00" step="900">\` step in seconds  
\`<input type="datetime-local" min="2019-12-25T19:30" step="900">\` step in seconds  
\`<input type="number" min="0" step="0.1" max="10">\` step in 0.1 increments from 0 to 10  
\`<input type="range" min="1.5" step="2" max="9.5">\` step in 2 increments from 1.5 to 9.5  
`,
      },
      { name: "patternG", fields: ["pattern"], display: ["pattern"] },
    ],

    default: {
      type: { value: "text" },
      name: { value: "NAME" },
      disabled: { value: false },
      readonly: { value: false },
      required: { value: false },
      defaultChecked: { value: false },
      placeholder: {
        value: "placeholder",
      },
      autocomplete: { value: false },
      list: { value: false },
      multiple: { value: false },
      min: { value: 0 },
      max: { value: 100 },
      step: { value: 5 },
      pattern: { value: "[A-Za-z]{3}" },
      value: { value: "you@example.com" },
    },
  },

  {
    name: "textarea",
    level: "inline",
    category: ["FORM"],
    allowedChildren: [],
    fieldsOrder: [
      "defaultValue",
      "cols",
      "rows",
      "maxlength",
      "wrap",
      "name",
      "disabled",
      "readonly",
      "placeholder",
      "required",
    ],
    fields: [
      {
        name: "defaultValue",
        type: EMetaFieldTypes.textarea,
      },
      {
        name: "cols",
        type: EMetaFieldTypes.number,
        default: {
          value: 20,
        },
        conditions: { integer: true },
      },
      {
        name: "rows",
        type: EMetaFieldTypes.number,
        conditions: { integer: true },
        default: {
          value: 2,
        },
      },
      {
        name: "disabled",
        type: EMetaFieldTypes.boolean,
      },
      {
        name: "maxlength",
        javascript: "maxLength",
        type: EMetaFieldTypes.number,
        conditions: { integer: true },
        default: {
          value: 0,
        },
        help: "### maxlength  \nMaximum number of characters allowed in this textarea.",
      },
      {
        name: "name",
        type: EMetaFieldTypes.string,
        default: { value: "textarea" },
      },
      {
        name: "placeholder",
        type: EMetaFieldTypes.string,
      },
      {
        name: "readonly",
        type: EMetaFieldTypes.boolean,
        javascript: "readOnly",
        default: {
          value: false,
        },
      },
      {
        name: "required",
        type: EMetaFieldTypes.boolean,
        default: {
          value: false,
        },
      },
      {
        name: "wrap",
        type: EMetaFieldTypes.select,
        options: [{ id: "hard" }, { id: "soft" }],
        default: {
          value: "soft",
        },
        help:
          "#### wrap  \n" +
          "Defines if newlines in the text, if there are any, are submitted with the form and textarea. " +
          "If the setting for wrap is `hard`, they are submitted, if `soft` they are not.",
      },
    ],
    group: [
      { name: "defaultValue", fields: ["defaultValue"] },
      {
        name: "columnsRowsMaxLength",
        fields: ["cols", "rows", "maxlength"],
        display: ["colums", "rows", "maxlength"],
      },
      {
        name: "wrap",
        fields: ["wrap"],
      },
      {
        name: "name",
        fields: ["name"],
      },
      {
        name: "disabledReadOnlyRequiredG",
        fields: ["disabled", "readonly", "required"],
        display: ["disabled", "readonly", "required"],
      },
      {
        name: "placeholder",
        fields: ["placeholder"],
      },
    ],

    default: {
      defaultValue: { value: "Very nice text" },
      cols: {
        value: 20,
      },
      rows: {
        value: 2,
      },
      maxlength: {
        value: 0,
      },
      wrap: {
        value: "soft",
      },
      name: {
        value: "textarea",
      },
      disabled: {
        value: false,
      },
      readonly: {
        value: false,
      },
      placeholder: {
        value: "placeholder",
      },
      required: {
        value: false,
      },
    },
  },

  {
    name: "label",
    level: "block",
    category: ["FORM"],
    fieldsOrder: ["for"],
    fields: [
      {
        name: "for",
        type: EMetaFieldTypes.inputInLabelId,
        javascript: "htmlFor",
        editInContentSnippet: true,
        help:
          "### for  \nSee for example [w3schools - label](https://www.w3schools.com/tags/tag_label.asp)  \n" +
          "#### Multiple labels are pointing to the same form field  \n" +
          "You can define multiple labels for one form field by applying the same field id to multiple labels. You do this, for example, if you want to access the same form field from different places.",
      },
    ],
    group: [
      {
        name: "for",
        fields: ["for"],
      },
    ],

    default: {
      for: { value: "", onOff: true },
    },
  },

  {
    name: "fieldset",
    level: "block",
    category: ["FORM"],
    fieldsOrder: ["form", "name", "disabled"],
    fields: [
      {
        name: "form",
        type: EMetaFieldTypes.string,
        help: "### fieldset  \nSee for example [HTML fieldset Tag](https://www.w3schools.com/tags/tag_fieldset.asp)",
      },
      {
        name: "name",
        type: EMetaFieldTypes.string,
      },
      {
        name: "disabled",
        type: EMetaFieldTypes.boolean,
      },
    ],
    group: [
      {
        name: "form",
        fields: ["form"],
      },
      {
        name: "name",
        fields: ["name"],
      },
      {
        name: "disable",
        fields: ["disable"],
      },
    ],

    default: {
      form: { value: "#formId" },
      name: { value: "address" },
      disabled: { value: false },
    },
  },
  {
    name: "legend",
    level: "block",
    category: ["FORM"],
    allowedChildren: ["inline"],
  },

  {
    name: "select",
    level: "inline",
    category: ["FORM"],
    allowedChildren: ["option", "optgroup"],
    fieldsOrder: ["value", "multiple", "size", "name", "disabled", "required"],
    fields: [
      {
        name: "value",
        type: EMetaFieldTypes.string,
        onOff: true,
      },
      {
        name: "disabled",
        type: EMetaFieldTypes.boolean,
        default: {
          value: false,
        },
      },
      {
        name: "multiple",
        type: EMetaFieldTypes.boolean,
        default: {
          value: false,
        },
      },
      {
        name: "name",
        type: EMetaFieldTypes.string,
      },
      {
        name: "required",
        type: EMetaFieldTypes.boolean,
        default: {
          value: false,
        },
      },
      {
        name: "size",
        type: EMetaFieldTypes.number,
        default: {
          value: 1,
        },
      },
    ],
    group: [
      {
        name: "value",
        fields: ["value"],
      },
      {
        name: "multiple",
        fields: ["multiple"],
        help: "### select  \nSee for example [w3schools - HTML <select> Tag](https://www.w3schools.com/tags/tag_select.asp)",
      },
      {
        name: "size",
        fields: ["size"],
      },
      {
        name: "name",
        fields: ["name"],
      },
      {
        name: "disabled",
        fields: ["disabled"],
      },
      {
        name: "required",
        fields: ["required"],
      },
    ],

    default: {
      value: {
        value: "option 1",
      },
      disabled: {
        value: false,
      },
      multiple: {
        value: false,
      },
      name: {
        value: "selectedName",
      },
      required: {
        value: false,
      },
      size: {
        value: 1,
      },
    },
  },
  {
    name: "optgroup",
    level: "inline",
    category: ["FORM"],
    allowedParent: ["select"],
    allowedSiblings: ["optgroup"],
    allowedChildren: ["option"],
    fieldsOrder: ["label", "disabled"],
    fields: [
      {
        name: "label",
        type: EMetaFieldTypes.string,
        help: "### optgroup  \nSee for example [w3schools - HTML optgroup Tag](https://www.w3schools.com/tags/tag_optgroup.asp)",
      },
      {
        name: "disabled",
        type: EMetaFieldTypes.boolean,
      },
    ],
    default: {
      label: {
        value: "Swedish Cars",
      },
      disabled: {
        value: false,
      },
    },
  },
  {
    name: "option",
    level: "inline",
    category: ["FORM"],
    allowedParent: ["select", "optgroup", "datalist"],
    allowedSiblings: ["option"],
    allowedChildren: ["text"],
    fieldsOrder: ["value", "disabled"],
    fields: [
      {
        name: "value",
        type: EMetaFieldTypes.string,
        onOff: true,
      },
      {
        name: "disabled",
        type: EMetaFieldTypes.boolean,
      },
    ],
    default: {
      value: {
        value: "option 1",
      },
      disabled: {
        value: false,
      },
    },
  },

  {
    name: "button",
    level: "inline",
    category: ["FORM"],
    fieldsOrder: ["type", "name", "disabled"],
    fields: [
      {
        name: "type",
        type: EMetaFieldTypes.select,
        options: [{ id: "button" }, { id: "reset" }, { id: "submit" }],
        onOff: true,
      },
      {
        name: "name",
        type: EMetaFieldTypes.string,
        help:
          "#### name  \n" +
          "References elements in JavaScript or, when a form is submitted, in the backend. " +
          "Only form elements with a `name` attribute will have their values passed on form submit.",
      },
      {
        name: "disabled",
        type: EMetaFieldTypes.boolean,
      },
    ],
    group: [
      { name: "type", fields: ["type"] },
      { name: "name", fields: ["name"] },
      { name: "disabled", fields: ["disabled"] },
    ],

    default: {
      type: { value: "button", onOff: true },
      name: { value: "button" },
      disabled: { value: false },
    },
  },

  {
    name: "datalist",
    level: "block",
    category: ["FORM"],
    allowedChildren: ["option"],
    help: "### datalist  \nSee for example [w3schools - HTML datalist](https://www.w3schools.com/tags/tag_datalist.asp)",
  },
];
