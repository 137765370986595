import { ChangeEvent, TouchEvent, FC } from "react";
import { itemIF, TValueType } from "../../data/types/item";
import { itemTypes } from "../../lib/meta/getMetas";
import { setNewItemValue } from "../../lib/reducer/items/actions";
import { useStateContext } from "../../lib/state/state";
import { snippetIF } from "../../data/types/snippets";
import { getMetaFromSnippet } from "../../lib/snippets/addAndGetMetas";

type TEditFieldCheckbox = {
  itemType: itemTypes;
  fieldName: string;
  valueType?: TValueType;
  editItem: itemIF;
  snippet: snippetIF;
  isFieldDisabled?: boolean;
  addOrRemoveEditItemId?: number;
  fieldGroupName?: string;
  editSnippetIdOther?: string;
};
export const EditFieldCheckbox: FC<TEditFieldCheckbox> = ({
  itemType,
  fieldName,
  editItem,
  valueType,
  isFieldDisabled,
  addOrRemoveEditItemId,
  fieldGroupName,
  editSnippetIdOther,
  snippet,
}) => {
  const { dispatch } = useStateContext();

  const meta = getMetaFromSnippet({
    snippet,
    itemType,
    itemName: editItem.name,
  });
  const fieldMeta = meta?.fields?.find((field) => field.name === fieldName);

  const setValue = (value: boolean) => {
    dispatch(
      setNewItemValue({
        addOrRemoveEditItemId,
        editItemId: editItem.id,
        fieldGroupName,
        fieldName,
        itemType,
        value,
        valueType,
        editSnippetId: editSnippetIdOther,
      })
    );
  };

  const valObj = editItem.value?.[fieldName];
  const isChecked =
    valueType === "onOff"
      ? fieldMeta?.onOff || valObj?.onOff || false
      : (valObj?.value as boolean) || false;

  return (
    <EditFieldCheckboxSimple
      fieldName={fieldName}
      isChecked={isChecked}
      isFieldDisabled={isFieldDisabled}
      setValue={setValue}
    />
  );
};

export const EditFieldCheckboxSimple: FC<{
  fieldName: string;
  isChecked: boolean;
  isFieldDisabled?: boolean;
  setValue: (value: boolean) => void;
}> = ({ fieldName, isChecked, isFieldDisabled, setValue }) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setValue && setValue(isChecked);
  };

  const handleTouchStart = (event: TouchEvent<HTMLInputElement>) => {
    if (isFieldDisabled) {
      event.preventDefault();
    }
  };

  const handleTouchEnd = (event: TouchEvent<HTMLInputElement>) => {
    if (isFieldDisabled) {
      event.preventDefault();
    }
  };

  return (
    <input
      title={`Select ${fieldName}`}
      type="checkbox"
      checked={isChecked}
      disabled={isFieldDisabled}
      className="checkbox"
      onChange={handleChange}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    />
  );
};
