import { Button } from "../atoms/button";
import { EFieldType } from "../../lib/items/getIds";
import { useStateContext } from "../../lib/state/state";
import { setNewItemValue } from "../../lib/reducer/items/actions";
import { itemTypes } from "../../lib/meta/getMetas";
import { itemIF } from "../../data/types/item";

type TAssignId = {
  isSpecialFieldType?: EFieldType;
  isFieldDisabled?: boolean;

  assignedIds?: string[];

  fieldName: string;
  fieldGroupName: string;
  editSnippetIdOther: string;
  addOrRemoveEditItemId: number;
  itemType: itemTypes;
  editItem: itemIF;
};
export const AssignId = ({
  isSpecialFieldType,
  isFieldDisabled,

  assignedIds,

  fieldName,
  fieldGroupName,
  editSnippetIdOther,
  addOrRemoveEditItemId,
  itemType,
  editItem,
}: TAssignId) => {
  const { dispatch } = useStateContext();
  const fieldValue = editItem.value?.[fieldName]?.value;
  return (
    <div className="btn-row">
      {(!assignedIds || assignedIds.length === 0) && (
        <Button disableBtn={true} btnOnClick={() => console.log("clicked")}>
          {isSpecialFieldType === EFieldType.selectPathId
            ? "No SVG Path with Id"
            : isSpecialFieldType === EFieldType.assignSvgGradientId
            ? "No SVG Gradient with Id"
            : isSpecialFieldType === EFieldType.assignSvgFilterId
            ? "No SVG Filter with Id"
            : isSpecialFieldType === EFieldType.selectKeyframeName
            ? "No @keyframes"
            : "No Datalist with Id"}
        </Button>
      )}
      {assignedIds &&
        assignedIds.length > 0 &&
        assignedIds.map((id) => {
          return (
            <Button
              key={id}
              activeBtn={id === fieldValue}
              disableBtn={isFieldDisabled}
              btnOnClick={() => {
                const newValue = id === fieldValue ? "" : id;

                dispatch(
                  setNewItemValue({
                    addOrRemoveEditItemId,
                    editItemId: editItem.id,
                    fieldGroupName,
                    fieldName,
                    itemType,
                    value: newValue,
                    editSnippetId: editSnippetIdOther,
                  })
                );
              }}
              dontDisableActiveBtn={true}
            >
              {id}
            </Button>
          );
        })}
    </div>
  );
};
