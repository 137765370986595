import { NavLink } from "react-router-dom";
import MainLayout from "../MainLayout";
import { useStateContext } from "../../lib/state/state";
import { PAGES } from "../../data/settings/constants";
import { selectPage } from "../../lib/reducer/settings/actions";

import "./PageNotFound.css";

const NotFoundInner = () => {
  const {
    state: {
      settings: { selectedPage },
    },
    dispatch,
  } = useStateContext();

  return (
    <div className="error-box mt-2r mb-2r">
      <h1 className="h1">404</h1>
      <p className="p-big">Something's missing.</p>
      <p className="p-normal">
        Sorry, we can't find that page. You'll find lots to explore on the home
        page.
      </p>
      <p className="p-normal mt-2r mb-r">
        <NavLink
          className="tw-button"
          onClick={() => {
            if (selectedPage !== PAGES.HOME) {
              dispatch(selectPage(PAGES.HOME));
            }
          }}
          to="/home"
        >
          Back to Homepage
        </NavLink>
      </p>
    </div>
  );
};

const NotFound = () => (
  <MainLayout title="Page not found" description="Page not found">
    <div className="container">
      <NotFoundInner />
    </div>
  </MainLayout>
);

export default NotFound;
