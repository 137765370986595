import { snippetIF, snippetsIF } from "../types/snippets";
import { defaultStylepack2 } from "../stylepack/default";

import { v4 as uuidv4 } from "uuid";
import { renderCssAndHtmlForSnippet } from "../../lib/snippets/renderCssAndHtmlForSnippet";
const clone = require("rfdc")();

export const starterSnippt = {
  id: "123",
  name: "New Snippet",
  description: "enter description here",
  public: false,
  data: {
    htmls: [
      { id: 4, position: 1, name: "div", level: 1, parent: 0 },
      {
        id: 5,
        position: 1,
        name: "text",
        value: { text: { value: "TEXT" } },
        level: 2,
        parent: 4,
      },
    ],
    editHtmlId: 5,
    htmlsMeta: [],
    selectors: [],
    editSelectorId: 0,
    selectorsMeta: [],
    properties: [],
    editPropertyId: 0,
    propertiesMeta: [],
    svgs: [],
    editSvgId: 0,
    svgsMeta: [],
    stylepacks: [defaultStylepack2],
    editStylepackId: 1,
  },
  owner: "anonymous",
} as snippetIF;



export const getNewSnippet = () => {  
  const newSnippet = clone(starterSnippt) as snippetIF;
  const updatedSnippet = renderCssAndHtmlForSnippet(newSnippet);

  return {
    ...updatedSnippet,
    id: uuidv4(),
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  }
};

export const initialSnippets: snippetsIF = {
  editSnippetId: "",
  snippets: [],
};
