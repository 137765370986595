import { FC } from "react";
import { snippetIF } from "../../data/types/snippets";
import { useStateContext } from "../../lib/state/state";
import { useDivPosition } from "../modules/GetPositionWrapper";
import { PublishUnpublishBtn } from "../cloud/PublishUnpublishSnippetBtn";
import { changeSnippet } from "../../lib/reducer/snippet/actions";
import { StringInputSnippet } from "./StringField";
import { EditFieldTextareaSimple } from "./Textarea";
import { getLocalDateAndTime } from "../../lib/dates/format";

export const EditSnippet: FC<{ snippet: snippetIF; isDisabled?: boolean }> = ({
  snippet,
  isDisabled,
}) => {
  const { dispatch } = useStateContext();
  const { divRef, divPos } = useDivPosition();
  const updatedAtStr = getLocalDateAndTime(snippet.updatedAt);

  return (
    <>
      <div className="form-elements-wrapper">
        <div className="mb-1r">
          <label className={`label${isDisabled ? " disabled" : ""}`}>
            <span>name</span>
            <StringInputSnippet
              fieldName="name"
              isFieldDisabled={isDisabled}
              fieldValue={snippet.name}
            />
          </label>
        </div>

        <div className="flex mb-1r" ref={divRef}>
          <PublishUnpublishBtn
            snippet={snippet}
            divPos={divPos}
            isDisabled={isDisabled}
          />
        </div>

        <div className="flex">
          <label className="label">
            <span>updated at</span>
            <StringInputSnippet
              fieldName="updatedAt"
              isFieldDisabled={true}
              fieldValue={updatedAtStr}
            />
          </label>
        </div>

        <div className="flex mt-1r">
          <label className={`label${isDisabled ? " disabled" : ""}`}>
            <span>description</span>
            <EditFieldTextareaSimple
              fieldName="description"
              fieldValue={snippet.description ?? "no description"}
              isFieldDisabled={isDisabled}
              setValue={(value) => {
                dispatch(
                  changeSnippet({
                    fieldName: "description",
                    value,
                  })
                );
              }}
            />
          </label>
        </div>
      </div>
    </>
  );
};

export const EditItemSnippet = () => {
  const {
    state: {
      snippet: { snippets, editSnippetId },
    },
  } = useStateContext();
  const editSnippet = snippets.find((snippet) => snippet.id === editSnippetId);
  if (!editSnippet) {
    return null;
  }
  return <EditSnippet snippet={editSnippet} />;
};
