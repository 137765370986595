import { metaIF, EMetaFieldTypes } from "../../types/item";
import { standardUnits } from "./utils";
export const CSS_PROPERTIES_FLEX: metaIF[] = [
  {
    name: "align-items",
    category: ["FLEX"],
    setOnParent: true,
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "flex-start",
            help: "#### flex-start \nAlign at the top of the row or left of the column.",
          },
          {
            id: "center",
            help: "#### center  \nAlign the center of the children with the center of the container (row or column).",
          },
          {
            id: "flex-end",
            help: "#### flex-end  \nAlign at the bottom of the row or right of the column.",
          },
          {
            id: "stretch",
            help:
              "#### stretch (default) \n" +
              "Full height of the row, when the children have not explicitely a width defined, " +
              "or full width if the column, if no width is set for the respective child.",
          },
          {
            id: "baseline",
            help:
              "#### baseline  \n" +
              "The text baseline for the children is set on the flex container text baseline. " +
              "This makes especially sense when the children have one-liner text content with different font sizes.  \n" +
              "Has no effect when `flex-direction` is `column`.",
          },
        ],
        help:
          "### align-items  \n" +
          "If there is vertical space in a flex row or horizontal space in a flex column, " +
          "then the children can be vertically or horizontally arranged with align-items.  \n" +
          "flex-row: at the top, center, bottom, stretched over the full height or on the text base line  \n" +
          "flex-column: at the left, center, right or stretched over the full width  \n",
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["align-items"],
      },
    ],
    default: { main: { value: "center" } },
  },
  {
    name: "align-content",
    category: ["FLEX"],
    setOnParent: true,
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "flex-start",
            help:
              "#### flex-start (default) \n" +
              "Rows or columns align at the top or left of the flex container.",
          },
          {
            id: "center",
            help: "#### center  \nRows or columns are placed in the center of the container.",
          },
          {
            id: "flex-end",
            help:
              "#### flex-end  \n" +
              "Rows or columns align at the bottom or right of the flex container.",
          },
          {
            id: "space-around",
            help:
              "#### space-around  \n" +
              "Rows or columns are vertically or horizontally evenly distributed with space before, between and after the rows or columns.",
          },
          {
            id: "space-between",
            help:
              "#### space-between  \n" +
              "Rows or columns are vertically or horizontally evenly distributed with space between the rows or columns.",
          },
          {
            id: "stretch",
            help:
              "#### stretch  \n" +
              "Rows or columns and their children in it, are evenly distributed in the flex container and " +
              "take together its full height or width. If a height or width is set for the children, they do not stretch.",
          },
        ],
        help:
          "### align-content  \n" +
          "When `flex-wrap` is set and the children of a flex container populate several rows or columns, " +
          "`align-content` positions the rows or columns top/left, center, bottom/right, " +
          "with space between the rows or columns or stretched over the full height or width of the container.  \n",
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["align-content"],
      },
    ],
    default: { main: { value: "center" } },
  },
  {
    name: "align-self",
    category: ["FLEX"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "auto",
          },
          {
            id: "flex-start",
          },
          {
            id: "center",
          },
          {
            id: "flex-end",
          },
          {
            id: "stretch",
          },
          {
            id: "baseline",
          },
        ],
        help:
          "### align-self  \n" +
          "Overwrites for the child the `align-items` value (or its default value `stretch`) of the flex container.",
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["align-self"],
      },
    ],
    default: { main: { value: "auto" } },
  },
  {
    name: "justify-content",
    category: ["FLEX"],
    setOnParent: true,
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "flex-start",
            help: "#### flex-start (default)  \nLeft of the row or top of the column.",
          },
          {
            id: "center",
            help:
              "#### center  \n" +
              "All children in the center of the row or column, with no extra space between them.",
          },
          {
            id: "flex-end",
            help: "#### flex-end  \nRight of the row or bottom of the column.",
          },
          {
            id: "space-around",
            help:
              "#### space-around  \n" +
              "The available space is evenly distributed on the left/top and right/bottom and between the children.",
          },
          {
            id: "space-between",
            help:
              "#### space-between  \n" +
              "The available space is evenly distributed between the children.",
          },
          {
            id: "space-evenly",
          },
        ],
        help:
          "### justify-content  \n" +
          "When the children of a flex container leave space in the row or column, " +
          "then the children can be arranged with the help of `justify-content` " +
          "at the left/top, center, right/bottom, with space between or equally distributed " +
          "in the row or column.  \n",
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["justify-content"],
      },
    ],
    default: { main: { value: "center" } },
  },

  {
    name: "order",
    category: ["FLEX"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        help: "### order  \nSets the order of flex children. With the help of `order` you can, for example, use `media queries` to define the `order` of columns for different screen sizes.",
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["order"],
      },
    ],
    default: {
      main: {
        value: 2,
      },
    },
  },
  {
    name: "display-flex",
    code: "display",
    category: ["FLEX"],
    setOnParent: true,
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "flex",
          },
          {
            id: "inline-flex",
          },
        ],
        help:
          "### display:flex  \n" +
          "Defines a `flex` container.  \n" +
          "The container has the default `flex-direction: row`, " +
          "means that its children are displayed horizontally next to each other in a row.  \n" +
          "The default `flex-wrap` is `nowrap`. " +
          "When the children of the `flex` container are together too wide for the row, " +
          "they are squeezed to fit in it, but do not overflow in the next row.",
        warning:
          "### display:flex  \n" +
          "For any flex function to work, `display:flex` must be set first on the container.",
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["display-flex"],
      },
    ],
    default: { main: { value: "flex" } },
  },
  {
    name: "flex-basis",
    category: ["FLEX"],
    conditions: {
      greaterOrEqual0: true,
    },
    fieldsOrder: ["basis"],
    fields: [
      {
        name: "basis",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnits,
        canBeAuto: true,
        canBeCalculated: true,
        help:
          "### flex-basis  \n" +
          "Sets the width in a flex row or the height in a flex column in px, rem or %. " +
          "It overwrites more general settings like `flex-grow`.  \n" +
          "If you select the default `auto` instead of entering a value, " +
          "then the width or height of the child is used instead.",
      },
    ],
    group: [
      {
        name: "flexBasisG",
        fields: ["basis"],
        display: ["flex-basis"],
      },
    ],
    default: {
      basis: { value: 100, unit: "%" },
    },
  },
  {
    name: "flex-direction",
    category: ["FLEX"],
    setOnParent: true,
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "row",
          },
          {
            id: "column",
          },
          {
            id: "row-reverse",
          },
          {
            id: "column-reverse",
          },
        ],
        help:
          "### flex-direction  \n" +
          "The children of the flex container are placed dependent on the `flex-direction`:  \n" +
          "`row` (default): next to each other, West to East  \n" +
          "`row-reverse`: next to each other, East to West  \n" +
          "`column`: vertically, North to South  \n" +
          "`column-reverse`: vertically, South to North ",
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["flex-direction"],
      },
    ],
    default: { main: { value: "column" } },
  },
  {
    name: "flex-grow",
    category: ["FLEX"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        conditions: {
          greaterOrEqual0: true,
          regex: /^\d+$/,
        },
        help:
          "### flex-grow  \n" +
          "If there is space left in the flex row (width) or column (height), " +
          "this space is distributed following the integer value of `flex-grow` for the respective child.  \n" +
          "A `flex-grow` of 0 means that no extra space is given to the respective child.  \n" +
          "If all children have the same value greater 0, the space is distributed evenly.  \n" +
          "If the `flex-grow` value differs, the available free space is distributed in the relation of the `flex-grow` values.",
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["flex-grow"],
      },
    ],
    default: { main: { value: 1 } },
  },
  {
    name: "flex-shrink",
    category: ["FLEX"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        conditions: {
          greaterOrEqual0: true,
        },
        help:
          "### flex-shrink  \n" +
          "If children of a flex container have to be squeezed, " +
          "horizontally in a row or vertically in a column, " +
          "you can shrink children in relation less or more than others.  \n" +
          "Sqeezing or shrinking means here that they are made as small as their content " +
          "and the settings influencing their dimensions allow it.  \n" +
          "If the `flex-shrink` value for a child is 0, it is not shrunk.  \n" +
          "If all flex children have the same `flex-shrink` value greater than 0, " +
          "then they are all shrunk the same.  \n" +
          "If there is one child with a greater `flex-shrink` value than the others, " +
          "it is harder squeezed in relation to the `flex-shrink` value of all the children.",
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["flex-shrink"],
      },
    ],
    default: { main: { value: 2 } },
  },
  {
    name: "flex",
    category: ["FLEX"],
    fieldsOrder: ["grow", "shrink", "basis"],
    fields: [
      {
        name: "grow",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
      {
        name: "shrink",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
      {
        name: "basis",
        type: EMetaFieldTypes.select,
        options: [{ id: "value" }, { id: "none" }],
        onOff: true,
        canBeAuto: true,
        canBeCalculated: true,
      },
      {
        name: "basisValue",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnits,
        dependentOn: { field: "basis", values: ["value"] },
      },
    ],

    group: [
      {
        name: "flexG",
        fields: ["grow", "shrink", "basis", "basisValue"],
        display: ["grow", "shrink", "basis", "value"],
      },
    ],

    wrapperForGroup: [
      {
        name: "flex",
        fields: ["grow", "shrink", "basis", "basisValue"],
        wrapperFn: (valObj) => {
          const grow = valObj.grow;
          const shrink = valObj.shrink;
          const basis = valObj.basis;

          if (basis === "value") {
            const basisValue = valObj.basisValue;
            return `${grow} ${shrink} ${basisValue}`;
          }

          if (basis === "calculate") {
            const calculateValue = valObj.basisCalculate;
            return `${grow} ${shrink} calc(${calculateValue})`;
          }

          return `${grow} ${shrink} ${basis}`;
        },
      },
    ],
    default: {
      grow: {
        value: 1,
      },
      shrink: {
        value: 1,
      },
      basis: {
        value: "value",
      },
      basisValue: {
        value: 0,
        unit: "%",
      },
    },
    help: "### flex  \nThe flex property is a shorthand for `flex-grow`, `flex-shrink` and `flex-basis`.  \nFor more information see for example [w3schools - CSS flex property](https://www.w3schools.com/cssref/css3_pr_flex.asp).",
  },
  {
    name: "flex-wrap",
    category: ["FLEX"],
    setOnParent: true,
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "nowrap",
            help:
              "#### nowrap (default) \n" +
              "Flex children are squeezed to fit in one row or column.",
          },
          {
            id: "wrap",
            help:
              "#### wrap  \n" +
              "If the width or height of the children is together greater than " +
              "the width or height of their flex container, " +
              "then the last children are rendered in a new row or column. ",
          },
          {
            id: "wrap-reverse",
            help:
              "#### wrap-reverse  \n" +
              "Like `wrap`, but the rows or columns start at the bottom or " +
              "on the right of the flex-container.",
          },
        ],
        help:
          "### flex-wrap   \n" +
          "Places children of a flex-container in one or multiple rows (`flex-direction` is `row`) " +
          "or columns (`flex-direction` is `column`).  \n",
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["flex-wrap"],
      },
    ],
    default: { main: { value: "wrap" } },
  },
  {
    name: "gap",
    category: ["FLEX"],
    setOnParent: true,
    fieldsOrder: ["row", "column"],
    fields: [
      {
        name: "row",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnits,
      },
      {
        name: "column",
        type: EMetaFieldTypes.number,
        units: standardUnits,
      },
    ],
    default: {
      row: {
        value: 10,
        unit: "px",
      },
      column: {
        value: 20,
        unit: "px",
      },
    },
    help: "### gap  \nYou can set here the size of the gap between the rows and the columns in a flexbox.  \nIt is a shorthand for 'row-gap' and 'column-gap'. If you set the value for the row only, this is used for both row and column gap.",
  },
];
