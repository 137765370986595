import {
  itemIF,
  TItemValue,
  metaIF,
  metaFieldIF,
  groupIF,
} from "../../data/types/item";
import { itemTypes } from "../meta/getMetas";
import { snippetIF } from "../../data/types/snippets";

export const getGroups = ({ meta }: { meta: metaIF }) => {
  if (meta?.group) {
    const groups = meta.group.map((g) => g);
    return groups;
  }
  const nameOfFirstField =
    meta?.fields && meta?.fields[0] ? meta?.fields[0].name : "main";

  const fieldsOrder = [] as string[];
  if (meta?.fieldsOrder) {
    meta?.fieldsOrder.forEach((fieldName) => {
      fieldsOrder.push(fieldName);
    });
  }
  if (fieldsOrder.length === 0) {
    fieldsOrder.push(nameOfFirstField);
  }

  return fieldsOrder.map((fieldName) => {
    return {
      name: `${fieldName}G`,
      fields: [fieldName],
      display: [fieldName === "main" ? meta.name : fieldName],
    };
  }) as groupIF[];
};

export interface TEditField {
  editItem?: itemIF;
  fieldIsDisabled?: boolean;
  fieldMeta?: metaFieldIF;
  fieldName?: string;
  itemType: itemTypes;
  itemValue?: TItemValue;
  meta?: metaIF;
  snippetId?: string;
}

export const getFirstUniqueId = ({
  editSnippet,
  editItem,
}: {
  editSnippet: snippetIF;
  editItem: itemIF;
}) => {
  const idNamesOfOtherHtmlOrSvg = [] as string[];
  editSnippet.data.htmls
    .filter((i) => i.id !== editItem.id && i.value?.id?.onOff)
    .forEach((i) => {
      if (i.value?.id?.value) {
        idNamesOfOtherHtmlOrSvg.push(i.value.id.value);
      }
    });
  editSnippet.data.svgs
    .filter((i) => i.id !== editItem.id && i.value?.id?.onOff)
    .forEach((i) => {
      if (i.value?.id?.value) {
        idNamesOfOtherHtmlOrSvg.push(i.value.id.value);
      }
    });

  if (idNamesOfOtherHtmlOrSvg.includes(editItem.value?.id?.value)) {
    // find a free id name with the patter id-[n]
    let idExists = true;
    let idCount = 1;
    while (idExists) {
      if (idNamesOfOtherHtmlOrSvg.includes(`id-${idCount}`)) {
        idCount += 1;
      } else {
        idExists = false;
      }
    }
    return `id-${idCount}`;
  }
};
