import { renderToString } from "react-dom/server";
import { snippetIF } from "../../data/types/snippets";
import { itemTypes } from "../../lib/meta/getMetas";
import { RenderElementAndChildren } from "./HtmlOrSvgElementAndChildren";
import { getTopItem } from "../../lib/items/getDescendantsAndAncestors";
import { itemIF } from "../../data/types/item";

export const getRenderSvgString = (snippet: snippetIF): string => {
  const editSvg = snippet.data.svgs?.find(
    (s) => s.id === snippet.data.editSvgId
  );
  if (!editSvg) {
    return "";
  }

  const topEditSvg = getTopItem({
    items: snippet.data.svgs,
    item: editSvg,
  });

  const svgStr = renderToString(
    <RenderElementAndChildren
      key={topEditSvg.id}
      itemType={itemTypes.SVGS}
      item={topEditSvg}
      snippet={snippet}
    />
  );

  return svgStr;
};

export const generateHtmlForSnippet = (snippet: snippetIF): string => {
  const items = snippet.data.htmls.filter((h) => h.parent === 0);
  const html = renderToString(
    <>
      {items.map((item) => {
        return (
          <RenderElementAndChildren
            key={item.id}
            itemType={itemTypes.BLOCK_HTML}
            item={item}
            snippet={snippet}
          />
        );
      })}
    </>
  );

  return html;
};

export const generateHtmlStrForItem = ({
  itemType,
  item,
  snippet,
}: {
  itemType: itemTypes;
  item: itemIF;
  snippet: snippetIF;
}): string => {
  const itemStr = renderToString(
    <RenderElementAndChildren
      key={item.id}
      itemType={itemType}
      item={item}
      snippet={snippet}
    />
  );

  return itemStr;
};
