import { FC, useEffect, ReactNode } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useStateContext } from "../../lib/state/state";
import { refreshTokens } from "../../lib/signinup/handleTokens";
import {
  userDataInSettings,
} from "../../lib/reducer/settings/actions";
import Code from "../pages/Code";
import SignInUp from "../pages/SignInUp";
import UserPage from "../pages/User";
import LibraryPage from "../pages/Library";
import Terms from "../pages/Terms";
import PrivacyPage from "../pages/Privacy";
import NotFound from "../pages/PageNotFound";
import { Navigator } from "./Navigator";

export const CRoutes: FC<{ children?: ReactNode }> = ({ children }) => {
  const {
    dispatch,
  } = useStateContext();

  useEffect(() => {
    const asyncFn = async () => {
      const email = await refreshTokens();
      dispatch(userDataInSettings({ userData: { email: email ? email : "" } }));
    };

    asyncFn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<LibraryPage />} />
        <Route path="/" element={<LibraryPage />} />
        <Route path="/home" element={<LibraryPage />} />
        <Route path="/code" element={<Code />}></Route>
        <Route path="/gallery" element={<LibraryPage />}></Route>
        <Route path="/signin" element={<SignInUp />}></Route>
        <Route path="/user" element={<UserPage />}></Route>

        <Route path="/terms" element={<Terms />}></Route>
        <Route path="/privacy" element={<PrivacyPage />}></Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Navigator />
      {children}
    </BrowserRouter>
  );
};
