import { metaIF, EMetaFieldTypes } from "../../types/item";
import { standardUnits } from "./utils";

export const CSS_PROPERTIES_POSITION: metaIF[] = [
  {
    name: "bottom",
    category: ["POSITION"],
    fieldsOrder: ["bottom"],
    fields: [
      {
        name: "bottom",
        type: EMetaFieldTypes.number,
        onOff: true,
        canBeCalculated: true,
        canBeAuto: true,
        units: standardUnits,
        help:
          "### top  \n" +
          "Positions a `absolute` or `fixed` element's bottom relative to the bottom side of its next positioned ancestor. HTMLs are positioned when they have a `position` attribute other than `static`.  \n" +
          "If an element has the position `relative`, it is moved the given number of units up (positive value) or down (negative value) from its current position.  \n" +
          "If the element is not positioned, `bottom` has no effect. ",
      },
    ],
    default: {
      bottom: { value: 100, unit: "px" },
    },
  },

  {
    name: "box-sizing",
    category: ["POSITION"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        help:
          "#### box-sizing  \n" +
          "If `box-sizing` is not set or set with its default value `content-box`, " +
          "then the `width` and `height` of the element does not include `border`s and `padding`. " +
          "For example if the `width` is set to 100px, `padding` to 20px all around and the `border width` to 10px, " +
          "the computed `width` of the element is 10px + 20px + 100px + 20px + 10px = 160px.  \n" +
          "If `box-sizing` is set to `border-box`, then the `width` is 100px. Padding and borders are included.",
        options: [
          {
            id: "content-box",
          },
          {
            id: "border-box",
          },
        ],
      },
    ],
    group: [{ name: "mainG", fields: ["main"], display: ["box-sizing"] }],
    default: { main: { value: "border-box" } },
  },

  {
    name: "display",
    category: ["POSITION"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "block",
            help: "#### block  \nStyle as paragraph.",
          },
          {
            id: "inline",
            help: "#### inline  \nStyle as a word.",
          },
          {
            id: "inline-block",
            help:
              "#### inline-block  \n" +
              "Style as a word with some extra abilities. " +
              "You can apply width, height, margin and padding.",
          },
          {
            id: "none",
            help:
              "#### none  \n" +
              "The element and all its descendants are not displayed and do not take any space in the page.  \n" +
              "This makes sense when the contents of the element are in a waiting position " +
              "and are displayed, for example, dependent on a user events or screen size.",
          },
          {
            id: "table",
            help: "### display table  \nSee for example [The Anti-hero of CSS Layout - display:table](https://colintoh.com/blog/display-table-anti-hero)",
          },
          {
            id: "table-row",
            help: "### display table  \nSee for example [The Anti-hero of CSS Layout - display:table](https://colintoh.com/blog/display-table-anti-hero)",
          },
          {
            id: "table-cell",
            help: "### display table  \nSee for example [The Anti-hero of CSS Layout - display:table](https://colintoh.com/blog/display-table-anti-hero)",
          },
          {
            id: "table-column",
            help: "### display table  \nSee for example [The Anti-hero of CSS Layout - display:table](https://colintoh.com/blog/display-table-anti-hero)",
          },
          {
            id: "table-column-group",
            help: "### display table  \nSee for example [The Anti-hero of CSS Layout - display:table](https://colintoh.com/blog/display-table-anti-hero)",
          },
          {
            id: "table-header-group",
            help: "### display table  \nSee for example [The Anti-hero of CSS Layout - display:table](https://colintoh.com/blog/display-table-anti-hero)",
          },
          {
            id: "table-row-group",
            help: "### display table  \nSee for example [The Anti-hero of CSS Layout - display:table](https://colintoh.com/blog/display-table-anti-hero)",
          },
          {
            id: "table-footer-group",
            help: "### display table  \nSee for example [The Anti-hero of CSS Layout - display:table](https://colintoh.com/blog/display-table-anti-hero)",
          },
          {
            id: "table-caption",
            help: "### display table  \nSee for example [The Anti-hero of CSS Layout - display:table](https://colintoh.com/blog/display-table-anti-hero)",
          },
        ],
        help:
          "### display  \n" +
          "The `display` property describes the type of the rendering box of the element.  " +
          "Each HTML element has a default `block` or `inline` display setting and " +
          "a certain behaviour attached to it. " +
          "Block elements for example start per default in a new line and " +
          "take the full width of the available space.   \n" +
          "With the CSS display property you can change its default type and so " +
          "redress the element.  \n" +
          /*
        'There are amongst others no table or list display attributes to be found. ' +
        'Reason is that we recommend to use here the respective HTML.  \n'+
        'We are aware that there are different opinions out how to style a table, for example.  ' +
        'It has advantages for certain edge cases to use CSS. ' +
        'Advantage of the traditional solution, use HTML elements, ' +
        'is especially the higher semantic page hygiene. '+
        'Every browser immediatelly knows what a table is and how it should be build. '+
        'An erroneous table will not be displayed. '+
        'If the HTML table does not work for our cause, we choose a flex layout instead.  \n' +
        */
          "NOTE: The list contains a selection of the `display` attributes. " +
          "The `table` display setting and its variants are left out, for example, " +
          "because we recommend that you use HTML table elements, instead of CSS tables.  \n" +
          "The `display`: `flex` attribute is missing, too, but it is to be found in the tab FLEX.  \n",
      },
    ],
    group: [{ name: "mainG", fields: ["main"], display: ["display"] }],
    default: { main: { value: "inline-block" } },
  },
  {
    name: "left",
    category: ["POSITION"],
    fieldsOrder: ["left"],
    fields: [
      {
        name: "left",
        type: EMetaFieldTypes.number,
        onOff: true,
        canBeCalculated: true,
        canBeAuto: true,
        units: standardUnits,
      },
    ],
    default: {
      left: { value: 100, unit: "px" },
    },
  },

  {
    name: "margin",
    category: ["POSITION"],
    addMultipleTimes: true,
    fieldsOrder: ["main", "top", "right", "bottom", "left"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "all",
            display: "margin",
          },
          {
            id: "top",
            display: "margin-top",
          },
          {
            id: "right",
            display: "margin-right",
          },
          {
            id: "bottom",
            display: "margin-bottom",
          },
          {
            id: "left",
            display: "margin-left",
          },
        ],
      },
      {
        name: "top",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnits,
        canBeCalculated: true,
        canBeInherited: true,
        canBeAuto: true,
        dependentOn: { field: "main", values: ["all", "top"] },
      },
      {
        name: "right",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnits,
        canBeCalculated: true,
        canBeInherited: true,
        canBeAuto: true,
        dependentOn: { field: "main", values: ["all", "right"] },
      },
      {
        name: "bottom",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnits,
        canBeCalculated: true,
        canBeInherited: true,
        canBeAuto: true,
        dependentOn: { field: "main", values: ["all", "bottom"] },
      },
      {
        name: "left",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnits,
        canBeCalculated: true,
        canBeInherited: true,
        canBeAuto: true,
        dependentOn: { field: "main", values: ["all", "left"] },
      },
    ],

    group: [
      {
        name: "marginG",
        fields: ["main"],
        display: ["side"],
      },
      {
        name: "topG",
        display: ["top"],
        fields: ["top"],
      },
      {
        name: "rightG",
        display: ["right"],
        fields: ["right"],
      },
      {
        name: "bottomG",
        display: ["bottom"],
        fields: ["bottom"],
      },
      {
        name: "leftG",
        display: ["left"],
        fields: ["left"],
      },
    ],
    wrapperForGroup: [
      {
        name: "margin",
        fields: ["main", "top", "right", "bottom", "left"],
        wrapperFn: (vObj) => {
          const top = vObj.top;
          const right = vObj.right;
          const bottom = vObj.bottom;
          const left = vObj.left;

          const topStr = top ?? "";
          const rightStr = right ?? "";
          const bottomStr = bottom ?? "";
          let leftStr = left ?? "";

          const allStrs = [topStr, rightStr, bottomStr, leftStr];
          if (allStrs.length === 4 && allStrs.includes("inherit")) {
            return "inherit";
          }

          if (
            topStr === rightStr &&
            topStr === bottomStr &&
            topStr === leftStr
          ) {
            return topStr;
          }
          if (topStr === bottomStr && rightStr === leftStr) {
            return `${topStr} ${rightStr}`;
          }
          if (rightStr === leftStr) {
            return `${topStr} ${rightStr} ${bottomStr}`;
          }

          let resultStr = "";
          allStrs.forEach((str) => {
            const space = resultStr === "" ? "" : " ";
            resultStr += `${space}${str}`;
          });
          return resultStr;
        },
      },
    ],

    default: {
      main: { value: "all" },
      top: { value: 10, unit: "px" },
      right: { value: 20, unit: "px" },
      bottom: { value: 10, unit: "px" },
      left: { value: 20, unit: "px" },
    },
  },
  {
    name: "padding",
    category: ["POSITION"],
    addMultipleTimes: true,
    fieldsOrder: ["main", "top", "right", "bottom", "left"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "all",
            display: "padding",
          },
          {
            id: "top",
            display: "padding-top",
          },
          {
            id: "right",
            display: "padding-right",
          },
          {
            id: "bottom",
            display: "padding-bottom",
          },
          {
            id: "left",
            display: "padding-left",
          },
        ],
        help: "### padding  \nSee for example [w3schools - CSS Padding](https://www.w3schools.com/csS/css_padding.asp)",
      },
      {
        name: "top",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnits,
        dependentOn: {
          field: "main",
          values: ["all", "top"],
        },
        canBeCalculated: true,
        canBeInherited: true,
        canBeInitial: true,
      },
      {
        name: "right",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnits,
        dependentOn: {
          field: "main",
          values: ["all", "right"],
        },
        canBeCalculated: true,
        canBeInherited: true,
        canBeInitial: true,
      },
      {
        name: "bottom",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnits,
        dependentOn: {
          field: "main",
          values: ["all", "bottom"],
        },
        canBeCalculated: true,
        canBeInherited: true,
        canBeInitial: true,
      },
      {
        name: "left",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnits,
        dependentOn: {
          field: "main",
          values: ["all", "left"],
        },
        canBeCalculated: true,
        canBeInherited: true,
        canBeInitial: true,
      },
    ],

    group: [
      {
        name: "paddingG",
        fields: ["main"],
        display: ["padding"],
      },
      {
        name: "topG",
        display: ["top"],
        fields: ["top"],
      },
      {
        name: "rightG",
        display: ["right"],
        fields: ["right"],
      },
      {
        name: "bottomG",
        display: ["bottom"],
        fields: ["bottom"],
      },
      {
        name: "leftG",
        display: ["left"],
        fields: ["left"],
      },
    ],
    wrapperForGroup: [
      {
        name: "padding",
        fields: ["main", "top", "right", "bottom", "left"],
        wrapperFn: (vObj) => {
          const top = vObj.top ?? "";
          const right = vObj.right ?? "";
          const bottom = vObj.bottom ?? "";
          const left = vObj.left ?? "";

          const allStrs = [top, right, bottom, left].filter((str) => str !== "");
          if (allStrs.includes("initial")) {
            return "initial";
          }
          if (allStrs.includes("inherit")) {
            return "inherit";
          }

          if(allStrs.length === 1) {
            return allStrs[0];
          }

          if(top === right && top === bottom && top === left) {
            return top;
          }

          if(top === bottom && right === left) {
            return `${top} ${right}`;
          }

          if(right === left) {
            return `${top} ${right} ${bottom}`;
          }

          let resultStr = "";
          allStrs.forEach((str) => {
            const space = resultStr === "" ? "" : " ";
            resultStr += `${space}${str}`;
          });
          return resultStr;
        },
      },
    ],

    default: {
      main: { value: "all" },
      top: { value: 0.5, unit: "rem" },
      right: { value: 1, unit: "rem" },
      bottom: { value: 0.5, unit: "rem" },
      left: { value: 1, unit: "rem" },
    },
  },
  {
    name: "position",
    category: ["POSITION"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "static",
            help:
              "#### static (default)\n" +
              "Element is not positioned in any special way, it goes according to the normal flow of the page.  \n" +
              "Elements with a `static` position can not be used as anchor for absolute positioned children.",
          },
          {
            id: "relative",
            help: "#### relative\nElement is positioned relative to its normal position.",
          },
          {
            id: "fixed",
            help:
              "#### fixed\n" +
              "The element is positioned relative to the viewport. " +
              "The viewport is that area of a web page visible for a user in the browser.  \n" +
              "Elements positioned relative to the viewport do not scroll, when the rest of the page scrolls, " +
              "because their position is independent from the whereabouts of their anchestor.",
          },
          {
            id: "absolute",
            help:
              "#### absolute  \n" +
              "The element is not in the same space with its siblings, but in an extra layer on top. " +
              "If it has width and height, it does not push the next sibling down or to the right in the page.  \n" +
              "The element is positioned relative to the nearest positioned ancestor. " +
              "A `positioned` element is one whose position is anything except `static`. " +
              "If there are no positioned ancestors, it uses the document body and moves along with the page scrolling.",
          },
          {
            id: "sticky",
            help:
              "#### sticky\n" +
              "The element is positioned based on the user scrolling the page. " +
              "It stays `relative` to its position in the page and " +
              "scrolls with the page, until it is about to disappear. " +
              "In that moment it changes its behaviour and becomes `fixed`, " +
              "relative to the viewport of the page plus an offset, " +
              "until the user scrolls in the other direction.",
            warning:
              "#### sticky\n" +
              "You must specify at least one of the properties `top`, `right`, `bottom` or `left` for sticky positioning to work.",
          },
        ],
        help: "### position  \nDefines how the element behaves within the page flow.  \n",
      },
    ],
    group: [{ name: "mainG", fields: ["main"], display: ["position"] }],
    default: {
      main: { value: "relative" },
    },
  },
  {
    name: "right",
    category: ["POSITION"],
    fieldsOrder: ["right"],
    fields: [
      {
        name: "right",
        type: EMetaFieldTypes.number,
        onOff: true,
        canBeCalculated: true,
        canBeAuto: true,
        units: standardUnits,
        help:
          "### right  \n" +
          "Positions a `absolute` or `fixed` element on the right of its next positioned ancestor. HTMLs are positioned when they have a `position` attribute other than `static`.  \n" +
          "If an element has the position `relative`, it is moved the given number of units to the left from its current position.  \n" +
          "If the element is not positioned, `right` has no effect. ",
      },
    ],
    group: [{ name: "mainG", fields: ["main"], display: ["right"] }],
    default: {
      right: { value: 100, unit: "px" },
    },
  },
  {
    name: "top",
    category: ["POSITION"],
    fieldsOrder: ["main"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        canBeCalculated: true,
        canBeAuto: true,
        units: standardUnits,
        help:
          "### top  \n" +
          "Positions a `absolute` or `fixed` element's top relative to the top side of its next positioned ancestor. HTMLs are positioned when they have a `position` attribute other than `static`.  \n" +
          "If an element has the position `relative`, it is moved the given number of units down (positive value) or up (negative value) from its current position.  \n" +
          "If the element is not positioned, `top` has no effect. ",
      },
    ],
    default: {
      main: { value: 100, unit: "px" },
    },
  },
  {
    name: "z-index",
    category: ["POSITION"],
    fieldsOrder: ["main"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        help:
          "### z-index  \n" +
          "HTML elements are naturally stacked onto each other in the order " +
          "root element, none positioned elements, positioned elements " +
          "and within their group in the order of their appearance.  \n" +
          "When elements overlap, then the once further down in the stack pile are displayed underneath.  \n" +
          "If you want to influence the stacking order, you can set `z-index` on positioned elements. " +
          "The `z-index` can be a negative, zero or positive integer or `auto`. " +
          "The higher the integer is, the further up is the respective element in the stack within its stacking context.  \n" +
          "`auto` is the default. It comes with a `z-index` of 0 and no new stacking context is created.  \n" +
          "When you set a z-index other than `auto`, a new stacking context is created under the following conditions: " +
          "the element is positioned, child of a flex container or child of a grid container.  \n" +
          "To push an element (and its decendants) up in the stacking order and so display it on top of the others, " +
          "give it a position other than static and a comparable high z-index. " +
          "On this way you build, for example, modals, which are displayed under certain conditions " +
          "and often just temporarily on top of the rest of the page.",
      },
    ],
    group: [{ name: "mainG", fields: ["main"], display: ["z-index"] }],
    default: {
      main: { value: 1 },
    },
  },
];
