import {
  getMetas,
  getCategoryTitle,
  getCategories,
  getCategoryMetasForItemType,
} from "../meta/getMetas";
import { metaIF, itemIF, TObj, TItemsInCategories } from "../../data/types/item";
import {SELECTOR_CATEGORIES_HELP} from "../../data/selectors/constants/all";

export const isCssPropsBtnDisabled = ({
  meta,
  items,
  editSelector,
}: {
  meta?: metaIF;
  items?: itemIF[];
  editSelector?: itemIF;
}): boolean => {
  if (!editSelector || editSelector.name === "@media") {
    return true;
  }
  if (
    !meta?.addMultipleTimes &&
    items?.find((i: itemIF) => i.name === meta?.name)
  ) {
    return true;
  }

  if (meta?.enabledInSelector) {
    if (!meta.enabledInSelector.includes(editSelector.name)) {
      return true;
    }
  }
  return false;
};

export const separetSetOnParent = (props: metaIF[]) => {
  if (props) {
    const parentProps = props.filter((m) => m?.setOnParent);
    return {
      parent: parentProps.length > 0 ? parentProps : props,
      child:
        parentProps.length > 0
          ? props.filter((m) => !m.setOnParent)
          : undefined,
    };
  }
};

export const getItemsInCategory = ({
  itemType,
  withCategories,
  dontDisplayCategoryHelp,
}: {
  itemType: string;
  withCategories?: boolean;
  dontDisplayCategoryHelp?: boolean;
}): TItemsInCategories[] => {
  const itemsInCategories: TItemsInCategories[] = [];
  if (withCategories) {
    const categoryTitle = !dontDisplayCategoryHelp
      ? getCategoryTitle(itemType)
      : { title: {} as TObj };
      
    const categories = getCategories(itemType);
    if (categories) {
      Object.keys(categories).forEach((category) => {
        const categoryMetas = getCategoryMetasForItemType({
          category,
          itemType,
        });
        itemsInCategories.push({
          name: category,
          items: categoryMetas,
          title: categoryTitle.title
            ? categoryTitle.title[category]
            : undefined,
          help: SELECTOR_CATEGORIES_HELP[category],
        });
      });
    }
  } else {
    // get all metas without categories
    itemsInCategories.push({
      name: "all",
      items: getMetas(itemType),
    });
  }

  return itemsInCategories;
};
