function process(str: string, tag?: string) {
  const createTag = tag ? tag : "div";
  var div = document.createElement(createTag);
  div.innerHTML = str.trim();

  return format(div, 0).innerHTML;
}

function format(node: Element, level: number) {
  const indentBefore = new Array(level++ + 1).join("   ");
  const indentAfter = new Array(level - 1).join("   ");
  let textNode;
  for (var i = 0; i < node.children.length; i++) {
    textNode = document.createTextNode("\n" + indentBefore);
    node.insertBefore(textNode, node.children[i]);

    format(node.children[i], level);

    if (node.lastElementChild === node.children[i]) {
      textNode = document.createTextNode(`\n${indentAfter}`);
      node.appendChild(textNode);
    }
  }

  return node;
}

const htmlGradientId: RegExp = /url\(&quot;([^&]+)&quot;\)/g;
export const getFormattedCode = (str?: string) => {

  if(!str) return "";
  const formattedStr = str
    /*.replace(htmlClass, (hc) => {
        const hcR = hc.replace(snipptId, "");
        return hcR;
      })  */
    .replace(/<style>(?=[a-z0-9.#])/i, `<style>\n\n`)
    .replace(/(<style>)[^<]+(<\/style>)/, (css) => {
      return css
        .replace(/ {2}/g, " ")
        .replace(/.+;/g, (str) => {
          return `  ${str}`;
        })
        .replace(/}\n}/g, "  }\n}");
    })
    .replace(/<\/style>(.|\n)*/, (html) => {
      return html.replace(/ {2}/g, " ").replace(htmlGradientId, (gi) => {
        const giR = gi.replace(/&quot;/g, "").replace(/_[^)_]+(?=\))/, "");
        return giR;
      });
    });
  const processedStr = process(formattedStr);

  if (/[^ ]<style>/.test(processedStr)) {
    return processedStr.replace(/ <\/style>/, `</style>\n\n`);
  }
  if (/ {3}<style>/.test(processedStr)) {
    return processedStr.replace(/ <\/style>/, `   </style>\n`);
  }

  return processedStr;
};
