import { itemTypes, getMeta } from "./getMetas";
import { metaIF, groupIF, itemIF, IOptions } from "../../data/types/item";
import { addCanBeFieldsToFieldsFieldsOrderGroupDefault } from "./getMetaCanBeFields";
import { snippetIF } from "../../data/types/snippets";

const clone = require("rfdc")();

// fold metaAll into meta
// if item value is missing the metaAll fields values -> add metaAll field default

const combineMeta = ({
  meta,
  metaAll,
  itemName,
}: {
  meta: metaIF;
  metaAll: metaIF;
  itemName?: string;
}): metaIF | undefined => {
  if (!meta && !metaAll) {
    return undefined;
  }
  if (!metaAll) {
    return meta;
  }
  if (!meta) {
    return metaAll;
  }
  const omitFields = itemName
    ? metaAll.fields
        ?.filter((f) => f.notOnItem && f.notOnItem.includes(itemName))
        .map((f) => f.name)
    : [];

  const metaCombined: metaIF = clone(meta);
  if (metaAll.fieldsOrder) {
    const checkForOmittedFieldNames = metaAll.fieldsOrder.filter(
      (fieldName) => !omitFields || !omitFields.includes(fieldName)
    );
    const metaFieldsOrder = meta.fieldsOrder ? meta.fieldsOrder : ["main"];
    metaCombined.fieldsOrder = [
      ...metaFieldsOrder,
      ...checkForOmittedFieldNames,
    ];
  }
  if (metaAll.fields) {
    const checkForOmittedFields = metaAll.fields.filter(
      (f) => !omitFields || !omitFields.includes(f.name)
    );

    if (meta.fields) {
      metaCombined.fields = [...meta.fields, ...checkForOmittedFields];
    } else {
      metaCombined.fields = checkForOmittedFields;
    }
  }

  if (metaAll.group) {
    // if meta doesn't have a group: add a group for each field

    const checkForOmmitedFieldsInGroup = metaAll.group.filter((g) => {
      if (
        !omitFields ||
        !omitFields.find((fieldName) => g.fields.includes(fieldName))
      ) {
        return true;
      }
      return false;
    });

    if (!meta.group) {
      const newGroup = [] as groupIF[];
      meta.fields?.forEach((f) => {
        newGroup.push({
          name: `${f.name}G`,
          fields: [f.name],
          display: [f.name],
        });
      });
      metaCombined.group = [...newGroup, ...checkForOmmitedFieldsInGroup];
    } else {
      metaCombined.group = [...meta.group, ...checkForOmmitedFieldsInGroup];
    }
  }
  if (metaAll.wrapperForGroup) {
    if (meta.wrapperForGroup) {
      metaCombined.wrapperForGroup = [
        ...meta.wrapperForGroup,
        ...metaAll.wrapperForGroup,
      ];
    } else {
      metaCombined.wrapperForGroup = metaAll.wrapperForGroup;
    }
  }
  if (metaAll.default) {
    if (meta.default) {
      metaCombined.default = { ...meta.default, ...metaAll.default };
    } else {
      metaCombined.default = metaAll.default;
    }
  }
  return metaCombined;
};

const getCombinedMeta = ({
  itemType,
  itemName,
}: {
  itemType: itemTypes;
  itemName: string;
}): metaIF | undefined => {
  const meta = getMeta(itemType, itemName);
  const metaAll = getMeta(itemType, "ALL");

  if (!meta && !metaAll) {
    return;
  }
  if (!meta) {
    return metaAll;
  }

  if (meta.category.includes("addOrRemoveFieldGroup")) {
    return meta;
  }

  if (itemType === itemTypes.BLOCK_HTML && itemName === "text") {
    return meta;
  }

  const metaClone = clone(meta);
  const metaAllClone = metaAll ? clone(metaAll) : undefined;

  return metaAllClone
    ? combineMeta({ meta: metaClone, metaAll: metaAllClone })
    : metaClone;
};

const getFontnamesFromStylesheet = ({
  snippet,
}: {
  snippet: snippetIF;
}): IOptions[] | undefined => {
  const stylepack = snippet.data.stylepacks?.find(
    (s) => s.id === snippet.data.editStylepackId
  );
  if (!stylepack) {
    return;
  }
  const fonts: itemIF[] | undefined = stylepack?.value?.stylepackFonts?.value;
  if (!fonts) {
    return;
  }
  const fontNames: string[] | undefined = fonts.map(
    (f) => f.value?.font?.value
  );
  const options: IOptions[] = fontNames
    ? fontNames.map((f) => ({ id: f }))
    : [];
  return options;
};

const addFontNamesToFontFamilyMeta = ({
  meta,
  snippet,
}: {
  meta: metaIF;
  snippet: snippetIF;
}): metaIF => {
  if (meta.name !== "font-family") {
    return meta;
  }

  const fontNamesAsOptions = getFontnamesFromStylesheet({
    snippet,
  });
  const firstOption = fontNamesAsOptions?.[0];

  const newMeta: metaIF = {
    ...meta,
    fields: meta.fields?.map((f) => {
      if (f.name === "main") {
        return {
          ...f,
          options: fontNamesAsOptions,
        };
      }
      return f;
    }),
    default: {
      ...meta.default,
      main: {
        value: firstOption?.id ?? meta.default?.main?.value,
      },
    },
  };
  return newMeta;
};

// automatically add "ALL" fields with one function call
export const getMetaWithAllFields = ({
  itemType,
  itemName,
  snippet,
}: {
  itemType: itemTypes;
  itemName: string;
  snippet: snippetIF;
}) => {
  const combinedMeta = getCombinedMeta({ itemType, itemName });
  if (!combinedMeta) {
    console.warn("No meta found for", itemType, itemName);
    return;
  }

  const metaWithFontNames = addFontNamesToFontFamilyMeta({
    meta: combinedMeta,
    snippet,
  });

  if (metaWithFontNames.fields && itemType === itemTypes.CLASSES_CSS) {
    const metaWithCanBeFields =
      addCanBeFieldsToFieldsFieldsOrderGroupDefault(metaWithFontNames);
    return metaWithCanBeFields;
  }

  return metaWithFontNames;
};
