import { itemIF } from "../../../data/types/item";
import { snippetIF } from "../../../data/types/snippets";
import {
  getChildrenSelectors,
  getAssignedAnimations,
  getAssignedSelectorIds,
  getAssignedClassNames,
} from "../../items/getAssignedSelectorsNew";
import { itemTypes } from "../../meta/getMetas";
import { getKeyframesAndStepsForAssignedAnimations } from "../../render/css/animation";
import { getItemsOfType } from "../../snippets/addAndGetMetas";
import { getMediaSelectorsWithAssignedClassChild } from "./groupAndSortMediaSelectorNameNew";

const getRelevantPureClassSelectors = ({
  assignedClassNames,
  snippet,
}: {
  assignedClassNames: string[];
  snippet: snippetIF;
}) => {
  const selectors = getItemsOfType({
    itemType: itemTypes.SELECTORS,
    snippet,
  });
  const assignedSelectorsPlusChildren: itemIF[] = [];
  const assignedLevel1Classes = selectors?.filter((s) => {
    const selectorName = s.value?.main?.value;

    const isClassnameAssigned =
      s.name === "class" &&
      s.level === 1 &&
      selectorName &&
      typeof selectorName === "string" &&
      assignedClassNames?.includes(selectorName);

    return isClassnameAssigned;
  });

  assignedLevel1Classes?.forEach((selector) => {
    assignedSelectorsPlusChildren.push(selector);
    getChildrenSelectors({
      selector,
      assignedSelectorsPlusChildren,
      snippet,
    });
  });
  return assignedSelectorsPlusChildren;
};

const getRelevantKeyframeSelectors = ({
  assignedClassNames,
  snippet,
}: {
  assignedClassNames: string[];
  snippet: snippetIF;
}) => {
  const assignedAnimations = getAssignedAnimations({
    assignedClassNames: assignedClassNames,
    snippet,
  });

  const assignedKeyframesAndSteps = getKeyframesAndStepsForAssignedAnimations({
    assignedAnimations,
    snippet,
  });
  return assignedKeyframesAndSteps;
};

const getRelevantMediaSelectorsPlusChildren = ({
  assignedClassNames,
  snippet,
}: {
  assignedClassNames: string[];
  snippet: snippetIF;
}) => {
  const allRelevantMediaSelectors: itemIF[] = [];
  const mediaSelectorsWithAssignedChildClass =
    getMediaSelectorsWithAssignedClassChild({
      assignedClassNames: assignedClassNames || [],
      snippet
    });
  mediaSelectorsWithAssignedChildClass?.forEach((mediaSelector) => {
    allRelevantMediaSelectors.push(mediaSelector);
    getChildrenSelectors({
      selector: mediaSelector,
      snippet,
      assignedSelectorsPlusChildren: allRelevantMediaSelectors,
    });
  });
  return allRelevantMediaSelectors;
};

export const getFeaturingSelectors = ({ 
  itemType,
  snippet }: { 
  itemType: itemTypes;
  snippet: snippetIF }) => {
  const assignedSelectorIds: number[] = [];

  getAssignedSelectorIds({
    items: getItemsOfType({
      itemType,
      snippet,
    }),
    assignedIds: assignedSelectorIds,
  });

  const assignedClassNames =
    getAssignedClassNames({
      assignedSelectorIds,
      snippet,
    }) || [];

  const relevantPureClassSelectors = getRelevantPureClassSelectors({
    assignedClassNames,
    snippet
  });

  const relevantMediaSelectors = getRelevantMediaSelectorsPlusChildren({
    assignedClassNames,
    snippet
  });

  const relevantKeyframeSelectors = getRelevantKeyframeSelectors({
    assignedClassNames,
    snippet
  });

  return {
    classSelectors: relevantPureClassSelectors,
    mediaSelectors: relevantMediaSelectors,
    keyframeSelectors: relevantKeyframeSelectors,
  };
};
