import { useState, useEffect } from "react";
import { itemIF } from "../../data/types/item";
import { useStateContext } from "../../lib/state/state";
import { ReactComponent as Copy } from "../../assets/imgs/copy-all.svg";
import { copyItem } from "../../lib/reducer/items/actions";
import { Tooltip } from "../atoms/tooltip";
import {
  SortAndFindSnippets,
  SelectSnippetButtons,
} from "../modules/SortAndFind";
import { SectionTitle } from "../modules/SectionTitle";
import { itemTypes } from "../../lib/meta/getMetas";

export const CopyStylepack = () => {
  const {
    state: {
      snippet: { snippets, editSnippetId },
    },
    dispatch,
  } = useStateContext();

  const [selectedSnippetId, setSelectedSnippetId] = useState("");
  const [stylepackToCopy, setStylepackToCopy] = useState<itemIF | undefined>();

  const snippetsWithStylepacks = snippets.filter(
    (s) =>
      s.data.stylepacks &&
      s.data.stylepacks.length > 0 &&
      s.id !== editSnippetId
  );
  const selectedSnippet = snippetsWithStylepacks.find(
    (s) => s.id === selectedSnippetId
  );
  const stylepacks = selectedSnippet?.data.stylepacks;

  useEffect(() => {
    const selectedStylepack = stylepacks?.find(
      (s) => s.id === stylepackToCopy?.id
    );
    if (!selectedStylepack && stylepacks && stylepacks?.length > 0) {
      setStylepackToCopy(stylepacks[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSnippet]);

  return (
    <>
      <div className="mb-1r">
        <SectionTitle label="Copy stylepack" size="large" />
      </div>

      <SortAndFindSnippets
        allSnippets={snippetsWithStylepacks}
        title="Select a snippet"
      >
        <SelectSnippetButtons
          selectedSnippetId={selectedSnippetId}
          setSelectedSnippetId={setSelectedSnippetId}
          color="gray-outline"
        />
      </SortAndFindSnippets>

      <div className="form-elements-wrapper">
        <div>
          <div className="flex justify-space-between align-start mt-1r mb-1r mr-2r">
            <SectionTitle label="Select and copy" />
            <button
              type="button"
              className={`button blue-outline icon tooltip-container-parent${!stylepackToCopy ? " disabled" : ""}`}
              onClick={() => {
                if (!selectedSnippet || !stylepackToCopy) return;
                dispatch(
                  copyItem({
                    itemType: itemTypes.STYLEPACK,
                    item: stylepackToCopy,
                    editSnippetId: selectedSnippet.id,
                  })
                );
              }}
            >
              <span className="sr-only">Copy stylepack</span>
              <Copy />
              <Tooltip
                tooltipText="Select below a snippet and stylepack to copy from"
                direction="bottom-left"
              />
            </button>
          </div>

          <div className="btn-row">
            {stylepacks ? (
              stylepacks.map((s) => {
                return (
                  <button
                    key={s.id}
                    type="button"
                    title={s.value?.name?.value}
                    className={`button ${s.id === stylepackToCopy?.id ? "blue" : "gray-outline"}`}
                    onClick={() => {
                      setStylepackToCopy(s);
                    }}
                  >
                    {s.value?.name?.value}
                  </button>
                );
              })
            ) : (
              <button
                type="button"
                className="button gray-outline disabled"
                disabled
              >
                {`${selectedSnippet ? "NO STYLEPACKS" : "SELECT A SNIPPET"}`}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
