import { itemIF, TSelectorData } from "../../../data/types/item";
import { TCreateComposedSelectorItem } from "./editComposedSelectorItemNew";
import { itemTypes } from "../../meta/getMetas";
import { getSelectorNameArray, getSelectorNameStr } from "../getSelectorName";
import { getShortHand } from "../shorthandGroups";
import { getCssCode } from "./getCssCode";
import { snippetIF } from "../../../data/types/snippets";
import { getItemsOfType } from "../../snippets/addAndGetMetas";

const addPseudoelementContent = ({
  selector,
  selectorProperties,
  shortHand,
}: {
  selector: itemIF;
  selectorProperties?: itemIF[];
  shortHand: { [key: string]: string[] };
}) => {
  if (["::before", "::after"].includes(selector.name)) {
    if (!shortHand.content) {
      const content = selector.value?.main?.value;
      // don't render pseudo elements which have no CSS and a content attribute with an empty string
      if (
        content === "" &&
        (!selectorProperties || selectorProperties.length === 0)
      ) {
        return;
      }
      shortHand.content = [`"${content}"`];
    } else {
      const content = shortHand.content[0];
      if (content.includes("data:image")) {
        shortHand.content = [unescape(content)];
      }
    }
  }
};

export const addCssStrForProperties = ({
  sData,
  properties,
  selector,
  lineBreak = "",
  addSpaceAtLineStart = "",
  noCssPrefix = false,
  snippet
}: {
  sData: TSelectorData;
  properties: itemIF[];
  selector: itemIF;
  lineBreak?: string;
  addSpaceAtLineStart?: string;
  noCssPrefix?: boolean;
  snippet:snippetIF
}) => {
  const posAndShs = getShortHand({
    itemType: itemTypes.CLASSES_CSS,
    items: properties,
    snippet
  });

  sData.posAndShs = posAndShs;

  for (const p of posAndShs) {
    addPseudoelementContent({
      selector,
      selectorProperties: properties,
      shortHand: p.sh,
    });
  }

  sData.css = getCssCode({
    posAndShs,
    lineBreak,
    addSpaceAtLineStart: addSpaceAtLineStart + "      ",
    noCssPrefix
  });
};

export const getCssForClassOrHtml = ({
  selector,
  snippet,
  lineBreak,
  addSpaceAtLineStart,
}: TCreateComposedSelectorItem): TSelectorData => {

  if (!selector) {
    console.warn("getCssForClassOrHtml: no selector or selectors");
    return {} as TSelectorData;
  }

  const selectorNamesObjs = [] as { prefix: string; name: string }[];
  getSelectorNameArray({
    selector,
    selectorNamesObjs,
    snippet
  });
  const nameStr = getSelectorNameStr({
    selectorNamesObjs: selectorNamesObjs,
  });

  const sData: TSelectorData = {
    selector: selector,
    nameStr: "",
  };
  sData.nameStr = nameStr;

  const properties = getItemsOfType({
    snippet,
    itemType: itemTypes.CLASSES_CSS,
  });

  const selectorProperties = properties?.filter(
    (p) => p.classId === selector.id
  );

  const sorted = selectorProperties
    ?.slice()
    .sort((a, b) => a.position - b.position) as itemIF[];

  addCssStrForProperties({
    sData,
    properties: sorted,
    selector,
    lineBreak,
    addSpaceAtLineStart,
    snippet
  });
  return { ...sData } as TSelectorData;
};

export const sortSDatas = (sDatas: TSelectorData[]) => {
  sDatas.sort((a, b) => {
    if (a.selector.level && b.selector.level) {
      return a.selector.level > b.selector.level
        ? 1
        : a.selector.level < b.selector.level
          ? -1
          : 0;
    }
    return 0;
  });
  sDatas.sort((a, b) => {
    return a.selector.position > b.selector.position
      ? 1
      : a.selector.position < b.selector.position
        ? -1
        : 0;
  });
};
