import {
  metaIF,
  EMetaFieldTypes,
} from "../../types/item";
import { standardUnits } from "../../css/constants/utils";
import { allFieldsHrefId } from "./utils";
import { svgHelptexts } from "../../css/constants/svg";

export const svgViewBoxPreserveAspectRatioHelp =
  "### viewBox preserveAspectRatio  \n" +
  "If the `viewBox` attribute is set it controls the size of the canvas and the top left corner of the drawing area. The first 2 numbers are the coordinates of the top left corner of the canvas. Positive values set the corner to the left and up and negative values to the right and down.  \n" +
  "The third and fourth value of the `viewBox` set the size of the canvas. The shapes within the SVG are then scaled to fit in the canvas. How they are scaled depends on the settings in `preserveAspectRatio`.  \n" +
  "For more information see for example [MDN - preserveAspectRatio](https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/preserveAspectRatio).  \n\n" +
  "Some examples below. The smilie and the rectangle are 100 x 100 and the SVG `width` is 50 and the `height` 100.  \n\n" +
  "`ViewBox`: 0 0 100 100, `preserveAspectRatio`: ...YMin meet, ...YMax meet, xMin... slice, xMax... slice  \n\n" +
  '<img src="./helpimgs/smilie1.svg"/>\n\n' +
  "`ViewBox`: 0 0 50 50, `preserveAspectRatio`: ...YMin meet, ...YMax meet, xMin... slice, xMax... slice  \n\n" +
  '<img src="./helpimgs/smilie2.svg"/>\n\n' +
  "`ViewBox`: 0 0 200 200, `preserveAspectRatio`: ...YMin meet, ...YMax meet, xMin... slice, xMid... slice  \n\n" +
  '<img src="./helpimgs/smilie3.svg"/>';

const viewBoxMeta: metaIF = {
  name: "viewBox",
  level: "block",
  category: ["SVG"],

  fieldsOrder: ["viewBoxX", "viewBoxY", "viewBoxWidth", "viewBoxHeight"],

  fields: [
    {
      name: "viewBoxX",
      type: EMetaFieldTypes.number,
    },
    {
      name: "viewBoxY",
      type: EMetaFieldTypes.number,
    },
    {
      name: "viewBoxWidth",
      type: EMetaFieldTypes.number,
    },
    {
      name: "viewBoxHeight",
      type: EMetaFieldTypes.number,
    },
  ],

  group: [
    {
      name: "viewBox",
      display: ["viewBox x", "y", "width", "height"],
      fields: ["viewBoxX", "viewBoxY", "viewBoxWidth", "viewBoxHeight"],
      help: svgViewBoxPreserveAspectRatioHelp,
    },
  ],

  wrapperForGroup: [
    {
      name: "viewBox",
      fields: ["viewBoxX", "viewBoxY", "viewBoxWidth", "viewBoxHeight"],
      wrapperFn: (valueObj) => {
        return `${valueObj.viewBoxX} ${valueObj.viewBoxY} ${valueObj.viewBoxWidth} ${valueObj.viewBoxHeight}`;
      },
    },
  ],

  default: {
    viewBoxX: { value: 0 },
    viewBoxY: { value: 0 },
    viewBoxWidth: { value: 24 },
    viewBoxHeight: { value: 24 },
  },
};

export const SVG_CONSTANTS_META: metaIF[] = [
  {
    name: "svg",
    level: "block",
    category: ["SVG"],
    allowedChildren: [
      "title",
      "svg",
      "desc",
      "g",
      "use",
      "symbol",
      "defs",
      "circle",
      "ellipse",
      "line",
      "rect",
      "path",
      "polygon",
      "svg-text",
      "linearGradient",
      "radialGradient",
      "filter",
      "animate",
      "animateMotion",
      "animateTransform",
    ],
    fieldsOrder: [
      "main",
      "width",
      "height",

      ...viewBoxMeta.fieldsOrder!,

      "fill",
      "stroke",
      "stroke-width",
      "stroke-linecap",
      "stroke-linejoin",

      "align",
      "meetOrSlice",
      "x",
      "y",
    ],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.string,
        onOff: true,
        dontRender: true,
        conditions: {
          regex: "^[a-z0-9-_]*$",
        },
      },
      {
        name: "width",
        type: EMetaFieldTypes.number,
        units: standardUnits,
      },
      {
        name: "height",
        type: EMetaFieldTypes.number,
        units: standardUnits,
      },
      ...viewBoxMeta.fields!,
      {
        name: "fill",
        type: EMetaFieldTypes.colourPicker,
      },
      {
        name: "stroke",
        type: EMetaFieldTypes.colourPicker,
      },
      {
        name: "stroke-width",
        type: EMetaFieldTypes.number,
        javascript: "strokeWidth",
      },
      {
        name: "stroke-linecap",
        type: EMetaFieldTypes.select,
        options: [{ id: "butt" }, { id: "round" }, { id: "square" }],
        javascript: "strokeLinecap",
        help: svgHelptexts["stroke-linecap"],
      },
      {
        name: "stroke-linejoin",
        type: EMetaFieldTypes.select,
        options: [{ id: "miter" }, { id: "round" }, { id: "bevel" }],
        javascript: "strokeLinejoin",
        help: svgHelptexts["stroke-linejoin"],
      },
      {
        name: "align",
        type: EMetaFieldTypes.select,
        options: [
          {
            id: "none",
          },
          {
            id: "xMinYMin",
          },
          {
            id: "xMidYMin",
          },
          {
            id: "xMaxYMin",
          },
          {
            id: "xMinYMid",
          },
          {
            id: "xMidYMid",
          },
          {
            id: "xMaxYMid",
          },
          {
            id: "xMinYMax",
          },
          {
            id: "xMidYMax",
          },
          {
            id: "xMaxYMax",
          },
        ],
      },
      {
        name: "meetOrSlice",
        type: EMetaFieldTypes.select,
        options: [
          {
            id: "meet",
          },
          {
            id: "slice",
          },
        ],
      },
      {
        name: "x",
        type: EMetaFieldTypes.number,
      },
      {
        name: "y",
        type: EMetaFieldTypes.number,
      },
    ],

    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["name"],
        onOff: true,
        help: "### Name of the svg  \nThe `name` of the svg is needed to select an SVG in the HTML tab.  \nThe `name` is not visible within the SVG. If you wanted to label the SVG, create in the tab CSS an `id` and name it respectively. Go back to the SVG tab and assign the `id` to the SVG.",
      },
      {
        name: "widthAndHeightG",
        display: ["width", "height"],
        fields: ["width", "height"],
        help:
          "### width and height  \n`Width` and `height` of the visible area of the SVG. If the `viewBox` attribute is not set and within the SVG is a shape, which is higher or wider than the SVG, then just a section of the shape is displayed.  \n\n" +
          '<img src="./smilie1.svg"/>',
      },
      ...viewBoxMeta.group!,
      {
        name: "preserveAspectRatioG",
        display: ["preserveAspectRatio align", "meet or slice"],
        fields: ["align", "meetOrSlice"],
      },
      {
        name: "svgCssG",
        fields: [
          "fill",
          "stroke",
          "stroke-width",
          "stroke-linecap",
          "stroke-linejoin",
        ],
      },
      {
        name: "xYG",
        display: ["x", "y"],
        fields: ["x", "y"],
        help:
          "### SVG in SVG position  \n" +
          "If you nest one SVG in another, the value of the `x` and `y` attributes set the position of the child within its parent SVG.  \n" +
          "The position of an SVG is animatable.",
      },
    ],

    wrapperForGroup: [
      {
        name: "preserveAspectRatio",
        fields: ["align", "meetOrSlice"],
        wrapperFn: (valueObj) => `${valueObj.align} ${valueObj.meetOrSlice}`,
      },
      ...viewBoxMeta.wrapperForGroup!,
    ],

    default: {
      main: { value: "svg-name" },
      width: { value: 24, unit: "px" },
      height: { value: 24, unit: "px" },

      ...viewBoxMeta.default,

      fill: { value: "#f00" },
      stroke: { value: "#0f0" },
      "stroke-width": { value: 1 },
      "stroke-linecap": { value: "round" },
      "stroke-linejoin": { value: "round" },

      align: { value: "xMidYMid" },
      meetOrSlice: { value: "meet" },
      x: { value: 10 },
      y: { value: 10 },
    },
  },

  {
    name: "g",
    level: "block",
    category: ["SVG"],
    allowedChildren: [
      "title",
      "svg",
      "use",
      "circle",
      "ellipse",
      "line",
      "rect",
      "path",
      "polygon",
      "svg-text",
    ],
    availableFromLevel: 2,
    fieldsOrder: ["tabindex"],
    fields: [{ name: "tabindex", type: EMetaFieldTypes.number }],
    default: {
      tabindex: {
        value: -1,
      },
    },
    help: "### g  \n`g` is a container to group elements within a SVG. You can move or clone a `g` and its children like it is one element, and assign classes to style all elements within the container.",
  },

  {
    name: "symbol",
    level: "block",
    category: ["SVG"],
    fieldsOrder: ["width", "height", ...viewBoxMeta.fieldsOrder!],
    fields: [
      {
        name: "width",
        type: EMetaFieldTypes.number,
        units: standardUnits,
      },

      {
        name: "height",
        type: EMetaFieldTypes.number,
        units: standardUnits,
      },
      ...viewBoxMeta.fields!,
    ],
    group: [
      {
        name: "widthG",
        fields: ["width"],
        display: ["width"],
      },
      {
        name: "heightG",
        fields: ["height"],
        display: ["height"],
      },
      ...viewBoxMeta.group!,
    ],

    wrapperForGroup: viewBoxMeta.wrapperForGroup!,

    default: {
      width: {
        value: 10,
        unit: "px",
      },
      height: {
        value: 10,
        unit: "px",
      },
      ...viewBoxMeta.default,
    },
  },
  {
    name: "use",
    allowedChildren: ["title"],
    level: "inline",
    category: ["SVG"],
    fieldsOrder: ["href", "x", "y", "width", "height"],
    help: "### use  \nSee for example [MDN - use](https://developer.mozilla.org/en-US/docs/Web/SVG/Element/use)",
    fields: [
      allFieldsHrefId,
      {
        name: "x",
        type: EMetaFieldTypes.number,
      },
      {
        name: "y",
        type: EMetaFieldTypes.number,
      },
      {
        name: "width",
        type: EMetaFieldTypes.number,
        units: standardUnits,
      },
      {
        name: "height",
        type: EMetaFieldTypes.number,
        units: standardUnits,
      },
    ],
    group: [
      {
        name: "hrefG",
        fields: ["href"],
        display: ["href"],
      },
      {
        name: "xYG",
        fields: ["x", "y"],
        display: ["x", "y"],
      },
      {
        name: "widthHeightG",
        fields: ["width", "height"],
        display: ["width", "height"],
      },
    ],
    default: {
      href: {
        value: 0,
      },
      x: {
        value: 10,
      },
      y: {
        value: 0,
      },
      width: {
        value: 40,
        unit: "px",
      },
      height: {
        value: 40,
        unit: "px",
      },
    },
  },

  {
    name: "defs",
    level: "block",
    category: ["SVG"],
    allowedChildren: [
      "title",
      "svg",
      "circle",
      "ellipse",
      "line",
      "rect",
      "path",
      "polygon",
      "svg-text",
      "linearGradient",
      "radialGradient",
      "filter",
    ],
    help:
      "### defs  \nWithin devs you can store assets like path or circle. You give them and id and you can style them by assigning classes with CSS. To use assets within the SVG, add use elements with the asset id.  \n" +
      "For more information see for example [MDN - defs](https://developer.mozilla.org/en-US/docs/Web/SVG/Element/defs)",
  },
  {
    name: "title",
    level: "block",
    category: ["SVG"],
    allowedChildren: ["title"],
    fieldsOrder: ["text"],
    fields: [
      {
        name: "text",
        type: EMetaFieldTypes.textarea,
        onOff: true,
        dontRender: true,
        help:
          "### title  \nEnter here the SVG `title`. The `title` helps to identify its purpose. It can be read by people and screen readers and so improves the SVG's accessibility.  \n" +
          "If you hover over a SVG with a `title`, the `title` will be displayed in a simple box. You can't style the box with CSS. If you don't wish this effect, use the `desc` element instead.",
      },
    ],
    default: {
      text: {
        value: "SVG Title",
      },
    },
  },
  {
    name: "desc",
    level: "block",
    category: ["SVG"],
    allowedChildren: ["title"],
    fieldsOrder: ["text"],
    fields: [
      {
        name: "text",
        type: EMetaFieldTypes.textarea,
        onOff: true,
        dontRender: true,
        help: "### desc  \nEnter here a description of the SVG. The description helps to identify its purpose. It can be read by people and screen readers and so improves the SVG's accessibility.",
      },
    ],
    default: {
      text: {
        value: "SVG description",
      },
    },
  },
];
