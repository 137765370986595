import { getMetaWithAllFields } from "../meta/getMetaWithAllFields";
import { itemTypes } from "../meta/getMetas";
import { EMetaFieldTypes, itemIF, metaIF } from "../../data/types/item";
import { snippetIF } from "../../data/types/snippets";
import { replaceEndsWithSelectFields } from "./replaceEndsWithSelectFields";

export const getItemsOfType = ({
  itemType,
  snippet,
}: {
  itemType: itemTypes;
  snippet: snippetIF;
}): itemIF[] => {
  return itemType === itemTypes.BLOCK_HTML
    ? snippet.data?.htmls
    : itemType === itemTypes.SELECTORS
    ? snippet.data?.selectors
    : itemType === itemTypes.CLASSES_CSS
    ? snippet.data?.properties
    : itemType === itemTypes.SVGS
    ? snippet.data?.svgs
    : itemType === itemTypes.STYLEPACK
    ? snippet.data?.stylepacks ?? []
    : [];
};

export const getEditItemIdOfType = ({
  itemType,
  snippet,
}: {
  itemType: itemTypes;
  snippet: snippetIF;
}): number | undefined => {
  return itemType === itemTypes.BLOCK_HTML
    ? snippet.data?.editHtmlId
    : itemType === itemTypes.SELECTORS
    ? snippet.data?.editSelectorId
    : itemType === itemTypes.CLASSES_CSS
    ? snippet.data?.editPropertyId
    : itemType === itemTypes.SVGS
    ? snippet.data?.editSvgId
    : itemType === itemTypes.STYLEPACK
    ? snippet.data?.editStylepackId
    : undefined;
};

export const getItemOfType = ({
  itemType,
  itemId,
  snippet,
}: {
  itemType: itemTypes;
  itemId: number;
  snippet: snippetIF;
}): itemIF | undefined => {
  const items = getItemsOfType({ itemType, snippet });
  return items.find((i) => i.id === itemId);
};

export const replaceItemsAndEditItemIdOfType = ({
  itemType,
  snippet,
  items,
  editItemId,
}: {
  itemType: itemTypes;
  snippet: snippetIF;
  items: itemIF[];
  editItemId: number;
}): snippetIF => {
  return itemType === itemTypes.BLOCK_HTML
    ? {
        ...snippet,
        data: {
          ...snippet.data,
          htmls: items,
          editHtmlId: editItemId,
        },
      }
    : itemType === itemTypes.SELECTORS
    ? {
        ...snippet,
        data: {
          ...snippet.data,
          selectors: items,
          editSelectorId: editItemId,
        },
      }
    : itemType === itemTypes.CLASSES_CSS
    ? {
        ...snippet,
        data: {
          ...snippet.data,
          properties: items,
          editPropertyId: editItemId,
        },
      }
    : itemType === itemTypes.SVGS
    ? {
        ...snippet,
        data: {
          ...snippet.data,
          svgs: items,
          editSvgId: editItemId,
        },
      }
    : itemType === itemTypes.STYLEPACK
    ? {
        ...snippet,
        data: {
          ...snippet.data,
          stylepacks: items,
          editStylepackId: editItemId,
        },
      }
    : snippet;
};

export const emptyItemMetas = (snippet: snippetIF): snippetIF => {
  return {
    ...snippet,
    data: {
      ...snippet.data,
      htmlsMeta: [],
      selectorsMeta: [],
      propertiesMeta: [],
      svgsMeta: [],
      stylepacksMeta: [],
    },
  };
};

const getMetasOfType = ({
  itemType,
  snippet,
}: {
  itemType: itemTypes;
  snippet: snippetIF;
}): metaIF[] => {
  return itemType === itemTypes.BLOCK_HTML
    ? snippet.data?.htmlsMeta ?? []
    : itemType === itemTypes.SELECTORS
    ? snippet.data?.selectorsMeta ?? []
    : itemType === itemTypes.CLASSES_CSS
    ? snippet.data?.propertiesMeta ?? []
    : itemType === itemTypes.SVGS
    ? snippet.data?.svgsMeta ?? []
    : itemType === itemTypes.STYLEPACK
    ? snippet.data?.stylepacksMeta ?? []
    : [];
};

const getMetaNamesOfAddOrRemoveFieldGroups = ({
  meta,
  metaNamesOfAddOrRemoveFieldGroups,
}: {
  meta: metaIF;
  metaNamesOfAddOrRemoveFieldGroups: string[];
}) => {
  const addOrRemoveFieldMetas = meta.fields?.filter((f) => {
    return f.type === EMetaFieldTypes.addOrRemoveFieldGroup;
  });
  if (!addOrRemoveFieldMetas || addOrRemoveFieldMetas.length === 0) {
    return;
  }
  for (const addOrRemoveFieldMeta of addOrRemoveFieldMetas) {
    if (
      !metaNamesOfAddOrRemoveFieldGroups.includes(addOrRemoveFieldMeta.name)
    ) {
      metaNamesOfAddOrRemoveFieldGroups.push(addOrRemoveFieldMeta.name);
    }
  }
};

const addMissingAndRemoveDeletedMetas = ({
  itemType,
  snippet,
}: {
  itemType: itemTypes;
  snippet: snippetIF;
}): { newMetas: metaIF[]; newItems: itemIF[] } => {
  const items = getItemsOfType({ itemType, snippet });
  const metas = getMetasOfType({ itemType, snippet });

  // replace old can be *Select fields with new pattern main and mainValue
  const newItems = replaceEndsWithSelectFields({ itemType, items });

  const newMetas: metaIF[] = metas ? [...metas] : [];

  // check if there are metas for all items

  for (const item of items || []) {
    const meta = newMetas?.find((m) => m.name === item.name);
    if (!meta) {
      const newMeta = getMetaWithAllFields({
        itemType,
        itemName: item.name,
        snippet
      });
      if (newMeta) {
        newMetas.push(newMeta);

        // check if there are addOrRemoveFieldGroups in the newMeta

        const metaNamesOfAddOrRemoveFieldGroups: string[] = [];
        getMetaNamesOfAddOrRemoveFieldGroups({
          meta: newMeta,
          metaNamesOfAddOrRemoveFieldGroups,
        });
        for (const metaName of metaNamesOfAddOrRemoveFieldGroups) {
          const isInMetas = newMetas.find((m) => m.name === metaName);
          if (!isInMetas) {
            const newAddOrRemoveMeta = getMetaWithAllFields({
              itemType,
              itemName: metaName,
              snippet
            });
            if (newAddOrRemoveMeta) {
              newMetas.push(newAddOrRemoveMeta);
            }
          }
        }
      }
    }
  }

  if (itemType === itemTypes.SELECTORS) {
    const selectorsWithPrefix = items.filter((s) => s.prefix?.name);
    for (const selector of selectorsWithPrefix) {
      const prefixName = selector.prefix?.name;
      if (!prefixName) continue;

      const selectorMeta = newMetas.find((m) => m.name === prefixName);
      if (selectorMeta) continue;

      const newSelectorMeta = getMetaWithAllFields({
        itemType: itemTypes.CONNECTOR,
        itemName: prefixName,
        snippet
      });
      if (!newSelectorMeta) continue;

      newMetas.push(newSelectorMeta);
    }
  }

  // check if there are metas without item and remove them

  const metaNamesOfAddOrRemoveFieldGroups: string[] = [];
  for (const meta of newMetas || []) {
    getMetaNamesOfAddOrRemoveFieldGroups({
      meta,
      metaNamesOfAddOrRemoveFieldGroups,
    });
  }

  for (const meta of newMetas || []) {
    const item = items?.find((i) => i.name === meta.name);
    if (!item) {
      // before you remove meta check if it is for a addOrRemoveFieldGroup

      if (metaNamesOfAddOrRemoveFieldGroups.includes(meta.name)) {
        continue;
      }

      const index = newMetas.indexOf(meta);
      if (index > -1) {
        newMetas.splice(index, 1);
      }
    }
  }

  return { newMetas, newItems };
};

export const addMetasToSnippet = (snippet: snippetIF): snippetIF => {
  const htmlsMeta = addMissingAndRemoveDeletedMetas({
    itemType: itemTypes.BLOCK_HTML,
    snippet,
  });

  const selectorsMeta = addMissingAndRemoveDeletedMetas({
    itemType: itemTypes.SELECTORS,
    snippet,
  });

  const propertiesMeta = addMissingAndRemoveDeletedMetas({
    itemType: itemTypes.CLASSES_CSS,
    snippet,
  });

  const svgsMeta = addMissingAndRemoveDeletedMetas({
    itemType: itemTypes.SVGS,
    snippet,
  });

  const stylepacksMeta = addMissingAndRemoveDeletedMetas({
    itemType: itemTypes.STYLEPACK,
    snippet,
  });

  const newSnippet: snippetIF = {
    ...snippet,
    data: {
      ...snippet.data,
      htmlsMeta: htmlsMeta.newMetas,
      selectorsMeta: selectorsMeta.newMetas,
      propertiesMeta: propertiesMeta.newMetas,
      properties: propertiesMeta.newItems,
      svgsMeta: svgsMeta.newMetas,
      stylepacksMeta: stylepacksMeta.newMetas,
    },
  };

  return newSnippet;
};

export const getMetaFromSnippet = ({
  snippet,
  itemType,
  itemName,
}: {
  snippet: snippetIF;
  itemType: itemTypes;
  itemName: string;
}): metaIF | undefined => {
  if (!(snippet && itemType && itemName)) {
    return;
  }

  if (itemType === itemTypes.BLOCK_HTML) {
    return snippet.data?.htmlsMeta?.find((m) => m.name === itemName);
  }
  if (itemType === itemTypes.SELECTORS || itemType === itemTypes.CONNECTOR) {
    return snippet.data?.selectorsMeta?.find((m) => m.name === itemName);
  }
  if (itemType === itemTypes.CLASSES_CSS) {
    return snippet.data?.propertiesMeta?.find((m) => m.name === itemName);
  }
  if (itemType === itemTypes.SVGS) {
    return snippet.data?.svgsMeta?.find((m) => m.name === itemName);
  }
  if (itemType === itemTypes.STYLEPACK) {
    return snippet.data?.stylepacksMeta?.find((m) => m.name === itemName);
  }
};
