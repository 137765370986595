import React, { useEffect, useState, FC } from "react";
import { Color, formatHex, formatHsl, parse } from "culori";
import {
  colorToHsl,
  colorToRgb,
  eCFormat,
} from "../../../lib/utils/culoriColor";
import { SelectSimpleNative } from "../../atoms/SelectSimpleNative";

export const EnterNewValue = ({
  title,
  value,
  setValue,
  testNumber,
}: {
  title: string;
  value: number;
  setValue: (value: number) => void;
  testNumber: (value: number) => number;
}) => {
  const [valueStr, setValueStr] = useState(value.toString());

  useEffect(() => {
    const newValueStr = value.toString();
    if (valueStr !== newValueStr) {
      setValueStr(newValueStr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <label>
      <span className="sr-only">{title}</span>
      <input
        className="simple-text-input"
        type="text"
        value={valueStr}
        onChange={(e) => {
          const newValuestr2 = e.target.value;
          setValueStr(newValuestr2);
        }}
        onBlur={() => {
          const newNumber = parseFloat(valueStr);
          const testedNumber = testNumber(newNumber);
          setValue(testedNumber);
        }}
      />
    </label>
  );
};

export const EnterNewString = ({
  title,
  value,
  setValue,
  testStringFn,
}: {
  title: string;
  value: string;
  setValue: (value: string) => void;
  testStringFn: (value: string) => string;
}) => {
  const [valueStr, setValueStr] = useState(value);

  useEffect(() => {
    if (valueStr !== value) {
      setValueStr(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <label>
      <span className="sr-only">{title}</span>
      <input
        className="simple-text-input"
        type="text"
        value={valueStr}
        onChange={(e) => {
          const newValuestr2 = e.target.value;
          setValueStr(newValuestr2);
        }}
        onBlur={() => {
          const testedString = testStringFn(valueStr);
          setValue(testedString);
        }}
      />
    </label>
  );
};

const EnterHsl: FC<{
  color: Color;
  setColor: (color: Color) => void;
}> = ({ color, setColor }) => {
  const hsl = colorToHsl(color);
  return (
    <div className="colour-picker form mt-05r">
      <div className="group mt-05r">
        <EnterNewValue
          title="h"
          value={hsl.h ?? 0}
          setValue={(h) => {
            if (h !== hsl.h) {
              const newHsl = { ...hsl, h };
              setColor(newHsl);
            }
          }}
          testNumber={(h) => {
            if (h < 0) {
              return 0;
            }
            if (h > 360) {
              return 360;
            }
            const hRounded = Math.round(h);
            return hRounded;
          }}
        />
        <EnterNewValue
          title="s"
          value={hsl.s}
          setValue={(s) => {
            if (s !== hsl.s) {
              const newHsl = { ...hsl, s };
              setColor(newHsl);
            }
          }}
          testNumber={(s) => {
            if (s < 0) {
              return 0;
            }
            if (s > 1) {
              return 1;
            }
            const sRounded = Math.round(s * 100) / 100;
            return sRounded;
          }}
        />
        <EnterNewValue
          title="l"
          value={hsl.l}
          setValue={(l) => {
            if (l !== hsl.l) {
              const newHsl = { ...hsl, l };
              setColor(newHsl);
            }
          }}
          testNumber={(l) => {
            if (l < 0) {
              return 0;
            }
            if (l > 1) {
              return 1;
            }
            const lRounded = Math.round(l * 100) / 100;
            return lRounded;
          }}
        />
      </div>
    </div>
  );
};

const EnterRgba: FC<{
  color: Color;
  setColor: (color: Color) => void;
}> = ({ color, setColor }) => {
  const rgb = colorToRgb(color);
  return (
    <div className="colour-picker form mt-05r">
      <div className="group mt-05r">
        <EnterNewValue
          title="r"
          value={rgb.r}
          setValue={(r) => {
            if (r !== rgb.r) {
              const newRgb = { ...rgb, r };
              setColor(newRgb);
            }
          }}
          testNumber={(r) => {
            if (r < 0) {
              return 0;
            }
            if (r > 255) {
              return 255;
            }
            const rRounded = Math.round(r);
            return rRounded;
          }}
        />
        <EnterNewValue
          title="g"
          value={rgb.g}
          setValue={(g) => {
            if (g !== rgb.g) {
              const newRgb = { ...rgb, g };
              setColor(newRgb);
            }
          }}
          testNumber={(g) => {
            if (g < 0) {
              return 0;
            }
            if (g > 255) {
              return 255;
            }
            const gRounded = Math.round(g);
            return gRounded;
          }}
        />
        <EnterNewValue
          title="b"
          value={rgb.b}
          setValue={(b) => {
            if (b !== rgb.b) {
              const newRgb = { ...rgb, b };
              setColor(newRgb);
            }
          }}
          testNumber={(b) => {
            if (b < 0) {
              return 0;
            }
            if (b > 255) {
              return 255;
            }
            const bRounded = Math.round(b);
            return bRounded;
          }}
        />
      </div>
    </div>
  );
};

const EnterHex: FC<{
  color: Color;
  setColor: (color: Color) => void;
}> = ({ color, setColor }) => {
  const hexStr = formatHex(color);
  return (
    <div className="colour-picker form mt-05r">
      <div className="group mt-05r">
        <EnterNewString
          title="hex"
          value={hexStr}
          setValue={(h) => {
            const newColor = parse(h);
            if (newColor && newColor !== color) {
              setColor(newColor);
            }
          }}
          testStringFn={(h) => {
            const newColor = parse(h);
            if (!newColor) {
              return hexStr;
            }
            return h;
          }}
        />
      </div>
    </div>
  );
};

export const ShowColor: React.FC<{
  color: Color;
  setColor: (color: Color) => void;
}> = ({ color, setColor }) => {
  const [selectedFormat, setSelectedFormat] = useState(eCFormat.hsl);
  return (
    <div className="mt-1r">
      <div className="group-title" style={{ backgroundColor: formatHsl(color) }}>
      <SelectSimpleNative
        label="Select Color format"
        dontShowLabel={true}
        options={[
          { value: eCFormat.hsl, label: "HSL" },
          { value: eCFormat.rgb, label: "RGB" },
          { value: eCFormat.hex, label: "HEX" },
        ]}
        selectedValue={selectedFormat}
        setSelectedValue={setSelectedFormat}
      />
      </div>
      {selectedFormat === eCFormat.hsl && (
        <EnterHsl color={color} setColor={setColor} />
      )}
      {selectedFormat === eCFormat.rgb && (
        <EnterRgba color={color} setColor={setColor} />
      )}
      {selectedFormat === eCFormat.hex && (
        <EnterHex color={color} setColor={setColor} />
      )}
    </div>
  );
};
