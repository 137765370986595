import { itemIF, metaIF } from "../../data/types/item";

// get fieldsorder of meta and metaAll, remove fields which are used in wrapperFn
export const getFieldsOrder = ({
  item,
  meta,
  disabledFields,
}: {
  item: itemIF;
  meta?: metaIF;
  disabledFields: string[];
}) => {
  if (!meta) return [];

  const fieldsOrder = meta.fieldsOrder ? [...meta.fieldsOrder] : ["main"];

  const fieldsOrderAll = fieldsOrder.filter(
    (fieldName) => item.value && item.value[fieldName]
  );

  // Add fields in item.value which are not in fieldsOrder
  if (item.value) {
    Object.keys(item.value).forEach((fieldName) => {
      if (!fieldsOrder.includes(fieldName)) {
        const fieldMeta = meta?.fields?.find((f) => f?.name === fieldName);
        if (fieldMeta) {
          fieldsOrder.push(fieldName);
        }
      }
    });
  }

  return fieldsOrderAll.filter(
    (fieldName) => !disabledFields.includes(fieldName)
  );
};
