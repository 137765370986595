import { metaIF, EMetaFieldTypes, wrapperForGroupIF } from "../../types/item";
import { fieldsAll } from "../../constants";
import { keyframesHelp } from "../../selectors/constants/animation";

const get0Or1OrValue = (val:string) => {
  const valNo = Number(val);
  return valNo < 0 ? "0" : valNo > 1 ? "1" : val;
}

const timingWrapperFn: wrapperForGroupIF = {
  name: "timingFnCurveOrSteps",
  fields: ["timing", "x1", "y1", "x2", "y2", "intervals", "direction"],
  wrapperFn: (valueObj) => {
    let timing = valueObj.timing;
    if (valueObj.timing === "cubic-bezier") {
      const x1 = get0Or1OrValue(valueObj.x1);
      const y1 = get0Or1OrValue(valueObj.y1);
      const x2 = get0Or1OrValue(valueObj.x2);
      const y2 = get0Or1OrValue(valueObj.y2);
      timing = `cubic-bezier(${x1},${y1},${x2},${y2})`;
    }
    if (valueObj.timing === "steps") {
      timing = `steps(${valueObj.intervals},${valueObj.direction})`;
    }
    if (valueObj.timing === "none") {
      return "";
    }
    return `${timing}`;
  },
};

export const CSS_PROPERTIES_ANIMATION: metaIF[] = [
  {
    name: "animation",
    level: "inline",
    category: ["ANIMATION"],
    allowedChildren: [],
    noCssProperties: true,
    availableFromLevel: 2,

    fieldsOrder: [
      "keyframeId",
      "duration",
      "timing",
      "intervals",
      "direction",
      "x1",
      "y1",
      "x2",
      "y2",
      "delay",
      "iteration",
      "iteration-count",
      "animationDirection",
      "fill-mode",
    ],

    fields: [
      {
        name: "keyframeId",
        type: EMetaFieldTypes.selectKeyframeName,
        onOff: true,
        help: keyframesHelp,
        warning:
          "### Change settings and restart animation  \n" +
          "To restart the animation after you have changed its settings, switch in the `Preview` from `Code` to `Design` view.",
      },
      {
        name: "duration",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: [{ id: "s" }],
      },
      fieldsAll.timing,
      fieldsAll.timingX1,
      fieldsAll.timingY1,
      fieldsAll.timingX2,
      fieldsAll.timingY2,
      fieldsAll.timingIntervals,
      fieldsAll.timingDirection,
      {
        name: "delay",
        type: EMetaFieldTypes.number,
        units: [{ id: "s" }],
        default: {
          value: 0,
          unit: "s",
        },
      },

      {
        name: "iteration",
        type: EMetaFieldTypes.select,
        options: [
          {
            id: "infinite",
          },
          {
            id: "value",
          },
        ],
      },
      {
        name: "iteration-count",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "iteration",
          values: ["value"],
        },
      },
      {
        name: "animationDirection",
        type: EMetaFieldTypes.select,
        options: [
          {
            id: "normal",
          },
          {
            id: "reverse",
          },
          {
            id: "alternate",
          },
          {
            id: "alternate-reverse",
          },
        ],
        help:
          "### direction  \n" +
          "See for example [w3schools - CSS animation-direction Property](https://www.w3schools.com/cssref/css3_pr_animation-direction.asp)",
        default: {
          value: "normal",
        },
      },
      {
        name: "fill-mode",
        type: EMetaFieldTypes.select,
        options: [
          { id: "none" },
          { id: "forwards" },
          { id: "backwards" },
          { id: "initial" },
          { id: "inherit" },
        ],
        help:
          "### animation-fill-mode  \n" +
          "See for example [w3schools - CSS animation-fill-mode Property](https://www.w3schools.com/csSref/css3_pr_animation-fill-mode.asp)",
        default: {
          value: "none",
        },
      },
    ],

    group: [
      {
        name: "selectAnimationG",
        display: ["select keyframe"],
        fields: ["keyframeId"],
      },
      {
        name: "durationG",
        fields: ["duration"],
        display: ["duration"],
      },
      {
        name: "timingG",
        fields: ["timing"],
        display: ["timing"],
      },
      {
        name: "xYStartXYEndG",
        fields: ["x1", "y1", "x2", "y2"],
        display: ["x1", "y1", "x2", "y2"],
        dependentOn: {
          field: "timing",
          values: ["cubic-bezier"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
      {
        name: "stepsG",
        fields: ["intervals", "direction"],
        display: ["steps", "direction"],
        dependentOn: {
          field: "timing",
          values: ["steps"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
      {
        name: "delayG",
        fields: ["delay"],
        display: ["delay"],
      },
      {
        name: "iterationG",
        fields: ["iteration", "iteration-count"],
        display: ["iteration", "count"],
      },
      {
        name: "directionG",
        fields: ["animationDirection"],
      },
      {
        name: "fillModeG",
        fields: ["fill-mode"],
        display: ["fill-mode"],
      },
    ],

    wrapperForGroup: [{ ...timingWrapperFn, name: "animation" }],

    default: {
      keyframeId: {
        value: 0,
      },
      duration: {
        value: 1,
        unit: "s",
      },
      timing: {
        value: "linear",
      },
      intervals: {
        value: 4,
      },
      direction: {
        value: "end",
      },
      x1: {
        value: 0.5,
      },
      y1: {
        value: 0,
      },
      x2: {
        value: 0.5,
      },
      y2: {
        value: 1,
      },
      delay: {
        value: 0,
        unit: "s",
      },
      iteration: {
        value: "infinite",
      },
      "iteration-count": {
        value: 2,
      },
      animationDirection: {
        value: "normal",
      },
      "fill-mode": {
        value: "none",
      },
    },
  },

  {
    name: "animation-delay",
    category: ["ANIMATION"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: [{ id: "s" }],
      },
    ],
    default: {
      main: {
        value: 0.5,
        unit: "s",
      },
    },
  },

  {
    name: "animation-play-state",
    category: ["ANIMATION"],

    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "paused",
          },
          {
            id: "running",
          },
        ],
        help:
          "### animation-play-state  \n" +
          "Can, for example, be used to pause an infinite running animation " +
          "in the class as default and then run it on hover.",
      },
    ],
    default: {
      main: {
        value: "paused",
      },
    },
  },
  {
    name: "animation-timing-function",
    category: ["ANIMATION"],
    fieldsOrder: ["timing", "x1", "y1", "x2", "y2", "intervals", "direction"],
    fields: [
      fieldsAll.timing,
      fieldsAll.timingX1,
      fieldsAll.timingY1,
      fieldsAll.timingX2,
      fieldsAll.timingY2,
      fieldsAll.timingIntervals,
      fieldsAll.timingDirection,
    ],
    group: [
      {
        name: "timingG",
        display: ["timing"],
        fields: ["timing"],
      },
      {
        name: "xYStartXYEnd",
        fields: ["x1", "y1", "x2", "y2"],
        display: ["x1", "y1", "x2", "y2"],
        dependentOn: {
          field: "timing",
          values: ["cubic-bezier"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
      {
        name: "steps",
        fields: ["intervals", "direction"],
        dependentOn: {
          field: "timing",
          values: ["steps"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
    ],

    wrapperForGroup: [{ ...timingWrapperFn, name: "animation-timing-function" }],

    default: {
      timing: { value: "ease" },
      x1: { value: 0.25 },
      y1: { value: 0.5 },
      x2: { value: 0.75 },
      y2: { value: 0.5 },
      intervals: { value: 3 },
      direction: { value: "end" },
    },
  },
];
