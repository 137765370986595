import { itemIF } from "../../data/types/item";
import { itemTypes } from "../meta/getMetas";
import {
  getAssignedSelectorIds,
  getAssignedSelectors,
  getAssignedClassNames,
} from "./getAssignedSelectorsNew";
import { addNewItemWithDescendants } from "./cloneItem";
import { getSelectorProperties } from "./addItems";
import { snippetIF } from "../../data/types/snippets";
import { getItemsOfType, replaceItemsAndEditItemIdOfType } from "../snippets/addAndGetMetas";

/*
check htmlsOSvgs assignedClasses for oldSelectorId and replace with newSelectorId
*/

export type TOldNewIds = { oldId: number; newId: number };
export const replaceAssignedClassId = ({
  htmlsOSvgs,
  oldNewIds,
}: {
  htmlsOSvgs: itemIF[];
  oldNewIds: TOldNewIds[];
}):itemIF[] => {
  const newHtmlsOSvgs:itemIF[] = htmlsOSvgs.map((h) => {
    const newAssignedClasses = h.assignedClasses?.map((ac) => {
      const hasOldId = oldNewIds.find((id) => id.oldId === ac);
      if (hasOldId) {
        return hasOldId.newId;
      }
      return ac;
    });
    return { ...h, assignedClasses: newAssignedClasses };
  });
  return newHtmlsOSvgs;
};

const addSelectors = ({
  assignedSelectors,
  snippetFrom,
  snippetTo,
}: {
  assignedSelectors: itemIF[];
  snippetFrom: snippetIF;
  snippetTo: snippetIF;
}) => {
  const queries = assignedSelectors.filter((s) => s.name === "@media");
  const queryKids = assignedSelectors.filter((s) =>
    queries.find((q) => q.id === s.parent)
  );
  const queryKidsIds = queryKids.map((k) => k.id);
  const assignedSelectorsNoQueryKids = assignedSelectors.filter(
    (s) => !queryKidsIds.includes(s.id)
  );

  let oldNewIds = [] as TOldNewIds[];
  let newSnippetTo: snippetIF = snippetTo;

  for (const s of assignedSelectorsNoQueryKids) {
    if (s.name === "@media") {
      // for @media selectors addNewItemWithDescendants always comes back just with the item, no descendants
      const qResult = addNewItemWithDescendants({
        itemType: itemTypes.SELECTORS,
        itemId: s.id,
        snippetFrom,
        snippetTo,
      });

      if (qResult?.oldNewIds) {
        oldNewIds = [...oldNewIds, ...qResult.oldNewIds];
      }

      newSnippetTo = qResult?.items
        ? replaceItemsAndEditItemIdOfType({
            itemType: itemTypes.SELECTORS,
            snippet: snippetTo,
            items: qResult?.items,
            editItemId: qResult?.editItemId,
          })
        : snippetTo;

      const queryKid = queryKids.find((k) => k.parent === s.id);
      const oNQuery = oldNewIds.find((on) => on.oldId === s.id);
      const query = qResult?.items.find((ns) => ns.id === oNQuery?.newId);

      if (queryKid && query) {
        const qkResult = addNewItemWithDescendants({
          itemType: itemTypes.SELECTORS,
          itemId: queryKid.id,
          itemToId: query.id,
          snippetFrom,
          snippetTo: newSnippetTo,
        });

        if (qkResult?.oldNewIds) {
          oldNewIds = [...oldNewIds, ...qkResult.oldNewIds];
        }

        if (qkResult?.items) {
          newSnippetTo = replaceItemsAndEditItemIdOfType({
            itemType: itemTypes.SELECTORS,
            snippet: newSnippetTo,
            items: qkResult?.items,
            editItemId: qkResult?.editItemId,
          });
        }
      }
    } else {
      const sResult = addNewItemWithDescendants({
        itemType: itemTypes.SELECTORS,
        itemId: s.id,
        snippetFrom,
        snippetTo,
      });

      if (sResult?.oldNewIds) {
        oldNewIds = [...oldNewIds, ...sResult.oldNewIds];
      }

      if (sResult?.items) {
        newSnippetTo = replaceItemsAndEditItemIdOfType({
          itemType: itemTypes.SELECTORS,
          snippet: snippetTo,
          items: sResult.items,
          editItemId: sResult?.editItemId,
        });
      }
    }
  }

  return {
    oldNewIds,
    newSnippetTo,
  }
};

export const addSelectorsAndProperties = ({
  itemType,
  htmlsOSvgs,
  snippetFrom,
  snippetTo,
}: {
  itemType: itemTypes;
  htmlsOSvgs: itemIF[];
  snippetFrom: snippetIF;
  snippetTo: snippetIF;
}):snippetIF => {
  const assignedIds = [] as number[];
  getAssignedSelectorIds({
    items: htmlsOSvgs,
    assignedIds,
  });

  if (assignedIds.length === 0) {
    return snippetTo;
  }

  const assignedClassNames = getAssignedClassNames({
    assignedSelectorIds: assignedIds,
    snippet: snippetFrom,
  });

  const assignedSelectors = getAssignedSelectors({
    assignedClassNames: assignedClassNames || [],
    snippet: snippetFrom,
  });

  /* above finds all same name and all dependent selectors of the selector to copy, but returns just the top selector in a branch on level 1. The descendants are added below.
  @media is an exception. Same name selectors are often found one on a certain level and the other on that level + 1 under the query selector.
  if you copy a selector and a same name selector is underneath a query selector, not just the top selector is found but on level query selector and on level 2 the same name selector as a child 

  filter out all selectors which are kids of queries
  loop through assignedSelectors, if you find a query, add first the query to new selectors and then its child, where you use the query as item to
  **/

  const { oldNewIds, newSnippetTo } = addSelectors({
    assignedSelectors,
    snippetFrom,
    snippetTo,
  });

  const newhtmlsOSvgs = replaceAssignedClassId({
    htmlsOSvgs,
    oldNewIds: oldNewIds,
  });

  const items = getItemsOfType({
    itemType,
    snippet: snippetFrom,
  })

  // replace html/svg with old classId with new classId 
  const newSnippetTo2 = replaceItemsAndEditItemIdOfType({
    itemType,
    snippet: newSnippetTo,
    items: items.map((i) => {
      const replacedClassIdItem =  newhtmlsOSvgs.find((h) => h.id === i.id);
      if (replacedClassIdItem) {
        return replacedClassIdItem;
      }
      return i;
    }),
    editItemId: newSnippetTo.data.editHtmlId,
  });

  const newSnippetTo3 = getSelectorProperties({
    oldNewIds: oldNewIds,
    snippetFrom,
    snippetTo: newSnippetTo2,
  });
  return newSnippetTo3
};
