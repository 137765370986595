import { itemIF, TShorthand } from "../../data/types/item";
import { getMetaFromSnippet } from "../snippets/addAndGetMetas";
import { itemTypes } from "../meta/getMetas";
import { snippetIF } from "../../data/types/snippets";
import { renderSingleItem } from "./renderSingleItem";

const getShortHandSpace = ({
  itemType,
  snippet,
  itemName,
  groupValueStr,
}: {
  itemType: itemTypes;
  snippet: snippetIF;
  itemName: string;
  groupValueStr: string;
}) => {
  const meta = getMetaFromSnippet({
    snippet,
    itemType,
    itemName,
  });
  const shorthandStr = meta?.addToShorthand;
  if (!shorthandStr) {
    return " ";
  }
  const groupShorthandSeparator = meta?.shorthandSeparator;

  const space =
    groupValueStr === ""
      ? ""
      : groupShorthandSeparator
      ? groupShorthandSeparator
      : meta.shorthandSeparator
      ? meta.shorthandSeparator
      : " ";

  return space;
};

export const buildValueStrForShorthandGroup = ({
  itemType,
  items,
  snippet,
}: {
  itemType: itemTypes;
  items: itemIF[];
  snippet: snippetIF;
}) => {
  const itemName = items[0].name;
  const meta = getMetaFromSnippet({
    snippet,
    itemType,
    itemName,
  });
  const shorthandStr = meta?.addToShorthand;
  if (!shorthandStr) {
    return;
  }

  const sorted = items.slice().sort((a, b) => a.position - b.position);
  const shortHandGroupFieldName = sorted[0].name;
  if(!shortHandGroupFieldName) {
    return;
  }

  let groupValueStr = "";
  for (const item of sorted) {
    const renderedSingleItem = renderSingleItem({
      itemType,
      item,
      snippet,
    });
    if(!renderedSingleItem) {
      continue;
    }

    const space = getShortHandSpace({
      itemType,
      snippet,
      itemName: itemName,
      groupValueStr,
    });

    const singleItemValueStr = renderedSingleItem?.[shortHandGroupFieldName]?.[0];
    if(singleItemValueStr) {
      groupValueStr += space + singleItemValueStr;
    }
  }

  if (groupValueStr.trim() === "") {
    return;
  }

  const sh = {} as TShorthand;

  if (!sh[shorthandStr]) {
    sh[shorthandStr] = [] as string[];
  }
  sh[shorthandStr].push(
    `${sh[shorthandStr].length === 0 ? "" : ", "}${groupValueStr}`
  );

  const firstAppearancePosition = sorted[0].position;

  const shortHandAndPosition = {
    pos: firstAppearancePosition,
    sh,
  }

  return shortHandAndPosition;
};
