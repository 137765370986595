import { FC, useState } from "react";
import { itemIF, metaIF } from "../../data/types/item";
import { useStateContext } from "../../lib/state/state";
import { setNewItemValue } from "../../lib/reducer/items/actions";
import { itemTypes } from "../../lib/meta/getMetas";
import { snippetIF } from "../../data/types/snippets";
import { getMetaFromSnippet } from "../../lib/snippets/addAndGetMetas";

type TStringInput = {
  itemType: itemTypes;
  fieldName: string;
  isFieldDisabled?: boolean;
  editItem: itemIF;
  addOrRemoveEditItemId?: number;
  fieldGroupName?: string;
  editSnippetIdOther?: string;
  snippet: snippetIF;
};

const getDisplayName = ({
  fieldName,
  meta,
}: {
  fieldName: string;
  meta?: metaIF;
}) => {
  const group = meta?.group?.find((g) => g.fields.includes(fieldName));
  if (!group) return fieldName;

  const fieldIndex = group.fields.indexOf(fieldName);
  const displayName = group.display?.[fieldIndex];
  if (displayName) {
    return displayName;
  }
  return fieldName;
};

export const NumberInput: FC<TStringInput> = ({
  itemType,
  fieldName,
  isFieldDisabled,
  editItem,
  addOrRemoveEditItemId,
  fieldGroupName,
  editSnippetIdOther,
  snippet,
}) => {
  const { dispatch } = useStateContext();

  const meta = getMetaFromSnippet({
    snippet: snippet,
    itemType: itemType,
    itemName: editItem.name,
  });
  const fieldMeta = meta?.fields?.find((f) => f?.name === fieldName);
  const displayName = getDisplayName({ fieldName, meta });

  const valueObj = editItem.value?.[fieldName];
  const fieldValue = valueObj?.value ?? 0;
  const [newValue, setNewValue] = useState(fieldValue);

  if (!fieldMeta) return null;

  const conditions = fieldMeta.conditions;
  if (conditions) {
    if (conditions.min || conditions.min === 0) {
      if ((newValue || newValue === 0) && newValue < conditions.min) {
        return;
      }
    }
    if (conditions.max || conditions.max === 0) {
      if (newValue > conditions.max) {
        return;
      }
    }
  }

  const unit = valueObj?.unit;

  return (
    <div className="unit-box">
      <input
        className="input"
        placeholder={`Enter ${displayName ?? "value"}`}
        type="number"
        disabled={isFieldDisabled}
        value={newValue}
        onChange={(e) => {
          const newValue = e.target.value;
          const numberValue = Number(newValue);
          if (numberValue || numberValue === 0) setNewValue(numberValue);
        }}
        onBlur={() => {
          if (fieldValue === newValue) return;

          dispatch(
            setNewItemValue({
              addOrRemoveEditItemId,
              editItemId: editItem.id,
              fieldGroupName,
              fieldName: fieldMeta.name,
              itemType,
              value: newValue,
              editSnippetId: editSnippetIdOther,
            })
          );
        }}
      />

      {fieldMeta.units && unit && (
        <label className="label unit">
          <span>unit</span>
          <select
            title="select"
            value={unit}
            disabled={isFieldDisabled}
            onChange={(e) => {
              const optionId = e.target.value;
              dispatch(
                setNewItemValue({
                  addOrRemoveEditItemId,
                  editItemId: editItem.id,
                  fieldGroupName,
                  fieldName: fieldMeta.name,
                  itemType,
                  value: optionId,
                  valueType: "unit",
                  editSnippetId: editSnippetIdOther,
                })
              );
            }}
          >
            {fieldMeta.units.map((option, i) => (
              <option key={i} value={option.id}>
                {option.id}
              </option>
            ))}
          </select>
        </label>
      )}
    </div>
  );
};
