import axios from "axios";
import { snippetIF } from "../../data/types/snippets";
import { QueryGetSnippetsArgs, Snippet } from "cssexy-be/src/generated/graphql";

const GET_SNIPPETS_QUERY = `
  query GetSnippets($filter: SnippetFilterInput, $limit: Int, $sort: SnippetSortInput, $nextToken: String) {
    getSnippets(filter: $filter, limit: $limit, sort: $sort, nextToken: $nextToken) {
      items {
        id
        copyCount
        data
        description
        name
        owner
        public
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

const graphqlEndpoint = process.env.REACT_APP_SNIPPETS_API_URL || "";
const apiKey = process.env.REACT_APP_SNIPPETS_API_KEY || "";

export const loadSnippets = async ({
  variables,
}: {
  variables: QueryGetSnippetsArgs;
}): Promise<{ snippets: snippetIF[]; nextToken?: string }> => {

  const requestPayload = {
    query: GET_SNIPPETS_QUERY,
    variables,
  };

  const response = await axios.post(
    graphqlEndpoint,
    JSON.stringify(requestPayload),
    {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": apiKey,
      },
    }
  );

  const snippetsAndToken = response?.data?.data?.getSnippets;
  const snippets: snippetIF[] = snippetsAndToken?.items.filter(
    (s: Snippet) => s
  );
  const snippetsWithParsedData = snippets.map((s) => {
    return {
      ...s,
      data: !s.data
        ? {}
        : typeof s.data === "string"
        ? JSON.parse(s.data)
        : s.data,
    };
  });

  const nextToken = snippetsAndToken?.nextToken;
  return { snippets: snippetsWithParsedData, nextToken };
};

const theFetch = async ({
  variables,
  fetchedSnippets,
}: {
  variables: QueryGetSnippetsArgs;
  fetchedSnippets: snippetIF[];
}) => {
  const requestPayload = {
    query: GET_SNIPPETS_QUERY,
    variables,
  };

  const response = await axios.post(
    graphqlEndpoint,
    JSON.stringify(requestPayload),
    {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": apiKey,
      },
    }
  );

  const snippetsAndToken = response?.data?.data?.getSnippets;
  const snippets: snippetIF[] = snippetsAndToken?.items.filter(
    (s: Snippet) => s
  );
  snippets?.forEach((s) => {
    if (s.id && !fetchedSnippets.find((fs) => fs.id === s.id)) {
      const newS = {
        ...s,
        data: !s.data
          ? {}
          : typeof s.data === "string"
          ? JSON.parse(s.data)
          : s.data,
      };
      fetchedSnippets.push(newS);
    }
  });

  const nextToken = snippetsAndToken?.nextToken;

  if (nextToken) {
    variables.nextToken = nextToken;
    await theFetch({
      variables,
      fetchedSnippets,
    });
  }
};

export const getSnippetsFromServer = async (
  variables: QueryGetSnippetsArgs
) => {
  try {
    const fetchedSnippets: snippetIF[] = [];
    await theFetch({
      variables,
      fetchedSnippets,
    });

    if (!fetchedSnippets) {
      return [];
    }

    return fetchedSnippets;
  } catch (error) {
    console.error(error);
    return [];
  }
};
