import { itemIF } from "../../data/types/item";
import { snippetIF } from "../../data/types/snippets";
import { getAssignedSelectorIds } from "./getAssignedSelectorsNew";
import { getCssForSelectorIds } from "../render/css/getCssForDataSelectorIdsFontsSnippet";

export const getAncestorIds = ({
  items,
  item,
  ancestorIds,
}: {
  items: itemIF[];
  item: itemIF;
  ancestorIds: number[];
}) => {
  const parent = items.find((i) => item.parent === i.id);
  if (parent) {
    ancestorIds.push(parent.id);
    getAncestorIds({ items, item: parent, ancestorIds });
  }
};

export const getAncestors = ({
  item,
  items,
  ancestors,
}: {
  item: itemIF;
  items: itemIF[];
  ancestors: itemIF[];
}) => {
  const parent = items.find((i) => i.id === item.parent);
  if (parent) {
    ancestors.push(parent);
    getAncestors({
      item: parent,
      items,
      ancestors,
    });
  }
};

export const getKidsIds = ({
  item,
  items,
  kidsIds,
}: {
  item: itemIF;
  items: itemIF[];
  kidsIds: number[];
}) => {
  const children = items.filter((i) => i.parent === item.id);

  children.forEach((c) => {
    kidsIds.push(c.id);
    getKidsIds({ item: c, items, kidsIds });
  });
};

export const getKids = ({
  item,
  items,
  kids,
}: {
  item: itemIF;
  items: itemIF[];
  kids: itemIF[];
}) => {
  const children = items.filter((i) => i.parent === item.id);
  children.forEach((c) => {
    kids.push(c);
    getKids({
      item: c,
      items,
      kids,
    });
  });
};



export const getSvgCss = ({
  svg,
  snippet,
}: {
  svg: itemIF;
  snippet: snippetIF;
}) => {
  const svgAndChildrenIds = [svg.id];
  getKidsIds({
    item: svg,
    items: snippet.data.svgs,
    kidsIds: svgAndChildrenIds,
  });
  const assignedIds = [] as number[];
  getAssignedSelectorIds({
    items: snippet.data.svgs.filter((s) => svgAndChildrenIds.includes(s.id)),
    assignedIds,
  });

  const svgCss = getCssForSelectorIds({
    assignedSelectorIds: assignedIds,
    snippet,
    addSpaceAtLineStart: "               ",
  });

  return svgCss;
};

export const getTopItem = ({
  item,
  items,
}: {
  item: itemIF;
  items: itemIF[];
}): itemIF => {
  if (item?.parent === 0) {
    return item;
  }
  const parent = items?.find((s) => s.id === item?.parent);
  if (parent) {
    if (parent.parent === 0) {
      return parent;
    }
    return getTopItem({ item: parent, items });
  }
  return item;
};