import React, { FC } from "react";
import { useStateContext } from "../../lib/state/state";
import { itemTypes, getMeta } from "../../lib/meta/getMetas";
import { itemIF } from "../../data/types/item";
import { ReactComponent as ArrowDown } from "../../assets/imgs/arrow-down.svg";
import { ReactComponent as ArrowUp } from "../../assets/imgs/arrow-up.svg";
import { ReactComponent as Close } from "../../assets/imgs/close.svg";
import { setEditCssProperty } from "../../lib/reducer/items/actions";
import {
  EditHtmlCodePreview,
  SelectorCodePreview,
} from "../render/EditItemCode";
import {
  useDivPosition,
} from "../modules/GetPositionWrapper";

/*
dontShowCodePreview: when a snippet is selected to copy HTML or SVG, the code preview is more in the way than helpful, so it is not shown
* */

export const RenderTreeButtonAndChildren: FC<{
  itemType: string;
  item: itemIF;
  editItem?: itemIF;
  editFn: (...args: any[]) => void;
  openItemsIds?: number[];
  dontShowCodePreview?: boolean;
}> = ({
  itemType,
  item,
  editItem,
  editFn,
  openItemsIds,
  dontShowCodePreview,
}) => {
  const {
    state: {
      settings: { selectedCategory },
    },
    dispatch,
  } = useStateContext();

  let isDisabled = false;
  // on page CSS, in Selector Tree -> disable media queries, you don't add CSS to the media query but to the child class
  if (itemType === itemTypes.CLASSES_CSS && item.htmlName === "@media") {
    isDisabled = true;
  }

  const btnOnClick = () => {
    // dependent on the view is the edit snippet the one selected in SNIPPETS, TEMPLATES or CONTENTS
    editFn(item);

    if (itemType === itemTypes.SELECTORS) {
      dispatch(
        setEditCssProperty({
          newEditItemId: item.id,
          selectedCategory: selectedCategory || "ALL",
        })
      );
    }
  };

  const editItemId = editItem ? editItem.id : 0;
  let prefixName;
  if (item.prefix) {
    const prefixMeta = getMeta(itemTypes.CONNECTOR, item.prefix.name);
    prefixName = prefixMeta?.display ? prefixMeta.display : item.prefix.name;
  }

  const displayChildren = !openItemsIds
    ? true
    : openItemsIds.includes(item.id)
      ? true
      : false;
  const RenderTreeBtnChildren = [] as React.ReactElement[];
  if (displayChildren && item.children && item.children?.length > 0) {
    item.children?.forEach((c) => {
      RenderTreeBtnChildren.push(
        <RenderTreeButtonAndChildren
          key={c.id}
          itemType={itemType}
          item={c}
          editItem={editItem}
          editFn={editFn}
          openItemsIds={openItemsIds}
          dontShowCodePreview={dontShowCodePreview}
        />
      );
    });
  }

  const { divRef, divPos } = useDivPosition();

  return (
    <React.Fragment key={item.id}>
      <div className="html-tree-row" ref={divRef}>
        <button
          className={`button small icon gray-outline disabled`}
          onClick={() => {}}
        >
          <span className="sr-only">{`  branch`}</span>
          {item.children?.length === 0 ? (
            <Close />
          ) : openItemsIds?.includes(item.id) ? (
            <ArrowUp />
          ) : (
            <ArrowDown />
          )}
        </button>
        <span className={`m-btn-box${item.level ? ` level${item.level}` : ""}`}>
          {item.prefix && (
            <button
              type="button"
              className={`button small ${item.id === editItemId ? "blue" : "blue-outline"}${isDisabled ? " disabled" : ""}`}
              onClick={btnOnClick}
            >
              <span>{prefixName}</span>
            </button>
          )}
          <button
            type="button"
            className={`button small ${item.id === editItemId ? "blue" : "blue-outline"}${isDisabled ? " disabled" : ""}`}
            onClick={btnOnClick}
            data-id={item.id}
          >
            <span>{item.name}</span>
          </button>
        </span>
        {!dontShowCodePreview &&
          editItemId === item.id &&
          (itemType === itemTypes.BLOCK_HTML || itemType === itemTypes.SVGS ? (
            <EditHtmlCodePreview
              editHtmlId={editItemId}
              itemType={itemType}
              parentPosition={divPos}
            />
          ) : (
            <SelectorCodePreview itemType={itemTypes.SELECTORS} parentPosition={divPos} />
          ))}
      </div>
      {RenderTreeBtnChildren}
    </React.Fragment>
  );
};
