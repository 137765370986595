import {
  canBeFields,
  itemIF,
  metaFieldIF,
  metaIF,
} from "../../data/types/item";
import { snippetIF } from "../../data/types/snippets";
import { getValueStr } from "../items/getItemData";
import { itemTypes } from "../meta/getMetas";
import { getItemsOfType } from "../snippets/addAndGetMetas";

const getKeyframeId = ({
  fieldMeta,
  item,
  snippet,
}: {
  fieldMeta: metaFieldIF;
  item: itemIF;
  snippet: snippetIF;
}) => {
  if (fieldMeta.name === "keyframeId") {
    if (item.value?.keyframeId.value === 0) {
      return "none";
    }
    const selectors = getItemsOfType({
      snippet,
      itemType: itemTypes.SELECTORS,
    });
    const keyFrameSelector = selectors?.find(
      (s) => s.id === item.value?.keyframeId.value
    );

    if (keyFrameSelector?.value?.main?.value) {
      return keyFrameSelector.value.main.value;
    }
  }
};

type TAttr = { [key: string]: string };

export const replaceFieldNameForDependentCanBeFieldsWithMainFieldName = ({
  meta,
  item,
  attr,
}: {
  meta: metaIF;
  item: itemIF;
  attr: TAttr;
}): Record<string, string> => {
  const newAttr: { [key: string]: string } = {};
  const fieldNames = Object.keys(attr);
  for (const fieldName of fieldNames) {
    const fieldMeta = meta.fields?.find((f) => f.name === fieldName);

    if (!fieldMeta) {
      newAttr[fieldName] = attr[fieldName];
      continue;
    }

    const endsWithValue = fieldName.endsWith("Value");
    const endsWithCalculate = fieldName.endsWith("Calculate");
    if (!endsWithValue && !endsWithCalculate) {
      newAttr[fieldName] = attr[fieldName];
      continue;
    }

    const mainFieldName = fieldName
      .replace("Value", "")
      .replace("Calculate", "");
    const mainField = meta.fields?.find((f) => f.name === mainFieldName);
    if (!mainField) {
      newAttr[fieldName] = attr[fieldName];
      continue;
    }

    const fieldMetaKeys = Object.keys(mainField) as (keyof metaFieldIF)[];
    const hasCanBeFields = fieldMetaKeys.some((key) =>
      canBeFields.includes(key)
    );
    if (!hasCanBeFields) {
      newAttr[fieldName] = attr[fieldName];
      continue;
    }

    const mainFieldValue = item.value?.[mainFieldName];
    if (!mainFieldValue) {
      newAttr[fieldName] = attr[fieldName];
      continue;
    }

    if (endsWithValue && mainFieldValue.value === "value") {
      newAttr[mainFieldName] = attr[fieldName];
    } else if (endsWithCalculate && mainFieldValue.value === "calculate") {
      newAttr[mainFieldName] = attr[fieldName];
    } else {
      newAttr[fieldName] = attr[fieldName];
    }
  }

  return newAttr;
};

export const getFieldValueStr = ({
  itemType,
  fieldMeta,
  item,
  snippet,
}: {
  itemType: itemTypes;
  fieldMeta: metaFieldIF;
  item: itemIF;
  snippet: snippetIF;
}): string | undefined => {
  if (itemType === itemTypes.SELECTORS) {
    const keyframeId = getKeyframeId({ fieldMeta, item, snippet });
    if (keyframeId) {
      return keyframeId;
    }
  }

  const fieldName = fieldMeta.name;

  if (!item?.value || !item?.value[fieldName]) {
    return;
  }

  let valObj = item.value[fieldName];
  if (!valObj) return;

  let valueStr = fieldMeta.wrapperFn
    ? fieldMeta.wrapperFn(valObj)
    : getValueStr({
        valObj,
      });

  if (valueStr || valueStr === "" || valueStr === "0") {
    return valueStr;
  }
};
