import { useState, useEffect } from "react";
import { replaceAHref } from "../../lib/render/replaceAHref";
import { snippetIF } from "../../data/types/snippets";
import { SUB_PAGES } from "../../data/settings/constants";
import "./Preview.css";

export const useSnippetHtml = ({
  snippet,
  isSvgPreview,
  selectedSubPage,
}: {
  snippet: snippetIF;
  isSvgPreview?: boolean;
  selectedSubPage?: SUB_PAGES;
}) => {
  const [sName, setSName] = useState(snippet.name);
  const htmlString =
    (isSvgPreview ? snippet.htmlStringSvg : snippet.htmlString) ||
    "<div>SNIPPET IS EMPTY</div>";
  const [sHtml, setSHtml] = useState(replaceAHref(htmlString));
  const [sHeadCss, setSHeadCss] = useState(snippet.htmlSelectorCss);
  const [sCss, setSCss] = useState(snippet.snippetCss);
  const [sFonts, setFonts] = useState(snippet.googleFontImportString);

  useEffect(() => {
    setSName(snippet.name);
    const htmlString =
      (isSvgPreview ? snippet.htmlStringSvg : snippet.htmlString) ||
      "<div>SNIPPET IS EMPTY</div>";
    setSHtml(replaceAHref(htmlString));
    setSHeadCss(snippet.htmlSelectorCss);
    setSCss(snippet.snippetCss);
    setFonts(snippet.googleFontImportString);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snippet]);

  useEffect(() => {
    const htmlString =
      (isSvgPreview ? snippet.htmlStringSvg : snippet.htmlString) ||
      "<div>SNIPPET IS EMPTY</div>";
    setSHtml(replaceAHref(htmlString));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubPage, snippet.data.editSvgId]);

  return { sName, sHtml, sHeadCss, sCss, sFonts };
};
