import React, { FC, useRef } from "react";
import { useOutsideAlerter } from "../atoms/detectClickOutside";

export const Notification: FC<{
  msg: string;
  notificationPosition?: "right" | "left";
  setShowMsg: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ notificationPosition, setShowMsg, msg }) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter({ ref: wrapperRef, setOpen: setShowMsg });

  const notificationPositionStr = notificationPosition
    ? notificationPosition === "right"
      ? " right"
      : " left"
    : "";

  return (
    <div
      className={`notification${
        msg.includes("Error") ? " warning" : ""
      }${notificationPositionStr}`}
      ref={wrapperRef}
    >
      {msg}
    </div>
  );
};
