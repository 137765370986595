import { useState, FC } from "react";
import { useStateContext } from "../../lib/state/state";
import { TMsgType, TFormData, TUserState } from "../../lib/signinup/helpers";
import { sendForgottenPassword } from "../../lib/signinup/sendForgottenPassword";
import { validateEmail } from "../../lib/utils/validateEmail";
import { InputWithValidation } from "./InputWithValidation";
import { FormInputMessage } from "../atoms/formErrOrSuccessMessage";
import { AccountWrapper } from "./Account";

import "./Account.css";

export type SignUpProps = {
  setSignType: (signType: TUserState) => void;
};

export const ResetPassword: FC<SignUpProps> = ({ setSignType }) => {
  const { dispatch } = useStateContext();
  const [formData, setFormData] = useState({
    email: { data: "", errorMessage: "" },
    verify: { data: "", errorMessage: "" },
  } as TFormData);
  const [signInErrorMessage, setSignInErrorMessage] = useState({} as TMsgType);

  const isSubmitButtonDisabled =
    formData.email.data === "" || formData.email.errorMessage !== "";

  return (
    <AccountWrapper title="Reset your password">
      <>
        <div className="login-form-field mt-2r">
          <InputWithValidation
            inputData={formData.email}
            label="Your email"
            onChange={(newData: string) => {
              setFormData({
                ...formData,
                email: {
                  ...formData.email,
                  data: newData,
                  errorMessage: validateEmail(newData)
                    ? ""
                    : "Enter a valid email address",
                },
              });
            }}
          />
          <FormInputMessage msg={{ errMsg: formData.email.errorMessage }} />
        </div>

        <div className="login-form-field mt-3r mb-1r">
          <button
            type="submit"
            aria-label="Reset your password"
            disabled={isSubmitButtonDisabled}
            className={`login-button primary${isSubmitButtonDisabled ? " disabled" : ""}`}
            onClick={() => {
              if (!isSubmitButtonDisabled) {
                sendForgottenPassword({
                  formData,
                  setFormData,
                  setSignType,
                  setSignInErrorMessage,
                  dispatch,
                });
              }
            }}
          >
          Reset password
          </button>
          <FormInputMessage msg={signInErrorMessage} />
        </div>
      </>
    </AccountWrapper>
  );
};
