import { FC, useEffect, useState, useRef } from "react";
import { itemIF, metaFieldIF } from "../../data/types/item";
import { PredictiveSearch } from "./PredictiveSearch/Main2";
import { useStateContext } from "../../lib/state/state";
import { setNewItemValue } from "../../lib/reducer/items/actions";
import { itemTypes } from "../../lib/meta/getMetas";
import { changeSnippet } from "../../lib/reducer/snippet/actions";

type TStringInput = {
  itemType?: itemTypes;
  fieldMeta: metaFieldIF;
  isFieldDisabled?: boolean;
  addOrRemoveEditItemId?: number;
  editItem: itemIF;
  fieldGroupName?: string;
  editSnippetIdOther?: string;
};

export const StringInput: FC<TStringInput> = ({
  itemType,
  fieldMeta,
  isFieldDisabled,
  addOrRemoveEditItemId,
  editItem,
  fieldGroupName,
  editSnippetIdOther,
}) => {
  const { dispatch } = useStateContext();

  const fieldValue = editItem.value?.[fieldMeta.name]?.value;

  const hasAutocomplete = fieldMeta.autocomplete;
  const [newValue, setNewValue] = useState(fieldValue || "");
  const [autocompleteListOn, setAutocompleteListOn] = useState(false);
  const [autocompleteValue, setAutocompleteValue] = useState("");
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setNewValue(fieldValue || "");
  }, [fieldValue]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setAutocompleteListOn(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [autocompleteListOn, wrapperRef]);

  return (
    <div ref={wrapperRef}>
      <input
        title="Enter string"
        type="text"
        className="input"
        disabled={isFieldDisabled}
        value={newValue}
        onChange={(e) => {
          setNewValue(e.target.value);
        }}
        onFocus={() => {
          if (hasAutocomplete && !autocompleteListOn) {
            setAutocompleteListOn(true);
          }

          if (autocompleteValue === "") return;
          setNewValue(autocompleteValue);
          setAutocompleteValue("");
        }}
        onBlur={() => {
          if (newValue === fieldValue) return;

          dispatch(
            setNewItemValue({
              addOrRemoveEditItemId,
              editItemId: editItem.id,
              fieldGroupName,
              fieldName: fieldMeta.name,
              itemType,
              value: newValue,
              editSnippetId: editSnippetIdOther,
            })
          );
        }}
      />
      {hasAutocomplete && autocompleteListOn && (
        <PredictiveSearch
          autocomplete={hasAutocomplete}
          maxSuggestions={10}
          parentInput={newValue}
          setAutocompleteValue={setAutocompleteValue}
        />
      )}
    </div>
  );
};

export const StringInputSnippet: FC<{
  fieldName: string;
  isFieldDisabled?: boolean;
  fieldValue: string;
}> = ({ fieldName, isFieldDisabled, fieldValue }) => {
  const { dispatch } = useStateContext();

  const [newValue, setNewValue] = useState(fieldValue || "");
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setNewValue(fieldValue || "");
  }, [fieldValue]);

  return (
    <div ref={wrapperRef}>
      <input
        title="Enter string"
        type="text"
        className="input"
        disabled={isFieldDisabled}
        value={newValue}
        onChange={(e) => {
          setNewValue(e.target.value);
        }}
        onBlur={() => {
          if (newValue === fieldValue) return;

          dispatch(
            changeSnippet({
              fieldName: "name",
              value: newValue,
            })
          );
        }}
      />
    </div>
  );
};
