import {
  Color,
  formatHex,
  formatRgb,
  formatHsl,
  converter,
  Hsl,
  Rgb,
} from "culori";
import { tHsla } from "./color";

export enum eHsl {
  hue = "h",
  saturation = "s",
  lightness = "l",
  transparency = "a",
}

export enum eCFormat {
  hex = "hex",
  rgb = "rgb",
  hsl = "hsl",
  oklch = "oklch",
  unknown = "unknown",
}

export const colorToHsl = (color: Color): Hsl => {
  const toHsl = converter("hsl");
  const hsl = toHsl(color);
  const hslWithDefaults: Hsl = {
    ...hsl,
    h: hsl.h ?? 0,
    alpha: hsl.alpha || hsl.alpha === 0 ? hsl.alpha : 1,
  };
  return hslWithDefaults;
};

export const colorToRgb = (color: Color): Rgb => {
  const toRgb = converter("rgb");
  const rgb = toRgb(color);
  const rgbWithDefaults: Rgb = {
    ...rgb,
    alpha: rgb.alpha || rgb.alpha === 0 ? rgb.alpha : 1,
  };
  return rgbWithDefaults;
};

export const convertHslaToCulori = (hsla: tHsla): Color => {
  const { h, s, l, a } = hsla;
  return { mode: "hsl", h, s, l, alpha: a };
};

export const colorsAreEqual = ({
  color1,
  color2,
  tolerance = 1e-6,
}: {
  color1: Color;
  color2: Color;
  tolerance?: number;
}): boolean => {
  // Convert both colors to a linear RGB representation
  const toLrgb = converter("lrgb");
  const lrgb1 = toLrgb(color1);
  const lrgb2 = toLrgb(color2);

  // If conversion fails, assume they're not equal
  if (!lrgb1 || !lrgb2) return false;

  // Compare each channel and the alpha (defaulting alpha to 1 if missing)
  return (
    Math.abs(lrgb1.r - lrgb2.r) < tolerance &&
    Math.abs(lrgb1.g - lrgb2.g) < tolerance &&
    Math.abs(lrgb1.b - lrgb2.b) < tolerance &&
    Math.abs((lrgb1.alpha ?? 1) - (lrgb2.alpha ?? 1)) < tolerance
  );
};

const setNewHslaValue = ({
  newValue,
  type,
  hsl,
}: {
  type: eHsl;
  newValue: number;
  hsl: Hsl;
}): Hsl => {
  if (type === eHsl.hue) {
    const hRounded = Math.round(newValue * 360);
    return { mode: "hsl", h: hRounded, s: 1, l: 0.5, alpha: 1 };
  }
  if (type === eHsl.saturation) {
    const sRounded = Math.round(newValue * 100) / 100;
    return { ...hsl, s: sRounded };
  }
  if (type === eHsl.lightness) {
    const lRounded = Math.round(newValue * 100) / 100;
    return { ...hsl, l: lRounded };
  }

  const aRounded = Math.round(newValue * 100) / 100;
  return { ...hsl, alpha: aRounded };
};

export const getNewColor = ({
  type,
  newValue,
  color,
}: {
  type: eHsl;
  newValue: number;
  color: Color;
}): Color | undefined => {
  const toHsl = converter("hsl");
  const hsl = toHsl(color);
  if (!hsl) {
    console.error("Failed to convert color to HSLA");
    return;
  }
  const newHsl = setNewHslaValue({ newValue, type, hsl });
  const newColor: Color = { ...newHsl, mode: "hsl" };
  return newColor;
};

export const detectColorFormat = (input: string): eCFormat => {
  const hexRegex = /^#([A-Fa-f0-9]{3,4}|[A-Fa-f0-9]{6}|[A-Fa-f0-9]{8})$/;
  const rgbRegex = /^rgba?\(/;
  const hslRegex = /^hsla?\(/;
  const oklchRegex = /^oklch\(/;

  if (hexRegex.test(input)) {
    return eCFormat.hex;
  } else if (rgbRegex.test(input)) {
    return eCFormat.rgb;
  } else if (hslRegex.test(input)) {
    return eCFormat.hsl;
  } else if (oklchRegex.test(input)) {
    return eCFormat.oklch;
  }
  return eCFormat.unknown;
};

const formatOklch = ({
  color,
  precision = 2,
}: {
  color: Color;
  precision?: number;
}): string => {
  const toOklch = converter("oklch");
  const oklchColor = toOklch(color);
  if (!oklchColor) {
    throw new Error("Failed to convert color to OKLCH");
  }

  // Format the properties with the given precision
  const l = oklchColor.l.toFixed(precision);
  const c = oklchColor.c.toFixed(precision);
  const h = oklchColor.h?.toFixed(precision) ?? 0;

  // Check if there's an alpha channel that isn't opaque
  if (typeof oklchColor.alpha === "number" && oklchColor.alpha !== 1) {
    const alpha = oklchColor.alpha.toFixed(precision);
    return `oklch(${l} ${c} ${h} / ${alpha})`;
  }

  return `oklch(${l} ${c} ${h})`;
};

export const formatColor = ({
  color,
  format,
}: {
  color: Color;
  format: eCFormat;
}): string => {
  switch (format) {
    case "hex":
      return formatHex(color);
    case "rgb":
      return formatRgb(color);
    case "hsl":
      return formatHsl(color);
    case "oklch":
      return formatOklch({ color });
    default:
      return formatHex(color);
  }
};