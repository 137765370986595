import { itemIF, TSelectorData } from "../../../data/types/item";
import { snippetIF } from "../../../data/types/snippets";
import { getAssignedClassNames } from "../../items/getAssignedSelectorsNew";
import { getCssForClassOrHtml } from "./getSelectorData";
import {
  getMediaSelectorsWithAssignedClassChild,
  getGroupedAndSortMediaSelectors,
  getCssStrFromSortedSData,
} from "./groupAndSortMediaSelectorNameNew";
import {
  getChildrenSelectors,
  getAssignedAnimations,
} from "../../items/getAssignedSelectorsNew";
import {
  getKeyframesAndStepsForAssignedAnimations,
  getKeyframesSData,
  getKeyframesAndStepsCss,
} from "./animation";

const getCssForSDatas = ({
  keyframesAndStepsSDataS,
  assignedSelectorsPlusChildrenWithData,
  sortedMediasSDataS,
  addSpaceAtLineStart = "",
  lineBreak = "",
}: {
  keyframesAndStepsSDataS: TSelectorData[];
  assignedSelectorsPlusChildrenWithData: TSelectorData[];
  sortedMediasSDataS: TSelectorData[];
  addSpaceAtLineStart?: string;
  lineBreak?: string;
}) => {
  const keyframeCss = getKeyframesAndStepsCss({
    keyframesAndStepsSDataS,
    lineBreak,
    addSpaceAtLineStart,
  });

  let assignedSelectorsCssStr = "";
  assignedSelectorsPlusChildrenWithData.forEach((sData) => {
    assignedSelectorsCssStr += `${
      assignedSelectorsCssStr === "" ? lineBreak : ""
    }${addSpaceAtLineStart}${sData.nameStr} {${lineBreak}${
      sData.css
    }${addSpaceAtLineStart}}${lineBreak}`;
  });

  const mediaCssStr = getCssStrFromSortedSData({
    sortedMediasSDataS,
    lineBreak,
    addSpaceAtLineStart,
  });

  return `${keyframeCss}${assignedSelectorsCssStr}${mediaCssStr}`;
};

const getSDataSForSelectorIds = ({
  assignedSelectorIds,
  snippet,
  addSpaceAtLineStart = "",
  keyframesAndStepsSDataSSnippets,
  assignedSelectorsPlusChildrenWithData,
  mediaSelectorsPlusChildClassesWithDataSorted,
}: {
  assignedSelectorIds: number[];
  snippet: snippetIF;
  addSpaceAtLineStart?: string;
  googleFamiliesSnippets?: string[];
  keyframesAndStepsSDataSSnippets?: TSelectorData[];
  assignedSelectorsPlusChildrenWithData: TSelectorData[];
  mediaSelectorsPlusChildClassesWithDataSorted: TSelectorData[];
}) => {
  const selectors = snippet?.data?.selectors;
  if (!selectors) {
    return;
  }

  const properties = snippet.data.properties;

  // Gets names of the assigned classes
  //PART1
  const names = getAssignedClassNames({
    assignedSelectorIds,
    snippet
  });

  //PART1
  const assignedLevel1Classes = selectors?.filter(
    (s) =>
      s.name === "class" &&
      s.level === 1 &&
      s.value?.main?.value &&
      names?.includes(s.value.main.value)
  );
  // Why is this ^ necesarry? Is it possible to just get the selectors which are classes and on level one using assignedSelectorIds

  //PART1
  // Adds any children of the assigned selectors
  const assignedSelectorsPlusChildren: itemIF[] = [];
  assignedLevel1Classes?.forEach((selector) => {
    assignedSelectorsPlusChildren.push(selector);
    getChildrenSelectors({
      selector,
      snippet,
      assignedSelectorsPlusChildren,
    });
  });

  const lineBreak = "\n";

  assignedSelectorsPlusChildren.forEach((selector) => {
    const sDataSnippetId = getCssForClassOrHtml({
      selector,
      lineBreak,
      snippet
    });

    assignedSelectorsPlusChildrenWithData.push(sDataSnippetId);
  });

  //////// Key frames & Animation ////////

  const allRelevantSelectorIds = assignedSelectorsPlusChildren.map((s) => s.id);

  // PART1
  const assignedAnimations = getAssignedAnimations({
    assignedClassNames: names || [],
    snippet
  });

  // PART1
  const assignedKeyframesAndSteps = getKeyframesAndStepsForAssignedAnimations({
    assignedAnimations,
    snippet
  });

  assignedKeyframesAndSteps.forEach((s) => allRelevantSelectorIds.push(s.id));

  // PART2
  const keyframesAndStepsSDataS = getKeyframesSData({
    assignedKeyframesAndSteps,
    properties,
    lineBreak,
    addSpaceAtLineStart,
    snippet,
  });
  if (keyframesAndStepsSDataSSnippets) {
    keyframesAndStepsSDataS.forEach((sData) =>
      keyframesAndStepsSDataSSnippets.push(sData)
    );
  }

  //////// Media Queries ////////

  // PART1
  const mediaSelectorsWithAssignedChildClass =
    getMediaSelectorsWithAssignedClassChild({
      assignedClassNames: names || [],
      snippet
    });

  // PART2
  getGroupedAndSortMediaSelectors({
    mediaSelectorsWithAssignedChildClass,
    mediaSelectorsPlusChildClassesWithDataSorted,
    snippet
  });

  mediaSelectorsPlusChildClassesWithDataSorted.forEach((sData) =>
    allRelevantSelectorIds.push(sData.selector.id)
  );
};

export const getCssForSelectorIds = ({
  assignedSelectorIds,
  snippet,
  addSpaceAtLineStart = "",
  googleFamiliesSnippets,
  keyframesAndStepsSDataSSnippets,
  selectorsWithChildrenSDataSSnippets,
  sortedMediasSDataSSnippets,
}: {
  assignedSelectorIds: number[];
  snippet: snippetIF;
  addSpaceAtLineStart?: string;
  googleFamiliesSnippets?: string[];
  keyframesAndStepsSDataSSnippets?: TSelectorData[];
  selectorsWithChildrenSDataSSnippets?: TSelectorData[];
  sortedMediasSDataSSnippets?: TSelectorData[];
}) => {
  const googleFamilies = googleFamiliesSnippets
    ? googleFamiliesSnippets
    : ([] as string[]);
  const keyframesAndStepsSDataS = keyframesAndStepsSDataSSnippets
    ? keyframesAndStepsSDataSSnippets
    : ([] as TSelectorData[]);
  const assignedSelectorsPlusChildrenWithData =
    selectorsWithChildrenSDataSSnippets
      ? selectorsWithChildrenSDataSSnippets
      : ([] as TSelectorData[]);
  const sortedMediasSDataS = sortedMediasSDataSSnippets
    ? sortedMediasSDataSSnippets
    : ([] as TSelectorData[]);

  getSDataSForSelectorIds({
    assignedSelectorIds,
    snippet,
    googleFamiliesSnippets: googleFamilies,
    keyframesAndStepsSDataSSnippets: keyframesAndStepsSDataS,
    assignedSelectorsPlusChildrenWithData:
      assignedSelectorsPlusChildrenWithData,
    mediaSelectorsPlusChildClassesWithDataSorted: sortedMediasSDataS,
    addSpaceAtLineStart,
  });

  return getCssForSDatas({
    keyframesAndStepsSDataS,
    assignedSelectorsPlusChildrenWithData,
    sortedMediasSDataS,
    addSpaceAtLineStart,
    lineBreak: "\n",
  });
};