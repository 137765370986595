import { metaIF } from "../../data/types/item";

export const renderItemWithGroupWrapperFunction = ({
  attr,
  meta,
}: {
  attr: Record<string, string>;
  meta?: metaIF;
}): Record<string, string> => {
  if (!meta?.wrapperForGroup) {
    return attr;
  }

  const attrKeys = Object.keys(attr);
  const fieldsAlreadyProcessed: string[] = [];
  const newAttr2: Record<string, string> = {};

  for (const w of meta.wrapperForGroup) {
    if (!w.wrapperFn) {
      continue;
    }

    const fieldsInGroupWrapper = attrKeys.filter((f) => w.fields.includes(f));

    if (fieldsInGroupWrapper.length === 0) {
      fieldsAlreadyProcessed.push(...(w.fields ?? []));
      continue;
    }

    const groupAttr: Record<string, string> = {};
    for (const field of fieldsInGroupWrapper) {
      groupAttr[field] = attr[field];
    }

    const valueStr = w.wrapperFn(groupAttr);

    newAttr2[w.name] = valueStr;

    fieldsAlreadyProcessed.push(...fieldsInGroupWrapper);
  }

  const fieldsNotProcessed = attrKeys.filter(
    (f) => !fieldsAlreadyProcessed.includes(f)
  );
  for (const f of fieldsNotProcessed) {
    newAttr2[f] = attr[f];
  }

  return newAttr2;
};
