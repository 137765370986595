import { itemIF } from "../../data/types/item";
import { itemTypes } from "../meta/getMetas";
import { getChildrenAndResetIdsParentsLevel } from "./addItems";
import { sortTree2 } from "../tree/sortTree";
import { getNewItem } from "./addItems";
import { TOldNewIds } from "./addSelectorsAndProperties";
import { snippetIF } from "../../data/types/snippets";
import {
  getEditItemIdOfType,
  getItemsOfType,
} from "../snippets/addAndGetMetas";
const clone = require("rfdc")();

/*
set new id, parent, level and position for new item/itemFrom
if new item is class, replace id in htmlsOSvgs assignedClassIds

find descendants of itemFrom
set new id, parent, level and position for descendants
*/

type TResult = {
  items: itemIF[];
  editItemId: number;
  oldNewIds?: TOldNewIds[];
};


// returns new items added to existing itemsTo
export const addNewItemWithDescendants = ({
  itemType,
  itemId,
  itemToId,
  snippetFrom,
  snippetTo,
}: {
  itemType: itemTypes;
  itemId?: number;
  itemToId?: number;
  snippetFrom: snippetIF;
  snippetTo: snippetIF;
}): TResult | undefined => {  
  const editItemIdSnippetFrom = getEditItemIdOfType({
    itemType,
    snippet: snippetFrom,
  });

  const copyItemId = itemId ? itemId : editItemIdSnippetFrom;
  const itemsFrom = getItemsOfType({
    itemType,
    snippet: snippetFrom,
  });

  const itemFrom = itemsFrom.find((i) => i.id === copyItemId);
  if (!itemFrom) {
    console.warn("addNewItemWithDescendants: itemFrom is not defined");
    return;
  }

  const cloneItem: itemIF = clone(itemFrom);
  const editItemIdSnippetTo = getEditItemIdOfType({
    itemType,
    snippet: snippetTo,
  });
  const newItemWithParentLevelPosition = getNewItem({
    itemType,
    itemFrom: cloneItem,
    itemToId: itemToId ?? editItemIdSnippetTo,
    snippetFrom,
    snippetTo,
  });

  const itemsTo = getItemsOfType({
    itemType,
    snippet: snippetTo,
  });
  const itemsToIncreaseSiblingPosition = itemsTo.map((i) => {
    if (
      i.parent === newItemWithParentLevelPosition.parent &&
      i.position >= newItemWithParentLevelPosition.position
    ) {
      return {
        ...i,
        position: i.position + 1,
      };
    }
    return i;
  });

  /*
  when copying between snippets, don't copy children of @media selector. 
  When copying selecors between snippets, the item to copy could be of a higher level than 2.
  In this case, the @media selector, if applicable, is copied as well with the to copy element on level 2
  */
  const isCopyBetweenSnippets = snippetFrom.id !== snippetTo.id;
  if (isCopyBetweenSnippets && itemFrom.name === "@media") {
    const newItemsToAll = [
      ...itemsToIncreaseSiblingPosition,
      newItemWithParentLevelPosition,
    ];
    const sorted = sortTree2(newItemsToAll);
    return {
      items: sorted,
      editItemId: newItemWithParentLevelPosition.id,
      oldNewIds: [
        { oldId: itemFrom.id, newId: newItemWithParentLevelPosition.id },
      ],
    };
  }

  const descendantsResult = getChildrenAndResetIdsParentsLevel({
    newItem: newItemWithParentLevelPosition,
    itemIdOld: itemFrom.id,
    itemsFrom,
  });

  const newItems = descendantsResult?.items as itemIF[];
  const newItemsToAll = [
    ...itemsToIncreaseSiblingPosition,
    newItemWithParentLevelPosition,
    ...newItems,
  ];
  const sorted = sortTree2(newItemsToAll);

  let data = {
    items: sorted,
    editItemId: newItemWithParentLevelPosition.id,
    oldNewIds: descendantsResult?.oldNewIds,
  } as TResult;
  return data;
};
