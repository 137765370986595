import { setIsLoading } from "../../lib/reducer/settings/actions";
import { changeSnippet } from "../../lib/reducer/snippet/actions";
import { syncSnippet } from "../../lib/lambda/syncSnippet";
import { snippetIF } from "../../data/types/snippets";

export const createOrUpdateSnippet = async ({
  editSnippet,
  userId,
  setIsPublic,
  dispatch,
  setMsg,
  setShowMsg,
  setIsModalOpen,
}: {
  editSnippet: snippetIF;
  userId?: string;
  setIsPublic?: boolean;
  dispatch: React.Dispatch<any>;
  setMsg?: (value: React.SetStateAction<string>) => void;
  setShowMsg?: (value: React.SetStateAction<boolean>) => void;
  setIsModalOpen?: (isOpen: boolean) => void;
}) => {
  if (!userId) {
    setMsg &&
      setMsg(
        "Error: You need to be signed in to create/uplodad cloud snippets."
      );
    setShowMsg && setShowMsg(true);
    return;
  }

  if (editSnippet.owner !== userId && editSnippet.owner !== "anonymous") {
    setMsg &&
      setMsg("Error: You can only create/update cloud snippets that you own.");
    setShowMsg && setShowMsg(true);
    setIsModalOpen && setIsModalOpen(false);
    return;
  }

  dispatch(setIsLoading(true));

  const newOrUpdatedSnippet = await syncSnippet({
    snippet: {
      ...editSnippet,
      public: setIsPublic ? !editSnippet.public : editSnippet.public,
    },
    userId,
  });

  if (!newOrUpdatedSnippet) {
    setMsg && setMsg("Error: Failed to create or update cloud snippet.");
    setShowMsg && setShowMsg(true);
    setIsModalOpen && setIsModalOpen(false);
    dispatch(setIsLoading(false));
    return;
  }

  dispatch(
    changeSnippet({
      fieldName: "updatedAt",
      value: newOrUpdatedSnippet.updatedAt,
    })
  );

  let msg = "Snippet is updated in the cloud";
  if (editSnippet.id !== newOrUpdatedSnippet.id) {
    dispatch(changeSnippet({ fieldName: "id", value: newOrUpdatedSnippet.id }));
    msg = "Snippet is created in the cloud";
  }

  if (setIsPublic) {
    dispatch(
      changeSnippet({ fieldName: "public", value: newOrUpdatedSnippet.public })
    );

    const addToMsg = newOrUpdatedSnippet?.public
      ? " and is now public"
      : " and is now private";
    msg = msg + addToMsg;
  }

  dispatch(setIsLoading(false));
  setMsg && setMsg(msg);
  setShowMsg && setShowMsg(true);
  setIsModalOpen && setIsModalOpen(false);
};
