import { useState, FC } from "react";
import { useStateContext } from "../../lib/state/state";
import { TMsgType, TFormData, TUserState } from "../../lib/signinup/helpers";
import {
  collectNewPassword,
  sendForgottenPassword,
} from "../../lib/signinup/sendForgottenPassword";

import { validateEmail } from "../../lib/utils/validateEmail";
import { InputWithValidation } from "./InputWithValidation";
import { FormInputMessage } from "../atoms/formErrOrSuccessMessage";
import { AccountWrapper } from "./Account";

import "./Account.css";

export type SignUpProps = {
  setSignType: (signType: TUserState) => void;
};

export const CreateNewPasswordWithCode: FC<SignUpProps> = ({ setSignType }) => {
  const { dispatch } = useStateContext();
  const [formData, setFormData] = useState({
    email: { data: "", errorMessage: "" },
    password: { data: "", errorMessage: "" },
    verify: { data: "", errorMessage: "" },
  } as TFormData);
  const [signInErrorMessage, setSignInErrorMessage] = useState({} as TMsgType);

  const isSubmitButtonDisabled =
    formData.email.data === "" ||
    formData.email.errorMessage !== "" ||
    formData.password.data === "" ||
    formData.password.errorMessage !== "" ||
    formData.verify.data === "" ||
    formData.verify.errorMessage !== "";

  return (
    <AccountWrapper title="Create a new password">
      <>
        <div className="mt-1r text-center">
          We sent a verification via email. Enter your code here.
        </div>

        <div className="login-form-field mt-2r">
          <InputWithValidation
            inputData={formData.email}
            label="Your email"
            onChange={(newData: string) => {
              setFormData({
                ...formData,
                email: {
                  ...formData.email,
                  data: newData,
                  errorMessage: validateEmail(newData)
                    ? ""
                    : "Enter a valid email address",
                },
              });
            }}
          />
          <FormInputMessage msg={{ errMsg: formData.email.errorMessage }} />
        </div>

        <div className="login-form-field mt-2r">
          <InputWithValidation
            inputData={formData.verify}
            label="Verification code"
            onChange={(newData: string) => {
              setFormData({
                ...formData,
                verify: {
                  ...formData.verify,
                  data: newData,
                  errorMessage:
                    newData.length !== 6
                      ? "Enter the 6 digit verification code"
                      : "",
                },
              });
            }}
          />
          <FormInputMessage msg={{ errMsg: formData.verify.errorMessage }} />
        </div>

        <div className="login-form-field mt-2r">
          <InputWithValidation
            inputData={formData.password}
            label="New Password"
            onChange={(newData: string) => {
              setFormData({
                ...formData,
                password: {
                  ...formData.password,
                  data: newData,
                  errorMessage:
                    newData.length > 7
                      ? ""
                      : "Your password must have 8 or more characters",
                },
              });
            }}
            isPasswordWithEye={true}
          />
          <FormInputMessage msg={{ errMsg: formData.password.errorMessage }} />
        </div>

        <div className="login-form-field mt-3r mb-1r">
          <button
            type="submit"
            aria-label="Update password"
            disabled={isSubmitButtonDisabled}
            className={`login-button primary${isSubmitButtonDisabled ? " disabled" : ""}`}
            onClick={() => {
              if (!isSubmitButtonDisabled) {
                collectNewPassword({
                  formData,
                  setFormData,
                  setSignType,
                  setSignInErrorMessage,
                  dispatch,
                });
              }
            }}
          >
            Create new password
          </button>
          <FormInputMessage msg={signInErrorMessage} />
        </div>
        <div className="login-form-field mt-2r mb-1r">
          You didn't get the code?
          <a
            href="#home"
            className="login-link ml-05r"
            onClick={() => {
              sendForgottenPassword({
                formData,
                setFormData,
                setSignType,
                setSignInErrorMessage,
                dispatch,
              });
            }}
          >
            Resend
          </a>
        </div>
      </>
    </AccountWrapper>
  );
};
