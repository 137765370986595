import { snippetIF } from "./snippets";
import {
  PAGES,
  SUB_PAGES,
  settingsIF,
} from "../settings/constants";
import { IUser } from "../user/constants";
import { itemTypes } from "../../lib/meta/getMetas";

export type TItemsInCategories = {
  name: string;
  items: metaIF[];
  title?: string;
  help?: string;
  warning?: string;
};

export type TObject = {
  [key: string]: any;
};

export const checkTypeItemIF = (item: itemIF | snippetIF): item is itemIF => {
  if ((item as itemIF).name) {
    return true;
  }
  return false;
};
export const checkTypeSnippetIF = (
  item: itemIF | snippetIF
): item is snippetIF => {
  if ((item as snippetIF).name) {
    return true;
  }
  return false;
};

export interface IAssignedClassesOrId {
  classes?: number[];
  id?: number;
}

export interface componentIF {
  itemType: string;
}

export interface rgbaIF {
  r: number;
  g: number;
  b: number;
  a: number;
}

export interface colorSetValueIF extends rgbaIF {
  width: number;
  unit: string;
}

export type TTemplateSnippetIds = {
  id: string;
  newId?: string;
  snippetIds?: {
    [key: string]: string;
  };
};

export type TValueType = "value" | "unit" | "onOff" | "number" | "string";
export interface actionIF {
  addDefaultValues?: TObject;
  addAfterItemId?: number;
  addOrRemoveEditItemId?: number;
  assignedId?: string;
  categoryName?: string;
  changeFieldKey?: string;
  changedTemplateAndSnippetIds?: TTemplateSnippetIds;
  classId?: number;
  classIds?: number[];
  color?: string;
  coc?: "CODE" | "CONTENT";
  copyEditItem?: boolean;
  deletePropertiesIds?: number[];
  editCodeSnippetId?: string;
  editClassId?: number;
  editHtmlName?: string;
  editItemId?: number;
  editPropId?: number;
  editTemplateId?: string;
  editSelectorId?: number;
  editSnippetId?: string;
  editContextSnippetId?: string;
  editEventId?: number;
  editFontId?: number;
  email?: string;
  event?: {
    type: "onclick";
    data: {
      mode: "Add" | "Replace" | "Remove";
      classId: string;
    };
  };
  fieldName?: string;
  fieldGroupName?: string;
  fieldGroupId?: number;
  fontsNew?: itemIF[];
  groupItemId?: number;
  htmlId?: number;
  htmlSvgCodeSnippetSvgIds?: string[];
  index?: number;
  isChecked?: boolean;
  isLoading?: boolean;
  isSplitScreenMode?: boolean;
  itemKey?: string;
  itemType?: itemTypes;
  item?: itemIF;
  itemFromId?: number;
  itemToId?: number;
  items?: itemIF[];
  itemValue?: itemValueIF;
  name?: string;
  newCategory?: string;
  newEditItemId?: number;
  newFont?: itemIF;
  newId?: string;
  newItem?: itemIF;
  newSnippet?: snippetIF;
  oldId?: string;
  oldNew?: { [key: string]: string };
  otherSnippetId?: string;
  page?: PAGES;
  previewSnippetId?: string;
  scrollPos?: number;
  selectedCategory?: string;
  selectedOption?: IOptions;
  selector?: {
    id: number;
    name: string;
  };
  selectorId?: number;
  settings?: settingsIF;
  snippet?: snippetIF;
  snippets?: snippetIF[];
  snippetFromId?: string;
  snippetToId?: string;
  snippetId?: string;
  snippetsInWorkspaceStr?: string;
  snippetTemplateSvgView?: "SNIPPET" | "SVG";
  subPage?: SUB_PAGES;
  svgId?: number;
  type: string;
  userData?: IUser;
  userId?: string;
  username?: string;
  value?: any;
  valueObj?: { id?: string; user?: string };
  valueType?: TValueType;
  variableId?: number;
  cssVariableName?: string;
}

export interface shortHandIF {
  [key: string]: {
    css: string[];
  }[];
}

export type TObj = {
  [key: string]: string;
};


/*
when several fields are rendered together define here the fields and the render result as string
when passed to the wrapperForGroup wrapperFn the single fields are already rendered to string
*/

export interface wrapperForGroupIF {
  name: string;
  javascript?: string;
  fields: string[];
  wrapperFn?: (value: Record<string, string>) => string;
}

// checks the value of another field and returns true when the condition in the other field is met, false otherwise
export interface IDependentOnValueInOtherField {
  field: string;
  wrapperFn: (value: TObject, dependentValue: TObject) => boolean;
}

export interface dependentOnIF {
  field: string;
  values: string[];
  dontDisableDependentOnField?: boolean;
}

export interface IOptions {
  id: string;
  css?: string;
  dependents?: string[];
  display?: string;
  height?: string;
  help?: string;
  import?: string;
  keyframeId?: number;
  max?: number;
  min?: number;
  name?: string;
  nameActual?: string;
  replaceInhelp?: string[];
  replaceInwarning?: string[];
  type?: string;
  warning?: string;
  width?: string;
  wrapperFn?: (value: any, ...rest: any) => string;
}

export enum EMetaFieldTypes {
  "addOrRemoveFieldGroup" = "addOrRemoveFieldGroup",
  "assignSvgFilterId" = "assignSvgFilterId",
  "assignSvgGradientId" = "assignSvgGradientId",
  "boolean" = "boolean",
  "colourPicker" = "colourPicker",
  "inputInLabelId" = "inputInLabelId",
  "isClassOnHtmlTag" = "isClassOnHtmlTag",
  "number" = "number",
  "otherAnimationId" = "otherAnimationId",
  "propertyOfClassAndAll" = "propertyOfClassAndAll",
  "readonly" = "readonly",
  "select" = "select",
  "selectContentSvgId" = "selectContentSvgId",
  "selectKeyframeName" = "selectKeyframeName",
  "selectPathId" = "selectPathId",
  "string" = "string",
  "textarea" = "textarea",
  "upload" = "upload",
  "x" = "x",
}

export type TAutocomplete = "tags" | "language" | "htmlSelectors" | "fonts";

export const canBeFields:(keyof metaFieldIF)[] = [
  "canBeCalculated",
  "canBeInherited",
  "canBeInitial",
  "canBeAuto",
  "canBeAddOptions",
];

// onOff: if set on fieldMeta -> field is always on
// if set on field default value -> field is on as default but can be switched off
export interface metaFieldIF {
  addResultsToOptions?: boolean;
  autocomplete?: TAutocomplete;
  canBeCalculated?: boolean;
  canBeInherited?: boolean;
  canBeInitial?: boolean;
  canBeAuto?: boolean;
  canBeAddOptions?: string[];
  conditions?: conditionIF;
  dataSource?: string;
  default?: {
    [key: string]: any;
  };
  dependentOn?: dependentOnIF;
  dependentOnValueInOtherField?: IDependentOnValueInOtherField;
  dontDisableDependentOnField?: boolean;
  dontEdit?: boolean;
  disabled?: boolean;
  display?: string;
  dontDisplayForValue?: string;
  dontPrintWhenNull?: boolean;
  dontRender?: boolean;
  editInContentSnippet?: boolean;
  getOptionsFromStylepackFonts?: true;
  help?: string;
  isPropertyName?: boolean;
  javascript?: string;
  min?: number;
  max?: number;
  name: string;
  notOnItem?: string[];
  onOff?: boolean;
  options?: IOptions[];
  placeholder?: boolean;
  prefix?: string;
  replaceInhelp?: string[];
  replaceInwarning?: string[];
  smallInput?: boolean;
  type: EMetaFieldTypes;
  units?: IOptions[];
  useValueAsKeyOfField?: string;
  warning?: string;
  wrapperFn?: (value: any, ...rest: any) => string | undefined;
  wrapsField?: string;
}

export interface conditionIF {
  first?: boolean;
  greaterOrEqual0?: boolean;
  integer?: boolean;
  isRequired?: boolean;
  last?: boolean;
  lessOrEqual1?: boolean;
  max?: number;
  min?: number;
  notFirst?: boolean;
  onlyOne?: boolean;
  regex?: any;
}

export interface groupIF {
  name: string;
  javascript?: string;
  display?: string[];
  fields: string[];
  dependentOn?: dependentOnIF;
  help?: string;
  warning?: string;
  onOff?: boolean;
}

export interface partOfShortHandIF {
  shorthand: string;
  fields: string[];
}

interface metaIFBase {
  allowedChildren?: string[];
  allowedParent?: string[];
  allowedSiblings?: string[];
  availableFromLevel?: number;
  availableOnlyLevel?: number;
  category: string[];
  fields?: metaFieldIF[];
  fieldsOrder?: string[];
  group?: groupIF[];
  name: string;
}

export interface metaIF extends metaIFBase{
  addMultipleTimes?: boolean;
  addToShorthand?: string;
  childrenAsAttributes?: {
    attributeName: string;
    childrenName?: string;
    children: string[];
    separator?: string;
    render?: boolean;
    min?: number;
    wrapperFn?: (valueObj: itemValueIF) => boolean;
  };
  code?: string;
  conditions?: conditionIF;
  default?: itemValueIF;
  dependentOn?: {
    field: string;
    values: string[];
  };
  display?: string;
  dontAssignClassesOrId?: boolean;
  enabledInSelector?: string[];
  fieldSeparator?: string;
  hasNoEndTag?: boolean;
  help?: string;
  intervals?: number;
  isPartOfShortHand?: partOfShortHandIF;
  javascript?: string;
  level?: string;
  min?: number;
  minElements?: number;
  noCssProperties?: boolean;
  onOff?: boolean;
  orientation?: TObject[];
  prefix?: string;
  replaceInhelp?: string[];
  replaceInwarning?: string[];
  setOnParent?: boolean;
  shorthandSeparator?: string;
  useFieldAsPropertyName?: string;
  warning?: string;
  wrapperForGroup?: wrapperForGroupIF[];
  wrapperFn?: (value: any, ...rest: any) => string;
}

export type TItemValue = {
  value: any;
  unit?: string;
  onOff?: boolean;
};

export interface itemValueIF {
  [key: string]: TItemValue;
}

export interface IAassignedClassesOrId {
  classes?: number[];
  id?: number;
}

interface itemIFBase {
  id: number;
  level?: number;
  name: string;
  parent?: number;
  position: number;
  value?: itemValueIF;
}

export interface itemIF extends itemIFBase{
  assignedClasses?: number[];
  children?: itemIF[];
  classId?: number;
  sameNameClassesIds?: number[];
  events?: itemIF[];
  htmlId?: number;
  htmlName?: string;
  isEditItem?: boolean;
  parentHtmlId?: number;
  prefix?: {
    name: string;
  };
  snippetId?: string;
}

export interface itemWithChildrenIF extends itemIF {
  children: itemWithChildrenIF[];
}

export interface OptionIF {
  id: string;
}

export interface cssForAllSelectorsIF {
  nameStr: string;
  selector: itemIF;
  selectorProperties?: itemIF[];
}

export type TShorthand = { [key: string]: string[] };
export type TPosAndSh = { pos: number; sh: TShorthand };
export type TSelectorData = {
  nameStr: string;
  css?: string;
  selector: itemIF;
  shorthand?: TShorthand;
  posAndShs?: TPosAndSh[];
  snippetId?: string;  
};

export interface itemAttributeChangeIF {
  addOrRemoveEditItemId?: number;
  addOrRemoveParent?: itemIF;
  editEventId?: number;
  editHtmlName?: string;
  editItemId?: number;
  editSelectorId?: number;
  editSnippetId?: string;
  fieldGroupName?: string;
  fieldName?: string;
  groupItemId?: number;
  itemType?: itemTypes;
  otherSnippetId?: string;
  svgId?: number;
  stylePackId?: string;
  type?: string;
  value: any;
  valueType?: TValueType;
}
