import { metaIF, EMetaFieldTypes } from "../../types/item";
import { standardUnits } from "./utils";

const borderStyleOptions = [
  {
    id: "dashed",
  },
  {
    id: "dotted",
  },
  {
    id: "double",
  },
  {
    id: "groove",
  },
  {
    id: "hidden",
  },
  {
    id: "inset",
  },
  {
    id: "outset",
  },
  {
    id: "ridge",
  },
  {
    id: "solid",
  },
];

export const CSS_PROPERTIES_BORDER: metaIF[] = [
  {
    name: "border",
    category: ["BORDER"],
    fieldsOrder: ["main", "width", "style", "color"],
    addMultipleTimes: true,
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        options: [
          {
            id: "all",
            display: "border",
          },
          {
            id: "top",
            display: "border-top",
          },
          {
            id: "right",
            display: "border-right",
          },
          {
            id: "bottom",
            display: "border-bottom",
          },
          {
            id: "left",
            display: "border-left",
          },
          {
            id: "none",
            display: "border",
          },
        ],
        onOff: true,
        help:
          "### border  \n" +
          "Draw a border around the selected element by choosing the border `width`, `style` and `color`.  \n" +
          "You can style `all` borders the same or choose the `width`, `style` and `color` just " +
          "for the `top`, `right`, `bottom` or `left` border.  \n" +
          "If you want to style borders differently, " +
          "for example `all` borders but the `bottom` once are blue and the `bottom` border is black, " +
          "add multiple `border` properties, select the general style with `all` first " +
          "and the more specific setting underneath in the list of the properties. " +
          "Settings further down in the list overwrite settings on top.",
      },
      {
        name: "width",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: [{ id: "px" }, { id: "rem" }],
        dependentOn: {
          field: "main",
          values: ["all", "top", "right", "bottom", "left"],
        },
      },
      {
        name: "style",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: borderStyleOptions,
        dependentOn: {
          field: "main",
          values: ["all", "top", "right", "bottom", "left"],
        },
      },
      {
        name: "color",
        type: EMetaFieldTypes.colourPicker,
        onOff: true,
        dependentOn: {
          field: "main",
          values: ["all", "top", "right", "bottom", "left"],
        },
      },
    ],

    group: [
      {
        name: "borderG",
        fields: ["main"],
        display: ["border"],
      },
      {
        name: "widthG",
        fields: ["width"],
        display: ["width"],
      },
      {
        name: "styleG",
        fields: ["style"],
        display: ["style"],
      },
      {
        name: "colorG",
        fields: ["color"],
        display: ["color"],
      },
    ],

    default: {
      main: { value: "all" },
      width: { value: 1, unit: "px" },
      style: { value: "solid" },
      color: { value: { r: 60, g: 188, b: 195, a: 1 } },
    },
  },
  {
    name: "border-color",
    category: ["BORDER"],
    fieldsOrder: ["side", "color"],
    addMultipleTimes: true,
    fields: [
      {
        name: "side",
        type: EMetaFieldTypes.select,
        dontRender: true,
        options: [
          {
            id: "all",
            display: "border-color",
          },
          {
            id: "top",
            display: "border-top-color",
          },
          {
            id: "right",
            display: "border-right-color",
          },
          {
            id: "bottom",
            display: "border-bottom-color",
          },
          {
            id: "left",
            display: "border-left-color",
          },
        ],
        onOff: true,
      },
      {
        name: "color",
        type: EMetaFieldTypes.colourPicker,
        onOff: true,
        canBeInherited: true,
      },
    ],
    group: [
      {
        name: "sideG",
        fields: ["side"],
        display: ["side"],
      },
      {
        name: "borderColorG",
        fields: ["color"],
        display: ["border-color"],
      },
    ],

    default: {
      side: { value: "all" },
      color: { value: { r: 60, g: 188, b: 195, a: 1 } },
    },
  },
  {
    name: "border-style",
    category: ["BORDER"],
    fieldsOrder: ["main", "style"],
    addMultipleTimes: true,
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        dontRender: true,
        onOff: true,
        options: [
          {
            id: "all",
            display: "border-style",
          },
          {
            id: "top",
            display: "border-top-style",
          },
          {
            id: "right",
            display: "border-right-style",
          },
          {
            id: "bottom",
            display: "border-bottom-style",
          },
          {
            id: "left",
            display: "border-left-style",
          },
        ],
      },
      {
        name: "style",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: borderStyleOptions,
      },
    ],
    group: [
      {
        name: "sideG",
        fields: ["main"],
        display: ["side"],
      },
      {
        name: "styleG",
        fields: ["style"],
        display: ["border-style"],
      },
    ],

    default: {
      main: { value: "all" },
      style: { value: "solid" },
    },
  },
  {
    name: "border-width",
    category: ["BORDER"],
    fieldsOrder: ["main", "width"],
    addMultipleTimes: true,
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        dontRender: true,
        options: [
          {
            id: "all",
            display: "border-width",
          },
          {
            id: "top",
            display: "border-top-width",
          },
          {
            id: "right",
            display: "border-right-width",
          },
          {
            id: "bottom",
            display: "border-bottom-width",
          },
          {
            id: "left",
            display: "border-left-width",
          },
        ],
        onOff: true,
      },
      {
        name: "width",
        type: EMetaFieldTypes.number,
        units: standardUnits,
      },
    ],

    default: {
      main: { value: "all" },
      width: { value: 1, unit: "px", onOff: true },
    },
  },
  {
    name: "border-radius",
    category: ["BORDER"],
    addMultipleTimes: true,
    fieldsOrder: ["selectCorner", "valueOrPair", "hRadius", "vRadius"],
    fields: [
      {
        name: "selectCorner",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "all",
            display: "border-radius",
          },
          {
            id: "top-left",
            display: "border-top-left-radius",
          },
          {
            id: "top-right",
            display: "border-top-right-radius",
          },
          {
            id: "bottom-right",
            display: "border-bottom-right-radius",
          },
          {
            id: "bottom-left",
            display: "border-bottom-left-radius",
          },
        ],
        help:
          "### border-radius corner \n" +
          "Rounds the corners of the element. " +
          "A value of 0 for the border-radius means the corner has a 90 degree angle. " +
          "50% rounds the corner as in a circle.  \n" +
          "You can style `all` corners the same or choose the `radius` just " +
          "for the `top-left`, `top-right`, `bottom-right` or `bottom-left` corner.",
      },
      {
        name: "valueOrPair",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "value",
          },
          {
            id: "pair",
          },
        ],
        help:
          "### border-radius value  \n" +
          "You can enter for the `border-radius` one value or a pair (e.g. `1/2`).  \n" +
          "Corners with one value are rounded symmetrically.  \n" +
          "When you enter a pair of values, the first value defines the radius " +
          "for the horizontally leaning part of the corner, and the second for the vertically leaning part.",
      },
      {
        name: "hRadius",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: [{ id: "px" }, { id: "%" }, { id: "rem" }],
        canBeCalculated: true,
      },
      {
        name: "vRadius",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: [{ id: "px" }, { id: "%" }, { id: "rem" }],
        dependentOn: {
          field: "valueOrPair",
          values: ["pair"],
        },
        canBeCalculated: true,
      },
    ],
    group: [
      {
        name: "corner",
        fields: ["selectCorner"],
        display: ["corner"],
      },
      {
        name: "valueOrPairG",
        fields: ["valueOrPair", "hRadius", "vRadius"],
        display: ["h/v", "horizontal", "vertical"],
      },
    ],

    wrapperForGroup: [
      {
        name: "border-radius",
        fields: ["selectCorner", "valueOrPair", "hRadius", "vRadius"],
        wrapperFn: (valObj) => {
          const vRadius = valObj["vRadius"] ?? "";
          const hRadius = valObj["hRadius"] ?? "";
          const selectCorner = valObj["selectCorner"] ?? "";

          const separator = vRadius && hRadius
              ? selectCorner === "all"
                ? " / "
                : " "
              : "";
          const borderRadius = `${hRadius}${separator}${vRadius}`;
          return borderRadius;
        },
      },
    ],

    default: {
      selectCorner: { value: "all" },
      valueOrPair: { value: "value" },
      hRadius: { value: 4, unit: "px" },
      vRadius: { value: 50, unit: "%" },
    },
  },
  {
    name: "outline",
    category: ["BORDER"],
    addMultipleTimes: true,
    fieldsOrder: ["main", "width", "style", "color"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        options: [
          { id: "value", display: "outline" },
          { id: "none", display: "outline" },
          { id: "color", display: "outline-color" },
          { id: "style", display: "outline-style" },
          { id: "width", display: "outline-width" },
        ],
        onOff: true,
        help:
          "### outline  \n" +
          "An additional border outside the border of the element. " +
          "You can define a `width`, `style` and `color` for the outline.  \n" +
          "You can add the `outline-offset` property, to add distance between the outer edge of the element and the outline " +
          "or draw the `outline` closer with a negative `offset` value.",
      },
      {
        name: "width",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnits,
        dependentOn: {
          field: "main",
          values: ["value", "width"],
        },
        conditions: {
          greaterOrEqual0: true,
        },
      },
      {
        name: "style",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: borderStyleOptions,
        dependentOn: {
          field: "main",
          values: ["value", "style"],
        },
      },
      {
        name: "color",
        type: EMetaFieldTypes.colourPicker,
        onOff: true,
        dependentOn: {
          field: "main",
          values: ["value", "color"],
        },
      },
    ],
    group: [
      {
        name: "outline",
        fields: ["main"],
      },
      {
        name: "widthG",
        display: ["width"],
        fields: ["width"],
      },
      {
        name: "styleG",
        display: ["style"],
        fields: ["style"],
      },
      {
        name: "colorG",
        display: ["color"],
        fields: ["color"],
      },
    ],

    default: {
      main: { value: "value" },
      width: { value: 1, unit: "px" },
      style: { value: "solid" },
      color: { value: { r: 60, g: 188, b: 195, a: 1 } },
    },
  },
  {
    name: "outline-color",
    category: ["BORDER"],
    fieldsOrder: ["color"],
    fields: [
      {
        name: "color",
        type: EMetaFieldTypes.colourPicker,
        onOff: true,
      },
    ],
    group: [
      {
        name: "colorG",
        display: ["color"],
        fields: ["color"],
      },
    ],

    default: {
      color: { value: { r: 60, g: 188, b: 195, a: 1 } },
    },
  },
  {
    name: "outline-style",
    category: ["BORDER"],
    fieldsOrder: ["style"],
    fields: [
      {
        name: "style",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: borderStyleOptions,
        dependentOn: {
          field: "main",
          values: ["value", "style"],
        },
      },
    ],
    group: [
      {
        name: "styleG",
        display: ["style"],
        fields: ["style"],
      },
    ],

    default: {
      style: { value: "solid" },
    },
  },
  {
    name: "outline-width",
    category: ["BORDER"],
    fieldsOrder: ["width"],
    fields: [
      {
        name: "width",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnits,
        conditions: {
          greaterOrEqual0: true,
        },
      },
    ],

    default: {
      width: { value: 1, unit: "px" },
    },
  },

  {
    name: "outline-offset",
    category: ["BORDER"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnits,
        help:
          "#### outline-offset  \n" +
          "Adds distance between the outer edge of the element and the outline or " +
          "draws the `outline` closer with a negative `outline-offset` value.",
      },
    ],
    default: { main: { value: 1, unit: "rem" } },
  },
];
