import { useState, FC } from "react";
import { useStateContext } from "../../lib/state/state";
import { TMsgType, TFormData, TUserState } from "../../lib/signinup/helpers";
import { signUpFn } from "../../lib/signinup/signup";
import { validateEmail } from "../../lib/utils/validateEmail";
import { InputWithValidation } from "./InputWithValidation";
import { FormInputMessage } from "../atoms/formErrOrSuccessMessage";
import { AccountWrapper } from "./Account";

import "./Account.css";

export type SignUpProps = {
  setSignType: (signType: TUserState) => void;
};

export const SignUp: FC<SignUpProps> = ({ setSignType }) => {
  const [formData, setFormData] = useState({
    email: { data: "", errorMessage: "" },
    username: { data: "", errorMessage: "" },
    password: { data: "", errorMessage: "" },
    password2: { data: "", errorMessage: "" },
  } as TFormData);
  const { dispatch } = useStateContext();
  const [signInErrorMessage, setSignInErrorMessage] = useState({} as TMsgType);

  const isSubmitButtonDisabled =
    formData.email.data === "" ||
    formData.password.data === "" ||
    formData.password2.data === "" ||
    formData.email.errorMessage !== "" ||
    formData.password.errorMessage !== "" ||
    formData.password2.errorMessage !== "";

  return (
    <AccountWrapper title="Sign up">
      <>
        <div className="login-form-field mt-2r">
          <InputWithValidation
            inputData={formData.email}
            label="Your email"
            onChange={(newData: string) => {
              setFormData({
                ...formData,
                email: {
                  ...formData.email,
                  data: newData,
                  errorMessage: validateEmail(newData)
                    ? ""
                    : "Enter a valid email address",
                },
              });
            }}
          />
          <FormInputMessage msg={{ errMsg: formData.email.errorMessage }} />
        </div>

        <div className="login-form-field mt-2r">
          <InputWithValidation
            inputData={formData.username!}
            label="Your username"
            onChange={(newData: string) => {

              const formDataUsername = formData.username!;
              // TODO: Add check if username is already taken

              setFormData({
                ...formData,
                username: {
                  ...formDataUsername,
                  data: newData,
                },
              });
            }}
          />
          <FormInputMessage msg={{ errMsg: formData.username?.errorMessage }} />
        </div>

        <div className="login-form-field mt-2r">
          <InputWithValidation
            inputData={formData.password}
            label="Password"
            onChange={(newData: string) => {
              setFormData({
                ...formData,
                password: {
                  ...formData.password,
                  data: newData,
                  errorMessage:
                    newData.length > 7
                      ? ""
                      : "Your password must have 8 or more characters",
                },
              });
            }}
            isPasswordWithEye={true}
          />
          <FormInputMessage msg={{ errMsg: formData.password.errorMessage }} />
        </div>

        <div className="login-form-field mt-2r">
          <InputWithValidation
            inputData={formData.password2}
            label="Repeat your password"
            onChange={(newData: string) => {
              setFormData({
                ...formData,
                password2: {
                  ...formData.password2,
                  data: newData,
                  errorMessage:
                    newData !== formData.password.data
                      ? "The passwords don't match"
                      : "",
                },
              });
            }}
            isPasswordWithEye={true}
          />
          <FormInputMessage msg={{ errMsg: formData.password2.errorMessage }} />
        </div>

        <div className="login-form-field mt-3r mb-1r">
          <button
            type="submit"
            aria-label="Update password"
            className={`login-button primary${isSubmitButtonDisabled ? " disabled" : ""}`}
            disabled={isSubmitButtonDisabled}
            onClick={() => {
              if (!isSubmitButtonDisabled) {
                signUpFn({
                  formData,
                  setSignType,
                  setSignInErrorMessage,
                  dispatch,
                });
              }
            }}
          >
            Sign up
          </button>
          <FormInputMessage msg={signInErrorMessage} />
        </div>
        <div className="login-form-field mt-2r mb-1r">
          You already have an account?
          <a
            href="#home"
            className="login-link ml-05r"
            onClick={() => {
              setSignType("signin");
            }}
          >
            Sign in
          </a>
        </div>
      </>
    </AccountWrapper>
  );
};
