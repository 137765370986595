import { TObject, itemIF, metaFieldIF } from "../../data/types/item";
import { snippetIF } from "../../data/types/snippets";
import { itemTypes } from "../meta/getMetas";
import { getMetaWithAllFields } from "../meta/getMetaWithAllFields";
import { getItemValuesAsObj } from "./getItemValuesAsObject";
import { getMetaFromSnippet } from "../snippets/addAndGetMetas";

export const getItemFieldValueStringForAddOrRemoveFieldGroup = ({
  itemType,
  fieldMeta,
  item,
  snippet,
  attr,
}: {
  itemType: itemTypes;
  fieldMeta: metaFieldIF;
  item: itemIF;
  snippet: snippetIF;
  attr: TObject;
}) => {
  try {
    const addOrRemoveItems: itemIF[] = item.value?.[fieldMeta.name]?.value;

    if (!addOrRemoveItems || !addOrRemoveItems[0]) {
      return;
    }

    const fieldName = addOrRemoveItems[0].name;
    if (!fieldName) {
      console.warn(`Empty add or remove group: `, addOrRemoveItems);
      return;
    }

    const meta =
      getMetaFromSnippet({
        snippet,
        itemType,
        itemName: fieldName,
      }) ?? getMetaWithAllFields({ itemType, itemName: fieldName, snippet });

    const fieldSeparator = meta?.fieldSeparator ?? "";

    const aRAttr: Record<string, string> = {};
    for (const fgItem of addOrRemoveItems) {
      const newAttr = getItemValuesAsObj({
        itemType,
        item: fgItem,
        snippet,
      });

      if (!newAttr) {
        continue;
      }

      // when key value field not just the value has changed but the key as well

      const keys = Object.keys(newAttr);
      for (const key of keys) {
        const value = newAttr[key];
        if (!value) {
          continue;
        }
        if (!aRAttr[key]) {
          aRAttr[key] = value;
        } else {
          aRAttr[key] += `${fieldSeparator} ` + value;
        }
      }
    }

    const keys = Object.keys(aRAttr);
    for (const key of keys) {
      if (aRAttr[key]) {
        attr[key] = aRAttr[key];
      }
    }

    return true;
  } catch (e) {
    console.error(
      "Error in getItemFieldValueStringForAddOrRemoveFieldGroup",
      e
    );
  }
};
