import { actionIF } from "../../../data/types/item";
import { snippetIF, snippetsIF } from "../../../data/types/snippets";
import {
  DELETE_SELECTOR_DESCENDANTS_AND_PROPERTIES,
  CHANGE_SNIPPET,
} from "./actions";
import {
  COPY_SVG_FROM_OTHER_SNIPPET,
  CLONE_ITEM_AND_CHILDREN,
} from "../items/actions";
import { addNewItemWithDescendants } from "../../items/cloneItem";
import { deleteSelectorAndProperties } from "../items/deleteItem";
import { addSelectorsAndProperties } from "../../items/addSelectorsAndProperties";
import { itemTypes } from "../../meta/getMetas";
import { getSelectorProperties } from "../../items/addItems";
import { replaceItemsAndEditItemIdOfType } from "../../snippets/addAndGetMetas";
import { getItemsOfType } from "../../snippets/addAndGetMetas";

type TSnippetReducerNew = {
  snippet: snippetsIF;
  action: actionIF;
};

export const changeSnippetReducer = ({
  snippet,
  action,
}: TSnippetReducerNew) => {
  const editSnippet = snippet.snippets.find(
    (s) => s.id === snippet.editSnippetId
  );
  const itemType = action.itemType;

  if (!editSnippet) {
    console.warn("changeSnippetReducer: no editSnippet");
    return snippet;
  }

  try {
    switch (action.type) {
      case DELETE_SELECTOR_DESCENDANTS_AND_PROPERTIES:
        if (!editSnippet) {
          console.warn(
            "DELETE_SELECTOR_DESCENDANTS_AND_PROPERTIES: no editSnippet"
          );
          return snippet;
        }
        const selectors = editSnippet?.data?.selectors;
        const editItemId = editSnippet?.data?.editSelectorId || 0;
        const itemId = action.selectorId ? action.selectorId : editItemId;
        const properties = editSnippet?.data?.properties;

        const deleteResult = deleteSelectorAndProperties({
          items: selectors,
          itemId,
          editItemId,
          properties,
        });

        return {
          ...snippet,
          snippets: snippet.snippets.map((s) => {
            if (s.id === snippet.editSnippetId) {
              return {
                ...s,
                data: {
                  ...s.data,
                  selectors: deleteResult.items,
                  editSelectorId: deleteResult.editItemId,
                  properties: deleteResult.properties,
                  editPropertyId: 0,
                },
              };
            }
            return s;
          }) as snippetIF[],
        };

      case COPY_SVG_FROM_OTHER_SNIPPET:
      case CLONE_ITEM_AND_CHILDREN:
        // close within the edit snippet
        if (!itemType || !editSnippet) {
          console.warn("CLONE_ITEM_AND_CHILDREN: no itemType or editSnippet");
          return snippet;
        }

        const snippetFrom = action.snippetFromId
          ? snippet.snippets.find((s) => s.id === action.snippetFromId)
          : editSnippet;
        if (!snippetFrom) {
          console.warn("CLONE_ITEM_AND_CHILDREN: no snippetFrom");
          return snippet;
        }

        const snippetTo = editSnippet;

        const newItemsToEditItemAndOldNewIds = addNewItemWithDescendants({
          itemType,
          itemId: action?.itemFromId,
          itemToId: action?.itemToId,
          snippetFrom,
          snippetTo,
        });

        if (!newItemsToEditItemAndOldNewIds) {
          console.warn("CLONE_ITEM_AND_CHILDREN: no ciacResult");
          return snippet;
        }

        const newItemsTo = newItemsToEditItemAndOldNewIds.items;
        const newEditItemToId = newItemsToEditItemAndOldNewIds.editItemId;
        const newSnippetTo = replaceItemsAndEditItemIdOfType({
          itemType,
          snippet: snippetTo,
          items: newItemsTo,
          editItemId: newEditItemToId,
        });

        // depending on the itemType html, svg, or selectors are added

        // if itemType is html or svg, add assigned selectors and their properties as well
        if (
          snippetFrom.id !== snippetTo.id &&
          (itemType === itemTypes.BLOCK_HTML || itemType === itemTypes.SVGS)
        ) {
          const itemsTo = getItemsOfType({
            itemType,
            snippet: snippetTo,
          });

          const itemsAdded = newItemsTo.filter(
            (i) => !itemsTo.find((it) => it.id === i.id)
          );
          const newSnippetTo2 = addSelectorsAndProperties({
            itemType,
            htmlsOSvgs: itemsAdded,
            snippetFrom,
            snippetTo: newSnippetTo,
          });

          return {
            ...snippet,
            snippets: snippet.snippets.map((s) => {
              if (s.id === snippetTo.id) {
                return newSnippetTo2;
              }
              return s;
            }),
          };
        }

        if (
          snippetFrom.id === snippetTo.id &&
          itemType === itemTypes.SELECTORS
        ) {
          const oldNewIds = newItemsToEditItemAndOldNewIds.oldNewIds;
          const newSnippetTo2 = getSelectorProperties({
            oldNewIds: oldNewIds,
            snippetFrom,
            snippetTo: newSnippetTo,
          });
          return {
            ...snippet,
            snippets: snippet.snippets.map((s) => {
              if (s.id === snippetTo.id) {
                return newSnippetTo2;
              }
              return s;
            }),
          };
        }

        return {
          ...snippet,
          snippets: snippet.snippets.map((s) => {
            if (s.id === snippetTo.id) {
              return newSnippetTo;
            }
            return s;
          }),
        };

      case CHANGE_SNIPPET:
        const { fieldName, value } = action;
        if (
          !fieldName ||
          (!value && value !== "" && value !== 0 && value !== false)
        ) {
          console.warn("CHANGE_SNIPPET: no fieldName");
          return snippet;
        }

        const newSnippet = {
          ...snippet,
          snippets: snippet.snippets.map((s) => {
            if (s.id === snippet.editSnippetId) {
              return {
                ...s,
                [fieldName]: value,
              };
            }
            return s;
          }),
          editSnippetId: fieldName === "id" ? value : snippet.editSnippetId,
        };

        return newSnippet;

      default:
    }
  } catch (error) {
    console.error("snippetReducer error", error);
    return snippet;
  }
};
