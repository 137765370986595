import { snippetIF } from "../../data/types/snippets";
import { getUserId } from "../signinup/handleTokens";
import { getServerSnippet } from "./getSnippet";
import { updateServerSnippet } from "../snippets/updateSnippet";

const msgDownloadSuccessOwnSnippet =
  "Your snippet is added to your local collection.";
const msgDownloadSuccessOtherSnippet =
  "A copy of the snippet is added to your local collection and the snippet got a popularity point.";

export const updateServerSnippetAfterDownload = async (
  snippetId: string
): Promise<{ msg?: string; copyCount?: number }> => {
  try {
    const serverSnippet = await getServerSnippet(snippetId);
    if (!serverSnippet) {
      return {
        msg: "Error: Failed to download snippet.",
      };
    }

    const userId = getUserId();

    if (serverSnippet.owner !== userId) {
      const newCopyCount = serverSnippet.copyCount
        ? serverSnippet.copyCount + 1
        : 2;
      const serverSnippetWithNewCopyCount = {
        ...serverSnippet,
        copyCount: newCopyCount,
      } as snippetIF;
      await updateServerSnippet({
        editSnippet: serverSnippetWithNewCopyCount,
      });
      return {
        msg: msgDownloadSuccessOtherSnippet,
        copyCount: newCopyCount,
      };
    }

    return {
      msg: msgDownloadSuccessOwnSnippet,
    };
  } catch (error) {
    console.log("Error in updateServerSnippetAfterDownload", error);
    return {
      msg: "Error: Failed to download snippet.",
    };
  }
};
