import { snippetIF } from "../../../data/types/snippets";
import { getNewSnippet } from "../../../data/snippet/initalValues";
import { v4 as uuidv4 } from "uuid";
const clone = require("rfdc")();

export const getNewSnippetOrCopy = ({
  snippet,
  isCopy,
}: {
  snippet?: snippetIF;
  isCopy?: boolean;
}) => {
  if (isCopy) {
    if (!snippet) {
      console.warn("No snippet found to copy");
      return;
    }
    const newSnippet = clone(snippet) as snippetIF;
    return {
      ...newSnippet,
      id: uuidv4(),
      name: `${newSnippet.name} copy`,
      owner: "anonymous",
      public: false,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };
  }

  return getNewSnippet();
};
