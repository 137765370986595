import { Color } from "culori";

export type tRgba = { r: number; g: number; b: number; a: number };
export type tHsla = { h: number; s: number; l: number; a: number };
export enum eHsl {
  hue = "h",
  saturation = "s",
  lightness = "l",
  transparency = "a",
}

export const noColourRgba: tRgba = {
  r: 0,
  g: 0,
  b: 0,
  a: 1,
};
export const disabledRgba: tRgba = { r: 204, g: 204, b: 204, a: 1 };



export const convertRgbaToCulori = (rgba: tRgba): Color => {
  const { r, g, b, a } = rgba;
  return {
    mode: "rgb",
    r: r / 255,
    g: g / 255,
    b: b / 255,
    alpha: a ?? 1,
  };
};
