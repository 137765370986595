import { FC } from "react";
import { useStateContext } from "../../lib/state/state";
import { itemIF } from "../../data/types/item";
import {
  CONNECTOR_CATEGORIES_HELP,
  CONNECTORS,
} from "../../data/selectors/constants/all";
import { addRemoveConnector } from "../../lib/reducer/items/actions";
import { SectionTitle } from "../modules/SectionTitle";
import { useDivPosition } from "../modules/GetPositionWrapper";

interface propsIF {
  editSelector?: itemIF;
}

export const AddConnectorBtns: FC<propsIF> = ({ editSelector }) => {
  const { dispatch } = useStateContext();

  const { divRef, divPos } = useDivPosition();

  return (
    <div className="add-buttons-box mb-1r" ref={divRef}>
      <SectionTitle
        label="Add connector"
        help={CONNECTOR_CATEGORIES_HELP.connector}
        direction="left"
        size="small"
        parentPosition={divPos}
      />
      <div className="btn-row">
        {CONNECTORS.map((con) => {
          const isDisabled = !editSelector || editSelector.name === "@media";
          return (
            <button
              type="button"
              key={con.name}
              className={`button ${editSelector?.prefix?.name === con.name ? "blue" : "blue-outline"}${isDisabled ? " disabled" : ""}`}
              onClick={() => {
                if (isDisabled) return;
                dispatch(
                  addRemoveConnector({
                    name: con.name,
                  })
                );
              }}
            >
              {con.display}
            </button>
          );
        })}
      </div>
    </div>
  );
};
