import { metaIF, EMetaFieldTypes } from "../../types/item";
import { gridUnits, standardUnits } from "./utils";

const helpObj = {
  gridColRowStartEndHelp:
    "  \n" +
    "With `grid-column-start`, `grid-column-end`, `grid-row-start` and `grid-row-end` " +
    "you can change the position of cells in the grid. " +
    "For example, if the grid-column and -row start of the 1st cell " +
    "is 2 and the column and row end is 4, " +
    "the cell is not just stretching over 4 cells, " +
    "but is additionally relocated to start from 2nd column and 2nd row. ",
  allTopLeftBottomRightHelpText:
    "#### Select all, right, top, bottom or left  \n" +
    "When you select `all`, the values for this property are applied to all of its sides.  \n" +
    "To define the property for one side only, select `top`, `right`, `bottom` or `left`.  \n" +
    "If you want to set different values for the sides of the element, " +
    "add multiple properties, the general value with `all` first " +
    "and the more specific setting underneath. " +
    "Settings further down in the list overwrite settings on top.",
};

const gridTemplateColumns:metaIF = {
  name: "grid-template-columns",
  category: ["GRID"],
  addToShorthand: "grid-template-columns",
  shorthandSeparator: " ",
  setOnParent: true,
  addMultipleTimes: true,
  fieldsOrder: [
    "repeat",
    "repeatNumberOfColumns",
    "numberOfColumns",
    "templateColumns",
  ],
  fields: [
    {
      name: "repeat",
      type: EMetaFieldTypes.boolean,
    },
    {
      name: "repeatNumberOfColumns",
      type: EMetaFieldTypes.select,
      options: [{ id: "value" }, { id: "auto-fit" }, { id: "auto-fill" }],
    },
    {
      name: "numberOfColumns",
      type: EMetaFieldTypes.number,
      dependentOn: {
        field: "repeatNumberOfColumns",
        values: ["value"],
      },
    },
    {
      name: "templateColumns",
      type: EMetaFieldTypes.addOrRemoveFieldGroup,
      onOff: true,
    },
  ],
  group: [
    {
      name: "repeatG",
      fields: ["repeat", "repeatNumberOfColumns", "numberOfColumns"],
      display: ["repeat", "select", "number of columns"],
      warning: `### Auto-fill/-fit with minmax\nUse repeat auto-fill and -fit with minmax, or you'll see an error on Chrome.`,
    },
    {
      name: "templateColumnsG",
      fields: ["templateColumns"],
      display: ["columns"],
    },
  ],
  wrapperForGroup: [
    {
      name: "grid-template-columns",
      fields: [
        "repeat",
        "repeatNumberOfColumns",
        "numberOfColumns",
        "templateColumns",
      ],
      wrapperFn: (vObj) => {
        const repeat = vObj.repeat;
        const repeatNumberOfColumns = vObj.repeatNumberOfColumns;
        const numberOfColumns = vObj.numberOfColumns;
        const templateColumns = vObj.templateColumns;

        if (repeat) {
          const number = numberOfColumns ?? repeatNumberOfColumns;
          return `repeat(${number}, ${templateColumns})`;
        }

        return templateColumns;
      },
    },
  ],
  default: {
    repeat: {
      value: false,
      onOff: false,
    },
    repeatNumberOfColumns: {
      value: "value",
    },
    numberOfColumns: {
      value: 4,
    },
    templateColumns: {
      value: [],
      onOff: true,
    },
  },
};

export const CSS_PROPERTIES_GRID: metaIF[] = [
  {
    name: "align-content-grid",
    code: "align-content",
    category: ["GRID"],
    setOnParent: true,
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "start",
            help: "#### start (default) \nThe rows are aligned at the top of the grid.",
          },
          {
            id: "center",
            help: "#### center  \nThe rows are aligned in the center of the grid.",
          },
          {
            id: "end",
            help: "#### end  \nThe rows are aligned at the bottom of the grid.",
          },
          {
            id: "space-evenly",
            help:
              "#### space-evenly  \n" +
              "The available vertical space is divided by the number of rows plus 1. " +
              "This space is added on top, between the rows and at the bottom of the grid.",
          },
          {
            id: "space-around",
            help:
              "#### space-around  \n" +
              "The available vertical space is divided by the number of columns. " +
              "The result is again divided by 2 and added on top and bottom of each row.",
          },
          {
            id: "space-between",
            help:
              "#### space-between  \n" +
              "The available vertical space is divided by the number of columns minus 1. " +
              "This space is added between the rows.",
          },
        ],
        help:
          "### align-content  \n" +
          "If there is space in a grid to move its rows up or down, " +
          "`align-content` defines how the rows are vertically aligned.  \n",
      },
    ],
    default: { main: { value: "center" } },
  },
  {
    name: "align-self-grid",
    code: "align-self",
    category: ["GRID"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "start",
          },
          {
            id: "center",
          },
          {
            id: "end",
          },
          {
            id: "stretch",
          },
        ],
        help:
          "### align-self  \n" +
          "Aligns the cell at the top of the row, the center, the bottom or stretches it from top to bottom.",
      },
    ],
    default: { main: { value: "center" } },
  },
  {
    name: "display-grid",
    code: "display",
    category: ["GRID"],
    setOnParent: true,
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "grid",
          },
          {
            id: "inline-grid",
          },
        ],
        help: "#### display:grid  \nDefines a `grid` container.",
        warning:
          "#### display:grid  \n" +
          "For any grid function to work, `display:grid` or `display:inline-grid` must be set on the container.",
      },
    ],
    default: { main: { value: "grid" } },
  },
  {
    name: "grid-column-start",
    category: ["GRID"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        help:
          "### grid-column-start  \n" +
          "Starting column for a cell which stretches over several columns." +
          helpObj.gridColRowStartEndHelp,
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["grid-column-start"],
      },
    ],
    default: {
      main: {
        value: 1,
      },
    },
  },
  {
    name: "grid-column-end",
    category: ["GRID"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        help:
          "### grid-column-end  \n" +
          "A cell stretching over several columns starts at the column in `grid-column-start` and ends before the column `grid-column-end`." +
          helpObj.gridColRowStartEndHelp,
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["grid-column-end"],
      },
    ],

    default: {
      main: {
        value: 3,
      },
    },
  },
  {
    name: "grid-gap",
    category: ["GRID"],
    setOnParent: true,
    fieldsOrder: ["row", "column"],
    fields: [
      {
        name: "row",
        type: EMetaFieldTypes.number,
        units: [{ id: "px" }, { id: "rem" }, { id: "%" }],
        help: "### grid-gap row  \nGap between the rows of the grid.",
      },
      {
        name: "column",
        type: EMetaFieldTypes.number,
        units: [{ id: "px" }, { id: "rem" }, { id: "%" }],
        help: "### grid-gap column  \nGap between the columns of the grid.",
      },
    ],

    group: [
      {
        name: "rowG",
        display: ["row"],
        fields: ["row"],
      },
      {
        name: "columnG",
        display: ["column"],
        fields: ["column"],
      },
    ],

    default: {
      row: {
        value: 10,
        unit: "px",
        onOff: true,
      },
      column: {
        value: 20,
        unit: "px",
        onOff: true,
      },
    },
  },
  {
    name: "grid-row-start",
    category: ["GRID"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        help:
          "### grid-row-start  \n" +
          "Starting row for a cell which stretches over several rows." +
          helpObj.gridColRowStartEndHelp,
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["grid-row-start"],
      },
    ],
    default: {
      main: {
        value: 1,
      },
    },
  },
  {
    name: "grid-row-end",
    category: ["GRID"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        help:
          "### grid-row-end  \n" +
          "A cell stretching over several rows starts at the row in `grid-row-start` and ends before the row `grid-row-end`." +
          helpObj.gridColRowStartEndHelp,
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["grid-row-end"],
      },
    ],
    default: {
      main: {
        value: 3,
      },
    },
  },

  {
    name: "templateColumns",
    category: ["addOrRemoveFieldGroup"],
    display: "column",
    fieldsOrder: [
      "main",
      "width",
      "minWidth",
      "maxWidth",
      "fitContentWidth",
      "lineName",
    ],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        smallInput: true,
        onOff: true,
        options: [
          { id: "value" },
          { id: "minmax" },
          { id: "none" },
          { id: "auto" },
          { id: "min-content" },
          { id: "max-content" },
          { id: "fit-content" },
          { id: "linename" },
        ],
      },
      {
        name: "width",
        type: EMetaFieldTypes.number,
        units: gridUnits,
        dependentOn: {
          field: "main",
          values: ["value"],
        },
        onOff: true,
      },
      {
        name: "minWidth",
        type: EMetaFieldTypes.number,
        units: gridUnits,
        dependentOn: {
          field: "main",
          values: ["minmax"],
        },
        onOff: true,
      },
      {
        name: "maxWidth",
        type: EMetaFieldTypes.number,
        units: gridUnits,
        dependentOn: {
          field: "main",
          values: ["minmax"],
        },
        onOff: true,
      },
      {
        name: "fitContentWidth",
        type: EMetaFieldTypes.number,
        units: standardUnits,
        dependentOn: {
          field: "main",
          values: ["fit-content"],
        },
        onOff: true,
      },
      {
        name: "lineName",
        type: EMetaFieldTypes.string,
        dependentOn: {
          field: "main",
          values: ["linename"],
        },
        onOff: true,
      },
    ],
    group: [
      {
        name: "mainG",
        fields: [
          "main",
          "width",
          "minWidth",
          "maxWidth",
          "fitContentWidth",
          "lineName",
        ],
        display: ["select", "width", "min", "max", "fit-content", "line name"],
      },
    ],

    wrapperForGroup: [
      {
        name: "templateColumns",
        fields: [
          "main",
          "width",
          "minWidth",
          "maxWidth",
          "fitContentWidth",
          "lineName",
        ],
        wrapperFn: (vObj) => {
          const minWidth = vObj.minWidth;
          const maxWidth = vObj.maxWidth;
          if (minWidth && maxWidth) {
            return `minmax(${minWidth}, ${maxWidth})`;
          }

          const width = vObj.width;
          if (width) {
            return width;
          }

          const fitContentWidth = vObj.fitContentWidth;
          if (fitContentWidth) {
            return `fit-content(${fitContentWidth})`;
          }

          const lineName = vObj.lineName;
          if (lineName) {
            const lineNameStr = `[${lineName}]`;
            return lineNameStr;
          }

          const main = vObj.main;
          if (main) {
            return main;
          }

          return "";
        },
      },
    ],
    default: {
      main: {
        value: "value",
      },
      width: {
        value: 50,
        unit: "%",
      },
      minWidth: {
        value: 100,
        unit: "px",
      },
      maxWidth: {
        value: 200,
        unit: "px",
      },
      fitContentWidth: {
        value: 400,
        unit: "px",
      },
      lineName: {
        value: "first",
      },
    },
  },
  gridTemplateColumns,
  {
    ...gridTemplateColumns,
    name: "grid-template-rows",
    addToShorthand: "grid-template-rows",
    wrapperForGroup: gridTemplateColumns.wrapperForGroup?.map((w) => {
      if (w.name === "grid-template-columns") {
        return { ...w, name: "grid-template-rows" };
      }
      return w;
    }),
  },
  {
    name: "templateRows",
    category: ["addOrRemoveFieldGroup"],
    display: "row",
    fieldsOrder: ["main", "height", "minmaxMin", "minmaxMin"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        smallInput: true,
        options: [{ id: "value" }, { id: "auto" }, { id: "minmax" }],
      },
      {
        name: "height",
        type: EMetaFieldTypes.number,
        units: [
          { id: "%", min: 0, max: 100 },
          { id: "px" },
          { id: "rem" },
          { id: "fr" },
        ],
        dependentOn: {
          field: "main",
          values: ["value"],
        },
      },
      {
        name: "minmaxMin",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: [
          { id: "%", min: 0, max: 100 },
          { id: "px" },
          { id: "rem" },
          { id: "fr" },
        ],
        dependentOn: {
          field: "main",
          values: ["minmax"],
        },
      },
      {
        name: "minmaxMax",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: [
          { id: "%", min: 0, max: 100 },
          { id: "px" },
          { id: "rem" },
          { id: "fr" },
        ],
        dependentOn: {
          field: "main",
          values: ["minmax"],
        },
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["select"],
      },
      {
        name: "widthG",
        fields: ["height"],
        display: ["row height"],
      },
      {
        name: "minmaxG",
        fields: ["minmaxMin", "minmaxMax"],
        display: ["min", "max"],
        help: "### minmax  \nSee for example [Joomlashack - The minmax() Function](https://www.joomlashack.com/blog/tutorials/css-grid-12-the-minimax-function/)",
      },
    ],
    wrapperForGroup: [
      {
        name: "templateRows",
        fields: ["main", "height", "minmaxMin", "minmaxMax"],
        wrapperFn: (vObj) => {
          let vStr = "";
          const main = vObj.main;
          if (main === "auto") {
            vStr = "auto";
          }
          if (main === "value") {
            const height = vObj.height;
            vStr = height;
          }
          if (main === "minmax") {
            const minmaxMin = vObj.minmaxMin;
            const minmaxMax = vObj.minmaxMax;
            vStr = `minmax(${minmaxMin}, ${minmaxMax})`;
          }

          return vStr;
        },
      },
    ],
    default: {
      main: {
        value: "value",
      },
      height: {
        value: 50,
        unit: "%",
      },
      minmaxMin: {
        value: 100,
        unit: "px",
      },
      minmaxMax: {
        value: 1,
        unit: "fr",
      },
    },
  },

  {
    name: "justify-content-grid",
    code: "justify-content",
    category: ["GRID"],
    setOnParent: true,
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "start",
            help: "#### start (default)  \nColumns are aligned on the left of the grid.",
          },
          {
            id: "center",
            help:
              "#### center  \n" +
              "Columns are centered in the grid without extra space between the columns.",
          },
          {
            id: "end",
            help: "#### end  \nColumns are aligned on the right of the grid.",
          },
          {
            id: "space-evenly",
            help:
              "#### space-evenly  \n" +
              "The available horizontal space is divided by the number of columns plus 1. " +
              "This space is added on the left and right of the grid and between the columns.",
          },
          {
            id: "space-around",
            help:
              "#### space-around  \n" +
              "The available horizontal space is divided by the number of columns. " +
              "The result is again divided by 2 and added on the left and right side of each column.",
          },
          {
            id: "space-between",
            help:
              "#### space-between  \n" +
              "The available horizontal space is divided by the number of columns minus 1. " +
              "This space is added between the columns.",
          },
          {
            id: "stretch",
            help:
              "#### stretch (default)  \n" +
              "The available horizontal space is added in even measure to the columns with `grid-template-columns` width `auto`.",
          },
        ],
        help: "### justify-content  \n",
      },
    ],
    default: { main: { value: "center" } },
  },
  {
    name: "justify-self-grid",
    code: "justify-self",
    category: ["GRID"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "start",
          },
          {
            id: "center",
          },
          {
            id: "end",
          },
          {
            id: "stretch",
          },
        ],
        help:
          "### justify-self  \n" +
          "Aligns the cell to the left of the column, in the center, to the right or stretches it from the left to the right.",
      },
    ],
    default: { main: { value: "center" } },
  },
];
