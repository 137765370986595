import { FC, useState } from "react";
import { snippetIF } from "../../data/types/snippets";
import { useStateContext } from "../../lib/state/state";
import { getUserId } from "../../lib/signinup/handleTokens";
import { ModalWrapperRelativToParentPosition } from "../modules/TailwindModal";
import { TPosition } from "../../lib/ui/getModalPosition";
import Markdown from "react-markdown";
import { Tooltip } from "../atoms/tooltip";
import { EditFieldCheckboxSimple } from "../forms/Checkbox";
import { createOrUpdateSnippet } from "./createOrUpdateSnippet";
import { Notification } from "../atoms/Notification";

const confirmPublishTextGoPublic = `Do you really want to publish this snippet to the **Gallery**?

If you select the **public** flag, then everyone can see your snippet in the **Gallery** and can copy it.`;

const confirmPublishTextGoPrivate = `Do you really want to remove this snippet from the **Gallery**?

If you remove the public flag from your snippet, then you still can manage its cloud version in the **Gallery**, but noone else can see it there or copy it.`;

const ConfirmPublishSnippet: FC<{
  snippet?: snippetIF;
  setIsModalOpen?: (isOpen: boolean) => void;
  setMsg?: (value: React.SetStateAction<string>) => void;
  setShowMsg?: (value: React.SetStateAction<boolean>) => void;
}> = ({ snippet, setIsModalOpen, setMsg, setShowMsg }) => {
  const { dispatch } = useStateContext();

  if (!snippet) return null;

  const isPublic = snippet.public;
  const userId = getUserId();

  return (
    <div className="p-1r pl-15r">
      <Markdown>
        {isPublic
          ? userId
            ? confirmPublishTextGoPrivate
            : "Please sign in to unpublish your snippet"
          : userId
          ? confirmPublishTextGoPublic
          : "Please sign in to publish your snippet"}
      </Markdown>
      <div className="flex gap-1 mt-2r mb-1r">
        <button
          type="button"
          className={`button orange${userId ? "" : " disabled"}`}
          onClick={() => {
            createOrUpdateSnippet({
              editSnippet: snippet,
              userId,
              setIsPublic: true,
              dispatch,
              setIsModalOpen,
              setMsg,
              setShowMsg,
            });
          }}
        >
          {isPublic ? "Unpublish" : "Publish"}
        </button>
        <button
          type="button"
          className="button gray-outline"
          onClick={() => {
            setIsModalOpen && setIsModalOpen(false);
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export const PublishUnpublishBtn: FC<{
  snippet: snippetIF;
  divPos?: TPosition;
  isDisabled?: boolean;
}> = ({ snippet, divPos, isDisabled }) => {
  const {
    state: {
      settings: { user },
    },
  } = useStateContext();

  const [msg, setMsg] = useState("");
  const [showMsg, setShowMsg] = useState(false);

  const isUserSignedIn = user?.email;
  const isPublishButtonDisabled = isDisabled || !isUserSignedIn;

  return (
    <ModalWrapperRelativToParentPosition
      title={
        isUserSignedIn ? (snippet.public ? "Unpublish" : "Publish") : "Sign in"
      }
      component={
        <ConfirmPublishSnippet
          snippet={snippet}
          setMsg={setMsg}
          setShowMsg={setShowMsg}
        />
      }
      parentPosition={divPos}
      disabled={isDisabled || !isUserSignedIn}
    >
      <label
        className={`label${
          isPublishButtonDisabled ? " disabled" : ""
        } tooltip-container-parent`}
      >
        <span>public</span>
        <EditFieldCheckboxSimple
          fieldName="public"
          isChecked={snippet.public ?? false}
          isFieldDisabled={isPublishButtonDisabled}
          setValue={() => {}}
        />
        {isPublishButtonDisabled && (
          <Tooltip
            tooltipText={`Sign in to be able to ${
              snippet.public ? "publish to " : "unpublish from "
            }the Gallery.`}
            direction="top-left"
          />
        )}

              {showMsg && (
                <Notification
                  msg={msg}
                  notificationPosition="left"
                  setShowMsg={setShowMsg}
                />
              )}
        
      </label>
    </ModalWrapperRelativToParentPosition>
  );
};
