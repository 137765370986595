import { snippetIF } from "../../data/types/snippets";
import { initializeComposedSelectorItems } from "../../lib/render/css/editComposedSelectorItemNew";
import { createCssString } from "../render/css/getCssString";

export const getNewSnippetCss = ({
  editSnippet,
}: {
  editSnippet: snippetIF;
}):string => {
  editSnippet.composedSelectorObj = initializeComposedSelectorItems({
    snippet: editSnippet,
    });

  const snippetCss = createCssString({
    lineBreak: "\n",
    addSpaceAtLineStart: "",
    classComposedSelectorItems:
      editSnippet.composedSelectorObj?.classComposedSelectorItems,
    mediaComposedSelectorItems:
      editSnippet.composedSelectorObj?.mediaComposedSelectorItems,
    keyframeComposedSelectorItems:
      editSnippet.composedSelectorObj?.keyframeComposedSelectorItems,
  });

  return `\n${snippetCss}`;

};
