import { itemTypes } from "../../meta/getMetas";
import { snippetIF } from "../../../data/types/snippets";
import { itemAttributeChangeIF } from "../../../data/types/item";

export const DELETE_SELECTOR_DESCENDANTS_AND_PROPERTIES =
  "DELETE_SELECTOR_DESCENDANTS_AND_PROPERTIES";
export const deleteSelectorDescendantsAndProperties = ({
  selectorId,
  snippetId,
}: {
  selectorId?: string;
  snippetId?: string;
}) => ({
  type: DELETE_SELECTOR_DESCENDANTS_AND_PROPERTIES,
  selectorId,
  snippetId,
});

export const CHANGE_SNIPPET = "CHANGE_SNIPPET";
export const changeSnippet = ({ fieldName, value }: itemAttributeChangeIF) => {
  return {
    type: CHANGE_SNIPPET,
    itemType: itemTypes.SNIPPET,
    fieldName,
    value,
  };
};

export const COPY_SNIPPET = "COPY_SNIPPET";
export const copySnippet = (snippet?: snippetIF) => ({
  itemType: itemTypes.SNIPPET,
  type: COPY_SNIPPET,
  snippet,
});

export const ADD_SNIPPET = "ADD_SNIPPET";
export const addSnippet = () => ({
  itemType: itemTypes.SNIPPET,
  type: ADD_SNIPPET,
});

export const DELETE_SNIPPET = "DELETE_SNIPPET";
export const deleteSnippet = (snippetId?: string) => ({
  type: DELETE_SNIPPET,
  itemType: itemTypes.SNIPPET,
  snippetId,
});

export const SET_EDIT_SNIPPET = "SET_EDIT_SNIPPET";
export const setEditSnippetId = ({ snippetId }: { snippetId: string }) => {
  return {
    type: SET_EDIT_SNIPPET,
    itemType: itemTypes.SNIPPET,
    snippetId,
  };
};
