import { FC } from "react";
import { useStateContext } from "../../lib/state/state";
import { itemTypes } from "../../lib/meta/getMetas";
import { itemIF } from "../../data/types/item";
import { addItem } from "../../lib/reducer/items/actions";
import { getItemsInCategory } from "../../lib/css/main";
import { getAddItemIsDisabled } from "../../lib/items/getIsItemDisabled";
import { SectionTitle } from "../modules/SectionTitle";
import { useDivPosition } from "../modules/GetPositionWrapper";

export const AddSelectorBoxWithButtons: FC<{
  editSelector?: itemIF;
  selectors: itemIF[];
}> = ({ editSelector, selectors }) => {
  const { dispatch } = useStateContext();

  const itemsInCategories = getItemsInCategory({
    itemType: itemTypes.SELECTORS,
    withCategories: true,
  });

  const parent = selectors.find((i) => i.id === editSelector?.parent);
  const disabledMetas = getAddItemIsDisabled({
    editItem: editSelector,
    parent,
    items: itemsInCategories,
  });

  const itemType = itemTypes.SELECTORS;

  const { divRef, divPos } = useDivPosition();

  return (
    <>
      {itemsInCategories.map((iic) => {
        return (
          <div key={iic.name} className="add-buttons-box mb-1r" ref={divRef}>
            <SectionTitle
              label={iic.title!}
              help={iic.help}
              direction="left"
              size="small"
              parentPosition={divPos}
            />
            <div className="btn-row">
              {iic.items.map((meta) => {
                const isDisabled = disabledMetas.includes(meta.name);
                return (
                  <button
                    key={meta.name}
                    type="button"
                    className={`button blue-outline${isDisabled ? " disabled" : ""}`}
                    onClick={() => {
                      if (isDisabled) return;
                      dispatch(
                        addItem({
                          itemType,
                          name: meta.name,
                          addAfterItemId: editSelector?.id,
                        })
                      );
                    }}
                  >
                    {meta.display ? meta.display : meta.name}
                  </button>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};
