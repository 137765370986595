import { metaIF } from "../../data/types/item";

const getGroupAttributeName = ({
  groupName,
  meta,
}: {
  groupName: string;
  meta: metaIF;
}) => {
  if (meta.group) {
    const group = meta.group.find((g) => g.name === groupName);
    if (group) {
      if (group.javascript) {
        return group.javascript;
      }
      return groupName;
    }
  }
  if (meta.wrapperForGroup) {
    const group = meta.wrapperForGroup.find((g) => g.name === groupName);
    if (group) {
      if (group.javascript) {
        return group.javascript;
      }
      return groupName;
    }
  }
};

export const getAttrName = ({
  fieldName,
  meta,
}: {
  fieldName: string;
  meta: metaIF;
}): string => {
  const fieldMeta = meta.fields?.find((f) => f.name === fieldName);
  const groupAttributeName = getGroupAttributeName({
    groupName: fieldName,
    meta,
  });
  if (!fieldMeta && groupAttributeName) {
    return groupAttributeName;
  }

  if (fieldMeta?.javascript) {
    return fieldMeta.javascript;
  }

  return fieldName;
};

export const replaceAttrNameWithGroupNameOrJaveScript = ({
  attr,
  meta,
}: {
  attr: Record<string, string>;
  meta: metaIF;
}): Record<string, string> => {
  const fieldNames = Object.keys(attr);
  const newAttr: Record<string, string> = {};
  for (const fieldName of fieldNames) {
    const attrName = getAttrName({ fieldName, meta });
    newAttr[attrName] = attr[fieldName];
  }
  return newAttr;
};
