import React, { useState, FC, useRef } from "react";
import {
  detectColorFormat,
  formatColor,
} from "../../../lib/utils/culoriColor";
import { useOutsideAlerter } from "../../atoms/detectClickOutside";
import { ColorContinuum } from "./ColourPickerHsla_PickHSLA";
import { PresetColours } from "./ColourPickerHsla_PresetColours";
import { Color, parse } from "culori";
import "./ColourPickerHsla.css";

const ColorSwatch: FC<{
  isFieldDisabled?: boolean;
  color: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ isFieldDisabled, color, setIsOpen }) => {

  return (
    <div
      className={`swatch${isFieldDisabled ? " disabled" : ""}`}
      style={{ backgroundColor: color }}
      onClick={() => {
        if (isFieldDisabled) {
          return;
        }
        setIsOpen(true);
      }}
    ></div>
  );
};

export const ColourPickerAB2: FC<{
  colour: string;
  setColour: (color: string) => void;  
  presetColours?: string[];
  isFieldDisabled?: boolean;
}> = ({
  colour,
  setColour,
  presetColours,
  isFieldDisabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const format = detectColorFormat(colour);
  const culoriColor = parse(colour);
  const [pickerColor, setPickerColor] = useState<Color | undefined>(culoriColor);
  const popoverRef = useRef(null);

  const culoriPresetColours = presetColours?.map((presetColour) => parse(presetColour)).filter(c => c !== undefined) as Color[]; 

  useOutsideAlerter({
    ref: popoverRef,
    setOpen: setIsOpen,
    onClose: () => {
      if(!pickerColor || format === "unknown") {
        console.error('Invalid color provided');
        return null;
      };
      const colourString = formatColor({color: pickerColor, format});
      setColour(colourString);
    },
  });


  if(format === "unknown") {
    console.error('Invalid color provided');
    return null;
  }

  if (!pickerColor) {
    console.error('Invalid color provided');
    return null;
  }

  if (isFieldDisabled || !pickerColor) {
    const colourString = formatColor({color: pickerColor, format});
    return (
      <ColorSwatch isFieldDisabled={true} color={colourString} setIsOpen={setIsOpen} />
    );
  }

  return (
    <div className="picker">
      <ColorSwatch color={formatColor({color: pickerColor, format})} setIsOpen={setIsOpen} />

      {isOpen && (
        <div className="color-picker-popover" ref={popoverRef}>
          <div className="wrapper">
            <ColorContinuum color={pickerColor} setColor={setPickerColor} />
            <div className="mt-1r">
              <PresetColours colors={culoriPresetColours} setColor={setPickerColor} format={format} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
