import { useStateContext } from "../../lib/state/state";
import { setEditSnippetId } from "../../lib/reducer/snippet/actions";
import { DisplayHideChildren2 } from "../modules/DisplayOrHideSubs2";
import {
  SortAndFindSnippets,
  SelectSnippetButtons,
} from "../modules/SortAndFind";
import { SectionTitle } from "../modules/SectionTitle";
import { EditItemSnippet } from "../forms/EditSnippet";
import { AddOrCopySnippet } from "./AddOrCopySnippet";
import { CopySvgFromOtherSnippet } from "./CopySvgFromOtherSnippet";
import { CopyHtmlBetweenSnippets } from "./CopyHtmlBetweenSnippets";
import { Stylepack } from "../Stylepack/Main";

const SnippetsSelect = () => {
  const {
    state: {
      snippet: { snippets, editSnippetId },
    },
    dispatch,
  } = useStateContext();

  return (
    <div>
      <div className="mb-1r">
        <SectionTitle
          label="Select, add, copy or remove snippets"
          size="large"
        />
      </div>
      <div className="mb-1r">
        <SectionTitle label="Add, copy or remove" />
      </div>

      <AddOrCopySnippet />

      <div className="mt-1r">
        <div className="mb-1r">
          <SectionTitle label="Select snippet" />
        </div>
        <SortAndFindSnippets allSnippets={snippets}>
          <SelectSnippetButtons
            selectedSnippetId={editSnippetId}
            setSelectedSnippetId={(snippetId: string) => {
              dispatch(setEditSnippetId({ snippetId }));
            }}
          />
        </SortAndFindSnippets>
      </div>
      <div className="mt-1r mb-2r"></div>
    </div>
  );
};

const Mysnippets = () => {
  return (
    <div className="mb-2r">
      <DisplayHideChildren2
        titles={[
          "Snippets",
          "Edit",
          "Import HTML&CSS",
          "Import SVGs",
          "Stylepacks",
        ]}
      >
        <SnippetsSelect />

        <EditItemSnippet />

        <CopyHtmlBetweenSnippets />
        <CopySvgFromOtherSnippet />

        <Stylepack />
      </DisplayHideChildren2>
    </div>
  );
};

export default Mysnippets;
