import {
  metaIF,
  metaFieldIF,
  itemIF,
  TObj,
  EMetaFieldTypes,
} from "../types/item";
import { getCategoryMetas } from "../../lib/data/meta";
import { HTMLS_FORM } from "./constants-form";
import { randomString } from "../../lib/utils/random-string";

export const getHtmlMeta = (name: string) =>
  HTMLS.find((a: metaIF) => a.name === name);

export const BLOCK_HTML = "BLOCK_HTML";

export const getAllNames = () => HTMLS.map((h) => h.name);

export const HTMLS_CATEGORIES_TITLES = {
  text: "Add text",
  block: "Block Elements",
  inline: "Inline Elements",
  FORM: "Form Elements",
  textformatting: "Text Formatting",
  table: "Tables",
  quotation: "Quotations",
  computercode: "Computer Code",
  semantic: "Semantic Elements",
  media: "Media Elements",
} as TObj;

export const htmlHelpTexts = {
  helpId: `### ID

Enter ID for the HTML or SVG. Use characters, numbers, hyphens and underscores only. The first character of the ID must not be a number.

You can't enter any ID which already exists for another HTML or SVG within the current Snippet.`,
};

const fieldsAll = {
  videoAudioFields: [
    { name: "controls", type: EMetaFieldTypes.boolean, onOff: true },
    { name: "autoplay", javascript: "autoPlay", type: EMetaFieldTypes.boolean },
    { name: "loop", type: EMetaFieldTypes.boolean },
    { name: "muted", type: EMetaFieldTypes.boolean },
    {
      name: "poster",
      type: EMetaFieldTypes.string,
      help: "### poster  \nEnter the url of an image to be displayed while the video is downloading.",
    },
    {
      name: "preload",
      type: EMetaFieldTypes.select,
      options: [{ id: "auto" }, { id: "metadata" }, { id: "none" }],
      help: "### preload  \nSpecifies if and how the video should be loaded when the page loads.",
    },
    { name: "src", type: EMetaFieldTypes.string },
  ] as metaFieldIF[],
  tableCellFields: [
    {
      name: "colspan",
      javascript: "colSpan",
      type: EMetaFieldTypes.number,
      conditions: { integer: true },
      default: { value: 1 },
      help:
        "### colspan  \n" +
        "If you want one cell to take the space of a number of cells in this row, " +
        "enter the number as `colsan`.  \n" +
        'colspan="0" means that the respective cell stretches to the end of the row. If there are cells after this one in the row they are not displayed.',
    },
    {
      name: "rowspan",
      javascript: "rowSpan",
      type: EMetaFieldTypes.number,
      conditions: { integer: true },
      default: { value: 1 },
      help:
        "### rowspan  \n" +
        "Enter the number of rows over which the current cell should downwards stretch. " +
        "The cells underneath, which were before in the same column, are pushed one column to the right.  \n " +
        '`rolspan="0"` means that the respective cell is its own column and all cells underneath are pushed one column to the right.',
    },
  ] as metaFieldIF[],
  tableCellGroup: [
    {
      name: "colspan",
      fields: ["colspan"],
    },
    {
      name: "rowspan",
      fields: ["rowspan"],
    },
  ],
};

export const HTMLS: metaIF[] = [
  {
    name: "ALL",
    level: "block",
    category: ["ALL"],
    fieldsOrder: ["id", "title", "dataAttribute", "lang", "tabindex"],
    fields: [
      {
        name: "id",
        type: EMetaFieldTypes.string,
        conditions: {
          regex: "^[a-z-_][a-z0-9-_]*$",
        },
        help: htmlHelpTexts.helpId,
      },
      {
        name: "title",
        type: EMetaFieldTypes.string,
      },
      {
        name: "dataAttribute",
        type: EMetaFieldTypes.addOrRemoveFieldGroup,
      },
      {
        name: "lang",
        type: EMetaFieldTypes.string,
        autocomplete: "language",
        placeholder: true,
      },
      {
        name: "tabindex",
        javascript: "tabIndex",
        type: EMetaFieldTypes.number,
      },
    ],
    group: [
      {
        name: "id",
        fields: ["id"],
      },
      {
        name: "title",
        fields: ["title"],
      },
      {
        name: "dataAttributeG",
        display: ["data-attribute"],
        fields: ["dataAttribute"],
        help: `### data attribute

For a very good description how to write and what to do with data attributes, see [CSS-Tricks - A Complete Guide to Data Attributes](https://css-tricks.com/a-complete-guide-to-data-attributes/)`,
      },
      {
        name: "language",
        fields: ["lang"],
      },
      {
        name: "tabindex",
        fields: ["tabindex"],
        help: `### tabindex

The tabindex attribute in HTML is used to control the tab order of elements when navigating through a webpage using the keyboard. It determines the sequence in which elements receive focus when the "Tab" key is pressed.

\`\`\`
<element tabindex="value">Content</element>
\`\`\`

Where *value* is an integer that specifies the tab order of the element. *Value* can be

- *positive*: elements with a positive tabindex are focused in ascending order based on their tabindex values.
- *zero*: includes the element in the natural tab order
- *negative*: makes the element focusable, but it will not be reachable by the keyboard's "Tab" key.

`,
      },
    ],
    default: {
      id: {
        value: `id-${randomString(5)}`,
      },
      title: {
        value: "TITLE",
      },
      dataAttribute: {
        value: [] as itemIF[],
      },
      lang: {
        value: "en",
      },
      tabindex: {
        value: -1,
      },
    },
  },
  {
    name: "dataAttribute",
    display: "data-attribute",
    category: ["addOrRemoveFieldGroup"],
    fieldsOrder: ["dataAttributeKey", "dataAttributeValue"],
    fields: [
      {
        name: "dataAttributeKey",
        type: EMetaFieldTypes.string,
        useValueAsKeyOfField: "dataAttributeValue",
        conditions: {
          regex: "^[a-z0-9-]+$",
        },
      },
      {
        name: "dataAttributeValue",
        type: EMetaFieldTypes.string,
        conditions: {
          regex: "[a-z0-9-_ ]*",
        },
      },
    ],
    group: [
      {
        name: "keyValue",
        display: ["key", "value"],
        fields: ["dataAttributeKey", "dataAttributeValue"],
      },
    ],

    default: {
      dataAttributeKey: {
        value: "data-error",
        onOff: true,
      },
      dataAttributeValue: {
        value: "wrong",
        onOff: true,
      },
    },
  },
  ...HTMLS_FORM,

  {
    name: "img",
    level: "inline",
    category: ["inline"],
    allowedChildren: [],
    fieldsOrder: ["src", "upload", "url", "alt"],
    fields: [
      {
        name: "src",
        type: EMetaFieldTypes.select,
        onOff: true,
        placeholder: true,
        options: [
          {
            id: "upload",
            display: "src",
          },
          {
            id: "url",
            display: "src",
          },
        ],
      },
      {
        name: "upload",
        type: EMetaFieldTypes.upload,
        dependentOn: { field: "src", values: ["upload"] },
        onOff: true,
      },
      {
        name: "url",
        type: EMetaFieldTypes.string,
        dependentOn: { field: "src", values: ["url"] },
        onOff: true,
      },
      {
        name: "alt",
        type: EMetaFieldTypes.textarea,
        onOff: true,
        placeholder: true,
        help:
          "### alt  \n" +
          "Give a description of the image. This helps to identify the image by search engines and screen readers. " +
          "The alternate text is displayed, when the image cannot be found. ",
      },
    ],
    group: [
      {
        name: "srcG",
        fields: ["src", "upload", "url"],
      },
      {
        name: "alternate text",
        fields: ["alt"],
      },
    ],
    default: {
      src: {
        value: "upload",
      },
      upload: {
        value: {
          url: "",
          name: "",
          size: 0,
          type: "",
        },
      },
      url: {
        value: "https://cssexy.s3.eu-west-2.amazonaws.com/isleofarron.jpg",
        onOff: true,
      },
      alt: {
        value: "isleofarron",
        onOff: true,
      },
    },
  },

  {
    name: "br",
    level: "inline",
    category: ["inline"],
  },

  {
    name: "div",
    level: "block",
    category: ["block"],
  },

  {
    name: "h1",
    level: "block",
    category: ["block"],
    allowedChildren: ["inline"],
  },
  {
    name: "h2",
    level: "block",
    category: ["block"],
    allowedChildren: ["inline"],
  },
  {
    name: "h3",
    level: "block",
    category: ["block"],
    allowedChildren: ["inline"],
  },
  {
    name: "h4",
    level: "block",
    category: ["block"],
    allowedChildren: ["inline"],
  },
  {
    name: "h5",
    level: "block",
    category: ["block"],
    allowedChildren: ["inline"],
  },
  {
    name: "h6",
    level: "block",
    category: ["block"],
    allowedChildren: ["inline"],
  },
  {
    name: "hr",
    level: "block",
    category: ["block"],
    allowedChildren: [],
    help:
      "#### hr  \n" +
      "Adds a horizontal line which defines a thematic break and is used to separate content.",
  },
  {
    name: "dl",
    level: "block",
    category: ["block"],
    allowedChildren: ["dt", "dd"],
    help: "### description list  \nSee for example [HTML dl Tag](https://www.w3schools.com/tags/tag_dl.asp)",
  },
  {
    name: "dt",
    level: "block",
    category: ["block"],
    allowedParent: ["dl"],
    allowedSiblings: ["dd"],
    help: "### terms in description list  \nSee for example [HTML dt Tag](https://www.w3schools.com/tags/tag_dt.asp)",
  },
  {
    name: "dd",
    level: "block",
    category: ["block"],
    allowedParent: ["dl"],
    allowedSiblings: ["dt"],
    help: "### description in description list  \nSee for example [HTML dd Tag](https://www.w3schools.com/tags/tag_dd.asp)",
  },

  {
    name: "li",
    level: "block",
    category: ["block"],
    allowedSiblings: ["li"],
    allowedParent: ["ol", "menu", "ul"],
  },
  {
    name: "a",
    allowedChildren: [
      "img",
      "br",
      "div",
      "h1",
      "h2",
      "h3",
      "h4",
      "hr",
      "dl",
      "dt",
      "dd",
      "li",
      "ol",
      "p",
      "span",
      "svg",
      "table",
      "caption",
      "colgroup",
      "col",
      "caption",
      "thead",
      "tbody",
      "tfoot",
      "tr",
      "th",
      "td",
      "text",
      "ul",
      "article",
      "aside",
      "details",
      "figcaption",
      "figure",
      "footer",
      "header",
      "main",
      "nav",
      "section",
      "summary",
      "time",
      "audio",
      "video",
      "source",
      "b",
      "abbr",
      "address",
      "bdo",
      "blockquote",
      "cite",
      "q",
      "code",
      "pre",
      "samp",
      "var",
      "del",
      "em",
      "i",
      "ins",
      "mark",
      "small",
      "strong",
      "sub",
      "sup",
    ],
    level: "inline",
    category: ["inline"],
    fieldsOrder: ["href", "target"],
    fields: [
      {
        name: "href",
        type: EMetaFieldTypes.textarea,
        placeholder: true,
        onOff: true,
      },
      {
        name: "target",
        type: EMetaFieldTypes.select,
        options: [
          { id: "_blank" },
          { id: "_self" },
          { id: "_top" },
          { id: "_parent" },
        ],
        help:
          "### target  \n" +
          "Defines where to open the target page.  \n" +
          "`_blank`: new browser tab  \n" +
          "`_self` (default): current browser tab  \n" +
          "`_parent`: one level higher than the current `iframe`  \n" +
          "`_top`: top level, what is the document, in the current `iframe` hiearchy",
      },
    ],
    group: [
      {
        name: "address",
        fields: ["href"],
      },
      {
        name: "target",
        fields: ["target"],
      },
    ],

    default: {
      href: { value: "#home" },
      target: { value: "_blank" },
    },
  },
  {
    name: "ol",
    level: "block",
    category: ["block"],
    allowedChildren: ["li"],
  },

  {
    name: "p",
    level: "block",
    category: ["block"],
    allowedChildren: ["inline"],
  },
  {
    name: "progress",
    level: "inline",
    category: ["inline"],
    allowedChildren: [],
    fieldsOrder: ["value", "max"],
    fields: [
      {
        name: "value",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
      {
        name: "max",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
    ],
    default: {
      value: {
        value: 25,
      },
      max: {
        value: 100,
      },
    },
  },

  {
    name: "span",
    level: "inline",
    category: ["inline"],
  },
  {
    name: "svg",
    level: "inline",
    category: ["inline"],
    allowedChildren: [],
    fieldsOrder: ["htmlSvgCodeSnippetSvgId"],
    onOff: true,
    fields: [
      {
        name: "htmlSvgCodeSnippetSvgId",
        type: EMetaFieldTypes.selectContentSvgId,
        editInContentSnippet: true,
        help:
          "### Select SVG  \n" +
          "Click on the name of the SVG which you want to assign. If the SVG you are looking for is not in the list, go to the tab SVG and create it there.",
      },
    ],
    default: {
      htmlSvgCodeSnippetSvgId: {
        value: 0,
      },
    },
  },
  {
    name: "table",
    level: "block",
    category: ["table"],
    allowedChildren: ["thead", "tbody", "tfoot"],
    fieldsOrder: ["cellpadding", "cellspacing"],
    fields: [
      {
        name: "cellpadding",
        javascript: "cellPadding",
        type: EMetaFieldTypes.number,
      },
      {
        name: "cellspacing",
        javascript: "cellSpacing",
        type: EMetaFieldTypes.number,
      },
    ],
    group: [
      {
        name: "cellpadding",
        fields: ["cellpadding"],
        help:
          "### table  \n" +
          "See for example [w3schools - HTML Tables](https://www.w3schools.com/html/html_tables.asp)",
      },
      {
        name: "cellspacing",
        fields: ["cellspacing"],
      },
    ],

    default: {
      cellpadding: { value: 1 },
      cellspacing: { value: 1 },
    },
  },
  {
    name: "caption",
    level: "block",
    category: ["table"],
    allowedParent: ["table"],
    allowedChildren: ["text"],
    help: "### table caption  \nSee for example [w3schools - HTML caption Tag](https://www.w3schools.com/tags/tag_caption.asp#:~:text=The%20tag%20defines%20a,align%20and%20place%20the%20caption.)",
  },
  {
    name: "colgroup",
    level: "block",
    category: ["table"],
    allowedParent: ["table"],
    fieldsOrder: ["span"],
    fields: [{ name: "span", type: EMetaFieldTypes.number }],
    default: {
      span: { value: 2 },
    },
  },
  {
    name: "col",
    level: "inline",
    category: ["table"],
    allowedParent: ["colgroup"],
    allowedSiblings: ["col"],
    allowedChildren: [],
    fieldsOrder: ["span"],
    fields: [{ name: "span", type: EMetaFieldTypes.number }],
    default: {
      span: { value: 2 },
    },
  },
  {
    name: "thead",
    level: "block",
    category: ["table"],
    allowedChildren: ["tr"],
    allowedParent: ["table"],
    allowedSiblings: ["tbody", "tfoot"],
    conditions: { onlyOne: true },
  },

  {
    name: "tbody",
    level: "block",
    category: ["table"],
    allowedParent: ["table"],
    allowedChildren: ["tr"],
    allowedSiblings: ["thead", "tfoot"],
    conditions: { onlyOne: true },
  },
  {
    name: "tfoot",
    level: "block",
    category: ["table"],
    allowedChildren: ["tr"],
    allowedParent: ["table"],
    allowedSiblings: ["thead", "tbody"],
    conditions: { onlyOne: true },
  },
  {
    name: "tr",
    level: "block",
    category: ["table"],
    allowedChildren: ["td"],
    allowedSiblings: ["tr"],
    allowedParent: ["thead", "tbody", "tfoot"],
  },

  {
    name: "th",
    level: "block",
    category: ["table"],
    allowedChildren: ["inline", "table"],
    allowedSiblings: ["th"],
    allowedParent: ["tr"],
    fieldsOrder: ["colspan", "rowspan"],
    fields: fieldsAll.tableCellFields,
    group: fieldsAll.tableCellGroup,
    default: {
      colspan: { value: 2 },
      rowspan: { value: 2 },
    },
  },
  {
    name: "td",
    level: "block",
    category: ["table"],
    allowedChildren: ["inline", "table"],
    allowedSiblings: ["td"],
    allowedParent: ["tr"],
    fieldsOrder: ["colspan", "rowspan"],
    fields: fieldsAll.tableCellFields,
    group: fieldsAll.tableCellGroup,
    default: {
      colspan: { value: 2 },
      rowspan: { value: 2 },
    },
  },

  {
    name: "text",
    level: "inline",
    category: ["text"],
    allowedSiblings: ["inline"],
    allowedChildren: [],
    dontAssignClassesOrId: true,
    fieldsOrder: ["text", "markdown"],
    fields: [
      {
        name: "text",
        type: EMetaFieldTypes.textarea,
        onOff: true,
        placeholder: true,
      },
      {
        name: "markdown",
        type: EMetaFieldTypes.boolean,
        help: "### Markdown \nEnable to format your text with markdown.\n\n Please note that unformatted plain text is placed into a <p> element in markdown",
      },
    ],
    default: {
      text: { value: "TEXT" },
      markdown: { value: false },
    },
  },

  {
    name: "ul",
    level: "block",
    category: ["block"],
    allowedChildren: ["ol", "ul", "li"],
  },
  {
    name: "article",
    level: "block",
    category: ["semantic"],
    help: "### article  \nSpecifies independent, self-contained content.  \nSee for example [w3schools - article tag](https://www.w3schools.com/tags/tag_article.asp)",
  },
  {
    name: "aside",
    level: "block",
    category: ["semantic"],
    help: "### aside  \nRepresents a section of a page that consists of content which is related to the content around it.  \nSee for example [html5doctor - Understanding aside](http://html5doctor.com/understanding-aside/)",
  },
  {
    name: "details",
    level: "block",
    category: ["semantic"],
    fieldsOrder: ["open"],
    help: "### details  \nSpecify details that the user can open and close on demand.  \nSee for example [w3schools - details tag](https://www.w3schools.com/tags/tag_details.asp)",
    fields: [
      {
        name: "open",
        type: EMetaFieldTypes.boolean,
      },
    ],
    default: {
      open: {
        value: false,
      },
    },
  },
  {
    name: "figcaption",
    level: "block",
    category: ["semantic"],
    allowedParent: ["figure"],
    allowedSiblings: ["img"],
  },
  {
    name: "figure",
    level: "block",
    category: ["semantic"],
    allowedChildren: ["figcaption", "img"],
    help: "### figure  \nMarks a photo in a document.  \nSee for example [w3schools - figure tag](https://www.w3schools.com/tags/tag_figure.asp)",
  },
  {
    name: "footer",
    level: "block",
    category: ["semantic"],
    help: "### footer  \nA footer section in a document. See for example [w3schools - footer tag](https://www.w3schools.com/tags/tag_footer.asp)",
  },
  {
    name: "header",
    level: "block",
    category: ["semantic"],
    help: "### header  \nA header section. See for example [w3schools - header tag](https://www.w3schools.com/tags/tag_header.asp)",
  },
  {
    name: "main",
    level: "block",
    category: ["semantic"],
    help: "### main  \nSpecifies the main content of a document.  \nSee for example [w3schools - main tag](https://www.w3schools.com/tags/tag_main.asp)",
  },
  {
    name: "nav",
    level: "block",
    category: ["semantic"],
    help: "### nav  \nDefines a set of navigation links.  \nSee for example [w3schools - nav tag](https://www.w3schools.com/tags/tag_nav.asp)",
  },
  {
    name: "section",
    level: "block",
    category: ["semantic"],
  },

  {
    name: "summary",
    level: "block",
    category: ["semantic"],
    allowedParent: ["details"],
  },
  {
    name: "time",
    level: "block",
    category: ["semantic"],
    fieldsOrder: ["datetime"],
    fields: [{ name: "datetime", type: EMetaFieldTypes.string }],
    default: {
      datetime: {
        value: "2020-07-22 18:50",
      },
    },
    help: "### time  \nDefines a specific time.  \nSee for example [w3schools - time tag](https://www.w3schools.com/tags/tag_time.asp)",
  },
  {
    name: "audio",
    level: "block",
    category: ["media"],
    allowedChildren: ["source", "text"],
    fieldsOrder: ["controls", "autoplay", "loop", "muted", "preload", "src"],
    fields: fieldsAll.videoAudioFields,
    default: {
      controls: {
        value: true,
      },
      autoplay: {
        value: false,
      },
      loop: {
        value: false,
      },
      muted: {
        value: false,
      },
      preload: {
        value: "auto",
      },
      src: {
        value: "https://cssexy.s3.eu-west-2.amazonaws.com/something.mp3",
      },
    },
    help: "### audio  \nPlays an audio file.  \nSee for example [w3schools - audio tag](https://www.w3schools.com/tags/tag_audio.asp)",
  },
  {
    name: "video",
    level: "block",
    category: ["media"],
    allowedChildren: ["source", "text"],
    fieldsOrder: [
      "controls",
      "autoplay",
      "loop",
      "muted",
      "poster",
      "preload",
      "src",
    ],
    fields: fieldsAll.videoAudioFields,
    default: {
      controls: {
        value: true,
      },
      autoplay: {
        value: false,
      },
      loop: {
        value: false,
      },
      muted: {
        value: false,
      },
      poster: {
        value: "https://cssexy.s3.eu-west-2.amazonaws.com/hadrians_1.jpg",
      },
      preload: {
        value: "auto",
      },
      src: {
        value: "https://cssexy.s3.eu-west-2.amazonaws.com/something.mp4",
      },
    },
    help: "### video  \nPlays an video file.  \nSee for example [w3schools - video tag](https://www.w3schools.com/tags/tag_video.asp)",
  },
  {
    name: "source",
    level: "block",
    category: ["media"],
    allowedParent: ["audio", "video", "picture"],
    allowedSiblings: ["text"],
    allowedChildren: [],
    fieldsOrder: ["type", "src", "srcValue"],
    fields: [
      {
        name: "type",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          { id: "video/ogg" },
          { id: "video/mp4" },
          { id: "video/webm" },
          { id: "audio/ogg" },
          { id: "audio/mpeg" },
        ],
      },
      {
        name: "src",
        type: EMetaFieldTypes.select,
        options: [
          { id: "value" },
          { id: "https://cssexy.s3.eu-west-2.amazonaws.com/something.mp4" },
          { id: "https://cssexy.s3.eu-west-2.amazonaws.com/something.mp3" },
        ],
        onOff: true,
      },
      {
        name: "srcValue",
        type: EMetaFieldTypes.string,
        dependentOn: {
          field: "src",
          values: ["value"],
        },
        onOff: true,
      },
    ],
    default: {
      type: {
        value: "video/mp4",
      },
      src: {
        value: "value",
      },
      srcValue: {
        value: "https://cssexy.s3.eu-west-2.amazonaws.com/something.mp4",
      },
    },
  },

  {
    name: "b",
    level: "inline",
    category: ["textformatting"],
    allowedChildren: ["text"],
    help: "### bold  \nSee for example [w3schools - HTML Text Formatting](https://www.w3schools.com/html/html_formatting.asp)",
  },

  {
    name: "abbr",
    level: "inline",
    category: ["quotation"],
    allowedChildren: ["text"],
    help: "### HTML abbr Tag  \nSee for example [w3schools - HTML abbr Tag](https://www.w3schools.com/tags/tag_abbr.asp)",
  },
  {
    name: "address",
    level: "block",
    category: ["quotation"],
    help: "### address  \nSee for example [w3schools - HTML address Tag](https://www.w3schools.com/tags/tag_address.asp)",
  },

  {
    name: "bdo",
    level: "inline",
    category: ["quotation"],
    allowedChildren: ["text"],
    fieldsOrder: ["dir"],
    fields: [
      {
        name: "dir",
        type: EMetaFieldTypes.select,
        options: [{ id: "ltr" }, { id: "rtl" }],
        onOff: true,
      },
    ],
    default: {
      dir: { value: "ltr" },
    },
  },
  {
    name: "blockquote",
    level: "block",
    category: ["quotation"],
    fieldsOrder: ["cite"],
    fields: [{ name: "cite", type: EMetaFieldTypes.string }],
    default: {
      cite: {
        value: "http://www.worldwildlife.org/who/index.html",
      },
    },
    help: "### blockquote  \nSee for example [HTML blockquote Tag](https://www.w3schools.com/tags/tag_blockquote.asp)",
  },
  {
    name: "cite",
    level: "inline",
    category: ["quotation"],
  },
  {
    name: "q",
    level: "inline",
    category: ["quotation"],
    fieldsOrder: ["cite"],
    fields: [{ name: "cite", type: EMetaFieldTypes.string }],
    default: {
      cite: {
        value: "http://www.worldwildlife.org/who/index.html",
      },
    },
  },

  {
    name: "code",
    level: "inline",
    category: ["computercode"],
    help: "### code  \nSee for example [w3schools - HTML Computer Code Elements](https://www.w3schools.com/html/html_computercode_elements.asp#:~:text=HTML%20For%20Computer%20Code,the%20browser's%20default%20monospace%20font.)",
  },
  {
    name: "pre",
    level: "block",
    category: ["computercode"],
    help: "### code  \nSee for example [w3schools - HTML Computer Code Elements](https://www.w3schools.com/html/html_computercode_elements.asp#:~:text=HTML%20For%20Computer%20Code,the%20browser's%20default%20monospace%20font.)",
  },
  {
    name: "samp",
    level: "inline",
    category: ["computercode"],
    help: "### samp  \nSee for example [w3schools - HTML samp Tag](https://www.w3schools.com/tags/tag_samp.asp)",
  },
  {
    name: "var",
    level: "inline",
    category: ["computercode"],
    help: "### var  \nSee for example [w3schools - HTML var Tag](https://www.w3schools.com/tags/tag_var.asp)",
  },

  {
    name: "del",
    level: "inline",
    category: ["textformatting"],
    allowedChildren: ["text"],
    help: "### deleted  \nSee for example [w3schools - HTML Text Formatting](https://www.w3schools.com/html/html_formatting.asp)",
  },
  {
    name: "em",
    level: "inline",
    category: ["textformatting"],
    allowedChildren: ["text"],
    help: "### emphasized  \nSee for example [w3schools - HTML Text Formatting](https://www.w3schools.com/html/html_formatting.asp)",
  },

  {
    name: "i",
    level: "inline",
    category: ["textformatting"],
    allowedChildren: ["text"],
    help: "### italic  \nSee for example [w3schools - HTML Text Formatting](https://www.w3schools.com/html/html_formatting.asp)",
  },

  {
    name: "ins",
    level: "inline",
    category: ["textformatting"],
    allowedChildren: ["text"],
    help: "### inserted  \nSee for example [w3schools - HTML Text Formatting](https://www.w3schools.com/html/html_formatting.asp)",
  },

  {
    name: "mark",
    level: "inline",
    category: ["textformatting"],
    allowedChildren: ["text"],
    help: "### marked  \nSee for example [w3schools - HTML Text Formatting](https://www.w3schools.com/html/html_formatting.asp)",
  },
  {
    name: "small",
    level: "inline",
    category: ["textformatting"],
    allowedChildren: ["text"],
    help: "### small  \nSee for example [w3schools - HTML Text Formatting](https://www.w3schools.com/html/html_formatting.asp)",
  },
  {
    name: "strong",
    level: "inline",
    category: ["textformatting"],
    allowedChildren: ["text"],
    help: "### strong  \nSee for example [w3schools - HTML Text Formatting](https://www.w3schools.com/html/html_formatting.asp)",
  },

  {
    name: "sub",
    level: "inline",
    category: ["textformatting"],
    allowedChildren: ["text"],
    help: "### subscript  \nSee for example [w3schools - HTML Text Formatting](https://www.w3schools.com/html/html_formatting.asp)",
  },
  {
    name: "sup",
    level: "inline",
    category: ["textformatting"],
    allowedChildren: ["text"],
    help: "### superscript  \nSee for example [w3schools - HTML Text Formatting](https://www.w3schools.com/html/html_formatting.asp)",
  },

  {
    name: "iframe",
    level: "block",
    category: ["iframe"],
    help: "### iframe  \nSee for example [w3schools - HTML iframe Tag](https://www.w3schools.com/tags/tag_iframe.asp)",
  },
];

export const htmlNames = HTMLS.map((html) => html.name);

export const HTML_CATEGORIES: {
  [key: string]: { order?: string[] };
} = {
  text: {},
  block: {
    order: [
      "div",
      "p",
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "ul",
      "ol",
      "li",
      "hr",
      "dl",
      "dt",
      "dd",
    ],
  },
  inline: { order: ["a", "span", "img", "svg", "br", "progress"] },
  FORM: {},
  textformatting: {},
  quotation: {},
  computercode: {},
  form: {},
  table: {
    order: [
      "table",
      "th",
      "tr",
      "td",
      "caption",
      "colgroup",
      "col",
      "thead",
      "tbody",
      "tfoot",
    ],
  },
  semantic: {},
  media: {},
};

export const getHtmlCategoryMetas = (category: string) => {
  return getCategoryMetas({
    category,
    categories: HTML_CATEGORIES,
    metas: HTMLS,
  });
};
