import { getServerSnippet } from "./getSnippet";
import { createServerSnippet } from "../snippets/createSnippet";
import { updateServerSnippet } from "../snippets/updateSnippet";
import { snippetIF } from "../../data/types/snippets";

export const syncSnippet = async ({
  snippet,
  userId,
}: {
  snippet: snippetIF;
  userId: string;
}) => {
  const editSnippet = {
    ...snippet,
    owner: userId,
  };

  try {
    if (!editSnippet.id) {
      return;
    }
    const serverSnippet = await getServerSnippet(editSnippet.id);
    if (serverSnippet?.id) {
      const updatedSnippet = await updateServerSnippet({ editSnippet });
      if (updatedSnippet) {
        return {
          ...editSnippet,
          updatedAt: updatedSnippet.updatedAt,
        };
      }
      return;
    }

    const response = await createServerSnippet({ editSnippet });

    if (response) {
      const newSnippet = {
        ...editSnippet,
        id: response.id,
        createdAt: response.createdAt,
        updatedAt: response.updatedAt,
      };
      return newSnippet;
    }
  } catch (e) {
    console.error((e as Error).message);
  }
};
