import { FC } from "react";
import { itemIF, groupIF, EMetaFieldTypes } from "../../data/types/item";
import {
  getAllAssignedIdsInSnippet,
  getAssignedIdFieldType,
} from "../../lib/items/getIds";
import { getMetaFromSnippet } from "../../lib/snippets/addAndGetMetas";
import { AssignId } from "./AssignId";
import { Tooltip } from "../atoms/tooltip";
import { snippetIF } from "../../data/types/snippets";
import { itemTypes } from "../../lib/meta/getMetas";
import { CssexyWrapperForColorPicker } from "./ColourPickerCssexy";
import { EditFieldCheckbox } from "./Checkbox";
import { EditFieldTextarea } from "./Textarea";
import { NumberInput } from "./NumberField";
import { StringInput } from "./StringField";
import { EditFieldSelect } from "./Select";
import { UploadInput } from "./UploadInput";

export const RenderGroupFieldsOnly: FC<{
  itemType: itemTypes;
  group: groupIF;
  dependendOnDisabledFields: string[];
  isGroupDisabled?: boolean;
  editItem: itemIF;
  tooltipText?: string;
  snippet: snippetIF;
  addOrRemoveEditItemId?: number;
  fieldGroupName?: string;
  editSnippetIdOther?: string;
}> = ({
  itemType,
  group,
  dependendOnDisabledFields,
  isGroupDisabled,
  editItem,
  tooltipText,
  snippet,
  addOrRemoveEditItemId,
  fieldGroupName,
  editSnippetIdOther
}) => {

  const meta = getMetaFromSnippet({
    snippet,
    itemType,
    itemName: editItem.name,
  });

  return (
    <>
      {group.fields.map((fieldName, fieldIndex) => {
        if (dependendOnDisabledFields.includes(fieldName)) {
          return null;
        }
        const fieldMeta = meta?.fields?.find((f) => f?.name === fieldName);
        if (!fieldMeta) {
          return null;
        }


        const displayName = group.display?.[fieldIndex] || fieldMeta.name;

        if (
          fieldMeta.type === EMetaFieldTypes.selectKeyframeName ||
          fieldMeta.type === EMetaFieldTypes.assignSvgFilterId ||
          fieldMeta.type === EMetaFieldTypes.assignSvgGradientId ||
          fieldMeta.type === EMetaFieldTypes.selectPathId
        ) {
          const assignedFieldType = getAssignedIdFieldType(fieldMeta.type);
          const assignedIds = getAllAssignedIdsInSnippet({
            snippet,
            editItemId: editItem.id,
            isSpecialFieldType: assignedFieldType,
          });

          return (
            <label
              className={`label${isGroupDisabled ? " disabled" : ""}`}
              key={fieldIndex}
            >
              <span>{displayName}</span>
              <AssignId
                key={fieldName}
                itemType={itemType}
                fieldName={fieldName}
                fieldGroupName={fieldGroupName!}
                editItem={editItem}
                isSpecialFieldType={assignedFieldType}
                assignedIds={assignedIds}
                isFieldDisabled={isGroupDisabled}
                editSnippetIdOther={editSnippetIdOther!}
                addOrRemoveEditItemId={addOrRemoveEditItemId!}
              />
            </label>
          );
        }

        return (
          <label className="label" key={fieldIndex}>
            <span>{displayName}</span>
    <>
      {fieldMeta.type === EMetaFieldTypes.readonly && <div>{editItem.value?.[fieldName]?.value}</div>}

      {fieldMeta.type === EMetaFieldTypes.boolean && (
        <EditFieldCheckbox
          itemType={itemType}
          fieldName={fieldName}
          isFieldDisabled={isGroupDisabled}
          editItem={editItem}
          snippet={snippet}
          addOrRemoveEditItemId={addOrRemoveEditItemId}
          fieldGroupName={fieldGroupName}
          editSnippetIdOther={editSnippetIdOther}
        />
      )}
      {fieldMeta.type === EMetaFieldTypes.colourPicker && (
        <CssexyWrapperForColorPicker
          itemType={itemType}
          fieldName={fieldName}
          item={editItem}
          snippet={snippet}
          isFieldDisabled={isGroupDisabled}
          addOrRemoveEditItemId={addOrRemoveEditItemId}
          fieldGroupName={fieldGroupName}
          editSnippetIdOther={editSnippetIdOther}
        />
      )}
      {(fieldMeta.type === EMetaFieldTypes.select ||
        fieldMeta.type === EMetaFieldTypes.propertyOfClassAndAll) && (
        <EditFieldSelect
          itemType={itemType}
          fieldName={fieldName}
          isFieldDisabled={isGroupDisabled}
          editItem={editItem}
          snippet={snippet}
          addOrRemoveEditItemId={addOrRemoveEditItemId}
          fieldGroupName={fieldGroupName}
          editSnippetIdOther={editSnippetIdOther}
        />
      )}
      {fieldMeta.type === EMetaFieldTypes.number && (
        <NumberInput
          itemType={itemType}
          fieldName={fieldName}
          isFieldDisabled={isGroupDisabled}
          editItem={editItem}
          addOrRemoveEditItemId={addOrRemoveEditItemId}
          fieldGroupName={fieldGroupName}
          editSnippetIdOther={editSnippetIdOther}
          snippet={snippet}
        />
      )}
      {fieldMeta.type === EMetaFieldTypes.string && (
        <StringInput
          itemType={itemType}
          fieldMeta={fieldMeta}
          isFieldDisabled={isGroupDisabled}
          addOrRemoveEditItemId={addOrRemoveEditItemId}
          editItem={editItem}
          fieldGroupName={fieldGroupName}
          editSnippetIdOther={editSnippetIdOther}
        />
      )}
      {fieldMeta.type === EMetaFieldTypes.textarea && (
        <EditFieldTextarea
          itemType={itemType}
          fieldName={fieldName}
          editItem={editItem}
          isFieldDisabled={isGroupDisabled}
          addOrRemoveEditItemId={addOrRemoveEditItemId}
          fieldGroupName={fieldGroupName}
          editSnippetIdOther={editSnippetIdOther}
        />
      )}
      {fieldMeta.type === EMetaFieldTypes.upload && (
        <UploadInput isFieldDisabled={isGroupDisabled} />
      )}
    </>
            {tooltipText && <Tooltip tooltipText={tooltipText} />}
          </label>
        );
      })}
    </>
  );
};
