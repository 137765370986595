import { FC } from "react";
import { ReactComponent as Add } from "../../assets/imgs/add.svg";
import { useStateContext } from "../../lib/state/state";
import { itemTypes } from "../../lib/meta/getMetas";
import { addFieldGroup } from "../../lib/reducer/items/actions";
import {
  EMetaFieldTypes,
  groupIF,
  itemIF,
} from "../../data/types/item";
import { snippetIF } from "../../data/types/snippets";
import { EditItem } from "./EditItem";
import { RenderGroupFieldsOnly } from "./RenderGroupFieldsOnly";
import { removeFieldGroup } from "../../lib/reducer/items/actions";
import { ReactComponent as Delete } from "../../assets/imgs/delete.svg";
import { getMetaFromSnippet } from "../../lib/snippets/addAndGetMetas";

export const AddOrRemoveFieldGroupAddButton: FC<{
  itemType: itemTypes;
  firstFieldInGroupName: string;
  isItemDisabled?: boolean;
  editItemId: number;
  editSnippetIdOther?: string;
}> = ({
  itemType,
  isItemDisabled,
  firstFieldInGroupName,
  editItemId,
  editSnippetIdOther,
}) => {
  const { dispatch } = useStateContext();

  return (
    <div className="flex align-center">
      <label className="label">
        <span className="mr-1">Add</span>
        <button
          type="button"
          className={`button ${isItemDisabled ? "disabled" : "blue-outline"}`}
          onClick={() => {
            if (!isItemDisabled) {
              dispatch(
                addFieldGroup({
                  fieldName: firstFieldInGroupName,
                  itemType,
                  editItemId,
                  editSnippetId: editSnippetIdOther,
                })
              );
            }
          }}
        >
          <span className="sr-only">Add</span>
          <Add />
        </button>
      </label>
    </div>
  );
};

export const AddOrRemoveFieldGroup: FC<{
  itemType: itemTypes;
  group: groupIF;
  firstFieldInGroupName: string;
  editItem: itemIF;
  editSnippetIdOther?: string;
  snippet: snippetIF;
  isAddOrRemoveItem?: boolean;
  dependendOnDisabledFields: string[];
  isItemDisabled?: boolean;
  addOrRemoveEditItemId?: number;
}> = ({
  itemType,
  firstFieldInGroupName,
  group,
  editItem,
  editSnippetIdOther,
  snippet,
  isAddOrRemoveItem,
  dependendOnDisabledFields,
  isItemDisabled,
  addOrRemoveEditItemId,
}) => {
  const { dispatch } = useStateContext();

  const meta = getMetaFromSnippet({
    snippet,
    itemType,
    itemName: editItem.name,
  })
  const fieldMeta = meta?.fields?.find(f => f?.name === firstFieldInGroupName);
  if(!fieldMeta) return null;

  return (
    <>
      {fieldMeta.type === EMetaFieldTypes.addOrRemoveFieldGroup && (
        <>
          <div className="flex align-center">
            <AddOrRemoveFieldGroupAddButton
              itemType={itemType}
              firstFieldInGroupName={firstFieldInGroupName}
              isItemDisabled={isItemDisabled}
              editItemId={editItem.id}
              editSnippetIdOther={editSnippetIdOther}
            />
          </div>
          <div className="w-100p">
            {editItem.value?.[firstFieldInGroupName]?.value &&
              (editItem.value?.[firstFieldInGroupName]?.value as itemIF[]).map(
                (item, index) => {
                  return (
                    <EditItem
                      key={index}
                      itemType={itemType}
                      editItem={item}
                      snippet={snippet}
                      isItemDisabled={isItemDisabled}
                      addOrRemoveEditItemId={editItem.id}
                      editSnippetIdOther={editSnippetIdOther}
                    />
                  );
                }
              )}
          </div>
        </>
      )}

      {fieldMeta.type !== EMetaFieldTypes.addOrRemoveFieldGroup &&
        isAddOrRemoveItem && (
          <div className="flex justify-space-between gap-2 align-center add-or-remove-field-group-wrapper w-100p">
            <div className="flex gap-1 wrap">
              <RenderGroupFieldsOnly
                itemType={itemType}
                group={group}
                dependendOnDisabledFields={dependendOnDisabledFields}
                isGroupDisabled={isItemDisabled}
                editItem={editItem}
                snippet={snippet}
                addOrRemoveEditItemId={editItem.id}
                editSnippetIdOther={editSnippetIdOther}
                fieldGroupName={editItem.name}
              />
            </div>

            <button
              type="button"
              className={`button orange icon`}
              onClick={() => {
                if (isItemDisabled) return;
                dispatch(
                  removeFieldGroup({
                    fieldName: editItem.name,
                    fieldGroupId: editItem.id,
                    itemType,
                    editItemId: addOrRemoveEditItemId,
                    editSnippetId:
                      itemType === itemTypes.STYLEPACK
                        ? editSnippetIdOther
                        : undefined,
                  })
                );
              }}
            >
              <span className="sr-only">Remove field group</span>
              <Delete />
            </button>
          </div>
        )}
    </>
  );
};