import { itemIF } from "../../data/types/item";
import { snippetIF } from "../../data/types/snippets";
import { itemTypes } from "../meta/getMetas";
import { getFontFamilyCssName } from "./getItemValues_helper";

export const getItemValue_replaceValueForFontFamilyImportant = ({
  itemType,
  attr,
  item,
  snippet,
}: {
  itemType: itemTypes;
  attr: Record<string, string>;
  item: itemIF;
  snippet: snippetIF;
}) => {
  if(itemType !== itemTypes.CLASSES_CSS) {
    return;
  }

  const fieldNames = Object.keys(attr);
  for (const fieldName of fieldNames) {
    if (item.name === "font-family" && fieldName === "main") {
      const fontFamilyCssName = getFontFamilyCssName({
        item,
        snippet,
      });
      if (fontFamilyCssName) {
        attr.main = fontFamilyCssName;
      }
    }

    if(fieldName === "important" && attr.important !== "") {
      attr.important = "!important";
    }
  }
};
